

import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import * as Models from '../../../_shared/models/models-index';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'sd-bar-chart-card',
  templateUrl: './bar-chart-card.component.html',
  styleUrls: ['./bar-chart-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BarChartCardComponent implements OnInit {
    @Input() currentCard: Observable<Models.IBarChartCard>;
    currentCard$: Observable<any>;
    chartSettings: Models.ISingleAxisTrendChartSettings;

    constructor( ) { }
    ngOnInit() {
      this.currentCard$ =
        this.currentCard.pipe(
          map(update => update));
    }
}

