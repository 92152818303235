import { createSelector, createFeatureSelector } from '@ngrx/store';
import { FilterState, ReportFilters, ReportViewFilters } from './filter-state.model';
import { AppSelectors } from '../../../_store/selector-types';
import { AppState } from '../../../_store/app-state.model';
import { Filter } from '../filter.model';

const getReportFilters = (reportFilters: ReportFilters[], reportName: string) => {
  if (!reportFilters) {
    return undefined;
  }
  const reportFilter = reportFilters.find(report => report.reportName === reportName);

  return reportFilter ? reportFilter.filters : undefined;
};

export const selectFilterState =
  createFeatureSelector<FilterState>('filters');

/* Filter Selectors */
export const selectLockedFilters = createSelector(
  selectFilterState,
  (filterState) => filterState.lockedFilters
);

export const selectReportFilters = createSelector(
  selectFilterState,
  (filterState: FilterState, reportName: string) => getReportFilters(filterState.filters, reportName)
);

export const selectCurrentReportFilters = createSelector(
  selectFilterState,
  AppSelectors.selectCurrentRouteData,
  (filterState, routeData) => filterState.filters.find(report => report.reportName === routeData.reportName)
);

export const selectCurrentReportDateFilter = createSelector(
  selectCurrentReportFilters,
  selectLockedFilters,
  (currentReportFilters, lockedFilters) => {
    let dateFilter = lockedFilters ? lockedFilters.find(f => f.type === 'date') : null;
    const reportName = currentReportFilters ? currentReportFilters.reportName : null;

    if (!dateFilter) {
      dateFilter = currentReportFilters ? currentReportFilters.filters.find(f => f.type === 'date') : null;
    }

    return {
      reportName: reportName,
      dateFilter
    };
  }
);

export const selectOptions = createSelector(
  selectFilterState,
  filterState => filterState.options
);

export const selectReportViewFilterConfigurations = createSelector(
  selectFilterState,
  filterState => filterState.reportViewFilterConfigurations
);

export const selectReportViewFilterConfiguration = (props: { reportName: string }) =>
  createSelector(
  selectReportViewFilterConfigurations,
  (filterConfigurations) => filterConfigurations.find(config => config.reportName === props.reportName)
);

export const selectReportViewFiltersForReport = (props: { reportName: string }) =>
  createSelector(
    selectFilterState,
    selectReportViewFilterConfiguration({ reportName: props.reportName }),
    (state, config) => {
      return state.reportViewFilters.filter(filter => config.filterNames.includes(filter.name));
    }
  );
export const selectReportViewFilters = createSelector(
  selectFilterState,
  (filterState) => filterState.reportViewFilters
);

export const selectReportViewFiltersByNames = (props: { filterNames: string[] }) =>
  createSelector(
  selectFilterState,
  (filterState) => filterState.reportViewFilters.filter(filter => props.filterNames.includes(filter.name))
);

export const selectReportViewFilterSelections =  (props: { reportName: string }) => 
  createSelector(
  selectFilterState,
  (filterState) => !!filterState.reportViewFilterSelections 
    ? filterState.reportViewFilterSelections.find(report => report.reportName === props.reportName)?.selections ?? []
    : []
);

export const selectReportViewFilterSelection = (props: { reportName: string, filterName: string }) =>
  createSelector(
  selectReportViewFilterSelections({ reportName: props.reportName }),
  (selections) => selections.find(selection => selection.name === props.filterName)
);
