<div class="tags-container">
    <div *ngFor="let tag of tags" class="tag">
        {{ tag }}
        <button class="remove-tag" mat-icon-button (click)="removeTag(tag)" type="button">×</button>
    </div>
    <input #inputElement [formControl]="tagInput" [matAutocomplete]="auto" matAutocompleteTrigger
        [matAutocompleteDisabled]="false" (keyup.enter)="addTag()" (keydown.enter)="$event.preventDefault();"
        *ngIf="showInput">
    <button mat-raised-button [ngClass]="showInput ? 'icon-mode' : 'text-mode'" (click)="toggleInput()" type="button">
        {{ showInput ? '×' : 'Add Tag' }}
    </button>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onTagSelected($event)">
        <mat-option *ngFor="let suggestion of filteredSuggestions | async" [value]="suggestion">
            {{ suggestion }}
        </mat-option>
    </mat-autocomplete>
</div>