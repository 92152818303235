import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as SvgResources from '../lib/svg.resources';
import { HyFilterPanel } from '../types/filter-panel';
import * as FilterPanelConstants from '../types/constants';
import * as FilterPanelTypes from '../types/types';
import { IFacetedFilters, IFacetedFilterSelection } from '../types/faceted-filter.types';
import { Store } from '@ngrx/store';
import { FilterReturnEventType } from '../types/constants';
import { of } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { compareSelections, FilterWellFacade } from './filter-well.facade';

@Component({
	selector: 'cdui-filter-well',
	templateUrl: './filter-well.component.html',
	styleUrls: ['./filter-well.component.scss'],
})
export class FilterWellComponent implements OnInit, OnDestroy {
	private _initializing: boolean;
	@Input() set initializing(value: boolean) {
		this._initializing = value;
	}
	get initializing(): boolean {
		return this._initializing;
	}

	@Output('onFilterChange') onFilterChange = new EventEmitter<IFacetedFilters>();
	@Output('onFilterReset') onFilterReset = new EventEmitter<IFacetedFilters>();
	@Output('onToolbarInitialized') onToolbarInitialized = new EventEmitter<IFacetedFilters>();

	@ViewChild('mobileAnchor') mobileAnchor: ElementRef;

	svgIcons = SvgResources.svgIcons;
	svgIconTypes = SvgResources.svgIconTypes;
	filterIcon = SvgResources.sizableSvgIcon(this.svgIconTypes.filter, 6);
	addIcon = SvgResources.sizableSvgIcon(this.svgIconTypes.plus, 4);
	downIcon = SvgResources.sizableSvgIcon(this.svgIconTypes.chevronDown, 4);
	closeIcon = SvgResources.sizableSvgIcon(this.svgIconTypes.close, 4);

	selectedFilterItems: IFacetedFilterSelection[] = [];
	filters: IFacetedFilters = {} as IFacetedFilters;
	defaults: IFacetedFilterSelection[] = [];
	filterInputData: FilterPanelTypes.IPanelInputData;

	filtersDirty: boolean = false;
	mobilePanelOpen: boolean = false;
	mobileResultPanelOpen: boolean = false;
	noData: boolean = true;

	constructor(
		private panelRef: HyFilterPanel,
		// private globalStore: Store<ICoreAppState>,
		private facade: FilterWellFacade,
		// private appInsights: AppInsightsService,
	) {}

	ngOnInit(): void {
    //console.log('FilterWellComponent OnInit');
		this.facade.getData();

		this.facade.viewModel$.pipe(
			skipWhile(c => !Object.keys(c).length),
			take(1),
		).subscribe(({ filterData, defaultFilters, cachedFilters, urlFilters, config, noData }) => {
      //console.log('facade viewModel$ event filterData', filterData);
			if (!noData) {
				this.filters = {
					contextName: config.context,
					filters: []
				};
				this.noData = false;
				this.filterInputData = { inputData: filterData.items };
				this.defaults = [...defaultFilters];
				const nonDisregardedDefaults = this.facade.getNondisregardedDefaults(this.defaults);
				this.selectedFilterItems = urlFilters.length ? urlFilters : [...nonDisregardedDefaults, ...cachedFilters];
				this.filtersDirty = cachedFilters?.length > 0 || (this.defaults?.length !== nonDisregardedDefaults?.length);
				this.returnResult(FilterReturnEventType.Initializing);
			} else
				this.initWithNoData();

		})
	}

	ngOnDestroy(): void {
		this.facade.clearData();
	}

	filterItemSelected(item: IFacetedFilterSelection, chipElement: ElementRef): void {
		this.showPanel(chipElement, item);
	}

	mobileFilterItemSelected(item: IFacetedFilterSelection, chipElement: ElementRef): void {
		if (this.mobileResultPanelOpen)
			this.closeMobileResultPanel();

		this.mobilePanelOpen = true;
		this.showPanel(chipElement, item);
	}

	removeFilterItem(item: IFacetedFilterSelection): void {
		// if (this.selectedFilterItems.length === 1) {
		// 	this.selectedFilterItems = [];
		// 	this.filtersDirty = false;
		// } else {
		// 	const idx = this.selectedFilterItems.findIndex(fi => fi.path === item.path);
		// 	this.selectedFilterItems.splice(idx, 1);
		// }

		const idx = this.selectedFilterItems.findIndex(fi => fi.path === item.path);
		this.selectedFilterItems.splice(idx, 1);

		this.filtersDirty = !(this.selectedFilterItems.length && this.defaults.length);

		if (this.mobileResultPanelOpen && !this.selectedFilterItems.length)
			this.closeMobileResultPanel();

		if (this.defaults.findIndex(d => d.path === item.path) > -1)
			this.facade.setDefaultAsDisregarded(item);

		this.returnResult();
	}

	newFilterClicked(el: ElementRef): void {
		if (this._initializing || this.noData)
			return;

		this.showPanel(el);
	}

	resetFilters(): void {
		this.selectedFilterItems = [...this.defaults];
		this.returnResult(FilterReturnEventType.Resetting);
		this.filtersDirty = false;
		this.facade.clearState();

		if (this.mobileResultPanelOpen && !this.selectedFilterItems.length)
			this.closeMobileResultPanel();
	}

	showMobilePanel(): void {
		if (this.selectedFilterItems.length) {
			this.openMobileResultPanel();
		} else {
			this.openMobileFilterPanel();
		}
	}

	openMobileResultPanel(): void {
		this.mobileResultPanelOpen = true;
	}

	closeMobileResultPanel(): void {
		this.mobileResultPanelOpen = false;
	}

	openMobileFilterPanel(): void {
		this.closeMobileResultPanel();
		this.showPanel(this.mobileAnchor);
		this.mobilePanelOpen = true;
	}

	closeMobileFilterPanel(): void {
		this.mobilePanelOpen = false;
	}

	private showPanel(origin: ElementRef, selectedItem?: IFacetedFilterSelection): void {
		const panelConfig: FilterPanelTypes.IFilterPanelConfig = {
			panelTitle: 'Filter',
			panelClass: 'lrgPanel',
			category: 'IsTheConfigRight',
			type: 'faceted_filter',
			target: 'panel',
			inputData: of(this.filterInputData),
			query: null,
			queryTarget: null,
			singleSelectionMode: false,
		};

		if (selectedItem)
			panelConfig.inputFacetData = selectedItem;

		const panelParams: FilterPanelTypes.FilterPanelParams = {
			originElement: origin,
			inputDataType: 'faceted_filter',
			config: panelConfig
		};

		const panelRef = this.panelRef.open(panelParams);
		panelRef.afterClosed$.subscribe((result) => {
			if (result.dialogData.resultType === FilterPanelConstants.FilterPanelCloseType.applied) {
				const selected: IFacetedFilterSelection = <IFacetedFilterSelection>result.dialogData.data[0];
				if (selected) {
					if (selectedItem)
						this.updateSelection(selectedItem, selected);
					else
						this.addIfNotExist(selected);
				}
				if (this.mobilePanelOpen)
					this.closeMobileFilterPanel();
			}
		})
	}

	private updateSelection(currentItem: IFacetedFilterSelection, updatedItem: IFacetedFilterSelection): void {
		let currentIdx: number = this.selectedFilterItems.findIndex(sfi => compareSelections(sfi, currentItem));

		if (currentIdx >= 0) {
			this.selectedFilterItems[currentIdx] = { ...updatedItem };
			this.filtersDirty = true;
		}

		if (!this.facade.isDisregardedDefault(currentItem))
			this.facade.setDefaultAsDisregarded(currentItem);

		this.returnResult();
	}

	private addIfNotExist(newItem: IFacetedFilterSelection): void {
		// TODO: Need to add compare method for this
		const selection: IFacetedFilterSelection = this.selectedFilterItems.find(s => compareSelections(s, newItem));

		if (!selection) {
			this.selectedFilterItems.push(newItem);
			this.filtersDirty = true;
			this.returnResult();
		} else
      console.error('Selection already exists');
			// this.globalStore.dispatch(AppCoreActions.notify({ notificationType: NotificationType.warn, message: 'Selection already exists' }));
	}

	private returnResult(eventType?: FilterReturnEventType): void {
		//console.log('FILTERWELL|returnResult|this.selectedFilterItems', this.selectedFilterItems);
		this.filters.filters = [...this.selectedFilterItems.map(sfi => sfi.payload)];
		switch (eventType) {
			case FilterReturnEventType.Initializing:
				this.onToolbarInitialized.emit(this.filters);
				this._initializing = false;
				break;
			case FilterReturnEventType.Resetting:
				this.onFilterReset.emit(this.filters);
				// this.appInsights.trackEvent('filterReset', {
				// 	filters: this.filters.filters, // #TODO - format these nicely; maybe use the same format we show in the mat-chips
				// 	context: this.appConfigContext,
				// });
				break;
			default:
				this.onFilterChange.emit(this.filters);
				// this.appInsights.trackEvent('filterChange', {
				// 	filters: this.filters.filters, // #TODO - format these nicely; maybe use the same format we show in the mat-chips
				// 	context: this.appConfigContext,
				// });
		}

		this.facade.setState(this.selectedFilterItems);
	}

	private initWithNoData(): void {
		this.filters.filters = [];
		this.onToolbarInitialized.emit(this.filters);

	}
}
