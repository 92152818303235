import { ComponentFactoryResolver, ComponentRef, ViewContainerRef } from "@angular/core";
import { DateRangePaneComponent } from "../components/faceted-filter/panes/date-range-pane/date-range-pane.component";
import { FilterPaneComponent } from "../components/faceted-filter/panes/filter-pane/filter-pane.component";
import { HierarchyPaneComponent } from "../components/faceted-filter/panes/hierarchy-pane/hierarchy-pane.component";
import { OperatorPaneComponent } from "../components/faceted-filter/panes/operator-pane/operator-pane.component";
import { IFacetedFilterDataItem, IReplayItem } from "../types";
import { FilterUtils } from "./filter.utils";


export class PaneComponentFactory {
    private _host: ViewContainerRef;
    private _resolver: ComponentFactoryResolver;

    public readonly FacetedFilterPaneTypeMap = {
        container               : FilterPaneComponent,
        dateRangePicker         : DateRangePaneComponent,
        operator                : OperatorPaneComponent,
        hierarchyFacetContainer : HierarchyPaneComponent,
        commonStringOperator    : OperatorPaneComponent,
        percentOperator         : OperatorPaneComponent,
        numericOperator         : OperatorPaneComponent,
        numberOperator          : OperatorPaneComponent,
    };

    constructor(host: ViewContainerRef, resolver: ComponentFactoryResolver) {
        this._host = host;
        this._resolver = resolver;
    }

    public loadComponentInstance(item: IFacetedFilterDataItem): ComponentRef<any> {
        const componentType = this.getComponentType(item.type);
        const componentInstance = this.getComponentRef(componentType);
        componentInstance.instance.item = item;

        return componentInstance;
    }

    public loadReplayComponentInstance(replayItem: IReplayItem): ComponentRef<any> {
        const componentType = this.getComponentType(replayItem.item.type);
        const componentInstance = this.getComponentRef(componentType);
        componentInstance.instance.replayItem = replayItem;
        componentInstance.instance.replayValue = replayItem.value;

        return componentInstance;
    }


    private getComponentType(attribute: string): string {
        return FilterUtils.kebabToCamelCase(attribute);
    }

    private getComponentRef(type: string): ComponentRef<any> {
        const componentToLoad = this.FacetedFilterPaneTypeMap[type];
        const factory = this._resolver.resolveComponentFactory(componentToLoad);
        const instance: ComponentRef<typeof componentToLoad> = this._host.createComponent(factory);

        return instance;
    }
}
