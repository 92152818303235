import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import * as KpiSheetModels from './kpi-sheet.models';
import { Subscription } from 'rxjs';
import * as SharedServices from '../../../services/services-index';
import * as Models from '../../../models/models-index';

@Component({
  selector: 'kpi-sheet',
  templateUrl: './kpi-sheet.component.html',
  styleUrls: ['./kpi-sheet.component.scss']
})
export class KpiSheetComponent implements OnInit {
  @Input() dataSet: Models.DataSet;
  @Input() settings: Models.ElementSettings;
  @Input() locale: string;
  columns: KpiSheetModels.KpiSheetColumn[];
  metricColumns: KpiSheetModels.KpiSheetColumn[];
  rows: KpiSheetModels.KpiSheetRow[] = [];
  subscriptions: Subscription[] = [];
  filterBreadcrumbsForExport: string[] = [];
  popLabel = "POP";


  constructor(
    private formatting: SharedServices.FormattingService,
    private filterService: SharedServices.FilterService,
    private formattingService: SharedServices.FormattingService,
    private dataTableService: SharedServices.DataTableService
  ) {

  }

  ngOnInit(): void {
    this.configureColumns();
    this.configureRows();
  }

  private configureColumns(): void {
    const dimColumnIdx = this.dataSet.columns.findIndex(col => col.name === this.settings.dimensionName);
    const dimColumn = this.dataSet.columns[dimColumnIdx];

    //first configure dim column
    const cols: KpiSheetModels.KpiSheetColumn[] = [{
      name: dimColumn.name,
      label: dimColumn.displayName ?? dimColumn.name,
      rowIndex: dimColumnIdx
    }];

    const metricColumns = this.settings.metrics.map(m => {
      const colIdx = this.dataSet.columns.findIndex(cs => cs.name === m.name);
      const col = this.dataSet.columns[colIdx];

      if (colIdx < 0) {
        throw new Error(`Column ${m.name} not found in data set ${this.dataSet.name}`);
      }

      const kpiSheetColumn: KpiSheetModels.KpiSheetColumn = {
        name: m.name,
        label: m.title ?? col.displayName ?? col.name,
        isHeader: true,
        rowIndex: colIdx
      };

      return kpiSheetColumn;
    });

    this.columns = [...cols, ...metricColumns];
    this.metricColumns = metricColumns;
  }

  private configureRows(): void {
    //console.log(this.dataSet);
    // get col index of row by dimensionName
    const dimIndex = this.dataSet.columns.findIndex(col => col.name === this.settings.dimensionName);

    // we assume the first column we find that's meta is the logo
    const metaIndex = this.dataSet.columns.findIndex(col => col.type === 'meta');

    const dimensionRowFilters = (this.settings.dimensionRowFilters ?? []).map(f => f.toUpperCase())

    const rowFilter = (row) => !dimensionRowFilters.includes(row[dimIndex].value.toString().toUpperCase());

    this.rows = this.dataSet.rows.filter(rowFilter).map(row => {

      const newRow: KpiSheetModels.KpiSheetRow = {
        name: (row[dimIndex]?.label ?? row[dimIndex]?.value)?.toString(),
        code: this.getProviderImagePath(row[metaIndex]?.value?.toString()),
      };

      this.settings.metrics.forEach(m => {
        const kpiCol = this.columns.find(c => c.name === m.name);
        const popIndex = this.dataSet.columns.findIndex(col => col.name === m.name + 'POP');
        const yoyIndex = this.dataSet.columns.findIndex(col => col.name === m.name + 'YOY');

        newRow[m.name] = {
          current: row[kpiCol.rowIndex],
          pop: row[popIndex],
          yoy: row[yoyIndex]
        };
      });

      return newRow;
    });
  }

  getProviderImagePath(code: string): string {
    if (code == null) { return 'assets/images/Providers/total.png'; }
    return `assets/images/Providers/${code}.png`;
  }

  getTrendTextColor(trend: number, columnName: string): string {
    if (!trend) {
      return '';
    }
    const isTrendUp = this.isTrendUp(trend, columnName);

    if (isTrendUp == null) {
      return 'text-yellow';
    } else if (!!isTrendUp) {
      return 'text-green';
    } else if (!isTrendUp) {
      return 'text-red';
    }
  }

  isTrendUp(value: number, columnName: string) {
    if (!value || value === 0)
      return null;
    const metric = this.settings.metrics.find(metric => metric.name === columnName);

    return value > 0
      ? metric.metricInverted ? false : true
      : metric.metricInverted ? true : false;
  }

  private toRecords(): any[] {
    const columnNames = this.dataSet.columns.map(c => c.name);
    const records = [];

    this.dataSet.rows.forEach(row => {
      const record = {};
      for (let i = 0; i < columnNames.length; i++) {
        record[columnNames[i]] = row[i];
      }
      records.push(record);
    });

    return records;
  }


  // private excel(title: string): void {
  //   let data: string[][] = [[title]];

  //   // data.push(['Filters:', this.filterBreadcrumbsForExport]);
  //   // data.push(['Filters:']);

  //   (this.filterBreadcrumbsForExport || []).forEach(bc => {
  //     data.push([bc]);
  //   });

  //   const cols = this.columns.map(col => col.label ?? col.name);

  //   data.push(['']);
  //   data.push(cols);
  //   data = data.concat(this.getRowsForExport());


  //   const printTitle = title.replace(/-| /g, ''); // Regular expression /-| /g = all instances of ' ' or '-'

  //   new Angular5Csv(data, printTitle);
  // }

}
