<div *ngIf="currentCard$ | async as currentCard">    
    <div class="row labels">
        <span class="col-md-9 col-xl-10"><b>{{currentCard.dimensionName}}:</b></span>
        <span class="col-md-3 col-xl-2"><b>{{currentCard.metricOneName}}:</b></span>        
        <span *ngIf="!!currentCard.metricTwoName" class="col-md-3 col-xl-2">
            <b>{{currentCard.metricTwoName}}:</b>
        </span>
        <mat-divider></mat-divider>
    </div>
    <div class="row" *ngFor="let cc of currentCard.barValues">
        <div class="col-12">{{cc.dimensionValueName}}</div>
        <div class="col-md-9 col-xl-10">
            <div class="border">
                <table [ngStyle]="{ 'width': cc.barWidth }"
                        [matTooltip]="cc.tooltipText"
                        [matTooltipPosition]="'above'"
                >
                <tr>
                    <td [ngStyle]="cc.barStyleOne"></td>
                    <td [ngStyle]="cc.barStyleTwo"></td>
                </tr>
                </table>
            </div>
        </div>
        <div class="col-md-3 col-xl-2">
        {{cc.metricOneDisplay}}
        </div>
    </div>
</div>

    