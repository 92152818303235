import {
    IDefaultTableColumnConfig,
    ISalesSourceConfig,
    IDualAxisLineGraphConfig,
    INameValuePair,
    IPreviousDateRangeDisplay} from '../../../../_shared/models/models-index';

export class SalesSourceConfig implements ISalesSourceConfig {
    public reportTitle?: string = null;
    public metricDisplayModes: INameValuePair[] = [];
    public previousDateColumnDisplayOverrides?: IPreviousDateRangeDisplay = null;
    public useDefaultComparisonColumns: boolean = false;
    public graphConfig: IDualAxisLineGraphConfig = null;
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
}
