import { Router, Route } from '@angular/router';
import { AppInsightsService } from '../appInsights/appInsights.service';
import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SpinnerService } from '../spinner/spinner.service';
import { AppInjector } from '../../../app.injector';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private injector: Injector, private zone: NgZone/*, private appInsights: AppInsightsService*/) {
        super();
    }

    handleError(error: any): void {
         const ai = AppInjector.get(AppInsightsService);
         const router = AppInjector.get(Router);
         const spinner = AppInjector.get(SpinnerService);


         if (!(error instanceof HttpErrorResponse)) {
             this.zone.run(() => {
                 if (spinner.isSpinnerVisible)
                     spinner.hide();
                 ai.trackException(error);
                 router.navigated = false;
             });
         }
    }

}
