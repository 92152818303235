import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { IFacetedFilterSearchResult, IFacetedFilterDataItem } from "../types/faceted-filter.types";
import { TypeCheck } from "../Utils";

export interface ISearchState {
    isSearching: boolean;
    searchCleared: boolean;
}

@Injectable({ providedIn: 'root' })
export class FacetedFilterPanelSearch {
    private _isSearching = new BehaviorSubject<boolean>(false);
    isSearching$: Observable<boolean> = this._isSearching.asObservable();

    private _searchCleared = new BehaviorSubject<boolean>(false);
    searchCleared$: Observable<boolean> = this._searchCleared.asObservable();

    private _searchResults = new BehaviorSubject<IFacetedFilterSearchResult[]>([]);
    searchResults$: Observable<IFacetedFilterSearchResult[]> = this._searchResults.asObservable();

    private _searchState = new BehaviorSubject<ISearchState>({} as ISearchState);
    searchState$: Observable<ISearchState> = this._searchState.asObservable();

    searchData(data: IFacetedFilterDataItem[], term: string): Observable<never> {
        if (term && term.length) {
            this._searchResults.next([]);
            this._searchCleared.next(false);
            this._isSearching.next(true);
            this.setSearchState();
            const nodes: IFacetedFilterDataItem[] = [...data];
    
            this.find(nodes, term.toLowerCase());
        } else {
            this._searchResults.next([]);
            this._isSearching.next(false);
        }

        this.setSearchState();
        return of();
    }

    clearSearch(): void {
        this._searchResults.next([]);
        this._isSearching.next(false);
        this._searchCleared.next(true);
        
        this.setSearchState();
    }

    startMobileSearch(): void {
        this._isSearching.next(true);
        this.setSearchState();
    }

    private find(nodes: IFacetedFilterDataItem[], term: string): void {
        for (let i = 0; i < nodes.length; i++) {
            if (this.nodeIsSearchable(nodes[i]) && this.nodeIsMatch(nodes[i], term))
                this.addNodeToResults(nodes[i]);

            if (nodes[i].items && nodes[i].items.length)
                this.find(nodes[i].items, term);
        }
    }

    private nodeIsMatch(node: IFacetedFilterDataItem, term: string): boolean {
        if (node.label.toLowerCase().includes(term) || node.path.toLowerCase().includes(term))
            return true;
        
        if (TypeCheck.isHierarchyValue(node.value)
            && node.value.key.toLowerCase().includes(term)) {
            return true;
        }
        
        return false;
    }

    private nodeIsSearchable(node: IFacetedFilterDataItem): boolean {
        return node.searchable === undefined || node.searchable === true;
    }

    private addNodeToResults(node: IFacetedFilterDataItem): void {
        const result: IFacetedFilterSearchResult = {
            label: node.label,
            path: node.path,
            item: node
        };

        if (!this._searchResults.getValue().includes(result)) {
            const newResults: IFacetedFilterSearchResult[] = [...this._searchResults.getValue(), result];
            this._searchResults.next(newResults);
        }
    }

    private setSearchState(): void {
        const state: ISearchState = {
            isSearching: this._isSearching.getValue(),
            searchCleared: this._searchCleared.getValue()
        };
        this._searchState.next(state);
    }
}