import { Injectable } from "@angular/core";
import { AppFilterStorageKey, ISessionState, SessionStateStorage } from '../types/common';
import { FacetedFilterResultBuilder } from '../Utils/faceted-filter-result.builder';
import * as FilterPanelConstants from '../types/constants';
import { IDateRangeValue, IFacetedFilterData, IFacetedFilterDataItem, IFacetedFilterSelection, IHierarchyValue, ISelectionInfo } from '../types/faceted-filter.types';
import { BehaviorSubject, Observable, of } from "rxjs";
import { skipWhile, take, tap, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { TypeCheck } from '../Utils/type-check';
import { Params, Router } from "@angular/router";
import { IFilterWellViewModel, IFilterWellConfig, IFilterWellDefaults } from "./filterwell.types";

@Injectable({ providedIn: 'root' })
export class FilterWellFacade {
    private _viewModel = new BehaviorSubject<IFilterWellViewModel>({} as IFilterWellViewModel);
    viewModel$: Observable<IFilterWellViewModel> = this._viewModel.asObservable();

    private _keySuffix = AppFilterStorageKey.listviewFilterwell;
    private _key: string;
	  private _storageKey: string;
    private _filterDataKeySuffix = AppFilterStorageKey.listviewFilterwellData;
    private _filterDataKey;
    private _filterDataStorageKey: string;
    private _disregardedStorageKeySuffix = '.disregarded.default';
    private _disregardedDefaultDataKey: string;
    private _storage = new SessionStateStorage();
    private _cacheLifeInMinutes: number = 20; // TODO: Get this from config
    private _activeQueryParams: string[] = [];
    private _configDefaultFilterSelections: IFacetedFilterSelection[] = [];
    private serviceUri = '#TODO';

    constructor(
        private router: Router,
        private http: HttpClient,
    ) { }

    getData(): void {
      const myConfig: IFilterWellConfig = {
        context: '',
        contextPath: '',
        defaultFilters: []
      };
        this.setDataKeys('crs-data');
        this.setStorageKeys();
        this.getViewModelData(myConfig);
    }

    setState(filters: IFacetedFilterSelection[], key?: string): void {
        const storageKey = key ?? this._storageKey;
        const stateData: ISessionState = {
            date: new Date,
            data: filters
        };
        this._storage.set(storageKey, stateData);
    }

    clearState(): void {
		this._storage.remove(this._storageKey);
		this._storage.remove(this._disregardedDefaultDataKey);
    }

	clearData(): void {
		this._viewModel.next({} as IFilterWellViewModel);
    }

    setDefaultAsDisregarded(data: IFacetedFilterSelection): void {

        let current = this.getCachedSelections(this._disregardedDefaultDataKey);
        if (current && current.length)
            current.push(data);
        else
            current = [data];

        this.setState(current, this._disregardedDefaultDataKey);
    }

    isDisregardedDefault(data: IFacetedFilterSelection): boolean {
        const disregardedDefaultCache = this.getCachedSelections(this._disregardedDefaultDataKey);
        return !!disregardedDefaultCache?.find(ddc => ddc.path === data.path);
    }

    getNondisregardedDefaults(data: IFacetedFilterSelection[]): IFacetedFilterSelection[] {
        const disregardedDefaultCache = this.getCachedSelections(this._disregardedDefaultDataKey);
        let nonDisregards: IFacetedFilterSelection[] = [];

        if (!disregardedDefaultCache?.length)
            return data;

        data.forEach((d) => {
            if (!disregardedDefaultCache.find(ddc => ddc.path === d.path))
                nonDisregards.push(d);
        })

        return nonDisregards;
    }

    private setStorageKeys(): void {
        this._storageKey = `${window.location.pathname.replace(/\//g, '.')}.${this._key}`;
        this._filterDataStorageKey = `${window.location.pathname.replace(/\//g, '.')}.${this._filterDataKey}`;
        this._disregardedDefaultDataKey = `${this._filterDataStorageKey}.${this._disregardedStorageKeySuffix}`;
    }

    private setDataKeys(appConfigContext: string): void {
        this._key = `${appConfigContext}.${this._keySuffix}`;
        this._filterDataKey = `${appConfigContext}.${this._filterDataKeySuffix}`;
    }

    private setBaseDataUri(config: IFilterWellConfig): void {
        // if (!ENV.isProd)
        //     this._baseUri = config.localContextRoot ?? ENV.urls.filterContextRoot;
        // else
        //     this._baseUri = ENV.urls.filterContextRoot;
    }

    private setNoOpResult(): void {
        const noOpModel: IFilterWellViewModel = {
            filterData: undefined,
            defaultFilters: [],
            cachedFilters: [],
            urlFilters: [],
            config: undefined,
            noData: true
        };
        this._viewModel.next(noOpModel);
    }

	private cacheFilterData(data: IFacetedFilterData): void {
		const stateData: ISessionState = {
            date: new Date,
            data: data
        };
        this._storage.set(this._filterDataStorageKey, stateData);
	}

	private getCachedFilterData(): IFacetedFilterData {
		const stateData: ISessionState = this._storage.get(this._filterDataStorageKey);
		if (!Object.keys(stateData).length)
			return undefined;

		const now = new Date();
		const stateDate: Date = new Date(stateData.date);
		const diff = now.getTime() - stateDate.getTime();
		const lifeTime = Math.round(diff / 60000);
		if (lifeTime < this._cacheLifeInMinutes)
			return stateData.data as IFacetedFilterData;
		else {
			this._storage.remove(this._filterDataStorageKey);
			return undefined;
		}
	}

    private convertToConfig(value: string): IFilterWellConfig {
		return JSON.parse(value) as IFilterWellConfig;
    }

    private getViewModelData(config: IFilterWellConfig): void {
        this.getContextData(config).subscribe(filterData => {
            this._configDefaultFilterSelections = this.getDefaultFilterSelectionsNotAlreadyInCache(config, filterData.items);
            const model: IFilterWellViewModel = {
                filterData,
                config,
                defaultFilters : [...this._configDefaultFilterSelections],
                // urlFilters     : [...this.getUrlFilterSelections(filterData.items, routeInfo)],
                urlFilters: [],
                cachedFilters  : [...this.getCachedSelections()],
			};

            this._viewModel.next(model);
        })
    }

	// private getContextData(config: IFilterWellConfig): Observable<IFacetedFilterData> {
	// 	const cachedFilterData = this.getCachedFilterData();
	// 	if (cachedFilterData)
	// 		return of(cachedFilterData);

    //     const uri: string = `${this.serviceUri}getFilters`;

    //     return this.http.post<IFacetedFilterData>(uri, '').pipe(
    //         tap({
    //             next: response => this.cacheFilterData(response),
    //             error: () => this.setNoOpResult()
    //         }),
    //     );
	// }
	private getContextData(config: IFilterWellConfig): Observable<IFacetedFilterData> {
        return of(FAKE_DATA as IFacetedFilterData).pipe(
            tap(response => this.cacheFilterData(response))
        );
	}

    private getCachedSelections(key?: string): IFacetedFilterSelection[] {
        const storagekey = key ?? this._storageKey;
		const state = this._storage.get(storagekey);
		const stateFilters = state.data as IFacetedFilterSelection[];

        return stateFilters?.length ? [...stateFilters] : [];
	}

    private getDefaultFilterSelectionsNotAlreadyInCache(config: IFilterWellConfig, filterDataItems: IFacetedFilterDataItem[]): IFacetedFilterSelection[] {

        return config.defaultFilters.reduce(
            (selections, filter) => {
                const selection = this.toSelection(filter, filterDataItems);
                if (selection) {
                    this.checkCacheForStaleDefaultDateFilter(selection);
                    selection.isDefault = true;
                    const exists = !![...selections, ...this.getCachedSelections()].find(s => compareSelections(s, selection));

                    return exists ? selections : selections.concat(selection);
                } else {
                    return selections;
                }

            },
            [] as IFacetedFilterSelection[]
        );
    }

    // private getUrlFilterSelections(filterDataItems: IFacetedFilterDataItem[], routeInfo: SerializedRouterStateSnapshot): IFacetedFilterSelection[] {
    //     let filters: IFilterWellDefaults[] = [];

    //     this.setSegmentDefaultFromQueryParams(routeInfo?.root?.queryParams, filterDataItems, filters);
    //     this.setHierarchyDefaultFromQueryParams(routeInfo?.root?.queryParams, filterDataItems, filters);
    //     this.setEncodedDefaultsFromQueryParams(routeInfo?.root?.queryParams, filters);

    //     if (this._activeQueryParams.length)
    //         this.cleanUrl();

    //     if (!filters.length)
    //         return [];

    //     // Set config defaults as disregarded
    //     this._configDefaultFilterSelections.forEach(cd => this.setDefaultAsDisregarded(cd));

    //     return filters.reduce(
    //         (selections, filter) => {
    //             const selection = this.toSelection(filter, filterDataItems);
    //             if (selection) {
    //                 const exists = !!selections.find(s => compareSelections(s, selection));

    //                 return exists ? selections : selections.concat(selection);
    //             }
    //             return selections;
    //         },
    //         [] as IFacetedFilterSelection[]
    //     );
    // }

    private setEncodedDefaultsFromQueryParams(params: Params, defaults: IFilterWellDefaults[]): void {
        const encodedFilters: string = params?.filters;
        if (!encodedFilters)
            return;

        const decodedFilters = this.decodeFilters(encodedFilters);
        if (decodedFilters?.length) {
            defaults.push(...decodedFilters);
        }

        this._activeQueryParams.push('filters');
    }

    private setSegmentDefaultFromQueryParams(params: Params, filterDataItems: IFacetedFilterDataItem[], defaults: IFilterWellDefaults[]): void {
        const segmentPath: string = params?.segmentPath;
        if (!segmentPath)
            return;

        const paths = segmentPath.split('|');
        const segments = filterDataItems.find(f => f.label === paths[0]);
        if (segments) {
            const segmentContainer = segments.items.find(f => f.label === paths[1]);
            const defaultSegment = segmentContainer?.items.length ? this.findSegmentFacet(segmentPath, segmentContainer.items) : undefined;
            if (defaultSegment)
                defaults.push(defaultSegment);
        }

        this._activeQueryParams.push('segmentPath');
    }

    private setHierarchyDefaultFromQueryParams(params: Params, filterDataItems: IFacetedFilterDataItem[], defaults: IFilterWellDefaults[]): void {
        const hierarchyPath = params?.hierarchyPath;
        const hierarchyValue = params?.hierarchyValue;
        if (!hierarchyPath || !hierarchyValue)
            return;

        const paths = hierarchyPath.split('|')
        const hierarchies = filterDataItems.find(f => f.label === paths[0]);
        const hierarchyContainer = hierarchies.items.find(h => h.label === paths[1]);

        if (hierarchyContainer?.items?.length) {
            for (let i = 0; i < hierarchyContainer.items.length; i++) {
                const item = hierarchyContainer.items[i]
                const foundFilter = this.findRetailerFacet(hierarchyValue, item?.items);
                if (foundFilter) {
                    defaults.push(foundFilter);
                    break;
                }

            }
        }
        this._activeQueryParams.push(...['hierarchyPath', 'hierarchyValue']);
    }

    private findSegmentFacet(path: string, items: IFacetedFilterDataItem[]): IFilterWellDefaults {
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.path !== path) {
                if (item.items?.length)
                return this.findSegmentFacet(path, item.items);
            }
            else {
                return {
                    path: item.path,
                    valueType: item.valueType,
                    value: [<string>item.value]
                }
            }
        }
    }

    private findRetailerFacet(retailerId: string, items: IFacetedFilterDataItem[]): IFilterWellDefaults {

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (TypeCheck.isHierarchyContainer(item.type)) {
                return this.findRetailerFacet(retailerId, item.items);
            } else if (item.value['key'] === retailerId) {
                return {
                    path: item.path,
                    valueType: item.valueType,
                    value: [item.value as IHierarchyValue]
                };
            }
        }
    }

    private toSelection(filter: IFilterWellDefaults, CONFIG_ITEMS: IFacetedFilterDataItem[]): IFacetedFilterSelection {
        const paths     = filter.path.split('|');
        const rootPath  = paths.shift();
        const root      = CONFIG_ITEMS.find(fdi => fdi.path === rootPath);
        const existing  = this.findFilter(paths, root, filter.value);
        if (existing) {
            const selection = FacetedFilterResultBuilder.buildSelectionResult(existing);
            return selection;
        }

        return undefined;
    }

    private decodeFilters(encodedFilters: string): IFilterWellDefaults[] {
        try {
            return encodedFilters ? JSON.parse(atob(encodedFilters)) : [];
        } catch {
            console.error('Error decoding filters, not using filters from url.');
            return [];
        }
    }

    private findFilter(paths: string[], filterDataItem: IFacetedFilterDataItem, values?: any): ISelectionInfo {
        let selection: ISelectionInfo;
        if (filterDataItem.items?.length) {
            const node: IFacetedFilterDataItem = filterDataItem.items.find(fdi => fdi.label === paths[0]);
            if (node) {
                let selectedChildren: IFacetedFilterDataItem[];
                let selectedChild: IFacetedFilterDataItem;
                let isHierarchiesParent: boolean = false;
                if (values) {
                    if (Array.isArray(values)) {
                        if (TypeCheck.isHierarchyContainer(node.type)) {
                            const hierarchyValues = values as IHierarchyValue[];
                            const hvValues = hierarchyValues.map(hvv => hvv.value);
                            isHierarchiesParent = node.items.filter(i => hvValues.includes(i.label)).length > 1;
                        } else {
                            selectedChildren = node.items.filter(i => values.includes(i.value));
                        }
                    }
                } else {
                    selectedChild = node?.items?.find(n => n.label === paths[1]);
                }

                if (isHierarchiesParent) {
                    selection = {
                        item: node,
                        hierarchies: [...values]
                    };
                    return selection;
                }else if (selectedChildren?.length) {
                    selection = {
                        item: node,
                        facets: [...selectedChildren]
                    };
                    return selection;
                }else if (selectedChild && TypeCheck.isFacet(selectedChild.type)) {
                    selection = {
                        item: selectedChild,
                        facets: [selectedChild]
                    };
                    return selection;
                } else {
                    paths.shift();
                    return this.findFilter(paths, node, values);
                }
            }
        } else {
            switch (filterDataItem.valueType) {
                case FilterPanelConstants.FacetedFilterItemValueType.DateRangeValue:
                    return {
                        item: filterDataItem,
                        dateRange: values as IDateRangeValue
                    };
                case FilterPanelConstants.FacetedFilterItemValueType.OperatorValue:
                    // TODO: Need to determine query param shape for this!!
                    break;
                case FilterPanelConstants.FacetedFilterItemValueType.RetailerValue:
                    return {
                        item: filterDataItem,
                        hierarchies: values as IHierarchyValue[]
                    }
            }
        }

    }

    private checkCacheForStaleDefaultDateFilter(current: IFacetedFilterSelection): void {
        if (current.payload?.valueType === FilterPanelConstants.FacetedFilterItemValueType.DateRangeValue) {
            let cached = [...this.getCachedSelections()];
            const cachedFilterOfSameDateType = cached.find(cs => cs.path === current.path);
            if (cachedFilterOfSameDateType) {
                cached = cached.filter(c => c.path !== cachedFilterOfSameDateType.path);
                this._storage.remove(this._filterDataStorageKey);

                this.setState(cached);
            }
        }
    }

    private cleanUrl(): void {
        // this.ngUtils.removeQueryParams(this._activeQueryParams);
    }

}

export function compareSelections(a: IFacetedFilterSelection, b: IFacetedFilterSelection) {
    return a.path === b.path
        && a.displayValue === a.displayValue
        && a.payload.value === b.payload.value;
}

const FAKE_DATA =
{
    "label": "Filter",
    "items": [
        {
            "label": "Attributes",
            "name": null,
            "type": "container",
            "filterType": null,
            "path": "Attributes",
            "value": null,
            "valueType": null,
            "searchable": false,
            "items": [
                {
                    "label": "Created Date",
                    "name": null,
                    "type": "container",
                    "filterType": null,
                    "path": "Attributes|Created Date",
                    "value": null,
                    "valueType": null,
                    "searchable": true,
                    "items": [
                        {
                            "label": "Custom Date Range",
                            "name": "received",
                            "type": "date-range-picker",
                            "filterType": "attribute",
                            "path": "Attributes|Created Date|Custom Date Range",
                            "value": null,
                            "valueType": "date-range-value",
                            "searchable": false,
                            "items": []
                        },
                        {
                            "label": "Last 7 Days",
                            "name": "received",
                            "type": "exclusive-facet",
                            "filterType": "attribute",
                            "path": "Attributes|Created Date|Last 7 Days",
                            "value": {
                                "startDate": "10/02/2023",
                                "endDate": "10/09/2023"
                            },
                            "valueType": "date-range-value",
                            "searchable": true,
                            "items": []
                        },
                        {
                            "label": "Last 14 Days",
                            "name": "received",
                            "type": "exclusive-facet",
                            "filterType": "attribute",
                            "path": "Attributes|Created Date|Last 14 Days",
                            "value": {
                                "startDate": "09/25/2023",
                                "endDate": "10/09/2023"
                            },
                            "valueType": "date-range-value",
                            "searchable": true,
                            "items": []
                        },
                        {
                            "label": "Last 30 Days",
                            "name": "received",
                            "type": "exclusive-facet",
                            "filterType": "attribute",
                            "path": "Attributes|Created Date|Last 30 Days",
                            "value": {
                                "startDate": "09/09/2023",
                                "endDate": "10/09/2023"
                            },
                            "valueType": "date-range-value",
                            "searchable": true,
                            "items": []
                        },
                        {
                            "label": "Last 90 Days",
                            "name": "received",
                            "type": "exclusive-facet",
                            "filterType": "attribute",
                            "path": "Attributes|Created Date|Last 90 Days",
                            "value": {
                                "startDate": "07/11/2023",
                                "endDate": "10/09/2023"
                            },
                            "valueType": "date-range-value",
                            "searchable": true,
                            "items": []
                        },
                        {
                            "label": "This Month",
                            "name": "received",
                            "type": "exclusive-facet",
                            "filterType": "attribute",
                            "path": "Attributes|Created Date|This Month",
                            "value": {
                                "startDate": "10/01/2023",
                                "endDate": "10/09/2023"
                            },
                            "valueType": "date-range-value",
                            "searchable": true,
                            "items": []
                        },
                        {
                            "label": "Last Month",
                            "name": "received",
                            "type": "exclusive-facet",
                            "filterType": "attribute",
                            "path": "Attributes|Created Date|Last Month",
                            "value": {
                                "startDate": "09/01/2023",
                                "endDate": "09/30/2023"
                            },
                            "valueType": "date-range-value",
                            "searchable": true,
                            "items": []
                        }
                    ]
                },
                {
                    "label": "Email Address",
                    "name": "email-address",
                    "type": "common-string-operator",
                    "filterType": "attribute",
                    "path": "Attributes|Email Address",
                    "value": null,
                    "valueType": "string-operator-value",
                    "searchable": true,
                    "items": []
                },
                {
                    "label": "First Name",
                    "name": "first-name",
                    "type": "common-string-operator",
                    "filterType": "attribute",
                    "path": "Attributes|First Name",
                    "value": null,
                    "valueType": "string-operator-value",
                    "searchable": true,
                    "items": []
                },
                {
                    "label": "Last Activity Date",
                    "name": "last-activity",
                    "type": "container",
                    "filterType": "attribute",
                    "path": "Attributes|Last Activity Date",
                    "value": null,
                    "valueType": "date-range-value",
                    "searchable": true,
                    "items": [
                        {
                            "label": "Custom Date Range",
                            "name": "last-activity",
                            "type": "date-range-picker",
                            "filterType": "attribute",
                            "path": "Attributes|Last Activity Date|Custom Date Range",
                            "value": null,
                            "valueType": "date-range-value",
                            "searchable": false,
                            "items": []
                        },
                        {
                            "label": "Last 7 Days",
                            "name": "last-activity",
                            "type": "exclusive-facet",
                            "filterType": "attribute",
                            "path": "Attributes|Last Activity Date|Last 7 Days",
                            "value": {
                                "startDate": "10/02/2023",
                                "endDate": "10/09/2023"
                            },
                            "valueType": "date-range-value",
                            "searchable": true,
                            "items": []
                        },
                        {
                            "label": "Last 14 Days",
                            "name": "last-activity",
                            "type": "exclusive-facet",
                            "filterType": "attribute",
                            "path": "Attributes|Last Activity Date|Last 14 Days",
                            "value": {
                                "startDate": "09/25/2023",
                                "endDate": "10/09/2023"
                            },
                            "valueType": "date-range-value",
                            "searchable": true,
                            "items": []
                        },
                        {
                            "label": "Last 30 Days",
                            "name": "last-activity",
                            "type": "exclusive-facet",
                            "filterType": "attribute",
                            "path": "Attributes|Last Activity Date|Last 30 Days",
                            "value": {
                                "startDate": "09/09/2023",
                                "endDate": "10/09/2023"
                            },
                            "valueType": "date-range-value",
                            "searchable": true,
                            "items": []
                        },
                        {
                            "label": "Last 90 Days",
                            "name": "last-activity",
                            "type": "exclusive-facet",
                            "filterType": "attribute",
                            "path": "Attributes|Last Activity Date|Last 90 Days",
                            "value": {
                                "startDate": "07/11/2023",
                                "endDate": "10/09/2023"
                            },
                            "valueType": "date-range-value",
                            "searchable": true,
                            "items": []
                        },
                        {
                            "label": "This Month",
                            "name": "last-activity",
                            "type": "exclusive-facet",
                            "filterType": "attribute",
                            "path": "Attributes|Last Activity Date|This Month",
                            "value": {
                                "startDate": "10/01/2023",
                                "endDate": "10/09/2023"
                            },
                            "valueType": "date-range-value",
                            "searchable": true,
                            "items": []
                        },
                        {
                            "label": "Last Month",
                            "name": "last-activity",
                            "type": "exclusive-facet",
                            "filterType": "attribute",
                            "path": "Attributes|Last Activity Date|Last Month",
                            "value": {
                                "startDate": "09/01/2023",
                                "endDate": "09/30/2023"
                            },
                            "valueType": "date-range-value",
                            "searchable": true,
                            "items": []
                        }
                    ]
                },
                {
                    "label": "Last Name",
                    "name": "last-name",
                    "type": "common-string-operator",
                    "filterType": "attribute",
                    "path": "Attributes|Last Name",
                    "value": null,
                    "valueType": "string-operator-value",
                    "searchable": true,
                    "items": []
                }
            ]
        },
        {
            "label": "Segments",
            "name": null,
            "type": "container",
            "filterType": null,
            "path": "Segments",
            "value": null,
            "valueType": null,
            "searchable": false,
            "items": [
                {
                    "label": "Customer Status",
                    "name": null,
                    "type": "container",
                    "filterType": null,
                    "path": "Segments|Customer Status",
                    "value": null,
                    "valueType": null,
                    "searchable": true,
                    "items": [
                        {
                            "label": "Active",
                            "name": "customer-status",
                            "type": "facet",
                            "filterType": "segment",
                            "path": "Segments|Customer Status|Active",
                            "value": "Active",
                            "valueType": "simple-value",
                            "searchable": true,
                            "items": []
                        },
                        {
                            "label": "Lost",
                            "name": "customer-status",
                            "type": "facet",
                            "filterType": "segment",
                            "path": "Segments|Customer Status|Lost",
                            "value": "Lost",
                            "valueType": "simple-value",
                            "searchable": true,
                            "items": []
                        },
                        {
                            "label": "New",
                            "name": "customer-status",
                            "type": "facet",
                            "filterType": "segment",
                            "path": "Segments|Customer Status|New",
                            "value": "New",
                            "valueType": "simple-value",
                            "searchable": true,
                            "items": []
                        },
                        {
                            "label": "Sold",
                            "name": "customer-status",
                            "type": "facet",
                            "filterType": "segment",
                            "path": "Segments|Customer Status|Sold",
                            "value": "Sold",
                            "valueType": "simple-value",
                            "searchable": true,
                            "items": []
                        }
                    ]
                },
                {
                    "label": "Sales Enablement",
                    "name": null,
                    "type": "container",
                    "filterType": null,
                    "path": "Segments|Sales Enablement",
                    "value": null,
                    "valueType": null,
                    "searchable": true,
                    "items": [
                        {
                            "label": "Active Shopper",
                            "name": "sales-enablement",
                            "type": "facet",
                            "filterType": "segment",
                            "path": "Segments|Sales Enablement|Active Shopper",
                            "value": "Active Shopper",
                            "valueType": "simple-value",
                            "searchable": true,
                            "items": []
                        },
                        {
                            "label": "Back In Market",
                            "name": "sales-enablement",
                            "type": "facet",
                            "filterType": "segment",
                            "path": "Segments|Sales Enablement|Back In Market",
                            "value": "Back In Market",
                            "valueType": "simple-value",
                            "searchable": true,
                            "items": []
                        }
                    ]
                }
            ]
        },
        {
            "label": "Retailers",
            "name": null,
            "type": "container",
            "filterType": null,
            "path": "Retailers",
            "value": null,
            "valueType": null,
            "searchable": false,
            "items": [
                {
                    "label": "National Sales",
                    "name": "retailer",
                    "type": "hierarchy-facet-container",
                    "filterType": "attribute",
                    "path": "Retailers|National Sales",
                    "value": {
                        "key": "96bbbf7c-8f73-4f0e-a385-51b46972a380",
                        "value": "National Sales",
                        "name": "national sales"
                    },
                    "valueType": "hierarchy-value",
                    "searchable": true,
                    "items": [
                        {
                            "label": "East",
                            "name": "retailer",
                            "type": "hierarchy-facet-container",
                            "filterType": "attribute",
                            "path": "Retailers|National Sales|East",
                            "value": {
                                "key": "72",
                                "value": "East",
                                "name": "national sales"
                            },
                            "valueType": "hierarchy-value",
                            "searchable": true,
                            "items": [
                                {
                                    "label": "DC",
                                    "name": "retailer",
                                    "type": "hierarchy-facet-container",
                                    "filterType": "attribute",
                                    "path": "Retailers|National Sales|East|DC",
                                    "value": {
                                        "key": "72DC",
                                        "value": "DC",
                                        "name": "national sales"
                                    },
                                    "valueType": "hierarchy-value",
                                    "searchable": true,
                                    "items": [
                                        {
                                            "label": "4",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|East|DC|4",
                                            "value": {
                                                "key": "72DC4",
                                                "value": "4",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "BILL DODGE INFINITI (70243) - WESTBROOK",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|4|BILL DODGE INFINITI (70243) - WESTBROOK",
                                                    "value": {
                                                        "key": "a682f4d7-a97f-4ac7-8348-3e7562f2c831",
                                                        "value": "BILL DODGE INFINITI (70243) - WESTBROOK",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "Herb Chambers INFINITI of Boston (71242) - MEDFORD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|4|Herb Chambers INFINITI of Boston (71242) - MEDFORD",
                                                    "value": {
                                                        "key": "63665f85-d787-4306-836e-c365a92a2d38",
                                                        "value": "Herb Chambers INFINITI of Boston (71242) - MEDFORD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "HERB CHAMBERS INFINITI OF WESTBOROUGH (70534) - WESTBOROUGH",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|4|HERB CHAMBERS INFINITI OF WESTBOROUGH (70534) - WESTBOROUGH",
                                                    "value": {
                                                        "key": "daa5c810-370b-454c-a1ab-57ede0086dfc",
                                                        "value": "HERB CHAMBERS INFINITI OF WESTBOROUGH (70534) - WESTBOROUGH",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF HANOVER (72514) - HANOVER",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|4|INFINITI OF HANOVER (72514) - HANOVER",
                                                    "value": {
                                                        "key": "bad23313-84a4-487b-832e-ae0f2fb262d4",
                                                        "value": "INFINITI OF HANOVER (72514) - HANOVER",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF HANOVER (73514) - HANOVER",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|4|INFINITI OF HANOVER (73514) - HANOVER",
                                                    "value": {
                                                        "key": "cd158a5a-b8f2-458c-b964-7d7d795eb1bf",
                                                        "value": "INFINITI OF HANOVER (73514) - HANOVER",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF NASHUA (71503) - NASHUA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|4|INFINITI OF NASHUA (71503) - NASHUA",
                                                    "value": {
                                                        "key": "d9dd6dec-9ec5-4874-a434-dab1b17e6c05",
                                                        "value": "INFINITI OF NASHUA (71503) - NASHUA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF NORWOOD (70209) - NORWOOD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|4|INFINITI OF NORWOOD (70209) - NORWOOD",
                                                    "value": {
                                                        "key": "206995f3-337f-4ad6-b1fd-e29b1131b758",
                                                        "value": "INFINITI OF NORWOOD (70209) - NORWOOD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF WARWICK (72005) - WARWICK",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|4|INFINITI OF WARWICK (72005) - WARWICK",
                                                    "value": {
                                                        "key": "dbe0221e-518b-4beb-a7e0-1fa65aa4322c",
                                                        "value": "INFINITI OF WARWICK (72005) - WARWICK",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "KELLY INFINITI (70001) - DANVERS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|4|KELLY INFINITI (70001) - DANVERS",
                                                    "value": {
                                                        "key": "293c66df-87b3-4cda-8698-1340a37b56ed",
                                                        "value": "KELLY INFINITI (70001) - DANVERS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        },
                                        {
                                            "label": "5",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|East|DC|5",
                                            "value": {
                                                "key": "72DC5",
                                                "value": "5",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "BENNETT INFINITI OF ALLENTOWN (70414) - ALLENTOWN",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|5|BENNETT INFINITI OF ALLENTOWN (70414) - ALLENTOWN",
                                                    "value": {
                                                        "key": "496969ef-76c7-45ae-a4ea-b05f5e9298a1",
                                                        "value": "BENNETT INFINITI OF ALLENTOWN (70414) - ALLENTOWN",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "Bennett INFINITI of Wilkes-Barre (70543) - WILKES BARRE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|5|Bennett INFINITI of Wilkes-Barre (70543) - WILKES BARRE",
                                                    "value": {
                                                        "key": "62b6f34f-aa02-4d9b-8e28-b61152f7a80a",
                                                        "value": "Bennett INFINITI of Wilkes-Barre (70543) - WILKES BARRE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "COCHRAN INFINITI (70031) - MONROEVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|5|COCHRAN INFINITI (70031) - MONROEVILLE",
                                                    "value": {
                                                        "key": "97d51350-7571-4a8c-9b60-0d54680037cc",
                                                        "value": "COCHRAN INFINITI (70031) - MONROEVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "COCHRAN INFINITI OF SOUTH HILLS (70540) - PITTSBURGH",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|5|COCHRAN INFINITI OF SOUTH HILLS (70540) - PITTSBURGH",
                                                    "value": {
                                                        "key": "101f56bd-d847-4730-b5a3-7e4e84150da1",
                                                        "value": "COCHRAN INFINITI OF SOUTH HILLS (70540) - PITTSBURGH",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "FAULKNER INFINITI OF MECHANICSBURG (71544) - MECHANICSBURG",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|5|FAULKNER INFINITI OF MECHANICSBURG (71544) - MECHANICSBURG",
                                                    "value": {
                                                        "key": "0b3f8dda-db0f-4d65-bf73-0b9b21820d1f",
                                                        "value": "FAULKNER INFINITI OF MECHANICSBURG (71544) - MECHANICSBURG",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "FAULKNER INFINITI OF WILLOW GROVE (72028) - WILLOW GROVE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|5|FAULKNER INFINITI OF WILLOW GROVE (72028) - WILLOW GROVE",
                                                    "value": {
                                                        "key": "80be4f99-e90e-4dd7-a797-ea2dd93cc996",
                                                        "value": "FAULKNER INFINITI OF WILLOW GROVE (72028) - WILLOW GROVE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "HOLMAN INFINITI (70211) - MAPLE SHADE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|5|HOLMAN INFINITI (70211) - MAPLE SHADE",
                                                    "value": {
                                                        "key": "b80029a3-be6d-42b0-bf38-0b3ed0959b15",
                                                        "value": "HOLMAN INFINITI (70211) - MAPLE SHADE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF ARDMORE (72029) - ARDMORE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|5|INFINITI OF ARDMORE (72029) - ARDMORE",
                                                    "value": {
                                                        "key": "e1165cf1-e674-44c9-8465-c1835d6dd3bf",
                                                        "value": "INFINITI OF ARDMORE (72029) - ARDMORE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF WEST CHESTER (70508) - WEST CHESTER",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|5|INFINITI OF WEST CHESTER (70508) - WEST CHESTER",
                                                    "value": {
                                                        "key": "32ec11bc-22a3-45b9-835c-02ee72044214",
                                                        "value": "INFINITI OF WEST CHESTER (70508) - WEST CHESTER",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "PORTER INFINITI (71220) - NEWARK",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|5|PORTER INFINITI (71220) - NEWARK",
                                                    "value": {
                                                        "key": "1ca363e3-d3ac-4d41-8568-5c4d37910a02",
                                                        "value": "PORTER INFINITI (71220) - NEWARK",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        },
                                        {
                                            "label": "6",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|East|DC|6",
                                            "value": {
                                                "key": "72DC6",
                                                "value": "6",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "BERGLUND INFINITI OF ROANOKE (71549) - ROANOKE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|6|BERGLUND INFINITI OF ROANOKE (71549) - ROANOKE",
                                                    "value": {
                                                        "key": "50bdaf4d-cd00-464d-a28e-03f687e46182",
                                                        "value": "BERGLUND INFINITI OF ROANOKE (71549) - ROANOKE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "HYMAN BROS INFINITI OF RICHMOND (71225) - Midlothian",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|6|HYMAN BROS INFINITI OF RICHMOND (71225) - Midlothian",
                                                    "value": {
                                                        "key": "394c1f15-3625-4e51-b8b0-5f163379fed5",
                                                        "value": "HYMAN BROS INFINITI OF RICHMOND (71225) - Midlothian",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF SILVER SPRING (70565) - SILVER SPRING",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|6|INFINITI OF SILVER SPRING (70565) - SILVER SPRING",
                                                    "value": {
                                                        "key": "d7c5021a-f4d2-4349-a92f-f069e1263e70",
                                                        "value": "INFINITI OF SILVER SPRING (70565) - SILVER SPRING",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF SUITLAND (70563) - SUITLAND",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|6|INFINITI OF SUITLAND (70563) - SUITLAND",
                                                    "value": {
                                                        "key": "ee3243ec-3c90-4ea7-8129-d3927981ef3e",
                                                        "value": "INFINITI OF SUITLAND (70563) - SUITLAND",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "JBA INFINITI OF ELLICOTT CITY (71481) - ELLICOTT CITY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|6|JBA INFINITI OF ELLICOTT CITY (71481) - ELLICOTT CITY",
                                                    "value": {
                                                        "key": "e9fbf6d1-8132-4b0e-8d11-a93973d87f12",
                                                        "value": "JBA INFINITI OF ELLICOTT CITY (71481) - ELLICOTT CITY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "JIM COLEMAN INFINITI (70226) - BETHESDA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|6|JIM COLEMAN INFINITI (70226) - BETHESDA",
                                                    "value": {
                                                        "key": "e7998c46-6484-40ca-94b8-06b10d7dba76",
                                                        "value": "JIM COLEMAN INFINITI (70226) - BETHESDA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "NATIONWIDE INFINITI OF TIMONIUM (70032) - TIMONIUM",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|6|NATIONWIDE INFINITI OF TIMONIUM (70032) - TIMONIUM",
                                                    "value": {
                                                        "key": "92afcbb3-2013-43e9-8230-701761905496",
                                                        "value": "NATIONWIDE INFINITI OF TIMONIUM (70032) - TIMONIUM",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "PASSPORT INFINITI OF ALEXANDRIA (71248) - ALEXANDRIA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|6|PASSPORT INFINITI OF ALEXANDRIA (71248) - ALEXANDRIA",
                                                    "value": {
                                                        "key": "9b875680-fc01-4537-b0cf-6348274102fd",
                                                        "value": "PASSPORT INFINITI OF ALEXANDRIA (71248) - ALEXANDRIA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "PEARSON INFINITI (70225) - MIDLOTHIAN",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|6|PEARSON INFINITI (70225) - MIDLOTHIAN",
                                                    "value": {
                                                        "key": "3d482309-0645-4072-8699-909b2e4aa2dc",
                                                        "value": "PEARSON INFINITI (70225) - MIDLOTHIAN",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "PRIORITY INFINITI OF GREENBRIER (71224) - CHESAPEAKE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|6|PRIORITY INFINITI OF GREENBRIER (71224) - CHESAPEAKE",
                                                    "value": {
                                                        "key": "b563fb68-35eb-4b65-b76d-3db3ef3fd989",
                                                        "value": "PRIORITY INFINITI OF GREENBRIER (71224) - CHESAPEAKE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SHEEHY INFINITI OF ANNAPOLIS (70523) - ANNAPOLIS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|6|SHEEHY INFINITI OF ANNAPOLIS (70523) - ANNAPOLIS",
                                                    "value": {
                                                        "key": "8a71255d-868a-4391-bf50-d6ba9b3edafe",
                                                        "value": "SHEEHY INFINITI OF ANNAPOLIS (70523) - ANNAPOLIS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SHEEHY INFINITI OF CHANTILLY (71517) - CHANTILLY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|6|SHEEHY INFINITI OF CHANTILLY (71517) - CHANTILLY",
                                                    "value": {
                                                        "key": "dc273669-db89-4fe1-a6d3-d0feae1b8d48",
                                                        "value": "SHEEHY INFINITI OF CHANTILLY (71517) - CHANTILLY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SHEEHY INFINITI OF TYSONS (73035) - VIENNA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DC|6|SHEEHY INFINITI OF TYSONS (73035) - VIENNA",
                                                    "value": {
                                                        "key": "2169f8ba-8220-4c02-b613-d000a209cf4c",
                                                        "value": "SHEEHY INFINITI OF TYSONS (73035) - VIENNA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "label": "DET",
                                    "name": "retailer",
                                    "type": "hierarchy-facet-container",
                                    "filterType": "attribute",
                                    "path": "Retailers|National Sales|East|DET",
                                    "value": {
                                        "key": "72DET",
                                        "value": "DET",
                                        "name": "national sales"
                                    },
                                    "valueType": "hierarchy-value",
                                    "searchable": true,
                                    "items": [
                                        {
                                            "label": "7",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|East|DET|7",
                                            "value": {
                                                "key": "72DET7",
                                                "value": "7",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "BOMMARITO INFINITI (70069) - ELLISVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|7|BOMMARITO INFINITI (70069) - ELLISVILLE",
                                                    "value": {
                                                        "key": "ecb5d394-a6f8-4044-a551-89b518533697",
                                                        "value": "BOMMARITO INFINITI (70069) - ELLISVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "DREYER & REINBOLD INFINITI (70059) - INDIANAPOLIS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|7|DREYER & REINBOLD INFINITI (70059) - INDIANAPOLIS",
                                                    "value": {
                                                        "key": "90dcbc75-8b91-4038-8939-08b88bc3d696",
                                                        "value": "DREYER & REINBOLD INFINITI (70059) - INDIANAPOLIS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "DREYER & REINBOLD OF GREENWOOD (70478) - GREENWOOD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|7|DREYER & REINBOLD OF GREENWOOD (70478) - GREENWOOD",
                                                    "value": {
                                                        "key": "f66ffae8-a2ce-44aa-9489-ec69ad56e537",
                                                        "value": "DREYER & REINBOLD OF GREENWOOD (70478) - GREENWOOD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "FIELDS INFINITI (70207) - GLENCOE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|7|FIELDS INFINITI (70207) - GLENCOE",
                                                    "value": {
                                                        "key": "43ef232d-3ce5-4c92-886a-7a3ccaa734df",
                                                        "value": "FIELDS INFINITI (70207) - GLENCOE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF FORT WAYNE (70265) - FORT WAYNE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|7|INFINITI OF FORT WAYNE (70265) - FORT WAYNE",
                                                    "value": {
                                                        "key": "79559923-02bd-433a-ba55-efe3af2f86e7",
                                                        "value": "INFINITI OF FORT WAYNE (70265) - FORT WAYNE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF KANSAS CITY (72067) - MERRIAM",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|7|INFINITI OF KANSAS CITY (72067) - MERRIAM",
                                                    "value": {
                                                        "key": "55f8d382-a578-4fc2-85ea-f1102c9d7968",
                                                        "value": "INFINITI OF KANSAS CITY (72067) - MERRIAM",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF LEXINGTON (72430) - LEXINGTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|7|INFINITI OF LEXINGTON (72430) - LEXINGTON",
                                                    "value": {
                                                        "key": "22f90a5b-38ca-4424-add6-73460d54dc09",
                                                        "value": "INFINITI OF LEXINGTON (72430) - LEXINGTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI of Northern Kentucky (70577) - FORT WRIGHT",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|7|INFINITI of Northern Kentucky (70577) - FORT WRIGHT",
                                                    "value": {
                                                        "key": "5171c0d9-905e-43e7-899a-144e32241b03",
                                                        "value": "INFINITI of Northern Kentucky (70577) - FORT WRIGHT",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF NORTHERN KENTUCKY (71577) - FORT WRIGHT",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|7|INFINITI OF NORTHERN KENTUCKY (71577) - FORT WRIGHT",
                                                    "value": {
                                                        "key": "64ea8a71-40ae-4a5e-9d0e-51485aeb19d8",
                                                        "value": "INFINITI OF NORTHERN KENTUCKY (71577) - FORT WRIGHT",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF SPRINGFIELD (72253) - SPRINGFIELD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|7|INFINITI OF SPRINGFIELD (72253) - SPRINGFIELD",
                                                    "value": {
                                                        "key": "d6b56853-d9c6-4a64-a7c1-a2f528de8f21",
                                                        "value": "INFINITI OF SPRINGFIELD (72253) - SPRINGFIELD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "LOUISVILLE INFINITI (71217) - LOUISVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|7|LOUISVILLE INFINITI (71217) - LOUISVILLE",
                                                    "value": {
                                                        "key": "f92637ae-0d0c-4349-82fa-99f843915416",
                                                        "value": "LOUISVILLE INFINITI (71217) - LOUISVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "PLAZA INFINITI (71068) - CREVE COEUR",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|7|PLAZA INFINITI (71068) - CREVE COEUR",
                                                    "value": {
                                                        "key": "19038598-44ac-4cd0-83be-56865f2986a0",
                                                        "value": "PLAZA INFINITI (71068) - CREVE COEUR",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        },
                                        {
                                            "label": "8",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|East|DET|8",
                                            "value": {
                                                "key": "72DET8",
                                                "value": "8",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "AIRPORT INFINITI (72531) - CLEVELAND",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|8|AIRPORT INFINITI (72531) - CLEVELAND",
                                                    "value": {
                                                        "key": "0963af3e-a289-4eba-87e0-5bafb5a7e558",
                                                        "value": "AIRPORT INFINITI (72531) - CLEVELAND",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "ANN ARBOR INFINITI (71530) - ANN ARBOR",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|8|ANN ARBOR INFINITI (71530) - ANN ARBOR",
                                                    "value": {
                                                        "key": "a6d80fe5-acb7-40e5-8f43-d57eed7747c7",
                                                        "value": "ANN ARBOR INFINITI (71530) - ANN ARBOR",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "EVANS INFINITI OF DAYTON (71216) - CENTERVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|8|EVANS INFINITI OF DAYTON (71216) - CENTERVILLE",
                                                    "value": {
                                                        "key": "9742a169-1ee4-4810-92d3-92587270e93d",
                                                        "value": "EVANS INFINITI OF DAYTON (71216) - CENTERVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "GERMAIN INFINITI OF EASTON (70537) - COLUMBUS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|8|GERMAIN INFINITI OF EASTON (70537) - COLUMBUS",
                                                    "value": {
                                                        "key": "99ac9fff-ffc6-488f-9524-f50384b32499",
                                                        "value": "GERMAIN INFINITI OF EASTON (70537) - COLUMBUS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF AKRON (71559) - AKRON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|8|INFINITI OF AKRON (71559) - AKRON",
                                                    "value": {
                                                        "key": "8e5e0b9a-0ea0-44a8-a8f3-4736ae3168a5",
                                                        "value": "INFINITI OF AKRON (71559) - AKRON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF BEACHWOOD (73055) - BEACHWOOD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|8|INFINITI OF BEACHWOOD (73055) - BEACHWOOD",
                                                    "value": {
                                                        "key": "322afeea-cd41-4341-8479-ba92d7a3503b",
                                                        "value": "INFINITI OF BEACHWOOD (73055) - BEACHWOOD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF CINCINNATI (70231) - CINCINNATI",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|8|INFINITI OF CINCINNATI (70231) - CINCINNATI",
                                                    "value": {
                                                        "key": "f1d23231-0bbb-4a8f-bc48-eb34c56b7183",
                                                        "value": "INFINITI OF CINCINNATI (70231) - CINCINNATI",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF COLUMBUS (71232) - DUBLIN",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|8|INFINITI OF COLUMBUS (71232) - DUBLIN",
                                                    "value": {
                                                        "key": "8d985b79-631b-46d6-a041-10446b9728ab",
                                                        "value": "INFINITI OF COLUMBUS (71232) - DUBLIN",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF GRAND RAPIDS (71434) - GRAND RAPIDS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|8|INFINITI OF GRAND RAPIDS (71434) - GRAND RAPIDS",
                                                    "value": {
                                                        "key": "4a147471-423c-4fc0-bc58-6d1971f56530",
                                                        "value": "INFINITI OF GRAND RAPIDS (71434) - GRAND RAPIDS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SUBURBAN INFINITI (70310) - NOVI",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|8|SUBURBAN INFINITI (70310) - NOVI",
                                                    "value": {
                                                        "key": "afe21c97-217a-481c-bcc3-cbfda5e3bc6c",
                                                        "value": "SUBURBAN INFINITI (70310) - NOVI",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SUBURBAN INFINITI OF NOVI (71310) - NOVI",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|8|SUBURBAN INFINITI OF NOVI (71310) - NOVI",
                                                    "value": {
                                                        "key": "8809a7e0-10a8-4577-9590-a85e9a531590",
                                                        "value": "SUBURBAN INFINITI OF NOVI (71310) - NOVI",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SUBURBAN INFINITI OF TROY (70532) - TROY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|8|SUBURBAN INFINITI OF TROY (70532) - TROY",
                                                    "value": {
                                                        "key": "7ba4813e-05f6-4f31-9411-ad6a0e1bfab3",
                                                        "value": "SUBURBAN INFINITI OF TROY (70532) - TROY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SUBURBAN INFINITI OF TROY (71532) - TROY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|DET|8|SUBURBAN INFINITI OF TROY (71532) - TROY",
                                                    "value": {
                                                        "key": "76e1a771-f354-4697-836f-caee6e0b4880",
                                                        "value": "SUBURBAN INFINITI OF TROY (71532) - TROY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "label": "NY",
                                    "name": "retailer",
                                    "type": "hierarchy-facet-container",
                                    "filterType": "attribute",
                                    "path": "Retailers|National Sales|East|NY",
                                    "value": {
                                        "key": "72NY",
                                        "value": "NY",
                                        "name": "national sales"
                                    },
                                    "valueType": "hierarchy-value",
                                    "searchable": true,
                                    "items": [
                                        {
                                            "label": "1",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|East|NY|1",
                                            "value": {
                                                "key": "72NY1",
                                                "value": "1",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "COMPETITION INFINITI (70016) - SAINT JAMES",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|1|COMPETITION INFINITI (70016) - SAINT JAMES",
                                                    "value": {
                                                        "key": "38d6d9d3-e2df-41d9-b4e1-e611905c5b24",
                                                        "value": "COMPETITION INFINITI (70016) - SAINT JAMES",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "DEVAN INFINITI OF FAIRFIELD (72007) - FAIRFIELD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|1|DEVAN INFINITI OF FAIRFIELD (72007) - FAIRFIELD",
                                                    "value": {
                                                        "key": "5720298e-b201-4ea7-aeb6-721df7d6e8b0",
                                                        "value": "DEVAN INFINITI OF FAIRFIELD (72007) - FAIRFIELD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "GREENWICH INFINITI (71518) - GREENWICH",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|1|GREENWICH INFINITI (71518) - GREENWICH",
                                                    "value": {
                                                        "key": "9affe32d-9ba9-4867-8b77-fc7722800c38",
                                                        "value": "GREENWICH INFINITI (71518) - GREENWICH",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF BAYSIDE (70560) - BAYSIDE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|1|INFINITI OF BAYSIDE (70560) - BAYSIDE",
                                                    "value": {
                                                        "key": "f22a6b3d-61f8-4550-82e0-93a92678520b",
                                                        "value": "INFINITI OF BAYSIDE (70560) - BAYSIDE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF GREENWICH (72518) - GREENWICH",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|1|INFINITI OF GREENWICH (72518) - GREENWICH",
                                                    "value": {
                                                        "key": "9e412f67-7d96-4ffe-af18-1508f5f49f15",
                                                        "value": "INFINITI OF GREENWICH (72518) - GREENWICH",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF LYNBROOK (73015) - LYNBROOK",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|1|INFINITI OF LYNBROOK (73015) - LYNBROOK",
                                                    "value": {
                                                        "key": "90e2bbef-0729-4945-8f03-37996e7fbd9c",
                                                        "value": "INFINITI OF LYNBROOK (73015) - LYNBROOK",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF MANHASSET (71014) - MANHASSET",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|1|INFINITI OF MANHASSET (71014) - MANHASSET",
                                                    "value": {
                                                        "key": "3e990854-8e73-453f-940c-0c267cdd83b0",
                                                        "value": "INFINITI OF MANHASSET (71014) - MANHASSET",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF MANHATTAN (71513) - NEW YORK",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|1|INFINITI OF MANHATTAN (71513) - NEW YORK",
                                                    "value": {
                                                        "key": "3ba4f078-4aa0-4b0d-9d74-ee862a9113ea",
                                                        "value": "INFINITI OF MANHATTAN (71513) - NEW YORK",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF MASSAPEQUA (70017) - MASSAPEQUA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|1|INFINITI OF MASSAPEQUA (70017) - MASSAPEQUA",
                                                    "value": {
                                                        "key": "c3efe624-c92d-4860-a441-dfee059a2994",
                                                        "value": "INFINITI OF MASSAPEQUA (70017) - MASSAPEQUA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "KINGS INFINITI (70012) - BROOKLYN",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|1|KINGS INFINITI (70012) - BROOKLYN",
                                                    "value": {
                                                        "key": "f085558b-dfcd-4475-a064-116107a61558",
                                                        "value": "KINGS INFINITI (70012) - BROOKLYN",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "PEPE INFINITI (70221) - WHITE PLAINS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|1|PEPE INFINITI (70221) - WHITE PLAINS",
                                                    "value": {
                                                        "key": "5c92f55c-fb08-4b19-b8f5-c01201b96965",
                                                        "value": "PEPE INFINITI (70221) - WHITE PLAINS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        },
                                        {
                                            "label": "2",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|East|NY|2",
                                            "value": {
                                                "key": "72NY2",
                                                "value": "2",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "CIRCLE INFINITI (70214) - WEST LONG BRANCH",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|2|CIRCLE INFINITI (70214) - WEST LONG BRANCH",
                                                    "value": {
                                                        "key": "c7962f60-1e4e-48b2-abd6-68cbbad80d29",
                                                        "value": "CIRCLE INFINITI (70214) - WEST LONG BRANCH",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "DOUGLAS INFINITI (70025) - SUMMIT",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|2|DOUGLAS INFINITI (70025) - SUMMIT",
                                                    "value": {
                                                        "key": "68994534-7769-4c71-8974-e6fb945545b1",
                                                        "value": "DOUGLAS INFINITI (70025) - SUMMIT",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "EDISON INFINITI (71024) - EDISON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|2|EDISON INFINITI (71024) - EDISON",
                                                    "value": {
                                                        "key": "de6e25ba-7cb2-4690-9d15-48fc8dd7183b",
                                                        "value": "EDISON INFINITI (71024) - EDISON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "FETTE INFINITI (71021) - CLIFTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|2|FETTE INFINITI (71021) - CLIFTON",
                                                    "value": {
                                                        "key": "3a44340d-f7aa-4e60-a746-bd972fc6f0be",
                                                        "value": "FETTE INFINITI (71021) - CLIFTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "FLEMINGTON INFINITI (71213) - FLEMINGTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|2|FLEMINGTON INFINITI (71213) - FLEMINGTON",
                                                    "value": {
                                                        "key": "90ae0aa0-d94a-43c3-a448-9d9e191b2873",
                                                        "value": "FLEMINGTON INFINITI (71213) - FLEMINGTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF ENGLEWOOD (73020) - ENGLEWOOD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|2|INFINITI OF ENGLEWOOD (73020) - ENGLEWOOD",
                                                    "value": {
                                                        "key": "c7fab8d5-df3e-4b59-958b-e890d93a6b2c",
                                                        "value": "INFINITI OF ENGLEWOOD (73020) - ENGLEWOOD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF ENGLEWOOD (74020) - ENGLEWOOD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|2|INFINITI OF ENGLEWOOD (74020) - ENGLEWOOD",
                                                    "value": {
                                                        "key": "cad347f1-be14-43a5-bf12-12752994fab6",
                                                        "value": "INFINITI OF ENGLEWOOD (74020) - ENGLEWOOD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF FLEMINGTON (72213) - FLEMINGTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|2|INFINITI OF FLEMINGTON (72213) - FLEMINGTON",
                                                    "value": {
                                                        "key": "15ed79cb-24d3-4d8f-9d2f-caf9b1879cf0",
                                                        "value": "INFINITI OF FLEMINGTON (72213) - FLEMINGTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "RAMSEY INFINITI (70212) - RAMSEY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|2|RAMSEY INFINITI (70212) - RAMSEY",
                                                    "value": {
                                                        "key": "cdbfc6ad-022d-4033-91d7-b3f5ebcbb28d",
                                                        "value": "RAMSEY INFINITI (70212) - RAMSEY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "RAY CATENA INFINITI (70024) - EDISON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|2|RAY CATENA INFINITI (70024) - EDISON",
                                                    "value": {
                                                        "key": "16722433-1e2b-4477-a4c1-3570e12c2ab7",
                                                        "value": "RAY CATENA INFINITI (70024) - EDISON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "RAY CATENA INFINITI OF BRIDGEWATER (70520) - BRIDGEWATER",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|2|RAY CATENA INFINITI OF BRIDGEWATER (70520) - BRIDGEWATER",
                                                    "value": {
                                                        "key": "3382686b-cd17-4957-a8b9-eb1ec20062f6",
                                                        "value": "RAY CATENA INFINITI OF BRIDGEWATER (70520) - BRIDGEWATER",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SALERNO*DUANE INFINITI OF DENVILLE (71023) - DENVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|2|SALERNO*DUANE INFINITI OF DENVILLE (71023) - DENVILLE",
                                                    "value": {
                                                        "key": "d963ff5c-9258-4544-9c71-b811c5a733f5",
                                                        "value": "SALERNO*DUANE INFINITI OF DENVILLE (71023) - DENVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        },
                                        {
                                            "label": "3",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|East|NY|3",
                                            "value": {
                                                "key": "72NY3",
                                                "value": "3",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "BOB JOHNSON INFINITI (73009) - Rochester",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|3|BOB JOHNSON INFINITI (73009) - Rochester",
                                                    "value": {
                                                        "key": "a97b9e61-7b2b-45ff-8b08-1e56372f140f",
                                                        "value": "BOB JOHNSON INFINITI (73009) - Rochester",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "DORSCHEL INFINITI (72009) - ROCHESTER",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|3|DORSCHEL INFINITI (72009) - ROCHESTER",
                                                    "value": {
                                                        "key": "578e2eac-755b-42d8-b82c-5f3436bcfaf3",
                                                        "value": "DORSCHEL INFINITI (72009) - ROCHESTER",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "GEORGE HARTE INFINITI (70406) - WALLINGFORD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|3|GEORGE HARTE INFINITI (70406) - WALLINGFORD",
                                                    "value": {
                                                        "key": "8372f278-799f-4287-a0f5-2ced95611e9b",
                                                        "value": "GEORGE HARTE INFINITI (70406) - WALLINGFORD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "HARTE INFINITI (70006) - HARTFORD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|3|HARTE INFINITI (70006) - HARTFORD",
                                                    "value": {
                                                        "key": "7942b750-99b6-45a4-98f0-47570cbca7f9",
                                                        "value": "HARTE INFINITI (70006) - HARTFORD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF SYRACUSE (71408) - SYRACUSE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|3|INFINITI OF SYRACUSE (71408) - SYRACUSE",
                                                    "value": {
                                                        "key": "669be0d8-2f6c-4e3c-b012-20784adf518e",
                                                        "value": "INFINITI OF SYRACUSE (71408) - SYRACUSE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "LIA INFINITI (71407) - COHOES",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|3|LIA INFINITI (71407) - COHOES",
                                                    "value": {
                                                        "key": "d5eca6d1-d298-43ba-b06e-78c0b8b7aa3d",
                                                        "value": "LIA INFINITI (71407) - COHOES",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "WEST HERR INFINITI (71008) - WILLIAMSVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|East|NY|3|WEST HERR INFINITI (71008) - WILLIAMSVILLE",
                                                    "value": {
                                                        "key": "807c7dfc-2f4c-4121-a0d5-3c95df27c9a2",
                                                        "value": "WEST HERR INFINITI (71008) - WILLIAMSVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "label": "South",
                            "name": "retailer",
                            "type": "hierarchy-facet-container",
                            "filterType": "attribute",
                            "path": "Retailers|National Sales|South",
                            "value": {
                                "key": "62",
                                "value": "South",
                                "name": "national sales"
                            },
                            "valueType": "hierarchy-value",
                            "searchable": true,
                            "items": [
                                {
                                    "label": "ATL",
                                    "name": "retailer",
                                    "type": "hierarchy-facet-container",
                                    "filterType": "attribute",
                                    "path": "Retailers|National Sales|South|ATL",
                                    "value": {
                                        "key": "62ATL",
                                        "value": "ATL",
                                        "name": "national sales"
                                    },
                                    "valueType": "hierarchy-value",
                                    "searchable": true,
                                    "items": [
                                        {
                                            "label": "5",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|South|ATL|5",
                                            "value": {
                                                "key": "62ATL5",
                                                "value": "5",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "HARPER INFINITI (70309) - KNOXVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|5|HARPER INFINITI (70309) - KNOXVILLE",
                                                    "value": {
                                                        "key": "f7cfd016-33f6-4e10-866c-ff7848fe80c3",
                                                        "value": "HARPER INFINITI (70309) - KNOXVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF AUGUSTA (73247) - AUGUSTA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|5|INFINITI OF AUGUSTA (73247) - AUGUSTA",
                                                    "value": {
                                                        "key": "846d898f-c050-49aa-aec9-253360609c8e",
                                                        "value": "INFINITI OF AUGUSTA (73247) - AUGUSTA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF CHATTANOOGA (74233) - CHATTANOOGA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|5|INFINITI OF CHATTANOOGA (74233) - CHATTANOOGA",
                                                    "value": {
                                                        "key": "1b1273fd-bf65-4690-a43c-51644432da67",
                                                        "value": "INFINITI OF CHATTANOOGA (74233) - CHATTANOOGA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI of Cool Springs (72234) - Franklin",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|5|INFINITI of Cool Springs (72234) - Franklin",
                                                    "value": {
                                                        "key": "ca64e024-6f9e-47a5-b60a-ba84a68f714d",
                                                        "value": "INFINITI of Cool Springs (72234) - Franklin",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF GWINNETT (70493) - DULUTH",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|5|INFINITI OF GWINNETT (70493) - DULUTH",
                                                    "value": {
                                                        "key": "b4c36609-b59a-4746-86a1-70a9cda27cd7",
                                                        "value": "INFINITI OF GWINNETT (70493) - DULUTH",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF MACON (70571) - MACON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|5|INFINITI OF MACON (70571) - MACON",
                                                    "value": {
                                                        "key": "b5ee370c-5367-4507-becc-6a12b27a2980",
                                                        "value": "INFINITI OF MACON (70571) - MACON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI of Macon (71571) - Macon",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|5|INFINITI of Macon (71571) - Macon",
                                                    "value": {
                                                        "key": "c7e28647-bab8-4817-a9cf-c24f07a96825",
                                                        "value": "INFINITI of Macon (71571) - Macon",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF SOUTH ATLANTA (70498) - UNION CITY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|5|INFINITI OF SOUTH ATLANTA (70498) - UNION CITY",
                                                    "value": {
                                                        "key": "b779a28a-7246-4f26-92f6-632a00d1bc60",
                                                        "value": "INFINITI OF SOUTH ATLANTA (70498) - UNION CITY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "Jim Hudson INFINITI of Augusta (74247) - Augusta",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|5|Jim Hudson INFINITI of Augusta (74247) - Augusta",
                                                    "value": {
                                                        "key": "926af842-9377-4fa3-b39c-e36259af73e7",
                                                        "value": "Jim Hudson INFINITI of Augusta (74247) - Augusta",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "NALLEY INFINITI OF ATLANTA (71045) - ATLANTA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|5|NALLEY INFINITI OF ATLANTA (71045) - ATLANTA",
                                                    "value": {
                                                        "key": "db10ddef-a99d-415b-8f24-2e6a8d7a7f0f",
                                                        "value": "NALLEY INFINITI OF ATLANTA (71045) - ATLANTA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "NALLEY INFINITI-MARIETTA (71304) - MARIETTA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|5|NALLEY INFINITI-MARIETTA (71304) - MARIETTA",
                                                    "value": {
                                                        "key": "59625d37-1239-4238-9363-4e3b737540e8",
                                                        "value": "NALLEY INFINITI-MARIETTA (71304) - MARIETTA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "ROSWELL INFINITI OF NORTH ATLANTA (70044) - ROSWELL",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|5|ROSWELL INFINITI OF NORTH ATLANTA (70044) - ROSWELL",
                                                    "value": {
                                                        "key": "5fb30425-9545-4de6-9717-3fbb461c3c3a",
                                                        "value": "ROSWELL INFINITI OF NORTH ATLANTA (70044) - ROSWELL",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SMITH INFINITI/HUNTSVILLE (72486) - HUNTSVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|5|SMITH INFINITI/HUNTSVILLE (72486) - HUNTSVILLE",
                                                    "value": {
                                                        "key": "9717dbac-3a71-4b94-a45e-ba3d2ee5d660",
                                                        "value": "SMITH INFINITI/HUNTSVILLE (72486) - HUNTSVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        },
                                        {
                                            "label": "6",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|South|ATL|6",
                                            "value": {
                                                "key": "62ATL6",
                                                "value": "6",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "BREWBAKER INFINITI (71266) - MONTGOMERY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|6|BREWBAKER INFINITI (71266) - MONTGOMERY",
                                                    "value": {
                                                        "key": "48d3ceb7-b7f8-435e-b225-c3fe64e1be48",
                                                        "value": "BREWBAKER INFINITI (71266) - MONTGOMERY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "HERRIN-GEAR INFINITI (70203) - JACKSON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|6|HERRIN-GEAR INFINITI (70203) - JACKSON",
                                                    "value": {
                                                        "key": "e366cbb2-b954-4209-9192-b7c83d6c4e73",
                                                        "value": "HERRIN-GEAR INFINITI (70203) - JACKSON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF BATON ROUGE (70443) - BATON ROUGE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|6|INFINITI OF BATON ROUGE (70443) - BATON ROUGE",
                                                    "value": {
                                                        "key": "aca4863c-cf36-445a-8f6d-b9147bb3ae2b",
                                                        "value": "INFINITI OF BATON ROUGE (70443) - BATON ROUGE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF BIRMINGHAM (71073) - HOOVER",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|6|INFINITI OF BIRMINGHAM (71073) - HOOVER",
                                                    "value": {
                                                        "key": "9a61a635-1dff-4fe7-baba-9cf663706701",
                                                        "value": "INFINITI OF BIRMINGHAM (71073) - HOOVER",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF LAFAYETTE (70483) - LAFAYETTE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|6|INFINITI OF LAFAYETTE (70483) - LAFAYETTE",
                                                    "value": {
                                                        "key": "f0b43cec-0c4c-4ab4-9f35-2a2ac4288c45",
                                                        "value": "INFINITI OF LAFAYETTE (70483) - LAFAYETTE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF MEMPHIS (70072) - BARTLETT",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|6|INFINITI OF MEMPHIS (70072) - BARTLETT",
                                                    "value": {
                                                        "key": "a8fc722e-f6cd-4aba-9b94-c2db4f3bfb8a",
                                                        "value": "INFINITI OF MEMPHIS (70072) - BARTLETT",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF MEMPHIS (71072) - BARTLETT",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|6|INFINITI OF MEMPHIS (71072) - BARTLETT",
                                                    "value": {
                                                        "key": "72951c99-ac78-4ba3-9c4b-38c4dffdc368",
                                                        "value": "INFINITI OF MEMPHIS (71072) - BARTLETT",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF MOBILE (71239) - MOBILE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|6|INFINITI OF MOBILE (71239) - MOBILE",
                                                    "value": {
                                                        "key": "5fbb609d-635e-4d90-bb69-1a2b0d78740b",
                                                        "value": "INFINITI OF MOBILE (71239) - MOBILE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF MONTGOMERY (72266) - MONTGOMERY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|6|INFINITI OF MONTGOMERY (72266) - MONTGOMERY",
                                                    "value": {
                                                        "key": "c8850e58-3c85-4169-83d5-a828f445e555",
                                                        "value": "INFINITI OF MONTGOMERY (72266) - MONTGOMERY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI/SO MISSISSIPPI (70567) - D'IBERVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|6|INFINITI/SO MISSISSIPPI (70567) - D'IBERVILLE",
                                                    "value": {
                                                        "key": "8d9c2e8f-0119-4881-9fe3-7783472d8274",
                                                        "value": "INFINITI/SO MISSISSIPPI (70567) - D'IBERVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "ORR INFINITI (71264) - SHREVEPORT",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|6|ORR INFINITI (71264) - SHREVEPORT",
                                                    "value": {
                                                        "key": "771b38b9-7891-447c-9153-c247ba86db12",
                                                        "value": "ORR INFINITI (71264) - SHREVEPORT",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "RAY BRANDT INF/METAIRIE (71229) - METAIRIE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|6|RAY BRANDT INF/METAIRIE (71229) - METAIRIE",
                                                    "value": {
                                                        "key": "69708e80-c6b9-4b9c-a8b5-450baa9027bf",
                                                        "value": "RAY BRANDT INF/METAIRIE (71229) - METAIRIE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        },
                                        {
                                            "label": "7",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|South|ATL|7",
                                            "value": {
                                                "key": "62ATL7",
                                                "value": "7",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "BAKER INFINITI CHARLESTON (70320) - CHARLESTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|7|BAKER INFINITI CHARLESTON (70320) - CHARLESTON",
                                                    "value": {
                                                        "key": "72ac505a-6e03-4b9f-be4a-ffa5bf5b070c",
                                                        "value": "BAKER INFINITI CHARLESTON (70320) - CHARLESTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "BRADSHAW INFINITI (71423) - GREENVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|7|BRADSHAW INFINITI (71423) - GREENVILLE",
                                                    "value": {
                                                        "key": "60aff500-dc25-4123-b6d9-0bf0ca6cd535",
                                                        "value": "BRADSHAW INFINITI (71423) - GREENVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "CROSSROADS INFINITI OF APEX (70494) - APEX",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|7|CROSSROADS INFINITI OF APEX (70494) - APEX",
                                                    "value": {
                                                        "key": "4cdba8cb-fe52-4972-a36a-d01e950e49e4",
                                                        "value": "CROSSROADS INFINITI OF APEX (70494) - APEX",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "CROSSROADS INFINITI OF RALEIGH (72040) - RALEIGH",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|7|CROSSROADS INFINITI OF RALEIGH (72040) - RALEIGH",
                                                    "value": {
                                                        "key": "0a5943f2-a806-4974-9602-223c55dc7d9a",
                                                        "value": "CROSSROADS INFINITI OF RALEIGH (72040) - RALEIGH",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "CROSSROADS INFINITI OF WILMINGTON (70588) - WILMINGTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|7|CROSSROADS INFINITI OF WILMINGTON (70588) - WILMINGTON",
                                                    "value": {
                                                        "key": "4a3a4989-1c37-42fc-b041-7eb707ffc5fd",
                                                        "value": "CROSSROADS INFINITI OF WILMINGTON (70588) - WILMINGTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "DICK SMITH INFINITI (70202) - COLUMBIA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|7|DICK SMITH INFINITI (70202) - COLUMBIA",
                                                    "value": {
                                                        "key": "48f2ba5c-84a0-423c-9cf3-4cbcd8190789",
                                                        "value": "DICK SMITH INFINITI (70202) - COLUMBIA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF CHARLOTTE (71042) - MATTHEWS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|7|INFINITI OF CHARLOTTE (71042) - MATTHEWS",
                                                    "value": {
                                                        "key": "1be9c931-9f0b-4abe-b39f-24898fb97d94",
                                                        "value": "INFINITI OF CHARLOTTE (71042) - MATTHEWS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF HILTON HEAD (71500) - BLUFFTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|7|INFINITI OF HILTON HEAD (71500) - BLUFFTON",
                                                    "value": {
                                                        "key": "87807621-d746-4c36-9a3a-d111eade9f92",
                                                        "value": "INFINITI OF HILTON HEAD (71500) - BLUFFTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "LAKE NORMAN INFINITI (70522) - CORNELIUS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|7|LAKE NORMAN INFINITI (70522) - CORNELIUS",
                                                    "value": {
                                                        "key": "1130442f-199b-46c1-a63c-f7461b3e4dec",
                                                        "value": "LAKE NORMAN INFINITI (70522) - CORNELIUS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "MODERN INFINITI (71041) - GREENSBORO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|7|MODERN INFINITI (71041) - GREENSBORO",
                                                    "value": {
                                                        "key": "953ac684-724b-4b43-aa52-879a6f1f1eb0",
                                                        "value": "MODERN INFINITI (71041) - GREENSBORO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "MODERN INFINITI OF WINSTON-SALEM (70497) - WINSTON SALEM",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|ATL|7|MODERN INFINITI OF WINSTON-SALEM (70497) - WINSTON SALEM",
                                                    "value": {
                                                        "key": "5533e2b8-f080-415b-8ad8-bdcbd5f06008",
                                                        "value": "MODERN INFINITI OF WINSTON-SALEM (70497) - WINSTON SALEM",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "label": "DAL",
                                    "name": "retailer",
                                    "type": "hierarchy-facet-container",
                                    "filterType": "attribute",
                                    "path": "Retailers|National Sales|South|DAL",
                                    "value": {
                                        "key": "62DAL",
                                        "value": "DAL",
                                        "name": "national sales"
                                    },
                                    "valueType": "hierarchy-value",
                                    "searchable": true,
                                    "items": [
                                        {
                                            "label": "1",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|South|DAL|1",
                                            "value": {
                                                "key": "62DAL1",
                                                "value": "1",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "BOB MOORE INFINITI (70075) - OKLAHOMA CITY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|1|BOB MOORE INFINITI (70075) - OKLAHOMA CITY",
                                                    "value": {
                                                        "key": "fe80c095-87a4-4fd1-87ab-5545d04a833e",
                                                        "value": "BOB MOORE INFINITI (70075) - OKLAHOMA CITY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "CHARLIE CLARK INFINITI OF EL PASO (72447) - EL PASO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|1|CHARLIE CLARK INFINITI OF EL PASO (72447) - EL PASO",
                                                    "value": {
                                                        "key": "51483337-c02d-423b-8e9b-807fe0290f31",
                                                        "value": "CHARLIE CLARK INFINITI OF EL PASO (72447) - EL PASO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "CREST INFINITI (70477) - FRISCO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|1|CREST INFINITI (70477) - FRISCO",
                                                    "value": {
                                                        "key": "a70baf35-d083-4561-81c7-9ec194e3ba92",
                                                        "value": "CREST INFINITI (70477) - FRISCO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "GRUBBS INFINITI (70078) - GRAPEVINE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|1|GRUBBS INFINITI (70078) - GRAPEVINE",
                                                    "value": {
                                                        "key": "5496c4d6-664d-40b3-983c-1ef8a3e3975e",
                                                        "value": "GRUBBS INFINITI (70078) - GRAPEVINE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI EL PASO (71447) - EL PASO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|1|INFINITI EL PASO (71447) - EL PASO",
                                                    "value": {
                                                        "key": "e197f748-02e4-40a9-b4a1-c729b3b17de6",
                                                        "value": "INFINITI EL PASO (71447) - EL PASO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF CENTRAL ARKANSAS (75461) - BENTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|1|INFINITI OF CENTRAL ARKANSAS (75461) - BENTON",
                                                    "value": {
                                                        "key": "e492d856-fd3c-47fb-a2a1-291947326af8",
                                                        "value": "INFINITI OF CENTRAL ARKANSAS (75461) - BENTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "JACKIE COOPER INFINITI (70487) - TULSA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|1|JACKIE COOPER INFINITI (70487) - TULSA",
                                                    "value": {
                                                        "key": "23289895-32ea-4a73-930e-133d17c330d7",
                                                        "value": "JACKIE COOPER INFINITI (70487) - TULSA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "MCGAVOCK INFINITI OF LUBBOCK (70570) - LUBBOCK",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|1|MCGAVOCK INFINITI OF LUBBOCK (70570) - LUBBOCK",
                                                    "value": {
                                                        "key": "c2722bb6-24c7-4940-aa7e-58f25c14a4f9",
                                                        "value": "MCGAVOCK INFINITI OF LUBBOCK (70570) - LUBBOCK",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SEWELL INFINITI (71077) - DALLAS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|1|SEWELL INFINITI (71077) - DALLAS",
                                                    "value": {
                                                        "key": "f36467e9-485e-4b49-af4c-ccf81b0e1134",
                                                        "value": "SEWELL INFINITI (71077) - DALLAS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SEWELL INFINITI OF FORT WORTH (70538) - FORT WORTH",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|1|SEWELL INFINITI OF FORT WORTH (70538) - FORT WORTH",
                                                    "value": {
                                                        "key": "302ebb16-1991-4946-a45c-daa18b9e8396",
                                                        "value": "SEWELL INFINITI OF FORT WORTH (70538) - FORT WORTH",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "STEVEN INFINITI (71230) - WICHITA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|1|STEVEN INFINITI (71230) - WICHITA",
                                                    "value": {
                                                        "key": "99a50ec0-342e-4467-8270-bc31543ea2b9",
                                                        "value": "STEVEN INFINITI (71230) - WICHITA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        },
                                        {
                                            "label": "2",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|South|DAL|2",
                                            "value": {
                                                "key": "62DAL2",
                                                "value": "2",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "AUSTIN INFINITI (70403) - AUSTIN",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|2|AUSTIN INFINITI (70403) - AUSTIN",
                                                    "value": {
                                                        "key": "58c11ccb-b279-4472-a9af-702a804e33b5",
                                                        "value": "AUSTIN INFINITI (70403) - AUSTIN",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "BERT OGDEN INFINITI (70545) - EDINBURG",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|2|BERT OGDEN INFINITI (70545) - EDINBURG",
                                                    "value": {
                                                        "key": "2e789bc5-401f-49cf-8f8c-b35f116232b7",
                                                        "value": "BERT OGDEN INFINITI (70545) - EDINBURG",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "CLEAR LAKE INFINITI (71501) - HOUSTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|2|CLEAR LAKE INFINITI (71501) - HOUSTON",
                                                    "value": {
                                                        "key": "83208ae1-8bc9-4091-879a-87844ba72d9b",
                                                        "value": "CLEAR LAKE INFINITI (71501) - HOUSTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "CLEAR LAKE INFINITI (72501) - HOUSTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|2|CLEAR LAKE INFINITI (72501) - HOUSTON",
                                                    "value": {
                                                        "key": "aec3371a-b015-4cc8-ade0-0e3be0cc1ad8",
                                                        "value": "CLEAR LAKE INFINITI (72501) - HOUSTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "ED HICKS INFINITI (70551) - CORPUS CHRISTI",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|2|ED HICKS INFINITI (70551) - CORPUS CHRISTI",
                                                    "value": {
                                                        "key": "dbc56c15-c778-4b37-a0b0-27d1ba186c1f",
                                                        "value": "ED HICKS INFINITI (70551) - CORPUS CHRISTI",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "GRUBBS INFINITI OF SAN ANTONIO (71236) - SAN ANTONIO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|2|GRUBBS INFINITI OF SAN ANTONIO (71236) - SAN ANTONIO",
                                                    "value": {
                                                        "key": "3493251f-263b-4a93-a5c1-05d2093f19fe",
                                                        "value": "GRUBBS INFINITI OF SAN ANTONIO (71236) - SAN ANTONIO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "PRINCIPLE INFINITI OF BOERNE (70562) - BOERNE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|2|PRINCIPLE INFINITI OF BOERNE (70562) - BOERNE",
                                                    "value": {
                                                        "key": "3eeb673c-fe3a-42ec-b4fe-cc2db44f2452",
                                                        "value": "PRINCIPLE INFINITI OF BOERNE (70562) - BOERNE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SEWELL INFINITI OF NORTH HOUSTON (71488) - HOUSTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|2|SEWELL INFINITI OF NORTH HOUSTON (71488) - HOUSTON",
                                                    "value": {
                                                        "key": "5829baf5-7bf2-4514-887f-d8797b61be58",
                                                        "value": "SEWELL INFINITI OF NORTH HOUSTON (71488) - HOUSTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SOUTHWEST INFINITI (71235) - HOUSTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|2|SOUTHWEST INFINITI (71235) - HOUSTON",
                                                    "value": {
                                                        "key": "f45e1119-6934-4244-a824-feb38eb8e47b",
                                                        "value": "SOUTHWEST INFINITI (71235) - HOUSTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SOUTHWEST INFINITI (72235) - HOUSTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|2|SOUTHWEST INFINITI (72235) - HOUSTON",
                                                    "value": {
                                                        "key": "d3822605-239f-4615-aa0b-01addeb5a4d8",
                                                        "value": "SOUTHWEST INFINITI (72235) - HOUSTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "WEST HOUSTON INFINITI (70079) - HOUSTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|DAL|2|WEST HOUSTON INFINITI (70079) - HOUSTON",
                                                    "value": {
                                                        "key": "21405e5f-c2da-4479-8b24-8e5b62fe6ad6",
                                                        "value": "WEST HOUSTON INFINITI (70079) - HOUSTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "label": "FL",
                                    "name": "retailer",
                                    "type": "hierarchy-facet-container",
                                    "filterType": "attribute",
                                    "path": "Retailers|National Sales|South|FL",
                                    "value": {
                                        "key": "62FL",
                                        "value": "FL",
                                        "name": "national sales"
                                    },
                                    "valueType": "hierarchy-value",
                                    "searchable": true,
                                    "items": [
                                        {
                                            "label": "3",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|South|FL|3",
                                            "value": {
                                                "key": "62FL3",
                                                "value": "3",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "FORT MYERS INFINITI (71529) - FORT MYERS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|3|FORT MYERS INFINITI (71529) - FORT MYERS",
                                                    "value": {
                                                        "key": "b3151d3e-bf01-45a5-ba68-5425291e5b72",
                                                        "value": "FORT MYERS INFINITI (71529) - FORT MYERS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF COCONUT CREEK (70512) - COCONUT CREEK",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|3|INFINITI OF COCONUT CREEK (70512) - COCONUT CREEK",
                                                    "value": {
                                                        "key": "832eff91-3899-4aba-9aa3-fa2890a12d7a",
                                                        "value": "INFINITI OF COCONUT CREEK (70512) - COCONUT CREEK",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF CORAL GABLES (70564) - CORAL GABLES",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|3|INFINITI OF CORAL GABLES (70564) - CORAL GABLES",
                                                    "value": {
                                                        "key": "4bb02af8-0b80-442b-aaa1-d4a6f148a6f8",
                                                        "value": "INFINITI OF CORAL GABLES (70564) - CORAL GABLES",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF CORAL GABLES (71564) - CORAL GABLES",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|3|INFINITI OF CORAL GABLES (71564) - CORAL GABLES",
                                                    "value": {
                                                        "key": "53f3d2c2-c4f4-4c41-b595-517fbab6a494",
                                                        "value": "INFINITI OF CORAL GABLES (71564) - CORAL GABLES",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF PALM BEACHES (71050) - WEST PALM BEACH",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|3|INFINITI OF PALM BEACHES (71050) - WEST PALM BEACH",
                                                    "value": {
                                                        "key": "16ff3b34-bcf2-4baf-a4f5-049d2a09b712",
                                                        "value": "INFINITI OF PALM BEACHES (71050) - WEST PALM BEACH",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI STUART (70557) - STUART",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|3|INFINITI STUART (70557) - STUART",
                                                    "value": {
                                                        "key": "a5741406-4363-4190-aa4d-e663cb896f18",
                                                        "value": "INFINITI STUART (70557) - STUART",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "LAUDERDALE INFINITI (71527) - FORT LAUDERDALE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|3|LAUDERDALE INFINITI (71527) - FORT LAUDERDALE",
                                                    "value": {
                                                        "key": "e308d882-5555-4822-b50e-218ba4a359bf",
                                                        "value": "LAUDERDALE INFINITI (71527) - FORT LAUDERDALE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "NAPLES INFINITI (75033) - NAPLES",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|3|NAPLES INFINITI (75033) - NAPLES",
                                                    "value": {
                                                        "key": "e40c87a9-a5c0-4598-9d50-c9957ed76960",
                                                        "value": "NAPLES INFINITI (75033) - NAPLES",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SAWGRASS INFINITI (73051) - TAMARAC",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|3|SAWGRASS INFINITI (73051) - TAMARAC",
                                                    "value": {
                                                        "key": "87617102-4f90-4804-8a07-4d01867bd9ee",
                                                        "value": "SAWGRASS INFINITI (73051) - TAMARAC",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SOUTH MOTORS INFINITI (70053) - PALMETTO BAY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|3|SOUTH MOTORS INFINITI (70053) - PALMETTO BAY",
                                                    "value": {
                                                        "key": "6fe90ab0-c994-4acf-934f-49b8203027a3",
                                                        "value": "SOUTH MOTORS INFINITI (70053) - PALMETTO BAY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "WARREN HENRY INFINITI (70052) - NORTH MIAMI",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|3|WARREN HENRY INFINITI (70052) - NORTH MIAMI",
                                                    "value": {
                                                        "key": "d63afcc9-dc07-4530-9b43-81430105db13",
                                                        "value": "WARREN HENRY INFINITI (70052) - NORTH MIAMI",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        },
                                        {
                                            "label": "4",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|South|FL|4",
                                            "value": {
                                                "key": "62FL4",
                                                "value": "4",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "ATLANTIC INFINITI (70046) - JACKSONVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|4|ATLANTIC INFINITI (70046) - JACKSONVILLE",
                                                    "value": {
                                                        "key": "d87ad1b2-b20b-44ad-ab56-80ed00e331c0",
                                                        "value": "ATLANTIC INFINITI (70046) - JACKSONVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "DAYTONA INFINITI (70568) - DAYTONA BEACH",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|4|DAYTONA INFINITI (70568) - DAYTONA BEACH",
                                                    "value": {
                                                        "key": "5a4001bd-21f6-4285-8320-7264715a315e",
                                                        "value": "DAYTONA INFINITI (70568) - DAYTONA BEACH",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "HANANIA INFINITI OF ORANGE PARK (70555) - JACKSONVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|4|HANANIA INFINITI OF ORANGE PARK (70555) - JACKSONVILLE",
                                                    "value": {
                                                        "key": "c081efda-dcf2-4d33-aba2-a26167e7eec2",
                                                        "value": "HANANIA INFINITI OF ORANGE PARK (70555) - JACKSONVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF CLEARWATER (71049) - CLEARWATER",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|4|INFINITI OF CLEARWATER (71049) - CLEARWATER",
                                                    "value": {
                                                        "key": "d307e46a-3419-43f3-89c7-bddd9b94d345",
                                                        "value": "INFINITI OF CLEARWATER (71049) - CLEARWATER",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF MELBOURNE (71268) - MELBOURNE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|4|INFINITI OF MELBOURNE (71268) - MELBOURNE",
                                                    "value": {
                                                        "key": "c081127e-ec8c-4bd9-8a7c-8cd725a32ad3",
                                                        "value": "INFINITI OF MELBOURNE (71268) - MELBOURNE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF MELBOURNE (72268) - MELBOURNE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|4|INFINITI OF MELBOURNE (72268) - MELBOURNE",
                                                    "value": {
                                                        "key": "8877f5d3-7c6d-4882-8fcf-bce5bb33fddb",
                                                        "value": "INFINITI OF MELBOURNE (72268) - MELBOURNE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF SARASOTA (71245) - SARASOTA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|4|INFINITI OF SARASOTA (71245) - SARASOTA",
                                                    "value": {
                                                        "key": "09abd2f3-0437-4f01-ae7f-16dc44a4f134",
                                                        "value": "INFINITI OF SARASOTA (71245) - SARASOTA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF TALLAHASSEE (73251) - TALLAHASSEE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|4|INFINITI OF TALLAHASSEE (73251) - TALLAHASSEE",
                                                    "value": {
                                                        "key": "94667be0-6b84-4ca5-aa41-ce3bb389e9f2",
                                                        "value": "INFINITI OF TALLAHASSEE (73251) - TALLAHASSEE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF TAMPA (70048) - TAMPA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|4|INFINITI OF TAMPA (70048) - TAMPA",
                                                    "value": {
                                                        "key": "37ea4e1d-78f3-441b-a252-c5e56aa4fa53",
                                                        "value": "INFINITI OF TAMPA (70048) - TAMPA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "LOKEY INFINITI (70049) - CLEARWATER",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|4|LOKEY INFINITI (70049) - CLEARWATER",
                                                    "value": {
                                                        "key": "c8f03320-2bac-435d-b12e-54bd775f2b79",
                                                        "value": "LOKEY INFINITI (70049) - CLEARWATER",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "NAPLETONS INF TALLAHASSEE (72251) - TALLAHASSEE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|4|NAPLETONS INF TALLAHASSEE (72251) - TALLAHASSEE",
                                                    "value": {
                                                        "key": "d696154b-4add-4c97-b5c5-d3076c28ecf9",
                                                        "value": "NAPLETONS INF TALLAHASSEE (72251) - TALLAHASSEE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "ORLANDO INFINITI (71047) - ORLANDO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|4|ORLANDO INFINITI (71047) - ORLANDO",
                                                    "value": {
                                                        "key": "40765f50-dadc-4a69-899c-53fcde36cba8",
                                                        "value": "ORLANDO INFINITI (71047) - ORLANDO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "SANFORD INFINITI (70554) - SANFORD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|South|FL|4|SANFORD INFINITI (70554) - SANFORD",
                                                    "value": {
                                                        "key": "b213f530-7cfa-4b71-9925-556abe5f8aef",
                                                        "value": "SANFORD INFINITI (70554) - SANFORD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "label": "West",
                            "name": "retailer",
                            "type": "hierarchy-facet-container",
                            "filterType": "attribute",
                            "path": "Retailers|National Sales|West",
                            "value": {
                                "key": "92",
                                "value": "West",
                                "name": "national sales"
                            },
                            "valueType": "hierarchy-value",
                            "searchable": true,
                            "items": [
                                {
                                    "label": "CHI",
                                    "name": "retailer",
                                    "type": "hierarchy-facet-container",
                                    "filterType": "attribute",
                                    "path": "Retailers|National Sales|West|CHI",
                                    "value": {
                                        "key": "92CHI",
                                        "value": "CHI",
                                        "name": "national sales"
                                    },
                                    "valueType": "hierarchy-value",
                                    "searchable": true,
                                    "items": [
                                        {
                                            "label": "7",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|West|CHI|7",
                                            "value": {
                                                "key": "92CHI7",
                                                "value": "7",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "BERMAN INFINITI OF NILES (70581) - NILES",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|7|BERMAN INFINITI OF NILES (70581) - NILES",
                                                    "value": {
                                                        "key": "32efe130-a553-4c99-a08a-c6554d826384",
                                                        "value": "BERMAN INFINITI OF NILES (70581) - NILES",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "BERMAN'S INF/MERRILLVILLE (70556) - MERRILLVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|7|BERMAN'S INF/MERRILLVILLE (70556) - MERRILLVILLE",
                                                    "value": {
                                                        "key": "6ce49aa2-012c-4c4d-a122-cf87bfac8936",
                                                        "value": "BERMAN'S INF/MERRILLVILLE (70556) - MERRILLVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "BERMAN'S INFINITI CHICAGO (73063) - CHICAGO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|7|BERMAN'S INFINITI CHICAGO (73063) - CHICAGO",
                                                    "value": {
                                                        "key": "b0078ecd-dccc-41c3-9720-1cdc087df081",
                                                        "value": "BERMAN'S INFINITI CHICAGO (73063) - CHICAGO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "GREGORY INFINITI (72060) - LIBERTYVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|7|GREGORY INFINITI (72060) - LIBERTYVILLE",
                                                    "value": {
                                                        "key": "d2d5460a-47ba-40e4-a2b5-875047b80fe3",
                                                        "value": "GREGORY INFINITI (72060) - LIBERTYVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI HOFFMAN ESTATES (70521) - HOFFMAN ESTATES",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|7|INFINITI HOFFMAN ESTATES (70521) - HOFFMAN ESTATES",
                                                    "value": {
                                                        "key": "cefc4c93-17c1-491c-ae72-4549271ecce6",
                                                        "value": "INFINITI HOFFMAN ESTATES (70521) - HOFFMAN ESTATES",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF CLARENDON HILLS (70536) - CLARENDON HILLS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|7|INFINITI OF CLARENDON HILLS (70536) - CLARENDON HILLS",
                                                    "value": {
                                                        "key": "df2475f1-d8ce-4631-9255-d51d675f6cf2",
                                                        "value": "INFINITI OF CLARENDON HILLS (70536) - CLARENDON HILLS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF HOFFMAN ESTATES (71521) - HOFFMAN ESTATES",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|7|INFINITI OF HOFFMAN ESTATES (71521) - HOFFMAN ESTATES",
                                                    "value": {
                                                        "key": "5c7d6404-2c20-4fa0-bda8-f740a29c9efd",
                                                        "value": "INFINITI OF HOFFMAN ESTATES (71521) - HOFFMAN ESTATES",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF NAPERVILLE (70062) - NAPERVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|7|INFINITI OF NAPERVILLE (70062) - NAPERVILLE",
                                                    "value": {
                                                        "key": "0b16a811-1332-4252-8ff9-a52e87950a78",
                                                        "value": "INFINITI OF NAPERVILLE (70062) - NAPERVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF ORLAND PARK (71308) - ORLAND PARK",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|7|INFINITI OF ORLAND PARK (71308) - ORLAND PARK",
                                                    "value": {
                                                        "key": "aba53cb2-fd7e-4b30-b1d5-0e500eccbeb0",
                                                        "value": "INFINITI OF ORLAND PARK (71308) - ORLAND PARK",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "MOTOR WERKS INFINITI (71119) - BARRINGTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|7|MOTOR WERKS INFINITI (71119) - BARRINGTON",
                                                    "value": {
                                                        "key": "c1729999-50ad-4354-a087-de9570a439fe",
                                                        "value": "MOTOR WERKS INFINITI (71119) - BARRINGTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        },
                                        {
                                            "label": "8",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|West|CHI|8",
                                            "value": {
                                                "key": "92CHI8",
                                                "value": "8",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "BERGSTROM INFINITI (70546) - APPLETON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|8|BERGSTROM INFINITI (70546) - APPLETON",
                                                    "value": {
                                                        "key": "7b238cdf-291b-4240-9fcd-99e61cb2ab51",
                                                        "value": "BERGSTROM INFINITI (70546) - APPLETON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI MILWAUKEE (72065) - WEST ALLIS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|8|INFINITI MILWAUKEE (72065) - WEST ALLIS",
                                                    "value": {
                                                        "key": "b46efe10-3b58-4e1c-9449-db11a1a04e0f",
                                                        "value": "INFINITI MILWAUKEE (72065) - WEST ALLIS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI NORTH SHORE (72525) - GLENDALE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|8|INFINITI NORTH SHORE (72525) - GLENDALE",
                                                    "value": {
                                                        "key": "4180a81b-4e86-4262-b1dc-5bd1b3ab6ce7",
                                                        "value": "INFINITI NORTH SHORE (72525) - GLENDALE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF OMAHA (72313) - ELKHORN",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|8|INFINITI OF OMAHA (72313) - ELKHORN",
                                                    "value": {
                                                        "key": "1a784c3f-1cce-451e-af59-92f7c1cbd82b",
                                                        "value": "INFINITI OF OMAHA (72313) - ELKHORN",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "JIM LUPIENT INFINITI (70311) - GOLDEN VALLEY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|8|JIM LUPIENT INFINITI (70311) - GOLDEN VALLEY",
                                                    "value": {
                                                        "key": "a49325cc-e5e4-4a87-a24f-212cb6757fb5",
                                                        "value": "JIM LUPIENT INFINITI (70311) - GOLDEN VALLEY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "LUTHER INFINITI OF BLOOMINGTON (70066) - BLOOMINGTON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|8|LUTHER INFINITI OF BLOOMINGTON (70066) - BLOOMINGTON",
                                                    "value": {
                                                        "key": "2ea846b7-684e-4d58-a5b3-ad088d67cc42",
                                                        "value": "LUTHER INFINITI OF BLOOMINGTON (70066) - BLOOMINGTON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "WILLIS INFINITI (72254) - DES MOINES",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|8|WILLIS INFINITI (72254) - DES MOINES",
                                                    "value": {
                                                        "key": "775a485a-56a4-4e88-8302-4d753e3b5ac9",
                                                        "value": "WILLIS INFINITI (72254) - DES MOINES",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "ZIMBRICK INFINITI OF MADISON (70439) - MADISON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|CHI|8|ZIMBRICK INFINITI OF MADISON (70439) - MADISON",
                                                    "value": {
                                                        "key": "85802823-d322-47bb-8e2d-cdc33389f706",
                                                        "value": "ZIMBRICK INFINITI OF MADISON (70439) - MADISON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "label": "LA",
                                    "name": "retailer",
                                    "type": "hierarchy-facet-container",
                                    "filterType": "attribute",
                                    "path": "Retailers|National Sales|West|LA",
                                    "value": {
                                        "key": "92LA",
                                        "value": "LA",
                                        "name": "national sales"
                                    },
                                    "valueType": "hierarchy-value",
                                    "searchable": true,
                                    "items": [
                                        {
                                            "label": "1",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|West|LA|1",
                                            "value": {
                                                "key": "92LA1",
                                                "value": "1",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "GLENDALE INFINITI (71238) - GLENDALE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|1|GLENDALE INFINITI (71238) - GLENDALE",
                                                    "value": {
                                                        "key": "301e2a69-8a6e-417e-a022-e4404790b87f",
                                                        "value": "GLENDALE INFINITI (71238) - GLENDALE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "GLENDALE INFINITI (72238) - GLENDALE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|1|GLENDALE INFINITI (72238) - GLENDALE",
                                                    "value": {
                                                        "key": "2634e54c-bac6-4604-9681-facdaf1c2831",
                                                        "value": "GLENDALE INFINITI (72238) - GLENDALE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "HGREG INFINITI MONROVIA (71317) - MONROVIA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|1|HGREG INFINITI MONROVIA (71317) - MONROVIA",
                                                    "value": {
                                                        "key": "17fbd6f2-af7c-4f9f-b003-201be3250e24",
                                                        "value": "HGREG INFINITI MONROVIA (71317) - MONROVIA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF BEVERLY HILLS (70553) - BEVERLY HILLS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|1|INFINITI OF BEVERLY HILLS (70553) - BEVERLY HILLS",
                                                    "value": {
                                                        "key": "3b0ad8a5-0aa4-433d-8739-7ae98f20a4cd",
                                                        "value": "INFINITI OF BEVERLY HILLS (70553) - BEVERLY HILLS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF DOWNTOWN LA (70585) - LOS ANGELES",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|1|INFINITI OF DOWNTOWN LA (70585) - LOS ANGELES",
                                                    "value": {
                                                        "key": "23bfec4a-bc61-41d9-9816-6e8659b8be54",
                                                        "value": "INFINITI OF DOWNTOWN LA (70585) - LOS ANGELES",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI of Ontario (71110) - ONTARIO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|1|INFINITI of Ontario (71110) - ONTARIO",
                                                    "value": {
                                                        "key": "13cbcda8-6320-4856-a11e-d8e4bc283855",
                                                        "value": "INFINITI of Ontario (71110) - ONTARIO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF OXNARD (73418) - OXNARD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|1|INFINITI OF OXNARD (73418) - OXNARD",
                                                    "value": {
                                                        "key": "3956df66-3a82-439e-9cbf-3b1afb42cfe0",
                                                        "value": "INFINITI OF OXNARD (73418) - OXNARD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF THOUSAND OAKS (72100) - WESTLAKE VILLAGE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|1|INFINITI OF THOUSAND OAKS (72100) - WESTLAKE VILLAGE",
                                                    "value": {
                                                        "key": "ec9b5f9c-7bd9-4588-ac3e-8c4325477c13",
                                                        "value": "INFINITI OF THOUSAND OAKS (72100) - WESTLAKE VILLAGE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF VALENCIA (71504) - VALENCIA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|1|INFINITI OF VALENCIA (71504) - VALENCIA",
                                                    "value": {
                                                        "key": "d4b07253-b6cb-475b-aee8-c37597b0c5ae",
                                                        "value": "INFINITI OF VALENCIA (71504) - VALENCIA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF VAN NUYS (71101) - SHERMAN OAKS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|1|INFINITI OF VAN NUYS (71101) - SHERMAN OAKS",
                                                    "value": {
                                                        "key": "415994ea-313b-4e6e-b186-0d2ecca376a8",
                                                        "value": "INFINITI OF VAN NUYS (71101) - SHERMAN OAKS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF VAN NUYS (72101) - SHERMAN OAKS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|1|INFINITI OF VAN NUYS (72101) - SHERMAN OAKS",
                                                    "value": {
                                                        "key": "9acdad9e-7c5c-4b9d-aca3-d9661203ec36",
                                                        "value": "INFINITI OF VAN NUYS (72101) - SHERMAN OAKS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI PALM SPRINGS (72205) - CATHEDRAL CITY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|1|INFINITI PALM SPRINGS (72205) - CATHEDRAL CITY",
                                                    "value": {
                                                        "key": "57a4823d-3056-4c5f-97b6-e0213c8c7e23",
                                                        "value": "INFINITI PALM SPRINGS (72205) - CATHEDRAL CITY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "METRO INFINITI (70317) - MONROVIA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|1|METRO INFINITI (70317) - MONROVIA",
                                                    "value": {
                                                        "key": "ade5df8e-f0b3-42b7-8f9f-d40e94f44fad",
                                                        "value": "METRO INFINITI (70317) - MONROVIA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        },
                                        {
                                            "label": "2",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|West|LA|2",
                                            "value": {
                                                "key": "92LA2",
                                                "value": "2",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "AUTONATION INFINITI TUSTIN (70112) - TUSTIN",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|2|AUTONATION INFINITI TUSTIN (70112) - TUSTIN",
                                                    "value": {
                                                        "key": "a8a36d8d-93f4-4d58-8f59-d43353cc7e78",
                                                        "value": "AUTONATION INFINITI TUSTIN (70112) - TUSTIN",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "CERRITOS INFINITI (72107) - Cerritos",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|2|CERRITOS INFINITI (72107) - Cerritos",
                                                    "value": {
                                                        "key": "84971112-c383-4eb4-ac71-ec3c86ab8522",
                                                        "value": "CERRITOS INFINITI (72107) - Cerritos",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF ESCONDIDO (70587) - ESCONDIDO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|2|INFINITI OF ESCONDIDO (70587) - ESCONDIDO",
                                                    "value": {
                                                        "key": "87ad2f62-4859-4b64-83c6-2678733f19f5",
                                                        "value": "INFINITI OF ESCONDIDO (70587) - ESCONDIDO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF KEARNY MESA (73115) - SAN DIEGO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|2|INFINITI OF KEARNY MESA (73115) - SAN DIEGO",
                                                    "value": {
                                                        "key": "ea46387a-4889-463b-9ba5-411e2b548247",
                                                        "value": "INFINITI OF KEARNY MESA (73115) - SAN DIEGO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF MISSION VIEJO (70492) - MISSION VIEJO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|2|INFINITI OF MISSION VIEJO (70492) - MISSION VIEJO",
                                                    "value": {
                                                        "key": "d54689ca-6199-4d91-b255-00a9e7e1f938",
                                                        "value": "INFINITI OF MISSION VIEJO (70492) - MISSION VIEJO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF MISSION VIEJO (71492) - MISSION VIEJO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|2|INFINITI OF MISSION VIEJO (71492) - MISSION VIEJO",
                                                    "value": {
                                                        "key": "db35e2f9-fbd2-416f-bdce-2221b90341b8",
                                                        "value": "INFINITI OF MISSION VIEJO (71492) - MISSION VIEJO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF OCEANSIDE (70566) - OCEANSIDE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|2|INFINITI OF OCEANSIDE (70566) - OCEANSIDE",
                                                    "value": {
                                                        "key": "7e439c54-c4e6-4d4d-a0c3-1d9ed363e3ca",
                                                        "value": "INFINITI OF OCEANSIDE (70566) - OCEANSIDE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF SOUTH BAY (73105) - TORRANCE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|2|INFINITI OF SOUTH BAY (73105) - TORRANCE",
                                                    "value": {
                                                        "key": "07734225-7b0b-4e93-8a52-14eb0f1158fc",
                                                        "value": "INFINITI OF SOUTH BAY (73105) - TORRANCE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "KEARNY MESA INFINITI (72115) - SAN DIEGO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|2|KEARNY MESA INFINITI (72115) - SAN DIEGO",
                                                    "value": {
                                                        "key": "7ee38875-36d2-4236-a489-6f236aeaf440",
                                                        "value": "KEARNY MESA INFINITI (72115) - SAN DIEGO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "NORM REEVES CERRITOS INFINITI (71107) - CERRITOS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|2|NORM REEVES CERRITOS INFINITI (71107) - CERRITOS",
                                                    "value": {
                                                        "key": "0532efc7-34fd-4694-a767-8c21b35d3d06",
                                                        "value": "NORM REEVES CERRITOS INFINITI (71107) - CERRITOS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "ORANGE COAST INFINITI (70548) - WESTMINSTER",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|2|ORANGE COAST INFINITI (70548) - WESTMINSTER",
                                                    "value": {
                                                        "key": "fe6a5d30-9750-46e1-9a44-dffc49df721c",
                                                        "value": "ORANGE COAST INFINITI (70548) - WESTMINSTER",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "PERRY INFINITI (71491) - ESCONDIDO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|2|PERRY INFINITI (71491) - ESCONDIDO",
                                                    "value": {
                                                        "key": "a59195fa-b41f-43a3-8650-03a4f6902f5a",
                                                        "value": "PERRY INFINITI (71491) - ESCONDIDO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        },
                                        {
                                            "label": "3",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|West|LA|3",
                                            "value": {
                                                "key": "92LA3",
                                                "value": "3",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "COULTER INFINITI (71526) - MESA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|3|COULTER INFINITI (71526) - MESA",
                                                    "value": {
                                                        "key": "424ec07a-50a2-44e5-88c1-1e0b9a79630e",
                                                        "value": "COULTER INFINITI (71526) - MESA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "GARCIA INFINITI (71241) - ALBUQUERQUE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|3|GARCIA INFINITI (71241) - ALBUQUERQUE",
                                                    "value": {
                                                        "key": "3aae193c-972d-4a7c-b5ba-3a4ded569be6",
                                                        "value": "GARCIA INFINITI (71241) - ALBUQUERQUE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF LAS VEGAS (72215) - LAS VEGAS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|3|INFINITI OF LAS VEGAS (72215) - LAS VEGAS",
                                                    "value": {
                                                        "key": "e8b2e5bb-8ed2-45aa-8c68-30e29bef2b68",
                                                        "value": "INFINITI OF LAS VEGAS (72215) - LAS VEGAS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF PEORIA (72083) - PEORIA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|3|INFINITI OF PEORIA (72083) - PEORIA",
                                                    "value": {
                                                        "key": "7ff19722-8c91-4b37-84fd-50c8f58aa091",
                                                        "value": "INFINITI OF PEORIA (72083) - PEORIA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF SCOTTSDALE (71482) - SCOTTSDALE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|3|INFINITI OF SCOTTSDALE (71482) - SCOTTSDALE",
                                                    "value": {
                                                        "key": "2c2ff618-85c1-44f2-8389-07b3454a746a",
                                                        "value": "INFINITI OF SCOTTSDALE (71482) - SCOTTSDALE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF TUCSON (70237) - TUCSON",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|3|INFINITI OF TUCSON (70237) - TUCSON",
                                                    "value": {
                                                        "key": "0457ea3b-f8d4-4d7d-a215-67a0c4989dcd",
                                                        "value": "INFINITI OF TUCSON (70237) - TUCSON",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI ON CAMELBACK (71082) - PHOENIX",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|LA|3|INFINITI ON CAMELBACK (71082) - PHOENIX",
                                                    "value": {
                                                        "key": "323dd92e-ee05-497b-b2e5-d55d818eb291",
                                                        "value": "INFINITI ON CAMELBACK (71082) - PHOENIX",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "label": "SF",
                                    "name": "retailer",
                                    "type": "hierarchy-facet-container",
                                    "filterType": "attribute",
                                    "path": "Retailers|National Sales|West|SF",
                                    "value": {
                                        "key": "92SF",
                                        "value": "SF",
                                        "name": "national sales"
                                    },
                                    "valueType": "hierarchy-value",
                                    "searchable": true,
                                    "items": [
                                        {
                                            "label": "4",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|West|SF|4",
                                            "value": {
                                                "key": "92SF4",
                                                "value": "4",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "BESHOFF INFINITI (70535) - SAN JOSE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|4|BESHOFF INFINITI (70535) - SAN JOSE",
                                                    "value": {
                                                        "key": "fcc10baa-e052-4510-84d2-194a90c3b368",
                                                        "value": "BESHOFF INFINITI (70535) - SAN JOSE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "DUBLIN INFINITI (73116) - DUBLIN",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|4|DUBLIN INFINITI (73116) - DUBLIN",
                                                    "value": {
                                                        "key": "1a9aa03e-db23-43ae-9565-cf9e59814607",
                                                        "value": "DUBLIN INFINITI (73116) - DUBLIN",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "GOLDEN STATE INFINITI (70583) - COLMA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|4|GOLDEN STATE INFINITI (70583) - COLMA",
                                                    "value": {
                                                        "key": "3640bcb4-f515-4e6f-aa8a-059f3305c2e2",
                                                        "value": "GOLDEN STATE INFINITI (70583) - COLMA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF FAIRFIELD (71496) - FAIRFIELD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|4|INFINITI OF FAIRFIELD (71496) - FAIRFIELD",
                                                    "value": {
                                                        "key": "f11d815e-81cb-428e-a509-d696cff470c9",
                                                        "value": "INFINITI OF FAIRFIELD (71496) - FAIRFIELD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF MARIN (73240) - SAN RAFAEL",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|4|INFINITI OF MARIN (73240) - SAN RAFAEL",
                                                    "value": {
                                                        "key": "3aca2f3a-31a7-4f5e-a4c6-e517edb0f8d7",
                                                        "value": "INFINITI OF MARIN (73240) - SAN RAFAEL",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF SAN FRANCISCO (71550) - SAN FRANCISCO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|4|INFINITI OF SAN FRANCISCO (71550) - SAN FRANCISCO",
                                                    "value": {
                                                        "key": "177b6bbd-dfc0-498b-966a-ccc2e2560c45",
                                                        "value": "INFINITI OF SAN FRANCISCO (71550) - SAN FRANCISCO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF SAN JOSE (71535) - SAN JOSE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|4|INFINITI OF SAN JOSE (71535) - SAN JOSE",
                                                    "value": {
                                                        "key": "d0f6d862-59c1-4d06-8cfb-f2797435f9c9",
                                                        "value": "INFINITI OF SAN JOSE (71535) - SAN JOSE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "NIELLO INFINITI (70219) - CONCORD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|4|NIELLO INFINITI (70219) - CONCORD",
                                                    "value": {
                                                        "key": "a07d4171-cb0b-4d8d-8a45-982fd32e2f72",
                                                        "value": "NIELLO INFINITI (70219) - CONCORD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "PENINSULA INFINITI (71094) - REDWOOD CITY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|4|PENINSULA INFINITI (71094) - REDWOOD CITY",
                                                    "value": {
                                                        "key": "250e3646-5f7c-46fe-bedc-62e27c15d25b",
                                                        "value": "PENINSULA INFINITI (71094) - REDWOOD CITY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "REDWOOD CITY INFINITI (70584) - REDWOOD CITY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|4|REDWOOD CITY INFINITI (70584) - REDWOOD CITY",
                                                    "value": {
                                                        "key": "293fbd98-e2bd-40cd-aeb9-8fc84b212c9e",
                                                        "value": "REDWOOD CITY INFINITI (70584) - REDWOOD CITY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "STEVENS CREEK INFINITI (70582) - SANTA CLARA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|4|STEVENS CREEK INFINITI (70582) - SANTA CLARA",
                                                    "value": {
                                                        "key": "eda1c2ea-e21f-4928-aa8b-83fbe63971c9",
                                                        "value": "STEVENS CREEK INFINITI (70582) - SANTA CLARA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "Stevens Creek Infiniti (71096) - SANTA CLARA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|4|Stevens Creek Infiniti (71096) - SANTA CLARA",
                                                    "value": {
                                                        "key": "0e466283-03f6-4bba-8151-0158759a0936",
                                                        "value": "Stevens Creek Infiniti (71096) - SANTA CLARA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        },
                                        {
                                            "label": "5",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|West|SF|5",
                                            "value": {
                                                "key": "92SF5",
                                                "value": "5",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "FRESNO INFINITI (71097) - FRESNO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|5|FRESNO INFINITI (71097) - FRESNO",
                                                    "value": {
                                                        "key": "1f431f50-5079-4a49-b498-d0a1b5c7fc35",
                                                        "value": "FRESNO INFINITI (71097) - FRESNO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF BAKERSFIELD (70541) - BAKERSFIELD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|5|INFINITI OF BAKERSFIELD (70541) - BAKERSFIELD",
                                                    "value": {
                                                        "key": "832c74dd-376d-4b86-8fd6-4e6a56544c60",
                                                        "value": "INFINITI OF BAKERSFIELD (70541) - BAKERSFIELD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF ELK GROVE (70506) - ELK GROVE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|5|INFINITI OF ELK GROVE (70506) - ELK GROVE",
                                                    "value": {
                                                        "key": "32d00e60-1d95-4a01-889e-834818a7809b",
                                                        "value": "INFINITI OF ELK GROVE (70506) - ELK GROVE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF HONOLULU (70519) - HONOLULU",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|5|INFINITI OF HONOLULU (70519) - HONOLULU",
                                                    "value": {
                                                        "key": "e7644c08-2265-406b-b049-a5ce6b9a26dc",
                                                        "value": "INFINITI OF HONOLULU (70519) - HONOLULU",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF MODESTO (71510) - MODESTO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|5|INFINITI OF MODESTO (71510) - MODESTO",
                                                    "value": {
                                                        "key": "16284a51-d04b-4447-a192-7db6c074e327",
                                                        "value": "INFINITI OF MODESTO (71510) - MODESTO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF RENO (70490) - RENO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|5|INFINITI OF RENO (70490) - RENO",
                                                    "value": {
                                                        "key": "1f039915-dd8a-41b4-a215-bdc3a04dd6d0",
                                                        "value": "INFINITI OF RENO (70490) - RENO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI ROSEVILLE (71090) - ROSEVILLE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|5|INFINITI ROSEVILLE (71090) - ROSEVILLE",
                                                    "value": {
                                                        "key": "13f51eb6-5ce8-4e2f-a9bb-86c31e19fd10",
                                                        "value": "INFINITI ROSEVILLE (71090) - ROSEVILLE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        },
                                        {
                                            "label": "6",
                                            "name": "retailer",
                                            "type": "hierarchy-facet-container",
                                            "filterType": "attribute",
                                            "path": "Retailers|National Sales|West|SF|6",
                                            "value": {
                                                "key": "92SF6",
                                                "value": "6",
                                                "name": "national sales"
                                            },
                                            "valueType": "hierarchy-value",
                                            "searchable": true,
                                            "items": [
                                                {
                                                    "label": "BEAVERTON INFINITI (70089) - PORTLAND",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|6|BEAVERTON INFINITI (70089) - PORTLAND",
                                                    "value": {
                                                        "key": "768d819e-fe26-4631-9f03-a112534045f6",
                                                        "value": "BEAVERTON INFINITI (70089) - PORTLAND",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "BRONCO MOTORS INFINITI (70631) - NAMPA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|6|BRONCO MOTORS INFINITI (70631) - NAMPA",
                                                    "value": {
                                                        "key": "a62059b0-cfa9-4eea-8396-f13e2f16ae22",
                                                        "value": "BRONCO MOTORS INFINITI (70631) - NAMPA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF BELLEVUE (71088) - BELLEVUE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|6|INFINITI OF BELLEVUE (71088) - BELLEVUE",
                                                    "value": {
                                                        "key": "70e47f76-1a75-4e79-bea5-0fb33d5e1767",
                                                        "value": "INFINITI OF BELLEVUE (71088) - BELLEVUE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF DENVER (73084) - AURORA",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|6|INFINITI OF DENVER (73084) - AURORA",
                                                    "value": {
                                                        "key": "d08a2a98-7dbc-4a25-929b-72cb5818743d",
                                                        "value": "INFINITI OF DENVER (73084) - AURORA",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF LYNNWOOD (71542) - LYNNWOOD",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|6|INFINITI OF LYNNWOOD (71542) - LYNNWOOD",
                                                    "value": {
                                                        "key": "3a1eafdb-e192-4f4d-83fb-e04f30a693de",
                                                        "value": "INFINITI OF LYNNWOOD (71542) - LYNNWOOD",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "INFINITI OF TACOMA AT FIFE (70507) - FIFE",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|6|INFINITI OF TACOMA AT FIFE (70507) - FIFE",
                                                    "value": {
                                                        "key": "23e44657-530d-4605-83c4-db4aa809d78c",
                                                        "value": "INFINITI OF TACOMA AT FIFE (70507) - FIFE",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "MIKE WARD INFINITI (71505) - HIGHLANDS RANCH",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|6|MIKE WARD INFINITI (71505) - HIGHLANDS RANCH",
                                                    "value": {
                                                        "key": "4f5be44f-184d-4843-ba4c-7f6a5ab44e9b",
                                                        "value": "MIKE WARD INFINITI (71505) - HIGHLANDS RANCH",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "RED NOLAND INFINITI (70260) - COLORADO SPRINGS",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|6|RED NOLAND INFINITI (70260) - COLORADO SPRINGS",
                                                    "value": {
                                                        "key": "00259428-9024-489b-8652-5da79124b834",
                                                        "value": "RED NOLAND INFINITI (70260) - COLORADO SPRINGS",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "RICKENBAUGH INFINITI (70558) - DACONO",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|6|RICKENBAUGH INFINITI (70558) - DACONO",
                                                    "value": {
                                                        "key": "cab3e2ac-5ddb-4a04-9560-4367dac7de19",
                                                        "value": "RICKENBAUGH INFINITI (70558) - DACONO",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                },
                                                {
                                                    "label": "TIM DAHLE INFINITI (71085) - MURRAY",
                                                    "name": "retailer",
                                                    "type": "hierarchy-facet",
                                                    "filterType": "attribute",
                                                    "path": "Retailers|National Sales|West|SF|6|TIM DAHLE INFINITI (71085) - MURRAY",
                                                    "value": {
                                                        "key": "370192f6-66a3-40b7-82b4-718eb9d27fc9",
                                                        "value": "TIM DAHLE INFINITI (71085) - MURRAY",
                                                        "name": "national sales"
                                                    },
                                                    "valueType": "retailer-value",
                                                    "searchable": true,
                                                    "items": []
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};
