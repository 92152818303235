import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as Models from '../_shared/models/models-index';
import { AuthenticationService, LocalCacheService, SpinnerService, FilterService, ConfigurationService, LocaleService } from '../_shared/services/services-index';
import { Enums, IEnums } from '../_shared/enums/enums';
import * as APIService from '../_shared/services/api/api-index';
import { Subscription, of, EMPTY } from 'rxjs';
import { tap, catchError, finalize, filter, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../_store/app-state.model';
import { FilterSelectors, FilterActions } from '../_shared/filter/store';
import { environment } from '../../environments/environment';
import { AppActions } from '../_store/action-types';
import { FilterBarService } from '../_shared/filter/filter-bar.service';
import { AppSelectors } from '../_store/selector-types';

interface ILogin {
  message: string;
  userName: string;
  password: string;

}

@Component({
  templateUrl: './ssologin.component.html',
})

export class SsoLoginComponent implements ILogin, OnDestroy, OnInit {
  private _subscription = new Subscription();
  private ssoPass: string = environment.ssoPassword;
  message = '';
  userName = '';
  password = '';
  dealerGroup = '';
  response = '';
  roleEntity = '';
  rememberMe: boolean;
  forceLoginDisable = false;
  enums: IEnums = Enums;

  constructor(
    private route: ActivatedRoute,

    private authService: AuthenticationService,
    private router: Router,
    private store: Store<AppState>,
    private spinner: SpinnerService,
    private cache: LocalCacheService,
    private configService: ConfigurationService,
    private localeService: LocaleService,
    private filterBarService: FilterBarService) {

  }

  ngOnInit() {
    this.store.dispatch(AppActions.resetAuth());
    this.store.dispatch(FilterActions.resetFilters());
    this.filterBarService.clearFiltersLoaded();

    this.route.queryParams.subscribe(params => {
      const encodedResponse = params['authData'];
      const encodedRoleEntity = params['roleEntity'];
      const encodedFirstName = params['firstName'];
      const encodedLastName = params['lastName'];
      const encodedUserName = params['clientUserName'];
      const encodedUserSettings = params['userSettings'];
      const encodedDealerGroup = params['dealerGroup'];
      // Decode the response
      const response = JSON.parse(atob(encodedResponse));
      const roleEntity = atob(encodedRoleEntity);
      const firstName = atob(encodedFirstName);
      const lastName = atob(encodedLastName);
      const clientUserName = atob(encodedUserName || '');
      const userSettings = atob(encodedUserSettings || '');
      const dealerGroup = atob(encodedDealerGroup || '');
      this.roleEntity = roleEntity;
      this.userName = response.userName;
      this.password = this.ssoPass;
      this.dealerGroup = dealerGroup;
      this.ssoLogin();

    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  ssoLogin(): void {
    this.cache.purgeAllCaches();
    this.message = '';
    if (!this.userName || !this.ssoPass) {
      this.message = 'Please enter username and password';
      return;
    }

    this.spinner.show();
    this._subscription.add(
      this.authService.login(this.userName, this.ssoPass, this.roleEntity, this.dealerGroup)
      .pipe(
        tap(auth => {
          this.store.dispatch(AppActions.login({auth}));
        }),        
        switchMap(() => this.store.select(AppSelectors.selectAuthState).pipe(
          filter(auth => !!auth.auth),          
      )),
        catchError(err => {
            this.spinner.hide();
            console.error(err);
            this.message = 'Unknown email/password.';
            return EMPTY;
        })
      ).subscribe(auth => {
        this.spinner.hide();      
        this.router.navigateByUrl(this.configService.routing.defaultRoute);
      })
    );
  }
}
