import {
  IDefaultTableColumnConfig,
  IDigAdProviderConfig,
  IMultiLevelDualAxisLineGraphConfig,
  ICardHeaderConfig
} from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class DigAdProviderConfig implements IDigAdProviderConfig {
  reportTitle: string = 'Digital Advertising Provider Performance';
  public benchmarkCardHeader: ICardHeaderConfig = {
    title: 'Summary Data',
    iconClass: 'fa-file',
    helpTextKey: constants.helpTextKeys.digAdProviderSummaryHelp,
    helpTextTitle: 'Provider Summary',
    exportName: 'Digital Advertising Provider Performance - Summary Data',
    metricDisplayModes: ['MOM', 'YOY']
  };

  public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    parentMetrics: [
      {
        id: Enums.digitalAdvertisingMetrics.impressions.metricId,
        displayName: Enums.digitalAdvertisingMetrics.impressions.name,
        propertyName: Enums.digitalAdvertisingMetrics.impressions.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.clicks.metricId,
        displayName: Enums.digitalAdvertisingMetrics.clicks.name,
        propertyName: Enums.digitalAdvertisingMetrics.clicks.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.clickThroughRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.clickThroughRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.clickThroughRate.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.actualCostPerClick.metricId,
        displayName: Enums.digitalAdvertisingMetrics.actualCostPerClick.name,
        propertyName: 'costPerClick',
        metricFormatKey: constants.formatKeys.currency
      },
      {
        id: Enums.digitalAdvertisingMetrics.actualCostPerImpression.metricId,
        displayName: Enums.digitalAdvertisingMetrics.actualCostPerImpression.name,
        propertyName: 'costPerImpression',
        metricFormatKey: constants.formatKeys.currency
      },
      {
        id: Enums.digitalAdvertisingMetrics.totalVisits.metricId,
        displayName: Enums.digitalAdvertisingMetrics.totalVisits.name,
        propertyName: 'visits',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.uniqueVisitors.metricId,
        displayName: Enums.digitalAdvertisingMetrics.uniqueVisitors.name,
        propertyName: Enums.digitalAdvertisingMetrics.uniqueVisitors.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.formSubmissions.metricId,
        displayName: Enums.digitalAdvertisingMetrics.formSubmissions.name,
        propertyName: Enums.digitalAdvertisingMetrics.formSubmissions.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.phoneCalls.metricId,
        displayName: Enums.digitalAdvertisingMetrics.phoneCalls.name,
        propertyName: 'phoneLeads',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.conversionRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.conversionRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.conversionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalAdvertisingMetrics.bounceRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.bounceRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.bounceRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      }
    ],
    childMetrics: [
      {
        id: Enums.digitalAdvertisingProviders.allProviders.providerId,
        displayName: Enums.digitalAdvertisingProviders.allProviders.name,
        propertyName: Enums.digitalAdvertisingProviders.allProviders.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: Enums.digitalAdvertisingProviders.dealerInspire.providerId,
        displayName: Enums.digitalAdvertisingProviders.dealerInspire.name,
        propertyName: Enums.digitalAdvertisingProviders.dealerInspire.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: Enums.digitalAdvertisingProviders.dealerOn.providerId,
        displayName: Enums.digitalAdvertisingProviders.dealerOn.name,
        propertyName: Enums.digitalAdvertisingProviders.dealerOn.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: Enums.digitalAdvertisingProviders.dealerDotCom.providerId,
        displayName: Enums.digitalAdvertisingProviders.dealerDotCom.name,
        propertyName: Enums.digitalAdvertisingProviders.dealerDotCom.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
    ],

    defaultChildrenIds: [Enums.digitalAdvertisingProviders.allProviders.providerId, Enums.digitalAdvertisingProviders.allProviders.providerId],
    defaultParentIds: [Enums.digitalAdvertisingMetrics.impressions.metricId, Enums.digitalAdvertisingMetrics.clicks.metricId],
    metricOneColorHexCode: '#b00',
    metricTwoColorHexCode: '#5f0003'
  };

  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: 'Provider',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    },
    // Impressions
    {
      show: true,
      header: 'Impressions',
      columnDef: 'impressions',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Impressions',
      columnDef: 'impressionsPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Impressions MOM',
      columnDef: 'impressionsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Impressions',
      columnDef: 'impressionsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Impressions YOY',
      columnDef: 'impressionsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // Clicks
    {
      show: true,
      header: 'Clicks',
      columnDef: 'clicks',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Clicks',
      columnDef: 'clicksPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Clicks MOM',
      columnDef: 'clicksMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Clicks',
      columnDef: 'clicksPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Clicks YOY',
      columnDef: 'clicksYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // CTR
    {
      show: true,
      header: 'Click Through Rate',
      columnDef: 'clickThroughRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Click Through Rate',
      columnDef: 'clickThroughRatePreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Click Through Rate MOM',
      columnDef: 'clickThroughRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Click Through Rate',
      columnDef: 'clickThroughRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Click Through Rate YOY',
      columnDef: 'clickThroughRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // CPC
    {
      show: true,
      header: 'Cost Per Click',
      columnDef: 'costPerClick',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.currency, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Cost Per Click',
      columnDef: 'costPerClickPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.currency, clickable: false
    },
    {
      show: false,
      header: 'Cost Per Click MOM',
      columnDef: 'costPerClickMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Cost Per Click',
      columnDef: 'costPerClickPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.currency, clickable: false
    },
    {
      show: false,
      header: 'Cost Per Click YOY',
      columnDef: 'costPerClickYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // CPM
    {
      show: true,
      header: 'Cost Per Impression',
      columnDef: 'costPerImpression',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.currency, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Cost Per Impression',
      columnDef: 'costPerImpressionPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.currency, clickable: false
    },
    {
      show: false,
      header: 'Cost Per Impression MOM',
      columnDef: 'costPerImpressionMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Cost Per Impression',
      columnDef: 'costPerImpressionPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.currency, clickable: false
    },
    {
      show: false,
      header: 'Cost Per Impression YOY',
      columnDef: 'costPerImpressionYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // Total Site Visits
    {
      show: true,
      header: 'Total Visits',
      columnDef: 'visits',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Total Visits',
      columnDef: 'visitsPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Total Visits MOM',
      columnDef: 'visitsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Total Visits',
      columnDef: 'visitsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Total Visits YOY',
      columnDef: 'visitsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // Unique Site Visits
    {
      show: true,
      header: 'Unique Visitors',
      columnDef: 'uniqueVisitors',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Unique Visitors',
      columnDef: 'uniqueVisitorsPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Unique Visitors MOM',
      columnDef: 'uniqueVisitorsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Unique Visitors',
      columnDef: 'uniqueVisitorsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Unique Visitors YOY',
      columnDef: 'uniqueVisitorsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // Form Submissions
    {
      show: true,
      header: 'Form Submissions',
      columnDef: 'formSubmissions',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Form Submissions',
      columnDef: 'formSubmissionsPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Form Submissions MOM',
      columnDef: 'formSubmissionsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Form Submissions',
      columnDef: 'formSubmissionsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Form Submissions YOY',
      columnDef: 'formSubmissionsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // Phone Calls
    {
      show: true,
      header: 'Phone Calls',
      columnDef: 'phoneLeads',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Phone Calls',
      columnDef: 'phoneLeadsPreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Phone Calls MOM',
      columnDef: 'phoneLeadsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Phone Calls',
      columnDef: 'phoneLeadsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Phone Calls YOY',
      columnDef: 'phoneLeadsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // Conversion Rate
    {
      show: true,
      header: 'Conversion Rate',
      columnDef: 'conversionRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Conversion Rate',
      columnDef: 'conversionRatePreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Conversion Rate MOM',
      columnDef: 'conversionRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Conversion Rate',
      columnDef: 'conversionRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Conversion Rate YOY',
      columnDef: 'conversionRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // Bounce Rate
    {
      show: true,
      header: 'Bounce Rate',
      columnDef: 'bounceRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Bounce Rate',
      columnDef: 'bounceRatePreviousMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Bounce Rate MOM',
      columnDef: 'bounceRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Bounce Rate',
      columnDef: 'bounceRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Bounce Rate YOY',
      columnDef: 'bounceRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
  ];

}
