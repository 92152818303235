import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../_store/app-state.model';
import { map, take, tap } from 'rxjs/operators';
import { FilterBarService } from '../filter/filter-bar.service';
import { Filter, FilterTypes } from '../filter/filter.model';
import { Observable, of } from 'rxjs';
import { AppSelectors } from '../../_store/selector-types';
const clone = require('rfdc/default');


@Injectable()
export class LeadSummaryFilterResolverService implements Resolve<any> {

  constructor(
    private store$: Store<AppState>,
    private router: Router,
    private filterBarService: FilterBarService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Filter[]> {
    if (!route.data.reportName) {
      return of([]);
    } else {
      let resolvedFilters = this.filterBarService.getReportFilters(route.data.reportName).pipe(
        map(filters => {
          if (!filters)
            return [];
          const adjustedFilters = this.applyFilterOverrides(clone(filters), this.router.getCurrentNavigation().extras?.state?.filterOverrides);
          adjustedFilters?.forEach(adjustedFilter => {
            this.filterBarService.updateFilterSelected(route.data.reportName, adjustedFilter.type, adjustedFilter.selected);
          })
          return adjustedFilters;
        }),
        take(1)
      );

      return resolvedFilters;
    }
  }

  applyFilterOverrides(filters, overrides: { type: string, values: string }[]): Filter[] {
    if (!overrides) {
      return filters;
    }
    else {
      overrides.forEach(filterOverride => {

        let selected = [];
        let values: string[] = filterOverride.values?.split(',');
        let existingFilterIndex = filters.findIndex(f => f.type === filterOverride.type);

        if (!!values) {
          switch (filterOverride.type) {
            case FilterTypes.leadType:
              selected = [];
              this.store$.select(AppSelectors.selectLeadTypes).pipe(
                map(leadTypes => {
                  leadTypes.forEach(leadType => {
                    if (values.indexOf(leadType.leadTypeId.toString()) != -1)
                      selected.push({ value: leadType.leadTypeId, display: leadType.name });
                  });
                  if (existingFilterIndex != -1)
                    filters[existingFilterIndex].selected = clone(selected);
                }),
                take(1)
              ).subscribe();
              break;
            case FilterTypes.sourceType:
              selected = [];
              this.store$.select(AppSelectors.selectSourceTypes).pipe(
                map(sourceTypes => {
                  sourceTypes.forEach(sourceType => {
                    if (values.indexOf(sourceType.sourceTypeId.toString()) != -1)
                      selected.push({ value: sourceType.sourceTypeId, display: sourceType.name });
                  });
                  if (existingFilterIndex != -1)
                    filters[existingFilterIndex].selected = clone(selected);
                }),
                take(1)
              ).subscribe();
              break;
            default:
              break;
          }
        }
      })

      return filters;
    }
  }
}
