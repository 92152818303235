<mat-toolbar class="filter-bar mat-elevation-z3" id="app-filterbar">
    <mat-icon color="primary">filter_list</mat-icon>
    <mat-chip-list>
      <span #filterChip *ngFor="let filter of filters$ | async">
        <mat-chip
          class="mat-elevation-z2"
          [class.removable]="filter.removable"
          [removable]="filter.removable"
          color="primary"
          selected="true"
          (removed)="removeFilter(filter.type)">
          <a mat-button id="filter-text" (click)="onFilterClicked($event, filterChip, filter)">{{getDisplayValue(filter, locale) | translate: locale | async}}</a>
          <button
            mat-icon-button
            [class.lock-button]="filter.lockable"
            (click)="lockFilter(filter)">
            <mat-icon class="lock-button-icon" [class.locked]="filter.locked">{{filter.locked ? 'lock' : 'lock_open'}}</mat-icon>
          </button>
          <mat-icon matChipRemove *ngIf="filter.removable">cancel</mat-icon>
        </mat-chip>
      </span>
      <div #addFilterButton *ngIf="showAddFilter$ | async">
        <button mat-button
                color="primary"
                class="filter-button"
                [matMenuTriggerFor]="filterTypeMenu">+ {{'Filter' | translate: locale | async}}</button>
      </div>

      <mat-menu #filterTypeMenu="matMenu">

        <div style="width: 200px;">
          <span style="margin: 10px 0 0 10px">{{'Filter by' | translate: locale | async}}</span>
          <button *ngFor="let type of filterTypes$ | async" mat-menu-item (click)="onFilterTypeSelected(type)" class="filter-type-menu-item" style="outline: none;">
            <span>{{type.displayName | translate: locale | async}}</span>
          </button>
        </div>

      </mat-menu>
    </mat-chip-list>
  <!-- <div fxLayout="center center">
      <button mat-button color="primary">+ Filter</button>
  </div> -->
    <span style="flex: 1 1 auto"></span>
    <button
      mat-icon-button color="primary"
      class="clear-filter-button"
      (click)="resetFilters()">
      <mat-icon>replay</mat-icon>
    </button>
</mat-toolbar>
