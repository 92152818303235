<mat-card class="mat-elevation-z1">
  <mat-card-title>
    <i class="pad-right"></i><img src="{{getProviderImagePath(providerCode)}}" alt="{{providerName}}" />
    <div class="pull-right">
      <button mat-button class="card-options-button" [matMenuTriggerFor]="helpOptions"><i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em;"></i></button>
      <mat-menu #helpOptions="matMenu">
        <button mat-menu-item style="outline: none;" (click)="openHelpTextClicked()">Help</button>
      </mat-menu>
    </div>
  </mat-card-title>
  <mat-card-content class="card-block target-card">
    <table>
      <tbody>
        <tr>
          <td>
            <div style="position: absolute; width: 99%; z-index: 0" [chart]="currentChart"></div>
            <span class="focus-metric-value">
              <b>{{primaryMetricValue | formatter: primaryMetricFormatter}}</b>
              <i [ngStyle]="{'color': primaryMetricTrendColor}" [ngClass]="primaryMetricTrendArrow" class="pad-left"></i>
            </span>
            <br />
            <span class="focus-metric-label">{{primaryMetricName}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
  <mat-card-footer>
    <table>
      <tbody>
        <tr>
          <td *ngIf="!!leftMetricName">
            <span class="secondary-metric-value">
              <b>{{leftMetricValue | formatter: leftMetricFormatter}}</b>
              <i [ngStyle]="{'color': leftMetricTrendColor}" [ngClass]="leftMetricTrendArrow" class="pad-left"></i>
            </span>
            <br />
            <span class="secondary-metric-label">
              {{leftMetricName}}
            </span>
          </td>
          <td *ngIf="!!centerMetricName">
            <span class="secondary-metric-value">
              <b>{{centerMetricValue | formatter: centerMetricFormatter}}</b>
              <i [ngStyle]="{'color': centerMetricTrendColor}" [ngClass]="centerMetricTrendArrow" class="pad-left"></i>
            </span>
            <br />
            <span class="secondary-metric-label">
              {{centerMetricName}}
            </span>
          </td>
          <td *ngIf="!!rightMetricName">
            <span class="secondary-metric-value">
              <b>{{rightMetricValue | formatter: rightMetricFormatter}}</b>
              <i [ngStyle]="{'color': rightMetricTrendColor}" [ngClass]="rightMetricTrendArrow" class="pad-left"></i>
            </span>
            <br />
            <span class="secondary-metric-label">
              {{rightMetricName}}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card-footer>
</mat-card>
