import { Component, OnInit, Input } from '@angular/core';
import { Enums } from '../../enums/enums';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';


@Component({
  selector: 'sd-solid-guage-chart',
  templateUrl: './solid-guage-chart.component.html',
  styleUrls: ['./solid-guage-chart.component.scss'],
})

export class SolidGuageChartComponent implements OnInit {
    @Input() report: string;
    @Input() chartSize: number;
    @Input() chartheight: string;
    @Input() seriesName: string;
    @Input() seriesData: number;
    @Input() seriesColor: string;
    @Input() seriesBackgroundOpacity: number;
    currentChart: any;
    private enums = Enums;
    private defaultBackgroundColor = '#ffffff';
    private seriesOuterRadius = '110%';
    private seriesInnerRadius = '80%';


    constructor() { }

    ngOnInit() {
        let axisTitleOptions: Highcharts.YAxisTitleOptions;
        let chartOptions: Highcharts.ChartOptions;

        if (this.report === this.enums.vehicleEntityTypes.class.type) {
            axisTitleOptions = {
                text: '',
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    color: 'black'
                },
                y: -25
            };
            chartOptions = {
                type: 'solidgauge',
                backgroundColor: '#fff',
                height: 80,
                marginTop: -5,
                marginLeft: 8,
                width: this.chartSize, // 100,
                style: {
                    fontFamily: 'Roboto, "Helvetica Neue", sans-serif'
                },
            };

        } else {
            axisTitleOptions = {
                text: '',
                y: -35
            };
            chartOptions = {
                type: 'solidgauge',
                backgroundColor: '#fff',
                height: '90%',
                width: 100,
                style: {
                    fontFamily: 'Roboto, "Helvetica Neue", sans-serif'
                },
            };
        }

        this.updateChart(axisTitleOptions, chartOptions);
    }

    hexToRGB(hex, alpha) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
        } else {
            return 'rgb(' + r + ', ' + g + ', ' + b + ')';
        }
    }

    updateChart(titleOptions: Highcharts.YAxisTitleOptions, chartOptions: Highcharts.ChartOptions) {
        this.currentChart = new Chart({
            chart: chartOptions,
            title: null,
            credits: {
                enabled: false
            },
            tooltip: {
                enabled: false,
            },
            pane: {
                center: ['50%', '85%'],
                size: '110%',
                startAngle: -90,
                endAngle: 90,
                background: [{
                    backgroundColor: '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }]
            },
            yAxis: <Highcharts.YAxisOptions>{
                stops: [
                    [.55, this.seriesColor], // green
                    [.80, this.seriesColor], // yellow
                    [1, this.seriesColor] // red
                ],
                lineWidth: 0,
                tickWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
                title: titleOptions,
                labels: {
                    distance: -10,
                    y: 12,
                },
                min: 0,
                max: 100,
            },
            plotOptions: {
                solidgauge: {
                    color: this.seriesColor,
                    dataLabels: {
                        borderWidth: 0,
                        y: -20,
                        formatter: function() {
                            return '<span>' + this.y + '%</span>';
                        }
                    },
                }
            },
            series: [{
                type: 'solidgauge',
                name: this.seriesName,
                data: [this.seriesData],
            }]
        });
    }
}
