import { Injectable } from '@angular/core';
import { WebsiteTranslationConfig } from '../../../_config/translation/website/website.translation.config';
import { ITranslationService } from '../locale.model';


@Injectable()
export class WebsiteTranslationService implements ITranslationService {
  translationConfig = new WebsiteTranslationConfig;
  
  getMetricNameTranslation(metricId: string, defaultLabel: string, locale: string) {
    if (locale === 'en' || locale == undefined) {
      return defaultLabel;
    }

    const cleansedMetricId = metricId[0].toLowerCase() + metricId.substring(1);
    return this.translationConfig.metrics[locale][cleansedMetricId] ?? defaultLabel;
  }

  getReportTitleTranslation(value: string, locale: string) {
    if (locale === 'en' || locale == undefined) {
      return value;
    }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.translationConfig.reportTitles[locale][cleansedValue];
  }

  getLabelTranslation(value: string, locale: string) {
    if (!locale || locale === 'en' || !value) {
      return value;
    }

    const cleansedValue = value
    const label = this.translationConfig.labels[locale][cleansedValue] ?? value;
    return label;
  }

  getChartDimensions(value: string, locale: string) {
    if (locale === 'en' || locale == undefined) {
      return value;
    }
    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.translationConfig.chartDimensions[locale][cleansedValue] || value;
  }

  getChannels(value: string, locale: string) {
    if (!locale || locale === 'en' || !value) {
      return value;
    }
    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.translationConfig.channels[locale][cleansedValue] || value;
  }

}
