import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { Subject } from 'rxjs';
import { IFacetedFilterDataItem, IHierarchyValue, IReplayItem, ISelectedHierarchyInfo, ISelectionInfo } from '../../../../types/faceted-filter.types';
import { TypeCheck } from '../../../../Utils/type-check';
import { TypeInstanceOf } from '../../../../Utils/type-instance-of';

@Component({
	selector: 'hierarchy-pane',
	templateUrl: './hierarchy-pane.component.html',
	styleUrls: ['./hierarchy-pane.component.scss'],
})
export class HierarchyPaneComponent implements OnInit, OnDestroy {

	destroy$ = new Subject<boolean>();

	canSelectAll = false;
	canSelectChildren = true;

	selectedHierarchyContainerItems: IFacetedFilterDataItem[] = [];
	selectedHierarchyFacetItems: IFacetedFilterDataItem[] = [];

	hierarchyContainerItemsList: IFacetedFilterDataItem[] = [];
	hierarchyFacetItemsList: IFacetedFilterDataItem[] = [];

	@Input() set replayItem(replayItem: IReplayItem) {
		this.item = { ...replayItem.item };
		this.handleReplayItem(replayItem);
	}

	// TODO: Simplify this \/\/\/\/
	@Input() set replayValue(value: any) {
		if (Array.isArray(value) && TypeInstanceOf.HierarchyValue(value[0])) {
			const selectedItems: IFacetedFilterDataItem[] = [];
			for (let i = 0; i < value.length; i++) {
				const val: IHierarchyValue = value[i];
				const selected: IFacetedFilterDataItem = this.item.items.find(i => (i.value as IHierarchyValue).value === val.value);
				if (selected)
					selectedItems.push(selected);
			}
			if (selectedItems?.length) {
				if (TypeCheck.isHierarchyContainer(selectedItems[0].type)) {
					this.selectedHierarchyContainerItems = [...selectedItems];
					const selection: ISelectedHierarchyInfo = this.getSelectionInfo(this.selectedHierarchyContainerItems);
					this.onHierarchyReplaySelection.emit(selection);
				} else {
					this.selectedHierarchyFacetItems = [...selectedItems];
					const selection: ISelectedHierarchyInfo = this.getSelectionInfo(this.selectedHierarchyFacetItems);
					this.onHierarchyReplaySelection.emit(selection);
				}
			}
		}
	}

	@Input() item: IFacetedFilterDataItem;
	@Output() onHierarchyContainerItemsChanged = new EventEmitter<ISelectionInfo>();
	@Output() onHierarchyItemsChanged = new EventEmitter<ISelectionInfo>();
	@Output() onHierarchyReplaySelection = new EventEmitter<ISelectionInfo>();

	ngOnInit(): void {
		if (this.item) {
			this.buildHierarchyContainerItemsList();
			this.buildHierarchyFacetItemsList();
			this.setSelectionMode();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	isIndeterminate(): boolean {
		if (this.selectedHierarchyFacetItems?.length)
			return this.selectedHierarchyFacetItems.length !== this.hierarchyFacetItemsList.length
				&& this.selectedHierarchyFacetItems.length >= 1;

		return false;
	}

	selectAllIsChecked(): boolean {
		if (this.selectedHierarchyFacetItems?.length)
			return this.selectedHierarchyFacetItems.length === this.hierarchyFacetItemsList.length;

		return false;
	}

	selectAllCheckboxChanged($event: any): void {
		this.selectedHierarchyFacetItems = [];
		if ($event.checked) {
			this.selectedHierarchyFacetItems = [...this.hierarchyFacetItemsList];
		}
		const selection: ISelectedHierarchyInfo = {
			hierarchies: this.mapValues(this.selectedHierarchyFacetItems),
			item: this.item
		};
		this.onHierarchyItemsChanged.emit(selection);
	}

	hierarchyContainerItemsChanged($event: MatSelectionListChange): void {
		const lastSelectedOption: IFacetedFilterDataItem = this.selectedHierarchyContainerItems.length === 0 ? $event.options[0].value : undefined;
		// const lastSelectedOption: IFacetedFilterDataItem = this.selectedHierarchyContainerItems.length === 0 ? $event.option.value : undefined;

		this.manageCanSelectChildren();

		const selection: ISelectedHierarchyInfo = this.getSelectionInfo(this.selectedHierarchyContainerItems, lastSelectedOption);
		this.onHierarchyContainerItemsChanged.emit(selection);
	}

	hierarchyFacetItemsChanged($event: MatSelectionListChange): void {
		const selection: ISelectedHierarchyInfo = this.getSelectionInfo(this.selectedHierarchyFacetItems);
		this.onHierarchyItemsChanged.emit(selection);
	}

	private buildHierarchyContainerItemsList(): void {
		this.hierarchyContainerItemsList = this.item.items?.filter(hi => TypeCheck.isHierarchyContainer(hi.type));
	}

	private buildHierarchyFacetItemsList(): void {
		this.hierarchyFacetItemsList = this.item.items?.filter(hi => TypeCheck.isFacet(hi.type));
	}

	private setSelectionMode(): void {
		this.canSelectAll = false; // this.hierarchyFacetItemsList.length > 0;
	}

	private mapValues(items: IFacetedFilterDataItem[]): IHierarchyValue[] {
		const values: IHierarchyValue[] = [];
		for (let i = 0; i < items.length; i++) {
			values.push(items[i].value as IHierarchyValue);
		}
		return values;
	}

	private manageCanSelectChildren(): void {
		if (this.selectedHierarchyContainerItems.length > 1) {
			this.canSelectChildren = false;
			this.selectedHierarchyFacetItems = [];
		} else {
			this.canSelectChildren = true;
		}
	}

	private getPurgePath(lastOption?: IFacetedFilterDataItem): string {
		if (!this.selectedHierarchyContainerItems.length || this.selectedHierarchyContainerItems.length > 1) {
			if (lastOption)
				return lastOption.path;

			return this.selectedHierarchyContainerItems[0]?.path;
		}
		return null;
	}

	private getSelectionInfo(selectedItems: IFacetedFilterDataItem[], lastSelectedOption?: IFacetedFilterDataItem): ISelectedHierarchyInfo {
		const selection: ISelectedHierarchyInfo = {
			hierarchies: selectedItems?.length ? this.mapValues(selectedItems) : [],
			item: this.getSelectionItem(selectedItems),
			purgePath: this.getPurgePath(lastSelectedOption)
		};

		return selection;
	}

	private getSelectionItem(selectedItems: IFacetedFilterDataItem[]): IFacetedFilterDataItem {
		if (!selectedItems?.length || selectedItems.length > 1)
			return this.item;
		else
			return selectedItems[0];
	}

	private scrollToElement(el: Element): void {
		setTimeout(() => {
			el.scrollIntoView({ behavior: 'auto', block: 'start' });
		}, 350);
	}

	private handleReplayItem(replayItem: IReplayItem): void {
		setTimeout(() => {
			const selected = this.item.items?.find(i => i.label === replayItem.value);
			const idx: number = this.item.items?.findIndex(i => i.label === replayItem.value);
			let idxPrefix: string;
			if (selected) {
				let selection: ISelectedHierarchyInfo;
				if (TypeCheck.isHierarchyContainer(selected.type)) {
					this.selectedHierarchyContainerItems = [selected];
					selection = this.getSelectionInfo(this.selectedHierarchyContainerItems);
					idxPrefix = 'c_';
				} else {
					this.selectedHierarchyFacetItems = [selected];
					selection = this.getSelectionInfo(this.selectedHierarchyFacetItems);
					idxPrefix = 'f_';
				}

				this.onHierarchyReplaySelection.emit(selection);
				const firstSelectedEl: HTMLElement = document.getElementById(`${idxPrefix}${idx}`);
				this.scrollToElement(firstSelectedEl);
			} else {
				console.log('>>>>>>>>HANDLE REPLAY ITEM NOT SELECTED<<<<<<<<');
			}
		}, 1);
	}
}
