import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy } from '@angular/core';
import { ElementSettings } from '../../../models/report-view.models';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
import * as Models from '../../../models/models-index';
import chroma from 'chroma-js';
import { ChartService } from '../../chart/chart.service';
import { map, takeWhile } from 'rxjs/operators';
import { getChartColors } from '../panel-utils';

@Component({
  selector: 'kpi-pie-chart',
  templateUrl: './kpi-pie-chart.component.html',
  styleUrls: ['../panel-elements.scss', './kpi-pie-chart.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class KpiPieChartComponent implements Models.ElementComponent, OnInit, OnDestroy {
  @Input() dataSet: Models.DataSet;
  @Input() settings: ElementSettings;
  @Input() selectedMetric: string;

  componentActive: boolean = true;
  currentChart: Chart;
  locale: any;

  constructor(private chartService: ChartService) { }

  ngOnInit() {

    let seriesData = [];
    const metricIndex = this.dataSet.columns.findIndex(col => col.name === this.selectedMetric);
    const metricColumn = this.dataSet.columns[metricIndex];
    const dimIndex = this.dataSet.columns.findIndex(col => col.name === this.settings.dimensionName);
    const dimColumn = this.dataSet.columns[dimIndex];

    // copy rows and order by metric descending
    let rows = this.dataSet.rows.slice();
    rows.sort((a, b) => b[metricIndex].value as number - (a[metricIndex].value as number));

    rows.forEach(row => {
      if (!row[dimIndex].value || row[dimIndex].label === ''
            || this.settings.dimensionRowFilters?.some(f => f === row[dimIndex].value))
            return;

      if (!row[metricIndex].value)
        return;

      seriesData.push({
        name: row[dimIndex].label ?? row[dimIndex].value,
        displayValue: row[metricIndex].label ?? row[metricIndex].value,
        y: row[metricIndex].value,
      })
    })

    this.currentChart = this.generateChart(seriesData);

    this.chartService.reflowChart$.pipe(
      takeWhile(() => this.componentActive),
      map(() => {
        setTimeout(() => {
          this.currentChart.ref.reflow();
        }, 200);
      })
    ).subscribe()
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  // generateChartColors(darkColor: string, lightColor: string, length: number): string[] {
  //   const colors = chroma.scale([darkColor, lightColor])
  //     .mode('lch')
  //     .colors(length);

  //   return colors;
  // }



  // private generateChartColors(darkColor: string, lightColor: string, length: number) {

  //   let colors = [lightColor, darkColor]

  //   let a = lightColor;
  //   let b = darkColor;

  //   // This is really stupid right now and as more dimensions get added the colors will start to get very similar...
  //   // ...so we need more intelligence around how to choose which colors we are "averaging" ... but this works in a pinch
  //   for (let i = 0; i < length; i++) {
  //     let newColor = this.avgHex(a,b);
  //     colors.push(newColor);
  //     b = newColor;
  //   }

  //   return colors;
  // }

  // private hexToRgb(h) {
  //   return [<any>('0x' + h[1] + h[2]) | 0, <any>('0x' + h[3] + h[4]) | 0, <any>('0x' + h[5] + h[6]) | 0];
  // }

  // private rgbToHex(r, g, b) {
  //   return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  // }

  // private avgHex(h1, h2) {
  //   const a = this.hexToRgb(h1);
  //   const b = this.hexToRgb(h2);
  //   return this.rgbToHex(~~((a[0] + b[0]) / 2), ~~((a[1] + b[1]) / 2), ~~((a[2] + b[2]) / 2));
  // }

  private generateChart(seriesData: any[]) {
  
    const colors = getChartColors(this.settings);
   return new Chart({
      credits: {
        enabled: false
      },
      title: { text: ' ' },
      colors: colors,
      chart: {
        type: 'pie',
        height: 341,
        animation: null
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          dataLabels: {
            enabled: true,
            connectorShape: 'straight',
            distance: 30,
            style: {
              fontWeight: 'bold'
            },
            formatter: function () {
              const point = <any>this.point;
              return point.name + ': ' + point.displayValue + ' (' + (point.percentage).toFixed(0) + '%)';
            }
          },
          showInLegend: false,
          states: {
            hover: {
              halo: {
                size: 0
              }
            }
          },
          events: {
            click: function (e) { }
          }
        },
        series: {
          animation: true,
          point: {
            events: {}
          }
        }
      },
      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          const point = <any>this;
          return '<b>' + point.name + ': ' + point.displayValue + ' (' + (point.percentage).toFixed(0) + '%)</b>';
        }
      },
      legend: {
        enabled: true,
        align: 'center'
      },
      series: [<Highcharts.SeriesPieOptions>{
        data: seriesData
      }]
    });
  }
}
