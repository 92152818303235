import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormattingService } from '../services/services-index';
import { IEnums, Enums } from '../enums/enums';
import * as Models from '../models/models-index';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import * as constants from '../constants/constants';


@Component({
    selector: 'multi-level-trend-graph-card',
    templateUrl: './multi-level-trend-graph-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MultiLevelTrendGraphCardComponent implements OnDestroy, OnInit {

    @Input('graph') set graph(graph: any) {
        this.graph$.next(graph);
    }
    @Input() showHeaderLabel = true;
    private graph$ = new BehaviorSubject<any>(null);
    chart: { settings: Models.IMultiLevelDualAxisLineGraphConfig, data: any[], locale?: string, metricTranslator?: Function, dimensionTranslator?: Function};
    enums: IEnums = Enums;
    subscriptions: Subscription[] = [];
    metricOneConfig: Models.IMultiLevelTrendGraphMetricSelectedPropertyConfig = null;
    metricTwoConfig: Models.IMultiLevelTrendGraphMetricSelectedPropertyConfig = null;
    showMetricSelectDropdown: boolean;
    metricOneSelectConfig: Models.IMetricComplexSelectConfig = null;
    metricTwoSelectConfig: Models.IMetricComplexSelectConfig = null;

    graphDataObject: Models.IMultiLevelTrendGraph;

    constructor(
        private datePipe: DatePipe,
        private formattingService: FormattingService) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.graph$.pipe(map(metrics => {
                    this.chart = metrics;
                    this.chart.locale = !this.chart.locale ? 'en' : this.chart.locale;
                    const currentConfiguration = this.chart.settings.childMetrics.length <= 0
                    ? this.populateDimensionDropDowns(this.chart.settings, metrics.data)
                    : this.chart.settings;

                    this.generateSelectInputs(currentConfiguration);

                    this.graphDataObject = this.mapTrendGraphMetrics(metrics.data, currentConfiguration);
                    // Child dimensions come directly from the data - if we do not have data metrics, do not show dropdown.
                    this.showMetricSelectDropdown = this.graphDataObject.metrics.length > 0;
                })
            ).subscribe()
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    generateSelectInputs(config: Models.IMultiLevelDualAxisLineGraphConfig) {

        if (!!this.chart.metricTranslator) {
            config.parentMetrics.map(m => m.displayName = this.chart.metricTranslator(m.propertyName, m.displayName, this.chart.locale));
            config.childMetrics.map(m => m.displayName = this.chart.dimensionTranslator(m.propertyName, this.chart.locale));
        }

        this.metricOneSelectConfig = {
            defaultParentId: config.defaultParentIds[0],
            defaultChildId: config.defaultChildrenIds[0],
            parentOptions: config.parentMetrics,
            childOptions: config.childMetrics
        };

        this.metricTwoSelectConfig = {
            defaultParentId: config.defaultParentIds[1],
            defaultChildId: config.defaultChildrenIds[1],
            parentOptions: config.parentMetrics,
            childOptions: config.childMetrics
        };
    }

    applyMetricOneChange(metricConfig: Models.IMultiLevelTrendGraphMetricSelectedPropertyConfig) {
        this.metricOneConfig = metricConfig;
    }

    applyMetricTwoChange(metricConfig: Models.IMultiLevelTrendGraphMetricSelectedPropertyConfig) {
        this.metricTwoConfig = metricConfig;
    }

    // TODO: This is very specific to reports with date as the x-axis...could be made more generic, not sure if it should be though.
    mapTrendGraphMetrics(data: any, trendGraphData: Models.IMultiLevelDualAxisLineGraphConfig): Models.IMultiLevelTrendGraph {

        const xAxisValues: any[] = [];
        const graphData: Models.IMultiLevelMetric[] = [];
        const chartIds: string[] = [];

        const iterationDates = [...new Set(data.map(m => m[trendGraphData.xAxisPropertyName]))];

        const children = this.chart.settings.childMetrics;

        iterationDates.forEach(date => {
            xAxisValues.push(this.formatTrendDate(moment(date).toDate()));
        });

        children.forEach(child => {
            chartIds.push(child.propertyName);
            const currentMetrics = data.filter(record => record.dimension === child.propertyName);
            const currentChildData: Models.IMultiLevelMetric = {
                id: child.id,
                metrics: []
            };

            this.chart.settings.parentMetrics.forEach(parent => {
                currentChildData.metrics.push({metricId: parent.id, data: []});

                iterationDates.forEach(date => {
                    const currentDateMetrics = currentMetrics.filter(d => d.date === date);
                    const currentParentMetric = currentDateMetrics.map(v => v[parent.propertyName])[0] || 0;

                    currentChildData.metrics.find(m => m.metricId === parent.id).data.push(currentParentMetric);
                });

            });

            graphData.push(currentChildData);
        });

        const graph: Models.IMultiLevelTrendGraph = {
            ids: chartIds,
            dates: xAxisValues,
            xAxisValues: xAxisValues,
            metrics: graphData,
            metricOneColorHexCode: this.chart.settings.metricOneColorHexCode,
            metricTwoColorHexCode: this.chart.settings.metricTwoColorHexCode
        };

        return graph;
    }

    private formatTrendDate(date: Date): string {
        const dateFormat = 'M/d';
        return this.datePipe.transform(date, dateFormat, null, this.chart.locale);
    }

    populateDimensionDropDowns(config: Models.IMultiLevelDualAxisLineGraphConfig, metrics: any): Models.IMultiLevelDualAxisLineGraphConfig {
        const updatedConfig = config;
        const dimensions: any[]  = [...new Set(metrics.map(m => m.dimension))];
        dimensions.forEach(dim => {
            if (!!dim) {
                updatedConfig.childMetrics.push({
                    id: dim,
                    displayName: !!this.chart.dimensionTranslator ? this.chart.dimensionTranslator(dim, this.chart.locale) : dim,
                    propertyName: dim,
                    metricFormatKey: constants.formatKeys.entityDisplayName
                });
            }
        });

        return updatedConfig;
    }

}
