import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy } from '@angular/core';
import * as ElementModels from '../../../models/report-view.models';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import StockModule from 'highcharts/modules/stock';
import { map, takeWhile } from 'rxjs/operators';
import { ChartService } from '../../chart/chart.service';
import chroma from 'chroma-js';
import { getChartColors } from '../panel-utils';

@Component({
  selector: 'stacked-bar-chart-percent',
  templateUrl: './stacked-bar-chart-percent.component.html',
  styleUrls: ['../panel-elements.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class StackedBarChartPercentComponent implements ElementModels.ElementComponent, OnInit, OnDestroy {

  @Input() dataSet: ElementModels.DataSet;
  @Input() settings: ElementModels.ElementSettings;
  @Input() selectedMetric: string;

  componentActive: boolean = true;
  currentChart: any;
  primaryMetricName: string;
  primaryMetricDisplayValue: string;

  constructor(private chartService: ChartService) { }

  ngOnInit() {
    const [xDim, yDim] = this.settings.dimensionNames;

    if (!yDim || !xDim)
      throw new Error('Stacked Bar Chart Percent requires two dimensions');

    const metricIndex = this.dataSet.columns.findIndex(col => col.name === this.selectedMetric);

    const xDimIndex = this.dataSet.columns.findIndex(col => col.name === xDim);
    const yDimIndex = this.dataSet.columns.findIndex(col => col.name === yDim);

    if (xDimIndex == -1)
      throw new Error(xDim + " not found in DataSet");
    if (yDimIndex == -1)
      throw new Error(yDim + " not found in DataSet"); 

    const categories = Array.from(new Set(this.dataSet.rows.map(row => row[xDimIndex].value.toString())));
    const seriesNames = Array.from(new Set(this.dataSet.rows.map(row => row[yDimIndex].value.toString())));

    const seriesData = seriesNames.map(seriesName => {
      const data = categories.map(category => {
        const row = this.dataSet.rows.find(
          row => row[xDimIndex].value === category && row[yDimIndex].value === seriesName
        );
        return row ? {
          name: seriesName,
          y: row[metricIndex].value || 0,
          label: row[metricIndex].label || row[metricIndex].value || 0
        }: 0;
      });
      return { name: seriesName, data };
    });

    this.currentChart = this.generateChart(seriesData, categories);

    this.chartService.reflowChart$
      .pipe(
        takeWhile(() => this.componentActive),
        map(() => {
          setTimeout(() => {
            this.currentChart.ref.reflow();
          }, 300);
        })
      )
      .subscribe();
  }


  ngOnDestroy() {
    this.componentActive = false;
  }

  private generateChart(seriesData: any[], categories: string[]) {
    const colors = getChartColors(this.settings);
    StockModule(Highcharts);

    const maxIndex = Math.min(categories.length - 1, 4)

    //const categories = seriesData.map(sd => sd.name);
    const chartSeries: Highcharts.SeriesOptionsType[] = seriesData.map(sd => ({
      type: 'bar',
      name: sd.name,
      data: sd.data
    }));

    // if we have less than 4 categories reduce the max so it doesn't add extras
    const xAxisMax = Math.min(4, categories.length-1);

    var chart = new Chart(<any>{
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      colors: colors,
      chart: {
        type: 'bar',
        showAxes: false,
        marginLeft: 100,
        marginRight: 30
      },
      // AXES.....
      xAxis: {
        categories: categories,
        title: { text: null },
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        minorTickLength: 0,
        tickLength: 0,
        max: maxIndex,
        scrollbar: {
          enabled: true
        },
        labels: {
          style: {
            fontSize: '12px', // Change the font size
            fontFamily: 'Helvetica', // Change the font family
            //color: '#333333' // Change the font color
          }
        }
      },
      yAxis: {
        tickInterval: 20,
        labels: {
          format: '{value}%'
        },
        title: {
            text: ''
        },
        reversedStacks: false
      },
      tooltip: {
        formatter: function() {
            return this.x + '<br>'+ this.series.name + ': <b>' + this.point.label + '</b><br> % of Total: <b>' + (this.point.percentage).toFixed(2) + '%</b>';
        }
      },
      plotOptions: {
          series: {
              stacking: 'percent'
          }
      },
      legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal'
      },
      series: chartSeries
    });

    return chart;
  }

  // generateChartColors(darkColor: string, lightColor: string, length: number): string[] {
  //   const colors = chroma.scale([darkColor, lightColor])
  //     .mode('lch')
  //     .colors(length);

  //   return colors;
  // }
}
