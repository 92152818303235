import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppInsightsService } from './_shared/services/appInsights/appInsights.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';


@Injectable ({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private appInsights: AppInsightsService,
    private router: Router
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';

          if (error.error instanceof ErrorEvent) {

            // client-side error

            errorMessage = `Error: ${error.error.message}`;

          } else {

            // server-side error

            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

            // Handle FluentValidation messages
            if (error.status === 400 && error.error?.errors) {
              errorMessage = `Validation Error: ${JSON.stringify(error.error.errors)}`;
            }
          }

          this.appInsights.trackException(error);
          console.log(errorMessage);

          if (error.status === 401) {
            // handle unauthorized
            localStorage.removeItem('user');
            !!environment.loginRedirectUrl ? window.location.href = environment.loginRedirectUrl : this.router.navigateByUrl('/login');
          }

          return throwError(() => errorMessage);

        })

      );
  }
}
