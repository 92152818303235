import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Enums } from '../enums/enums';

@Component({
  selector: 'sd-overview-billboard',
  templateUrl: './overviewBillboard.component.html'
})
export class OverviewBillboardComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() subtitleOne: number;
  @Input() subtitleTwo: string;
  @Input() iconClass: string;
  @Input() trendArrow: number;
  @Input() styleTrendArrow: boolean;

  @Input() metricCurrent: number;
  @Input() metricPreviousMonth: number;
  @Input() metricMOM: number;
  @Input() metricPreviousYear: number;
  @Input() metricYOY: number;
  @Input() metricFormatter: any;

  @Input() footerLeftLabel: string;
  @Input() footerLeftValue: string;

  @Input() billboardColorClass: string;
  @Input() reverseMetric: boolean;

  @Input() footerRowCount: number;

  @Output() openHelpText = new EventEmitter();
  private enums = Enums;

  constructor() { }

  ngOnInit() { }

  getTrendArrowIconClass(trend: number): string {
    if (trend === 0) {
      return 'fa fa-arrows-h';
    } else if (trend < 0) {
      return 'fa fa-level-down';
    } else {
      return 'fa fa-level-up';
    }
  }

  getTrendColor(trend: number): string {
    this.reverseMetric = this.reverseMetric || false;

    if (!this.styleTrendArrow) {
      return '';
    }

    if (this.reverseMetric) {
      // For metrics like bounce rate where a reduction in the metric value is a positive indicator of performance
      if (trend <= 0) {
        return this.enums.trendColors.green.hexString;
      } else {
        return this.enums.trendColors.red.hexString;
      }
    } else {
      if (trend >= 0) {
        return this.enums.trendColors.green.hexString;
      } else {
        return this.enums.trendColors.red.hexString;
      }
    }
  }

  openHelpTextClicked(): void {
    this.openHelpText.emit();
  }

}
