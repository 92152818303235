

import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import * as Models from '../../../_shared/models/models-index';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'sd-bar-chart-card-v2',
  templateUrl: './bar-chart-card-v2.component.html',
  styleUrls: ['./bar-chart-card-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BarChartCardV2Component implements OnInit {
    @Input() currentCard: Models.IBarChartCard;
    chartSettings: Models.ISingleAxisTrendChartSettings;

    constructor( ) { }
    ngOnInit() {

    }

    getTrendColorClass =  (num: number) => num > 0 ? 'trend-up' : 'trend-down';

}

