import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CalendarChart } from '../../models/models-index';
const clone = require('rfdc/default');

declare var google: any;

@Component({
  selector: 'sd-google-chart',
  templateUrl: './sd-google-chart.component.html',
})
export class SdGoogleChartComponent implements OnInit {

  @Input() options: Observable<CalendarChart>;
  @Input() height: string = "100%";
  @Input() width: string = "100%";
  @Input() calendarColors: string[];

  chartType: string = 'Calendar';
  chartData: any = [];
  chartColumns: any = [
    { type: 'date', id: 'Date' },
    { type: 'number', id: 'Value' },
    { type: 'string', role: 'tooltip' }];
  chartOptions: any = {
    height: 343,
    width: 1000,
    tooltip: { isHtml: true }
  }
  monthNames: string[] = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  subscriptions: Subscription[] = [];
  constructor() { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.drawChart().subscribe()
    );
  }

  drawChart() {
    return this.options.pipe(
      map(options => {
        var dataRows: [Date, number, string][] = [];

        options.dataTable.forEach(r => {
          const date = new Date(r.date);
          dataRows.push([date, r.value, `<div style="padding: 8px;"><font size="3">${this.monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}:</font> ${options.formatKey(r.value)}</div>`])
        });

        this.chartData = clone(dataRows);
        this.chartOptions = {
          ...this.chartOptions,
          colorAxis: {
            colors: this.calendarColors
          }
        }
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
