import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Enums, IEnums } from '../../_shared/enums/enums';
import { Subscription } from 'rxjs';


@Component({
  selector: 'scorecard-billboard',
  templateUrl: 'scorecardBillboard.component.html',
  styleUrls: ['billboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScorecardBillboardComponent implements OnDestroy, OnInit {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() subtitleMetric: number;
  @Input() subtitleMetricFormatter: string;
  @Input() iconClass: string;
  @Input() reverseMetric?: boolean;
  @Input() trendArrow: number;
  @Input() metricFormatter: any;
  @Input() metricCurrent: number;
  @Input() metricPreviousMonth: number;
  @Input() metricPreviousYear: number;
  @Input() metricMOM: number;
  @Input() metricYOY: number;
  @Input() secondaryMetricLabel: string;
  @Input() secondaryMetric: number;
  @Input() secondaryMetricFormatter: string;
  @Input() footerLeftLabel: string;
  @Input() footerRightLabel: string;
  @Input() footerMetricFormatter: string;
  @Input() trendColorMOM: string;
  @Input() trendColorYOY: string;
  @Input() useChevronIcon: boolean;
  @Input() locale: string;
  @Output() openHelpText = new EventEmitter();
  enums: IEnums = Enums;
  subscriptions: Subscription[] = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
  // Trend/Target classes
  getTrendArrowIconClass(trend: number): string {

    if (trend === 0) {
      return 'fa fa-arrows-h';
    } else if (trend < 0) {
      return 'fa fa-level-down';
    } else {
      return 'fa fa-level-up';
    }
  }

  getTrendColor(trend: number): string {
    if (this.reverseMetric) {
      // For metrics like bounce rate where a reduction in the metric value is a positive indicator of performance
      if (trend <= 0) {
        return 'text-green';
      } else {
        return 'text-red';
      }
    } else {
      if (trend >= 0) {
        return 'text-green';
      } else {
        return 'text-red';
      }
    }
  }

  getTrendIconColor(trend: number): string {
    if (this.useChevronIcon){
      if (this.reverseMetric) {
        // For metrics like bounce rate where a reduction in the metric value is a positive indicator of performance
        if (trend <= 0) {
          return 'bg-green';
        } else {
          return 'bg-red';
        }
      } else {
        if (trend >= 0) {
          return 'bg-green';
        } else {
          return 'bg-red';
        }
      }
    }

    if (this.reverseMetric) {
      // For metrics like bounce rate where a reduction in the metric value is a positive indicator of performance
      if (trend <= 0) {
        return 'text-green';
      } else {
        return 'text-red';
      }
    } else {
      if (trend >= 0) {
        return 'text-green';
      } else {
        return 'text-red';
      }
    }
  }

  getBillboardColor(metricMom): string {
    if (!!this.reverseMetric) {
      metricMom = metricMom || 0;
      if (metricMom <= 0) {
        return 'billboard-green';
      } else {
        return 'billboard-red';
      }
    } else {
      metricMom = metricMom || 0;
      if (metricMom >= 0) {
        return 'billboard-green';
      } else {
        return 'billboard-red';
      }
    }
  }

  openHelpTextClicked(): void {
    this.openHelpText.emit();
  }
}
