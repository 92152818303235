<div class="card card-no-border mat-elevation-z1">
  <sd-card-header-select
    [defaultPerspective]="headerConfig.visualizationOptions.defaultPerspective"
    [perspectives]="headerConfig.visualizationOptions.perspectives" (perspectiveChanged)="perspectiveChanged($event)"
    (metricSelectionChanged)="metricSelectionChanged($event)" (expandClicked)="expandButtonClicked($event)">
  </sd-card-header-select>
  <!-- data table -->

  <combined-data-table [tableType]="'page-sorted'"></combined-data-table>
</div>
