import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { MaterialLibModule } from '@harmony-ui/material-lib';
import { MaterialLibModule } from './lib/material-lib.module';
// import { NgUtilsModule } from '@harmony-ui/ng-utils';
import { FilterPanelValueService } from './services/filter-panel-value.service';
import { HyFilterPanel } from './types/filter-panel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPanelListComponent } from './components/list/filter-panel-list.component';
import { FilterPanelCollectionComponent } from './components/collection/filter-panel-collection.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContinuationIndicatorComponent } from './components/faceted-filter/continuation-indicator/continuation-indicator.component';
import { FacetedFilterPanelComponent } from './components/faceted-filter/faceted-filter-panel.component';
import { BasePaneComponent } from './components/faceted-filter/panes/base-pane/base-pane.component';
import { DateRangePaneComponent } from './components/faceted-filter/panes/date-range-pane/date-range-pane.component';
import { FilterPaneComponent } from './components/faceted-filter/panes/filter-pane/filter-pane.component';
import { HierarchyPaneComponent } from './components/faceted-filter/panes/hierarchy-pane/hierarchy-pane.component';
import { OperatorPaneComponent } from './components/faceted-filter/panes/operator-pane/operator-pane.component';
import { FilterPanelHostComponent } from './components/host/default/filter-panel-host.component';
import { FacetedFilterPanelHostComponent } from './components/host/faceted/faceted-filter-panel-host/faceted-filter-panel-host.component';
import { FilterPaneManagerService } from './services/filter-pane-manager.service';
import { SafeSvgPipe } from './pipes/safeSvg.pipe';
import { TranslateBasePipe } from '../pipes/translate.pipe'
import { FilterWellComponent } from './ui/filter-well.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialLibModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule
  ],
  declarations: [
    FilterWellComponent,
    FilterPanelHostComponent,
    FilterPanelListComponent,
    FilterPanelCollectionComponent,
    FacetedFilterPanelHostComponent,
    FacetedFilterPanelComponent,
    FilterPaneComponent,
    DateRangePaneComponent,
    OperatorPaneComponent,
    ContinuationIndicatorComponent,
    HierarchyPaneComponent,
    BasePaneComponent,
    SafeSvgPipe,
    TranslateBasePipe
  ],
  exports: [
    FilterWellComponent,
    FilterPanelHostComponent,
    FilterPanelListComponent,
    FilterPanelCollectionComponent,
    FacetedFilterPanelHostComponent,
    FacetedFilterPanelComponent,
    FilterPaneComponent,
    DateRangePaneComponent,
    OperatorPaneComponent,
    ContinuationIndicatorComponent,
    HierarchyPaneComponent,
    BasePaneComponent,
    TranslateBasePipe
  ],
  providers: [FilterPanelValueService, HyFilterPanel, FilterPaneManagerService, TranslateBasePipe],
})
export class HyFilterPanelModule {}
