import { Subject, Observable } from 'rxjs';
import { FilterPanelClosedEvent, FilterPanelContent, IFilterPanelConfig, IFilterPanelResult } from './types';
import { OverlayRef } from '@angular/cdk/overlay';
import { FilterPanelCloseType } from './constants';

export class FilterPanelRef {

    private _afterClosed = new Subject<FilterPanelClosedEvent<IFilterPanelResult>>();
    afterClosed$: Observable<FilterPanelClosedEvent<IFilterPanelResult>> = this._afterClosed.asObservable();

    private _beforeClosedOnBackdropClick = new Subject<void>();
    beforeClosedOnBackdropClick$: Observable<void> = this._beforeClosedOnBackdropClick.asObservable();

    constructor(public overlayRef: OverlayRef, public config: IFilterPanelConfig, public content: FilterPanelContent, public inputDataType: string) {
        const panelResult: IFilterPanelResult = {
            resultType: FilterPanelCloseType.backdropClick,
            data: [],
            type: config.type,
            category: '',
            target: config.target
        };
        overlayRef.backdropClick().subscribe(() => {
            this._beforeClosedOnBackdropClick.next();
            this.closed(panelResult);
        });
    }

    close(result?: IFilterPanelResult) {
        if (result)
            this.closed(result);
    }


    private closed(result: IFilterPanelResult) {
        this.overlayRef.dispose();
        this._afterClosed.next({ dialogData: result });

        this._afterClosed.complete();
    }

}