import { IFacetedFilterDataItem, IReplayItem } from "../types";
import { TypeCheck } from "./type-check";


export class ReplayAction {
    public static GetItemsToReplay(paths: string[], item: IFacetedFilterDataItem, value?: any): IReplayItem[] {
        const result: IReplayItem[] = [...this.getItemsList(item, paths)];
        if (value) {
            const facetItem = result[result.length - 1];
            if (!facetItem.value) {
                facetItem.settable = true;
                facetItem.value = value;
            }
        }

        return result;
    }

    private static getItemsList(item: IFacetedFilterDataItem, paths: string[], result?: IReplayItem[]): IReplayItem[] {
        const currentResult = result && result.length ? [...result] : [];
        let val: any;
        if (TypeCheck.isHierarchyContainer(item.type))
            val = paths[0];
        else
            val = item.value ? item.value : paths[0];

        const replayItem: IReplayItem = {
            item: item,
            value: val,
            paths: [item.path],
            actionable: paths.length === 1 && this.replayItemActionable(item)
        };

        currentResult.push(replayItem);

        if (item.items) {
            const child: IFacetedFilterDataItem = item.items.find(i => (i.label ?? i.name) === paths[0]);
            if (child) {
                paths.shift();
                return this.getItemsList(child, paths, currentResult);
            } else {
                return currentResult;
            }
        } else {
            return currentResult;
        }
    }

    private static replayItemActionable(item: IFacetedFilterDataItem): boolean {
        if (!item.items)
            return true;

        return item.items.filter(i => TypeCheck.isContainer(i.type)).length > 0;
    }

    private static replayItemIsSettable(paths: string[], item: IFacetedFilterDataItem): boolean {
        return paths.length === 0 && TypeCheck.isFacet(item.type) && !!item.value;
    }
}
