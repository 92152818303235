
<mat-card class="mat-elevation-z1" [ngClass]="getBillboardColor(metric)">
  <mat-card-title class="legacy-billboard-title">
    <h3>{{title}}</h3>
    <div class="pull-right">
      <!--<button mat-button class="card-options-button" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"><i class="fa fa-ellipsis-v" style="font-size: 1.4em;"></i></button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item style="outline: none;" (click)="openHelpTextClicked()">Help</button>
      </mat-menu>-->
    </div>
  </mat-card-title>
  <mat-card-content class="card-block target-card" style="padding: 0px !important;">
    <table>
      <tbody>
        <tr>
          <td>
            <i class="fa" [ngClass]="iconClass"></i>
          </td>
          <td style="text-align:right;">
            <h3>
              <span style="font-size:55px;">{{metric}}</span> / 10
            </h3>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>
