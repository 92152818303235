
export const svgIcons = {
    archive:            `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                        </svg>`,
    arrowLeft:          `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>`,
    arrowTopRight:      `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                        </svg>`,
    ban:                `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                        </svg>`,
	bell:               `<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
						</svg>`,
    bulletList:         `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                        </svg>`,
    calendar:           `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>`,
    car:               `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M0 0h24v24H0V0z" fill="none"/><path d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.85 7h10.29l1.08 3.11H5.77L6.85 7zM19 17H5v-5h14v5z"/><circle cx="7.5" cy="14.5" r="1.5"/><circle cx="16.5" cy="14.5" r="1.5"/>
                        </svg>`,
    cash:               `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>`,
    chartPie:           `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                        </svg>`,
    chatBubble:         `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                        </svg>`,
    clipboardCheck:     `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                        </svg>`,
    computerDesktop:    `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                        </svg>`,
    check:              `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>`,
    checkCircle:        `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>`,
    chevronDblLeft:     `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                        </svg>`,
    chevronDblRight:    `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                        </svg>`,
    chevronDown:        `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>`,
    chevronLeft:        `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                        </svg>`,
    chevronRight:       `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                        </svg>`,
    chevronUp:          `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                        </svg>`,
    circle:			      	`<svg class="h-6 w-6" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx="4" cy="4" r="3"/>
                        </svg>`,
    clipboard:          `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                        </svg>`,
    close:              `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>`,
    cog:                `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>`,
    collection:         `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                        </svg>`,
    currencyDollar:    `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>`,
    customer:           `<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>`,
    dashboard:          `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                        </svg>`,
    documentAdd:        `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>`,
    dot:			      	  `<svg class="-ml-0.5 mr-1.5 h-6 w-6" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx="4" cy="4" r="3"/>
                        </svg>`,
    dollar:             `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M11.25 20.75v-2.1q-1.3-.225-2.237-1.012-.938-.788-1.388-2.213L9 14.85q.4 1.175 1.15 1.787.75.613 2 .613 1.075 0 1.9-.512.825-.513.825-1.638 0-.975-.613-1.55-.612-.575-2.562-1.2-2.05-.65-2.85-1.525-.8-.875-.8-2.175 0-1.5 1.063-2.375Q10.175 5.4 11.25 5.3V3.25h1.5V5.3q1.15.175 1.925.762.775.588 1.225 1.538l-1.375.625Q14.2 7.5 13.613 7.1q-.588-.4-1.563-.4-1.125 0-1.812.55-.688.55-.688 1.4 0 .875.7 1.4.7.525 2.625 1.1 1.775.525 2.637 1.525.863 1 .863 2.4 0 1.625-1.05 2.537-1.05.913-2.575 1.088v2.05Z"/>
                        </svg>`,
    dotsCircleH:        `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>`,
    dotsVertical:       `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>`,
    download:           `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                        </svg>`,
    downTrendArrow:     `<svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#EBEBEB"/>
                            <path d="M19.356 10.67C19.5165 10.5092 19.6066 10.2912 19.6066 10.064C19.6066 9.83679 19.5165 9.61885 19.356 9.458L18.546 8.648C18.4664 8.56842 18.3719 8.50529 18.268 8.46222C18.164 8.41915 18.0525 8.39698 17.94 8.39698C17.8274 8.39698 17.716 8.41915 17.612 8.46222C17.508 8.50529 17.4136 8.56842 17.334 8.648L11.807 14.153L6.27599 8.653C6.1151 8.49221 5.89695 8.4019 5.66949 8.4019C5.44203 8.4019 5.22388 8.49221 5.06299 8.653L4.25299 9.463C4.09276 9.62398 4.0028 9.84187 4.0028 10.069C4.0028 10.2961 4.09276 10.514 4.25299 10.675L11.198 17.62C11.3586 17.7798 11.5759 17.8695 11.8025 17.8695C12.0291 17.8695 12.2464 17.7798 12.407 17.62L19.356 10.67Z" fill="#C21B31"/>
                        </svg>`,
    duplicate:          `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                        </svg>`,
    eye:                `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>`,
	faceSmileSolid:     `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
							<path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-2.625 6c-.54 0-.828.419-.936.634a1.96 1.96 0 00-.189.866c0 .298.059.605.189.866.108.215.395.634.936.634.54 0 .828-.419.936-.634.13-.26.189-.568.189-.866 0-.298-.059-.605-.189-.866-.108-.215-.395-.634-.936-.634zm4.314.634c.108-.215.395-.634.936-.634.54 0 .828.419.936.634.13.26.189.568.189.866 0 .298-.059.605-.189.866-.108.215-.395.634-.936.634-.54 0-.828-.419-.936-.634a1.96 1.96 0 01-.189-.866c0-.298.059-.605.189-.866zm2.023 6.828a.75.75 0 10-1.06-1.06 3.75 3.75 0 01-5.304 0 .75.75 0 00-1.06 1.06 5.25 5.25 0 007.424 0z" clip-rule="evenodd" />
  						</svg>`,
	faceSmile:          `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" />
 						</svg>`,
	faceFrownSolid:     `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
							<path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-2.625 6c-.54 0-.828.419-.936.634a1.96 1.96 0 00-.189.866c0 .298.059.605.189.866.108.215.395.634.936.634.54 0 .828-.419.936-.634.13-.26.189-.568.189-.866 0-.298-.059-.605-.189-.866-.108-.215-.395-.634-.936-.634zm4.314.634c.108-.215.395-.634.936-.634.54 0 .828.419.936.634.13.26.189.568.189.866 0 .298-.059.605-.189.866-.108.215-.395.634-.936.634-.54 0-.828-.419-.936-.634a1.96 1.96 0 01-.189-.866c0-.298.059-.605.189-.866zm-4.34 7.964a.75.75 0 01-1.061-1.06 5.236 5.236 0 013.73-1.538 5.236 5.236 0 013.695 1.538.75.75 0 11-1.061 1.06 3.736 3.736 0 00-2.639-1.098 3.736 3.736 0 00-2.664 1.098z" clip-rule="evenodd" />
  						</svg>`,
	faceFrown:          `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M15.182 16.318A4.486 4.486 0 0012.016 15a4.486 4.486 0 00-3.198 1.318M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" />
  						</svg>`,
	faceNeutralSolid:   `<svg xmlns="http://www.w3.org/2000/svg" height="40" width="40">
							<path d="M26 17.958q.958 0 1.646-.666.687-.667.687-1.667 0-.958-.687-1.646-.688-.687-1.646-.687t-1.646.687q-.687.688-.687 1.646 0 1 .687 1.667.688.666 1.646.666Zm-12 0q.958 0 1.646-.666.687-.667.687-1.667 0-.958-.687-1.646-.688-.687-1.646-.687t-1.646.687q-.687.688-.687 1.646 0 1 .687 1.667.688.666 1.646.666Zm.833 7.917h10.375v-2.208H14.833ZM20 36.667q-3.458 0-6.5-1.313-3.042-1.312-5.292-3.562T4.646 26.5Q3.333 23.458 3.333 20t1.313-6.5q1.312-3.042 3.562-5.292T13.5 4.646q3.042-1.313 6.5-1.313t6.5 1.313q3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5t-1.313 6.5q-1.312 3.042-3.562 5.292T26.5 35.354q-3.042 1.313-6.5 1.313Z"/>
						</svg>`,
	faceNeutral:        `<svg xmlns="http://www.w3.org/2000/svg" height="40" width="40">
							<path d="M26 17.958q.958 0 1.646-.666.687-.667.687-1.667 0-.958-.687-1.646-.688-.687-1.646-.687t-1.646.687q-.687.688-.687 1.646 0 1 .687 1.667.688.666 1.646.666Zm-12 0q.958 0 1.646-.666.687-.667.687-1.667 0-.958-.687-1.646-.688-.687-1.646-.687t-1.646.687q-.687.688-.687 1.646 0 1 .687 1.667.688.666 1.646.666Zm.833 7.917h10.375v-2.208H14.833ZM20 36.667q-3.458 0-6.5-1.313-3.042-1.312-5.292-3.562T4.646 26.5Q3.333 23.458 3.333 20t1.313-6.5q1.312-3.042 3.562-5.292T13.5 4.646q3.042-1.313 6.5-1.313t6.5 1.313q3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5t-1.313 6.5q-1.312 3.042-3.562 5.292T26.5 35.354q-3.042 1.313-6.5 1.313ZM20 20Zm0 13.875q5.833 0 9.854-4.021 4.021-4.021 4.021-9.854 0-5.833-4.021-9.854Q25.833 6.125 20 6.125q-5.833 0-9.854 4.021Q6.125 14.167 6.125 20q0 5.833 4.021 9.854 4.021 4.021 9.854 4.021Z"/>
						</svg>`,
    filter:             `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                        </svg>`,
    flag:               `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9" />
                        </svg>`,
    globe:              `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                        </svg>`,
    grid:               `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                        </svg>`,
    home:               `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                        </svg>`,
    email:              `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>`,
    emailSolid:         `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                        </svg>`,
    info:               `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>`,
    inboundPaperAirplane: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 -rotate-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                        </svg>`,
    inbox:              `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="currentColor" stoke="none">
							<path d="M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h14q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21Zm0-2h14v-3h-3q-.75.95-1.787 1.475Q13.175 18 12 18t-2.212-.525Q8.75 16.95 8 16H5v3Zm7-3q.95 0 1.725-.55Q14.5 14.9 14.8 14H19V5H5v9h4.2q.3.9 1.075 1.45Q11.05 16 12 16Zm-7 3h14Z"/>
						</svg>`,
    library:            `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                        </svg>`,
    lockClosed:         `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                        </svg>`,
    lockOpen:           `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
                        </svg>`,
    logIn:              `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                        </svg>`,
    logOut:             `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>`,
    minus:              `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4" />
                        </svg>`,
    neutralTrendArrow: `<svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#EBEBEB"/>
                            <path d="M20.8696 10.4348H3.13043V13.5652H20.8696V10.4348Z" fill="#CCC516"/>
                            <path d="M20.3478 10.9565H3.65218V13.0435H20.3478V10.9565Z" stroke="#CCC516" stroke-linejoin="round"/>
                        </svg>`,
    newLabel:           `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M21,12l-4.37,6.16C16.26,18.68,15.65,19,15,19h-3l0-2h3l3.55-5L15,7H5v3H3V7c0-1.1,0.9-2,2-2h10c0.65,0,1.26,0.31,1.63,0.84 L21,12z M10,15H7v-3H5v3H2v2h3v3h2v-3h3V15z"/>
                        </svg>`,
    outboundPaperAirplane: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 rotate-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                        </svg>`,
    palette:            `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M12,22C6.49,22,2,17.51,2,12S6.49,2,12,2s10,4.04,10,9c0,3.31-2.69,6-6,6h-1.77c-0.28,0-0.5,0.22-0.5,0.5 c0,0.12,0.05,0.23,0.13,0.33c0.41,0.47,0.64,1.06,0.64,1.67C14.5,20.88,13.38,22,12,22z M12,4c-4.41,0-8,3.59-8,8s3.59,8,8,8 c0.28,0,0.5-0.22,0.5-0.5c0-0.16-0.08-0.28-0.14-0.35c-0.41-0.46-0.63-1.05-0.63-1.65c0-1.38,1.12-2.5,2.5-2.5H16 c2.21,0,4-1.79,4-4C20,7.14,16.41,4,12,4z"/><circle cx="6.5" cy="11.5" r="1.5"/><circle cx="9.5" cy="7.5" r="1.5"/><circle cx="14.5" cy="7.5" r="1.5"/><circle cx="17.5" cy="11.5" r="1.5"/></g></g></g></g>
                        </svg>`,
    paperClip:          `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                        </svg>`,
    pencilAlt:          `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>`,
    person:             `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>`,
    people:             `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>`,
    phone:              `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                        </svg>`,
    plus:               `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>`,
    reply:             `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
                        </svg>`,
    reset:              `<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                            <g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g>
                            <path d="M6,13c0-1.65,0.67-3.15,1.76-4.24L6.34,7.34C4.9,8.79,4,10.79,4,13c0,4.08,3.05,7.44,7,7.93v-2.02 C8.17,18.43,6,15.97,6,13z M20,13c0-4.42-3.58-8-8-8c-0.06,0-0.12,0.01-0.18,0.01l1.09-1.09L11.5,2.5L8,6l3.5,3.5l1.41-1.41 l-1.08-1.08C11.89,7.01,11.95,7,12,7c3.31,0,6,2.69,6,6c0,2.97-2.17,5.43-5,5.91v2.02C16.95,20.44,20,17.08,20,13z"/></g></g>
                        </svg>`,
    retailer:           `<svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor">
                            <path d="M22.48 23.02h-.46v-9.46c0-.27-.21-.49-.46-.49s-.46.22-.46.49v9.46H10.86v-9.46c0-.27-.21-.49-.46-.49s-.46.22-.46.49v9.46H2.98v-9.46c0-.27-.21-.49-.46-.49s-.46.22-.46.49v9.46h-.54c-.25 0-.46.22-.46.49s.21.49.46.49h20.96c.25 0 .46-.22.46-.49s-.21-.49-.46-.49zM23.12 8.49H.88c-.25 0-.46.22-.46.49s.21.49.46.49h22.24c.25 0 .46-.22.46-.49s-.21-.49-.46-.49zM5.27.98h13.46c.25 0 .46-.22.46-.49S18.98 0 18.73 0H5.27c-.25 0-.46.22-.46.49s.21.49.46.49zM3.35 12.68c1.21 0 2.2-1.05 2.2-2.34h-4.4c0 1.3.99 2.34 2.2 2.34z"/>
                            <path d="M7.74 12.68c1.21 0 2.2-1.05 2.2-2.34H5.55c0 1.3.98 2.34 2.19 2.34z"/><path d="M12.14 12.68c1.21 0 2.2-1.05 2.2-2.34h-4.4c0 1.3.98 2.34 2.2 2.34z"/><path d="M16.53 12.68c1.21 0 2.2-1.05 2.2-2.34h-4.39c-.01 1.3.98 2.34 2.19 2.34zM20.92 12.68c1.21 0 2.2-1.05 2.2-2.34h-4.39c0 1.3.98 2.34 2.19 2.34zM6.92 19.9c0 .48.37.88.82.88s.82-.39.82-.88c0-.3-.15-.57-.37-.73v-2.15c.22-.16.37-.43.37-.73 0-.35-.19-.65-.47-.79a.44.44 0 00-.36-.18.47.47 0 00-.36.18c-.28.14-.47.44-.47.79 0 .3.15.57.37.73v2.15a.92.92 0 00-.35.73zM22.94 8.1l-4.21-6.93h-2.84l3.02 6.93zM18 8.1l-2.75-6.93h-2.11l1.29 6.93zM12.87 1.17H11.5L10.4 8.1h3.57zM8.22 1.17H5.38L1.17 8.1H5.2zM6.11 8.1h3.57l1.29-6.93H8.86z"/>
                        </svg>`,
    rotateRight:        `<svg xmlns="http://www.w3.org/2000/svg"  class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M0 0h24v24H0V0z" fill="none"/>
                            <path d="M15.55 5.55L11 1v3.07C7.06 4.56 4 7.92 4 12s3.05 7.44 7 7.93v-2.02c-2.84-.48-5-2.94-5-5.91s2.16-5.43 5-5.91V10l4.55-4.45zM19.93 11c-.17-1.39-.72-2.73-1.62-3.89l-1.42 1.42c.54.75.88 1.6 1.02 2.47h2.02zM13 17.9v2.02c1.39-.17 2.74-.71 3.9-1.61l-1.44-1.44c-.75.54-1.59.89-2.46 1.03zm3.89-2.42l1.42 1.41c.9-1.16 1.45-2.5 1.62-3.89h-2.02c-.14.87-.48 1.72-1.02 2.48z"/>
                        </svg>`,
    searchSvg:          `<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                        </svg>`,
    signal:             `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.348 14.651a3.75 3.75 0 010-5.303m5.304 0a3.75 3.75 0 010 5.303m-7.425 2.122a6.75 6.75 0 010-9.546m9.546 0a6.75 6.75 0 010 9.546M5.106 18.894c-3.808-3.808-3.808-9.98 0-13.789m13.788 0c3.808 3.808 3.808 9.981 0 13.79M12 12h.008v.007H12V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                        </svg>`,
    sort:               `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 96 960 960">
                            <path d="M120 816v-60h240v60H120Zm0-210v-60h480v60H120Zm0-210v-60h720v60H120Z"/>
                        </svg>`,
    speakerphone:       `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                        </svg>`,
    tableCells:         `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                        </svg>
                        `,
    task:               `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                        </svg>`,
    template:           `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                        </svg>`,
    trash:              `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>`,
    tripOrigin:        `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12zm10 6c3.31 0 6-2.69 6-6s-2.69-6-6-6-6 2.69-6 6 2.69 6 6 6z"/>
                        </svg>`,
    upload:             `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                        </svg>`,
    upTrendArrow:       `<svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#EBEBEB"/>
                            <path d="M19.356 13.196C19.5165 13.3568 19.6066 13.5748 19.6066 13.802C19.6066 14.0292 19.5165 14.2471 19.356 14.408L18.546 15.218C18.4664 15.2976 18.3719 15.3607 18.268 15.4038C18.164 15.4468 18.0525 15.469 17.94 15.469C17.8274 15.469 17.716 15.4468 17.612 15.4038C17.508 15.3607 17.4136 15.2976 17.334 15.218L11.807 9.71599L6.27299 15.22C6.1121 15.3808 5.89395 15.4711 5.66649 15.4711C5.43903 15.4711 5.22088 15.3808 5.05999 15.22L4.24999 14.41C4.08976 14.249 3.9998 14.0311 3.9998 13.804C3.9998 13.5769 4.08976 13.359 4.24999 13.198L11.198 6.25C11.3587 6.08958 11.5764 5.99948 11.8035 5.99948C12.0305 5.99948 12.2483 6.08958 12.409 6.25L19.356 13.196Z" fill="#1BC23E"/>
                        </svg>`,
    userAdd:            `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                        </svg>`,
    users:              `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>`,
                       // To do: copied warehouse icon from figma, find the proper svg
    warehouse:          `<svg class="h-6 w-6" viewBox="0 0 31 33" stroke="white" stroke-width="2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2693_28518)">
                                <path d="M3.875 28.5002V11.1668L15.5 5.8335L27.125 11.1668V28.5002" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.7917 17.8335H21.9583V28.5002H9.04166V20.5002H16.7917" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.7917 28.4998V16.4998C16.7917 16.1462 16.6556 15.8071 16.4133 15.557C16.1711 15.307 15.8426 15.1665 15.5 15.1665H12.9167C12.5741 15.1665 12.2456 15.307 12.0033 15.557C11.7611 15.8071 11.625 16.1462 11.625 16.4998V20.4998" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_2693_28518">
                                <rect width="31" height="32" fill="white" transform="translate(0 0.5)"/>
                                </clipPath>
                            </defs>
                        </svg>`,
    world:              `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>`,
    wrench:             `<svg class="h-6 w-6" stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#feht2z0d7a)">
                                <path d="M7 10h3V7L6.5 3.5a6 6 0 0 1 8 8l6 6a2.121 2.121 0 0 1-3 3l-6-6a6 6 0 0 1-8-8L7 10z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="feht2z0d7a">
                                    <path d="M0 0h24v24H0z"/>
                                </clipPath>
                            </defs>
                        </svg>`,
    arrowUp:            `<svg xmlns="http://www.w3.org/2000/svg"  style=transform:rotate(90deg);" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>`,
    arrowDown:         `<svg xmlns="http://www.w3.org/2000/svg" style=transform:rotate(-90deg);" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                         <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                       </svg>`,
    flat:              `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                         <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
                        </svg>`,
    facebook:          `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" width="25" height="19">
                        <g transform="matrix(0.54347825 0 0 0.54285717 0 0)">
                        <g transform="matrix(0.4509804 0 0 0.45454547 0 0)">
                        <g transform="matrix(0.099609375 0 0 0.10026042 0 0)">
                        <g transform="matrix(1.8787245 0 0 1.8787245 128 -0)">
                        <path d="M353.701 0L55.087 0C 24.665 0 0.002 24.662 0.002 55.085L0.002 55.085L0.002 353.701C 0.002 384.124 24.664001 408.78598 55.086998 408.78598L55.086998 408.78598L202.362 408.78598L202.613 262.70798L164.662 262.70798C 159.73 262.70798 155.727 258.71997 155.70801 253.78798L155.70801 253.78798L155.526 206.70097C 155.507 201.74197 159.522 197.71198 164.481 197.71198L164.481 197.71198L202.363 197.71198L202.363 152.21397C 202.363 99.41397 234.61002 70.66397 281.711 70.66397L281.711 70.66397L320.361 70.66397C 325.306 70.66397 329.31598 74.67297 329.31598 79.61897L329.31598 79.61897L329.31598 119.32297C 329.31598 124.26697 325.309 128.27496 320.36597 128.27797L320.36597 128.27797L296.64697 128.28897C 271.03198 128.28897 266.07196 140.46097 266.07196 158.32397L266.07196 158.32397L266.07196 197.71298L322.35696 197.71298C 327.71997 197.71298 331.88095 202.39598 331.24896 207.72198L331.24896 207.72198L325.66797 254.80899C 325.13397 259.315 321.31296 262.71 316.77597 262.71L316.77597 262.71L266.32297 262.71L266.07196 408.788L353.70294 408.788C 384.12494 408.788 408.78693 384.126 408.78693 353.70398L408.78693 353.70398L408.78693 55.085C 408.786 24.662 384.124 0 353.701 0z" stroke="none" fill="#475993" fill-rule="nonzero" />
                        <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></g></g></g></g>
                        </svg>`,

googlecustomermatch:     `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" width="25" height="19">
                         <g transform="matrix(0.862069 0 0 0.76 0 0)"><g transform="matrix(0.028320312 0 0 0.032552086 0 0)">
                         <g transform="matrix(1.5401647 0 0 1.5401647 128 -0)">
                         <path d="M254.956 0C 179.332 0 111.908 33.168 66.228 85.752C 52.308 101.792 40.379997 119.608 30.891998 138.88L30.891998 138.88L114.9 203.048C 124.636 173.504 143.436 148.15201 168.02 130.216C 192.364 112.48 222.38 102 254.948 102C 290.132 102 321.908 114.472 346.876 134.87201L346.876 134.87201L419.508 62.34401C 375.26 23.8 318.516 0 254.956 0z" stroke="none" fill="#FF7976" fill-rule="nonzero" />
                         <path d="M30.9 138.872L114.908005 203.04C 124.644005 173.49599 143.444 148.144 168.028 130.208C 192.372 112.47199 222.388 101.99199 254.956 101.99199C 290.13998 101.99199 321.916 114.46399 346.884 134.86398L346.884 134.86398L419.516 62.335983C 375.252 23.808 318.516 0 254.956 0" stroke="none" fill="#E3443A" fill-rule="nonzero" />
                         <path d="M107.428 249.32C 107.428 247.024 107.596 244.76001 107.700005 242.488C 108.356 228.76001 110.796005 215.52802 114.908005 203.04001L114.908005 203.04001L30.9 138.872C 15.452 170.208 6.492 205.296 5.484 242.488C 5.42 244.768 5.2840004 247.03201 5.2840004 249.32C 5.2840004 251.61601 5.4200006 253.888 5.4760003 256.168C 6.484 293.312 15.444 328.384 30.852 359.688L30.852 359.688L114.836 295.4C 110.764 282.97598 108.34 269.8 107.7 256.16C 107.596 253.888 107.428 251.624 107.428 249.32z" stroke="none" fill="#F4D72C" fill-rule="nonzero" /><g>
                         <path d="M30.86 359.688L114.844 295.4C 110.772 282.97598 108.348 269.8 107.708 256.16C 107.604004 253.88 107.436 251.616 107.436 249.312C 107.436 247.01599 107.604 244.752 107.708 242.48C 108.364 228.752 110.804 215.51999 114.916 203.032L114.916 203.032L30.9 138.872" stroke="none" fill="#F7B92B" fill-rule="nonzero" /></g>
                         <path d="M337.876 374.848C 315.372 389.024 287.028 396.64798 254.95601 396.64798C 236.188 396.64798 218.30801 393.08 201.828 386.736C 189.692 382.064 178.364 375.81598 168.02 368.296C 143.444 350.4 124.636 325.128 114.90001 295.60797L114.90001 295.60797L30.892006 359.76797C 40.364006 379.00797 52.268005 396.8 66.164 412.81598C 111.844 465.456 179.31601 498.64798 254.95601 498.64798C 277.836 498.64798 300.324 495.59198 321.79602 489.63998C 357.492 479.736 390.38803 461.80798 417.62802 436.512L417.62802 436.512L337.876 374.848z" stroke="none" fill="#59C96E" fill-rule="nonzero" />
                         <path d="M30.9 359.768C 40.372 379.008 52.276 396.80002 66.172 412.816C 111.852 465.456 179.324 498.648 254.964 498.648C 277.844 498.648 300.332 495.592 321.80402 489.64C 357.5 479.73602 390.39603 461.808 417.63602 436.51202L417.63602 436.51202L337.876 374.85602C 315.372 389.032 287.028 396.656 254.95601 396.656C 236.188 396.656 218.30801 393.088 201.828 386.74402C 189.692 382.07202 178.364 375.824 168.02 368.30402" stroke="none" fill="#40A557" fill-rule="nonzero" />
                         <path d="M487.612 201.32L472.644 201.32L257.284 201.32L257.284 242.488L257.284 256.168L257.284 297.32L388.87598 297.32C 382.17996 329.32 363.95596 358.40802 337.87598 374.84802L337.87598 374.84802L417.636 436.50403C 439.508 416.2 457.676 390.72003 470.748 361.24805C 485.08398 328.96005 493.284 290.28806 493.284 247.79205C 493.284 233.072 491.02 217.32 487.612 201.32z" stroke="none" fill="#0FAEF4" fill-rule="nonzero" /><g>
                         <path d="M417.636 436.504C 439.508 416.2 457.836 390.432 470.908 360.96C 485.244 328.672 493.36398 290.14398 493.36398 247.65599C 493.36398 232.928 491.02 217.31999 487.61197 201.31999L487.61197 201.31999L473.28397 201.31999" stroke="none" fill="#4087ED" fill-rule="nonzero" />
                         <path d="M257.284 241.32L257.284 256.168L257.284 297.32L385.284 297.32" stroke="none" fill="#4087ED" fill-rule="nonzero" />
                         </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                         </g></g></g>
                         </svg>`,

google:                 `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" width="25" height="19">
                         <g transform="matrix(0.862069 0 0 0.76 0 0)"><g transform="matrix(0.028320312 0 0 0.032552086 0 0)">
                         <g transform="matrix(1.5401647 0 0 1.5401647 128 -0)">
                         <path d="M254.956 0C 179.332 0 111.908 33.168 66.228 85.752C 52.308 101.792 40.379997 119.608 30.891998 138.88L30.891998 138.88L114.9 203.048C 124.636 173.504 143.436 148.15201 168.02 130.216C 192.364 112.48 222.38 102 254.948 102C 290.132 102 321.908 114.472 346.876 134.87201L346.876 134.87201L419.508 62.34401C 375.26 23.8 318.516 0 254.956 0z" stroke="none" fill="#FF7976" fill-rule="nonzero" />
                         <path d="M30.9 138.872L114.908005 203.04C 124.644005 173.49599 143.444 148.144 168.028 130.208C 192.372 112.47199 222.388 101.99199 254.956 101.99199C 290.13998 101.99199 321.916 114.46399 346.884 134.86398L346.884 134.86398L419.516 62.335983C 375.252 23.808 318.516 0 254.956 0" stroke="none" fill="#E3443A" fill-rule="nonzero" />
                         <path d="M107.428 249.32C 107.428 247.024 107.596 244.76001 107.700005 242.488C 108.356 228.76001 110.796005 215.52802 114.908005 203.04001L114.908005 203.04001L30.9 138.872C 15.452 170.208 6.492 205.296 5.484 242.488C 5.42 244.768 5.2840004 247.03201 5.2840004 249.32C 5.2840004 251.61601 5.4200006 253.888 5.4760003 256.168C 6.484 293.312 15.444 328.384 30.852 359.688L30.852 359.688L114.836 295.4C 110.764 282.97598 108.34 269.8 107.7 256.16C 107.596 253.888 107.428 251.624 107.428 249.32z" stroke="none" fill="#F4D72C" fill-rule="nonzero" /><g>
                         <path d="M30.86 359.688L114.844 295.4C 110.772 282.97598 108.348 269.8 107.708 256.16C 107.604004 253.88 107.436 251.616 107.436 249.312C 107.436 247.01599 107.604 244.752 107.708 242.48C 108.364 228.752 110.804 215.51999 114.916 203.032L114.916 203.032L30.9 138.872" stroke="none" fill="#F7B92B" fill-rule="nonzero" /></g>
                         <path d="M337.876 374.848C 315.372 389.024 287.028 396.64798 254.95601 396.64798C 236.188 396.64798 218.30801 393.08 201.828 386.736C 189.692 382.064 178.364 375.81598 168.02 368.296C 143.444 350.4 124.636 325.128 114.90001 295.60797L114.90001 295.60797L30.892006 359.76797C 40.364006 379.00797 52.268005 396.8 66.164 412.81598C 111.844 465.456 179.31601 498.64798 254.95601 498.64798C 277.836 498.64798 300.324 495.59198 321.79602 489.63998C 357.492 479.736 390.38803 461.80798 417.62802 436.512L417.62802 436.512L337.876 374.848z" stroke="none" fill="#59C96E" fill-rule="nonzero" />
                         <path d="M30.9 359.768C 40.372 379.008 52.276 396.80002 66.172 412.816C 111.852 465.456 179.324 498.648 254.964 498.648C 277.844 498.648 300.332 495.592 321.80402 489.64C 357.5 479.73602 390.39603 461.808 417.63602 436.51202L417.63602 436.51202L337.876 374.85602C 315.372 389.032 287.028 396.656 254.95601 396.656C 236.188 396.656 218.30801 393.088 201.828 386.74402C 189.692 382.07202 178.364 375.824 168.02 368.30402" stroke="none" fill="#40A557" fill-rule="nonzero" />
                         <path d="M487.612 201.32L472.644 201.32L257.284 201.32L257.284 242.488L257.284 256.168L257.284 297.32L388.87598 297.32C 382.17996 329.32 363.95596 358.40802 337.87598 374.84802L337.87598 374.84802L417.636 436.50403C 439.508 416.2 457.676 390.72003 470.748 361.24805C 485.08398 328.96005 493.284 290.28806 493.284 247.79205C 493.284 233.072 491.02 217.32 487.612 201.32z" stroke="none" fill="#0FAEF4" fill-rule="nonzero" /><g>
                         <path d="M417.636 436.504C 439.508 416.2 457.836 390.432 470.908 360.96C 485.244 328.672 493.36398 290.14398 493.36398 247.65599C 493.36398 232.928 491.02 217.31999 487.61197 201.31999L487.61197 201.31999L473.28397 201.31999" stroke="none" fill="#4087ED" fill-rule="nonzero" />
                         <path d="M257.284 241.32L257.284 256.168L257.284 297.32L385.284 297.32" stroke="none" fill="#4087ED" fill-rule="nonzero" />
                         </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                         </g></g></g>
                         </svg>`,

    singlePerson:       `<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.3333 4.83333C10.3333 6.67428 8.84095 8.16667 7 8.16667C5.15905 8.16667 3.66667 6.67428 3.66667 4.83333C3.66667 2.99238 5.15905 1.5 7 1.5C8.84095 1.5 10.3333 2.99238 10.3333 4.83333Z" stroke="#4B5563" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7 10.6667C3.77834 10.6667 1.16667 13.2783 1.16667 16.5H12.8333C12.8333 13.2783 10.2217 10.6667 7 10.6667Z" stroke="#4B5563" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>`,

    anonymousPeople:    `<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.1667 14.6666H18.3333V12.9999C18.3333 11.6192 17.214 10.4999 15.8333 10.4999C15.037 10.4999 14.3276 10.8723 13.8698 11.4523M14.1667 14.6666H5.83333M14.1667 14.6666V12.9999C14.1667 12.4531 14.0613 11.9308 13.8698 11.4523M5.83333 14.6666H1.66666V12.9999C1.66666 11.6192 2.78595 10.4999 4.16666 10.4999C4.963 10.4999 5.67238 10.8723 6.1302 11.4523M5.83333 14.6666V12.9999C5.83333 12.4531 5.93868 11.9308 6.1302 11.4523M6.1302 11.4523C6.74458 9.91744 8.24568 8.83325 10 8.83325C11.7543 8.83325 13.2554 9.91744 13.8698 11.4523M12.5 3.83325C12.5 5.21396 11.3807 6.33325 10 6.33325C8.61929 6.33325 7.5 5.21396 7.5 3.83325C7.5 2.45254 8.61929 1.33325 10 1.33325C11.3807 1.33325 12.5 2.45254 12.5 3.83325ZM17.5 6.33325C17.5 7.25373 16.7538 7.99992 15.8333 7.99992C14.9129 7.99992 14.1667 7.25373 14.1667 6.33325C14.1667 5.41278 14.9129 4.66659 15.8333 4.66659C16.7538 4.66659 17.5 5.41278 17.5 6.33325ZM5.83333 6.33325C5.83333 7.25373 5.08714 7.99992 4.16666 7.99992C3.24619 7.99992 2.5 7.25373 2.5 6.33325C2.5 5.41278 3.24619 4.66659 4.16666 4.66659C5.08714 4.66659 5.83333 5.41278 5.83333 6.33325Z" stroke="#4B5563" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>`

}

export const svgIconTypes = {
    archive              : 'archive',
    arrowLeft            : 'arrowLeft',
    arrowTopRight        : 'arrowTopRight',
    ban                  : 'ban',
    bell                 : 'bell',
    bulletList           : 'bulletList',
    calendar             : 'calendar',
    car                  : 'car',
    chartPie             : 'chartPie',
    chatBubble           : 'chatBubble',
    check                : 'check',
    checkCircle          : 'checkCircle',
    chevronDblLeft       : 'chevronDblLeft',
    chevronDblRight      : 'chevronDblRight',
    chevronDown          : 'chevronDown',
    chevronLeft          : 'chevronLeft',
    chevronRight         : 'chevronRight',
    chevronUp            : 'chevronUp',
    circle               : 'circle',
    clipboard            : 'clipboard',
    close                : 'close',
    cog                  : 'cog',
    collection           : 'collection',
    computerDesktop      : 'computerDesktop',
    currencyDollar       : 'currencyDollar',
    customer             : 'customer',
    dashboard            : 'dashboard',
    documentAdd          : 'documentAdd',
    dot                  : 'dot',
    dollar               : 'dollar',
    dotsCircleHorizontal : 'dotsCircleH',
    dotsVertical         : 'dotsVertical',
    download             : 'download',
    downTrendArrow       : 'downTrendArrow',
    duplicate            : 'duplicate',
    eye                  : 'eye',
    email                : 'email',
    emailSolid           : 'emailSolid',
    faceSmile            : 'faceSmile',
    faceSmileSolid       : 'faceSmileSolid',
    faceFrown            : 'faceFrown',
    faceFrownSolid       : 'faceFrownSolid',
    faceNeutral          : 'faceNeutral',
    faceNeutralSolid     : 'faceNeutralSolid',
    filter               : 'filter',
    flag                 : 'flag',
    globe                : 'globe',
    grid                 : 'grid',
    home                 : 'home',
    info                 : 'info',
    inboundPaperAirplane : 'inboundPaperAirplane',
    inbox                : 'inbox',
    library              : 'library',
    logIn                : 'logIn',
    logOut               : 'logOut',
    lockClosed           : 'lockClosed',
    lockOpen             : 'lockOpen',
    minus                : 'minus',
    neutralTrendArrow    : 'neutralTrendArrow',
    newLabel             : 'newLabel',
    outboundPaperAirplane: 'outboundPaperAirplane',
    palette              : 'palette',
    paperClip            : 'paperClip',
    pencilAlt            : 'pencilAlt',
    person               : 'person',
    people               : 'people',
    phone                : 'phone',
    plus                 : 'plus',
    reply                : 'reply',
    reset                : 'reset',
    retailer             : 'retailer',
    rotateRight          : 'rotateRight',
    searchSvg            : 'searchSvg',
    signal               : 'signal',
    sort                 : 'sort',
    speakerphone         : 'speakerphone',
    tableCells           : 'tableCells',
    task                 : 'task',
    template             : 'template',
    trash                : 'trash',
    tripOrigin           : 'tripOrigin',
    upload               : 'upload',
    upTrendArrow         : 'upTrendArrow',
    userAdd              : 'userAdd',
    users                : 'users',
    world                : 'world',
    wrench               : 'wrench',
    arrowUp              : 'arrowUp',
    arrowDown            : 'arrowDown',
    flat                 : 'flat',
    facebook             : 'facebook',
    googlecustomermatch  : 'googlecustomermatch',
    google               : 'google',
    warehouse            : 'warehouse',
    singlePerson         : 'singlePerson',
    anonymousPeople      : 'anonymousPeople'
}

/**
 *
 * @param iconRef  The name of the icon
 * @param tailwindSize  Required! Tailwindcss size (ex: 6 the right hand side of w-6 or h-6). This will be used for Width and Height
 * @returns Svg Element as string.
 */
export function sizableSvgIcon(iconRef: string, tailwindSize: string | number) : string {
    if (!tailwindSize) {
        console.error('You must supply a value for tailwindWidth to resize svg icon');
        return '';
    }
    if (!iconRef) {
        console.error('You must supply a value for iconRef to resize svg icon');
        return '';
    }
    let targetIcon: string = svgIcons[iconRef];
    if (!targetIcon) {
        console.error('Cannot size svgIcon with invalid iconRef');
        return '';
    }

    const height: string = typeof tailwindSize === 'number' ? `h-${tailwindSize.toString()}` : `h-${tailwindSize}`;
    const width: string = typeof tailwindSize === 'number' ? `w-${tailwindSize.toString()}` : `w-${tailwindSize}`;

    targetIcon = targetIcon.replace('h-6', height).replace('w-6', width);

    return targetIcon;
}

export const harmonyLogo =
    `
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 415 425.64" style="enable-background:new 0 0 415 425.64;" xml:space="preserve">
<style type="text/css">
   .st0{fill:url(#SVGID_1_);}
</style>
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="43.7794" y1="212.8205" x2="513.938" y2="212.8205">
   <stop  offset="0" style="stop-color:#4F46E5"/>
   <stop  offset="0.1387" style="stop-color:#453FCD"/>
   <stop  offset="0.4323" style="stop-color:#2B2C8F"/>
   <stop  offset="0.7504" style="stop-color:#0C1645"/>
</linearGradient>
<path class="st0" d="M349.56,14.32h-77.7c-11.41,0-20.66,9.25-20.66,20.66v120.31l-88.41,30.38V34.98
   c0-11.41-9.25-20.66-20.66-20.66h-77.7c-11.41,0-20.66,9.25-20.66,20.66v355.68c0,11.41,9.25,20.66,20.66,20.66h77.7
   c11.41,0,20.66-9.25,20.66-20.66V285.13l88.41-30.38v135.92c0,11.41,9.25,20.66,20.66,20.66h77.7c11.41,0,20.66-9.25,20.66-20.66
   V34.98C370.22,23.57,360.97,14.32,349.56,14.32z"/>
</svg>`;

export const harmonyLogoBlue =
    `
    <svg width="48" height="58" viewBox="0 0 48 58" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M44.9622 0.0488281H33.5371C33.1382 0.0488281 32.7432 0.126784 32.3748 0.278234C32.0063 0.429684 31.6715 0.651656 31.3895 0.931484C31.1075 1.21131 30.8839 1.54351 30.7314 1.90909C30.5789 2.27467 30.5005 2.66648 30.5007 3.06212V20.6138L17.4993 25.0448V3.06212C17.4995 2.66648 17.4211 2.27467 17.2686 1.90909C17.1161 1.54351 16.8925 1.21131 16.6105 0.931484C16.3285 0.651656 15.9937 0.429684 15.6252 0.278234C15.2568 0.126784 14.8618 0.0488281 14.4629 0.0488281H3.03785C2.23216 0.0488281 1.45948 0.366294 0.889766 0.931395C0.320056 1.4965 0 2.26294 0 3.06212V54.9384C0 55.7376 0.320056 56.5041 0.889766 57.0692C1.45948 57.6343 2.23216 57.9517 3.03785 57.9517H14.4629C14.8618 57.9517 15.2568 57.8738 15.6252 57.7223C15.9937 57.5709 16.3285 57.3489 16.6105 57.0691C16.8925 56.7893 17.1161 56.4571 17.2686 56.0915C17.4211 55.7259 17.4995 55.3341 17.4993 54.9384V39.5453L30.5007 35.1158V54.9384C30.5005 55.3341 30.5789 55.7259 30.7314 56.0915C30.8839 56.4571 31.1075 56.7893 31.3895 57.0691C31.6715 57.3489 32.0063 57.5709 32.3748 57.7223C32.7432 57.8738 33.1382 57.9517 33.5371 57.9517H44.9622C45.7678 57.9517 46.5405 57.6343 47.1102 57.0692C47.6799 56.5041 48 55.7376 48 54.9384V3.06212C48 2.26294 47.6799 1.4965 47.1102 0.931395C46.5405 0.366294 45.7678 0.0488281 44.9622 0.0488281Z" fill="url(#paint0_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="0" y1="29.0003" x2="69.1327" y2="29.0003" gradientUnits="userSpaceOnUse">
    <stop stop-color="#2563EB"/>
    <stop offset="0.14" stop-color="#1D4ED8"/>
    <stop offset="0.43" stop-color="#1E40AF"/>
    <stop offset="0.75" stop-color="#1E3A8A"/>
    </linearGradient>
    </defs>
    </svg>

    `;
