import { InjectionToken } from "@angular/core";
import { IFilterPanelResult, ISimpleValue } from "./types";


export interface IFacetedFilterDataOptions {
    name: string;
    type: string;
    selectors: string[];
    default: string;
}

export interface IFacetedFilterDataItem {
    label: string;
    type: string;
    path: string;
    name?: string;
    searchable?: boolean;
    selectors?: string[];
    valueType?: string;
    value?: string | string[] | ISimpleValue | IDateRangeValue | IHierarchyValue | IOperatorValue;
    filterType?: string;
    items?: IFacetedFilterDataItem[];
}

// export interface INamedValue {
//   name: string;
//   path: string;
// }

export interface IDateRangeValue {
    name?: string;
    startDate: string;
    endDate: string;
    path?: string;
}

export interface IHierarchyValue {
    name: string;
    key: string;
    value: string;
    hierarchyTypeId?: string;
    path: string;
}

export function isHierarchyValueArray(value: any): value is IHierarchyValue[] {
  return Array.isArray(value) && value.every(item => item != null && typeof item.hierarchyTypeId === 'string');
}


export interface IOperatorValue {
    operator: string;
    operand: string | number | Date;
}

export interface IFacetedFilterData {
    label: string;
    options?: IFacetedFilterDataOptions[];
    items: IFacetedFilterDataItem[];
}

export interface IOperatorInfo {
    label: string;
    operator: string;
}

export interface IOperatorGroups {
    commonStringOperator: IOperatorInfo[];
    percentOperator: IOperatorInfo[];
    // commonNumberOperator: IOperatorInfo[];
    numericOperator: IOperatorInfo[];
}

export interface ISelectedOperatorInfo {
    operatorInfo: IOperatorInfo;
    operand: string;
    item: IFacetedFilterDataItem;
}

export interface ISelectedDateRangeInfo {
    dateRange: IDateRangeValue,
    item: IFacetedFilterDataItem;
}

export interface ISelectedFacetInfo {
    facets: IFacetedFilterDataItem[];
    item: IFacetedFilterDataItem;
}

export interface ISelectedHierarchyInfo {
    hierarchies: IHierarchyValue[];
    item: IFacetedFilterDataItem;
    purgePath?: string;
}

export type ISelectionInfo = ISelectedFacetInfo
                           | ISelectedDateRangeInfo
                           | ISelectedOperatorInfo
                           | ISelectedHierarchyInfo;

export interface IFacetedFilterSelection {
    name: string;
    type: string;
    path: string;
    displayPath: string;
    value?: string[] | IOperatorValue | IDateRangeValue | IHierarchyValue[];
    displayValue?: string;
    payload?: IFacetedFilterResult;
    isDefault?: boolean;
    isLocked?: boolean;
}

export interface IFacetedFilterResult {
    name: string;
    type?: string;
    value: string;
    valueType?: string;
}

export interface IFacetedFilters {
    contextName: string;
    filters: IFacetedFilterResult[];
}

export interface IReplayItem {
    item: IFacetedFilterDataItem;
    value?: string | string[] | ISimpleValue | IOperatorValue | IDateRangeValue | IHierarchyValue | IHierarchyValue[];
    paths: string[];
    actionable?: boolean;
    settable?: boolean;
}

interface IReplayItemValue {
    value: string | string[] | ISimpleValue | IOperatorValue | IDateRangeValue | IHierarchyValue;
    valueType: string
}

export interface IFacetedFilterSearchResult {
    label: string;
    path: string;
    item: IFacetedFilterDataItem;
}

export interface IFacetedFilterDataItemValue {
    key: string;
    value: string;
    name: string;
}

// export const FacetedFilterDataValueType =
// {
//     OrgType: "OrgType",
//     OrgCode1: "OrgCode1",
//     OrgCode2: "OrgCode2",
//     OrgCode3: "OrgCode3",
//     Retailer: "Retailer",
// }

export const FACETED_FILTER_DATA_TOKEN = new InjectionToken<IFacetedFilterData>('');
