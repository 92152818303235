import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { IEnums, Enums } from '../../enums/enums';
import * as Models from '../../models/models-index';
import { LayoutHelperService } from '../../../_layout/layout-index';
import { Subscription, Observable, of, combineLatest, BehaviorSubject } from 'rxjs';
import { SeriesOptionsType } from 'highcharts';
import { FormattingService } from '../../services/services-index';
import * as constants from '../../constants/constants';
import { ChartService } from '../chart/chart.service';

@Component({
    selector: 'multi-level-dual-line-chart',
    templateUrl: './multi-level-dual-line-chart.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MultiLevelDualLineChartComponent implements OnDestroy, OnInit {

    @Input('chartConfig') set chartConfig(chartConfig: Models.IMultiLevelTrendGraph) {
        this.chartConfig$.next(chartConfig);
    }
    @Input('metricOneConfig') set metricOneConfig(metricOneConfig: Models.IMultiLevelTrendGraphMetricSelectedPropertyConfig) {
        this.metricOneConfig$.next(metricOneConfig);
     }

    @Input('metricTwoConfig') set metricTwoConfig(metricTwoConfig: Models.IMultiLevelTrendGraphMetricSelectedPropertyConfig) {
        this.metricTwoConfig$.next(metricTwoConfig);
    }
    @Input() locale = 'en';
    @ViewChild('sdChart', { static: false }) chartRef: ElementRef;

    private chartConfig$ = new BehaviorSubject<Models.IMultiLevelTrendGraph>(null);
    private metricOneConfig$ = new BehaviorSubject<Models.IMultiLevelTrendGraphMetricSelectedPropertyConfig>(null);
    private metricTwoConfig$ = new BehaviorSubject<Models.IMultiLevelTrendGraphMetricSelectedPropertyConfig>(null);

    enums: IEnums = Enums;
    subscriptions: Subscription[] = [];
    currentChart$: Observable<Chart>;
    currentChart: Chart;
    chartSeries: SeriesOptionsType[];

    constructor(
        private layoutService: LayoutHelperService,
        private formattingService: FormattingService,
        private chartService: ChartService
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.layoutService.sideNavToggleAction$.subscribe(navState => {
                setTimeout(() => { this.reflowChart(); }, navState === 'open' ? 20 : 30);
            })
        );

      this.subscriptions.push(
        this.chartService.reflowChart$.subscribe(() => {
          this.currentChart.ref.reflow();
        })
      )

        this.subscriptions.push(
            combineLatest([this.chartConfig$, this.metricOneConfig$, this.metricTwoConfig$])
            .subscribe(([cc, mOne, mTwo]) => {
                this.currentChart = this.updateChartSeries(cc, mOne, mTwo);
                this.currentChart$ = of(this.currentChart);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    reflowChart() {
        if (this.currentChart && this.currentChart.ref) {
          this.currentChart.ref.reflow();
        }
    }

    getMetricFormatter(formatKey: string, loc: string) {

        // this.formattingService.getFormatter()

        if (formatKey === constants.formatKeys.percentageTwoDecimals) {
            return (val) => this.formattingService.percentPipeTwoDecimals(val, loc);
        } else if (formatKey === constants.formatKeys.currencyNoCents) {
            return (val) => this.formattingService.currencyNoCents(val);
        } else if (formatKey === constants.formatKeys.minutesTimeStringFromSeconds) {
            return (val) => this.formattingService.minutesTimeStringFromSeconds(val);
        } else if (formatKey === constants.formatKeys.minutesTimeString) {
            return (val) => this.formattingService.minutesTimeString(val);
        } else if (formatKey === constants.formatKeys.roundToHundreth) {
            return (val) => this.formattingService.roundToHundredth(val, loc);
        } else if (formatKey === constants.formatKeys.roundToTenth) {
            return (val) => this.formattingService.roundToTenth(val, loc);
        } else {
            return (val) => this.formattingService.localeString(val, loc);
        }
    }

    private updateChartSeries(
            graphDataObject: Models.IMultiLevelTrendGraph,
            metricOneConfig: Models.IMultiLevelTrendGraphMetricSelectedPropertyConfig,
            metricTwoConfig: Models.IMultiLevelTrendGraphMetricSelectedPropertyConfig
            ) {
        // this is dirty...need to find a cleaner way to make sure this scenario never happens
        if (!metricOneConfig || !metricTwoConfig ) {
            return;
        }

        let chartSeriesOneData = [];
        let chartSeriesTwoData = [];

        const chartMetricOneChildData = graphDataObject.metrics.find(m => m.id === metricOneConfig.childId);
        const chartMetricTwoChildData = graphDataObject.metrics.find(m => m.id === metricTwoConfig.childId);

        const metricOneFormatter = this.formattingService.getFormatter(metricOneConfig.metricFormatKey, this.locale);
        const metricTwoFormatter = this.formattingService.getFormatter(metricTwoConfig.metricFormatKey, this.locale);

        chartSeriesOneData = chartMetricOneChildData.metrics.find(m => m.metricId === metricOneConfig.parentId).data;
        chartSeriesTwoData = chartMetricTwoChildData.metrics.find(m => m.metricId === metricTwoConfig.parentId).data;

        this.chartSeries = [{
            name: metricOneConfig.childDisplayName + ' - ' +  metricOneConfig.parentDisplayName,
            type: 'spline',
            yAxis: 0,
            data: chartSeriesOneData,
            color: graphDataObject.metricOneColorHexCode,
            marker: { symbol: 'circle' },
            lineWidth: 3
        }, {
            name: metricTwoConfig.childDisplayName + ' - ' +  metricTwoConfig.parentDisplayName,
            type: 'spline',
            yAxis: 1,
            data: chartSeriesTwoData,
            color: graphDataObject.metricTwoColorHexCode,
            marker: { symbol: 'circle' },
            lineWidth: 3
        }];

        return new Chart({
            credits: {
                enabled: false
            },
            chart: {
                zoomType: 'xy',
                height: 308,
            },
            title: {
                text: ''
            },
            xAxis: [{
                categories: graphDataObject.dates,
                crosshair: true
            }],
            // AXES.....
            yAxis: [{ // Primary yAxis
                labels: {
                    formatter: function() { return metricOneFormatter(this.value); },
                    style: {
                        color: graphDataObject.metricOneColorHexCode
                    },
                },
                title: {
                    text: metricOneConfig.childDisplayName + ' - ' + metricOneConfig.parentDisplayName,
                    style: {
                        color: graphDataObject.metricOneColorHexCode
                    }
                }
            }, { // Secondary yAxis
                labels: {
                    formatter: function() { return metricTwoFormatter(this.value); },
                    style: {
                        color: graphDataObject.metricTwoColorHexCode
                    }
                },
                title: {
                    text: metricTwoConfig.childDisplayName + ' - ' + metricTwoConfig.parentDisplayName,
                    style: {
                        color: graphDataObject.metricTwoColorHexCode
                    }
                },
                opposite: true
            }],
            tooltip: {
                formatter: function () {
                    /* Have to do some sinning here to get highcharts to work...doesn't work if we try to abstract this into the traffic service :( */
                    let s = '<span style="font-size: 10px">' + this.x + '</span><br/>';

                    for (let i = 0; i < this.points.length; i++) {

                        const myPoint = this.points[i];

                        // this is all jacked, but we can't really tell which metric we are localizing since this is shared
                        // each tool tip constains both metrics, but are formatted separately
                        // it would be worth investigating adding information the series points to make this clean
                        // we could use color in series options, but that is just as bad

                        const metricFormatter = myPoint.series.name.split(' - ')[1] === metricOneConfig.parentDisplayName ? metricOneFormatter : metricTwoFormatter;

                        s += `<br/><span style="color:${myPoint.series.options.color}">\u25CF </span>` + myPoint.series.name + ': ';
                        s += metricFormatter(myPoint.y);
                    }

                    return s;
                },
                shared: true
            },
            legend: {
                align: 'center',
                x: 0,
                verticalAlign: 'top',
                y: 0,
                floating: false,
                backgroundColor: 'white',
                borderColor: '#CCC',
                borderWidth: 0,
                itemMarginTop: 10,
                shadow: false
            },
            series: this.chartSeries
        });
    }
}
