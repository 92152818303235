import { Injectable } from '@angular/core';
import { LayoutTranslationConfig } from '../../../_config/translation/layout/layout.translation.config';
import { TranslationService } from './translation.service';
@Injectable()
export class LayoutTranslationService {

  constructor(private translationService: TranslationService) { }
  layoutTranslationConfig = new LayoutTranslationConfig;

  getLayoutTranslation(val: string, locale: string) {

    const translation = this.translationService.getResourceStringTranslation(val, locale);

    if (!!translation && translation !== val) {
      return translation;
    }

    if (locale === 'en') {
      return val;
    }
    
    const value = val.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.layoutTranslationConfig.layoutTranslations[locale][value];
  }

  getNavLabelTranslation(val: string, locale: string) {

    const translation = this.translationService.getResourceStringTranslation(val, locale);

    if (!!translation && translation !== val) {
      return translation;
    }

    if (locale === 'en') {
      return val;
    }

    const value = val.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.layoutTranslationConfig.navLabelTranslations[locale][val];
  }
}

