import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'pagingdetail'})
export class PagingDetailPipe implements PipeTransform {
    transform(totalRecords: number, pageSize: number, pageNumber: number): string {
        if (totalRecords !== null) {
            let a: number;
            let b: number;

            a = (pageNumber - 1) * pageSize + 1;
            b = Math.min(pageNumber * pageSize, totalRecords);

            return `${a} - ${b} of ${totalRecords}`;
        }
        return '';
    }
}
