

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Observable } from 'rxjs';
import * as Models from '../../../_shared/models/models-index';
import { map } from 'rxjs/operators';

@Component({
  selector: 'sd-pie-chart',
  templateUrl: './pie-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PieChartComponent implements OnInit {
    @Input() chartMetrics:  Observable<{ title: string, seriesColors: string[], data: any[], locale?: string}>;
    currentChart$: Observable<Chart>;
    chartColors: string[];
    locale: string;
    title: string;
    constructor() { }

    ngOnInit() {
      this.currentChart$ =
        this.chartMetrics.pipe(
            map(update => {
                this.chartColors = update.seriesColors;
                this.title = update.title;
                return this.getPieChart(update.data, update.locale);
            })
        );
    }

    private getPieChart(seriesData: any, locale: string) {
        return new Chart({
          credits: {
            enabled: false
          },
          title: { text: this.title || ' ' },
          colors: this.chartColors,
          chart: {
            type: 'pie',
            height: 341, // height of dealer performance table: 353px, padding: 12px
            // width: 280, // inherit width -- set as an input if we need to change this, but probably want to inherit this
            animation: null
          },
          plotOptions: {
            pie: {
              allowPointSelect: false,
              dataLabels: {
                enabled: false
              },
              showInLegend: true,
              states: {
                hover: {
                  halo: {
                    size: 0
                  }
                }
              },
              events: {
                click: function (e) { }
              }
            },
            series: {
              animation: true,
              point: {
                events: {}
              }
            }
          },
          tooltip: {
            headerFormat: '',
            pointFormatter: function () {
              return '<b>' + this.name + '</b>: ' + this.y.toLocaleString(locale) + '<br><b>' + (this.percentage).toFixed(0) + '%</b>'  ;
            }
          },
          legend: {
            enabled: true,
            align: 'center'
          },
          series: [<Highcharts.SeriesPieOptions>{
            data: seriesData
          }]
        });
      }

}

