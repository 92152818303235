
<div class="fixed inset-0 bg-gray-600 bg-opacity-75 w-screen h-screen z-10" [ngStyle]="{'visibility': mobilePanelOpen || mobileResultPanelOpen ? 'visible' : 'hidden'}" aria-hidden="true"></div>

<div *ngIf="mobileResultPanelOpen" class="fixed left-0 top-0 bottom-16 right-0 bg-gray md:hidden z-10">

    <mat-card class="filter-panel-card lrgPanel flex flex-col mat-elevation-z2 rounded-md">

        <div class="faceted-filter-panel-header flex">

            <div class="faceted-filter-panel-header-content relative">

                <div class="flex-1 flex items-center justify-center text-xl h-full">
                    <span>Applied Filter</span><span *ngIf="selectedFilterItems.length > 1">s</span>&nbsp;<span>({{selectedFilterItems.length}})</span>
                </div>

                <div class="h-8 w-8 flex items-center justify-items-center hover:bg-primary-100" (click)="closeMobileResultPanel()">
                    <div class="text-white ml-0" [innerHtml]="svgIcons.close | safeSvg"></div>
                </div>

            </div>

        </div>

        <mat-card-content #rootContent class="faceted-filter-panel-content">

            <div class="w-full h-full">

                <ng-container *ngFor="let item of selectedFilterItems; let even = even">

                    <div class="w-full min-h-[56px] py-1 flex items-center text-gray-900 font-inter border-b border-gray-200 cursor-pointer panel-option"
                         [ngClass]="{'bg-gray-50': even}" (click)="mobileFilterItemSelected(item, mobileAnchor)">

                        <div class="pl-3 font-medium text-base flex-1">{{item.displayValue}}</div>

                        <div class="h-8 w-8 flex items-center justify-items-center hover:bg-primary-100" (click)="removeFilterItem(item)">
                            <div class="text-primary-600" [innerHtml]="svgIcons.close| safeSvg"></div>
                        </div>

                    </div>

                </ng-container>

            </div>

        </mat-card-content>

        <mat-card-footer class="flex md:hidden m-0 h-16 border-t border-gray-200 bg-white">

            <div class="w-full h-full flex items-center justify-center">
                <button [disabled]="!filtersDirty"  class="button mr-6" (click)="resetFilters()">
                    <span class="mr-1" [innerHtml]="svgIcons.reset | safeSvg"></span>Reset
                </button>

                <button class="button-primary" (click)="openMobileFilterPanel()">
                    <span [innerHtml]="addIcon | safeSvg"></span>Filter
                </button>
            </div>

        </mat-card-footer>

    </mat-card>

</div>

<div class="flex items-center justify-items-start w-full well-container relative">

    <div #mobileAnchor class="md:hidden absolute -left-3 top-[-96px] w-1 h-1">&nbsp;</div>

    <div class="flex items-center justify-items-center flex-wrap w-full">

        <div class="flex md:hidden ">

            <button [disabled]="initializing || noData" class="disabled:opacity-50 h-11 inline-flex items-center px-4 py-2 shadow-sm font-inter text-base font-normal rounded-md text-gray-100 bg-primary-600 focus:outline-none" (click)="showMobilePanel()">

                <span class="ml-2 mr-2 text-white">Filter/s: ({{selectedFilterItems.length}})</span>

                <!-- <div class="w-px flex items-center text-gray-200 font-normal h-9 py-1 mx-2">|</div> -->

                <span [innerHtml]="downIcon | safeSvg"></span>

            </button>

        </div>


        <div class="hidden md:flex items-center w-full h-full">

            <button #newFilterIcon [disabled]="noData" [ngClass]="noData ? 'text-gray-400 hover:text-gray-400 hover:no-underline cursor-context-menu' : 'text-gray-500 hover:underline hover:text-primary-800 cursor-pointer'" class="w-10" (click)="newFilterClicked(newFilterIcon)">
                <span [innerHtml]="filterIcon | safeSvg"></span>
            </button>

            <div class="flex-1 flex flex-wrap">

                <mat-chip-listbox #filterChipList class="filter-chip-list mr-3">

                    <div #filterChip *ngFor="let item of selectedFilterItems">

                    <mat-chip class="mat-chip mat-elevation-z2 mat-primary mat-standard-chip mat-chip-selected h-8" >

                        <!-- <div *ngIf="item.iconName" class="text-primary-600 ml-0" [innerHtml]="getChipIcon(item.iconName) | safeSvg"></div> -->

                        <a class="ml-1 mr-2 truncate cursor-pointer hover:bg-none" [title]="item.displayValue" (click)="filterItemSelected(item, filterChip)">
                            <span class="font-inter font-semibold">{{item.displayValue}}</span>
                        </a>

                        <div class="h-5 w-5 flex items-center justify-center m-0 rounded-full" matChipRemove (click)="removeFilterItem(item)">
                            <div class="text-white ml-0" [innerHtml]="closeIcon | safeSvg"></div>
                        </div>

                        <!-- <div *ngIf="item.isLocked" class="text-primary-600 ml-0" [innerHtml]="lockClosed | safeSvg"></div> -->

                    </mat-chip>

</div>

                </mat-chip-listbox>

            </div>

            <div class="flex w-[98px]">

                <button #newFilterBtn [disabled]="noData" [ngClass]="noData ? 'text-gray-400 hover:text-gray-400 hover:no-underline cursor-context-menu' : 'text-gray-500 hover:underline hover:text-primary-800 cursor-pointer'" class="flex items-center mr-3 text-base cursor-pointer" (click)="newFilterClicked(newFilterBtn)">
                    <span [innerHtml]="addIcon | safeSvg"></span>Filter
                </button>

                <div class="h-full flex items-center justify-items-end"> <!--*ngIf="facets && facets.length" -->

                    <button [disabled]="!filtersDirty || noData" [ngClass]="filtersDirty && !noData ? 'text-gray-500 cursor-pointer' : 'text-gray-300 cursor-context-menu'" class="flex items-center justify-items-end h-9 rounded hover:border-gray-300 focus:outline-none" (click)="resetFilters()">
                        <span class="mr-1" [innerHtml]="svgIcons.reset | safeSvg"></span>
                    </button>

                </div>

            </div>

        </div>

    </div>

</div>
