
<ng-container>

    <div class="pane-wrapper">

        <div class="pane bg-white border-r border-gray-300 scrollable-y scrollbar-thin scrollbar-thumb-primary-200 scrollbar-thumb-rounded-md scrollbar-track-primary-50 hover:scrollbar-thumb-primary-200">
    
            <div class="relative w-full h-10 flex items-center text-gray-900 bg-white hover:bg-white active:bg-white cursor-pointer border-b border-gray-300">

                <select [(ngModel)]="selectedOperator" (ngModelChange)="buildOperatorExpression()" class="w-full h-full focus:ring-0 p-0 pl-3 border-0 active:ring-0 text-gray-900 font-inter font-medium text-base md:text-sm cursor-pointer">
                    <option class="text-gray-900 font-inter font-medium text-base md:text-sm" *ngFor="let operator of operatorInfo" [value]="operator.operator">{{operator.label}}</option>
                </select>

            </div>

            <div [formGroup]="valueFormGroup" class="relative w-full h-10 flex items-center text-gray-900 bg-white hover:bg-white active:bg-white cursor-pointer border-b border-gray-300">

                <input #valueInput [formControl]="valueField" type="text" name="valueField" id="valueField" formControlName="valueField" class="focus:ring-0 block w-full h-full border-0 pl-3 text-gray-900 font-inter font-medium text-base md:text-sm placeholder-gray-300" placeholder="Filter Value">
                <div *ngIf="valueField.value && valueField.value.length" [innerHtml]="smallCloseIcon | safeSvg" class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-300" (click)="clearValue()"></div>

            </div>
            <div class="text-red-600 mt-1 ml-3" style="font-size: 10px" *ngIf="valueField.invalid">*Required</div>
    
        </div>

    </div>

</ng-container>