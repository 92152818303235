import { Directive, HostListener, ElementRef } from '@angular/core';

//@Directive({
//  selector: '[itemSelected]'
//})
//export class ItemSelectedDirective {

//  constructor(private el: ElementRef) { }

//  toggle() {
//    this.el.nativeElement.classList.toggle('selected');
//  }
//}

/**
* Allows the class to be toggled via click.
*/
@Directive({
  selector: '[itemSelectedToggle]'
})
export class ItemSelectedToggleDirective {
  constructor(private el: ElementRef) { }
  //@HostListener('mouseenter') onMouseEnter() {
  //  this.highlight('yellow');
  //}

  //@HostListener('mouseleave') onMouseLeave() {
  //  this.highlight(null);
  //}

  //private highlight(color: string) {
  //  this.el.nativeElement.style.backgroundColor = color;
  //}
  @HostListener('click') onClick() {
    this.toggle();
  }

  private toggle() {
    this.el.nativeElement.classList.toggle('selected');
  }
}
