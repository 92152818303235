import { ISaleType, ReportRequestModel } from './../../models/models';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';
import { LocalCacheService } from '../cache/localCache.service';
import { catchError } from 'rxjs/operators';
import { AppInsightsService } from '../appInsights/appInsights.service';
import { throwError, Observable } from 'rxjs';
import { GetDataSetConfigurationResponse } from '../../models/data-set-configuration-models';

@Injectable()
export class ReportsService {

    orgLookups$ = this.http.get<Models.IOrgLookup[]>
        (environment.baseApiUri + '/getOrgLookups').pipe(
            catchError(err => {
                this.appInsights.trackException(err);
                return throwError(() => err);
            })
        );

  constructor(
    private http: HttpClient,
    private cacheService: LocalCacheService,
    private appInsights: AppInsightsService) { }

  getReport(reportRequest: ReportRequestModel, reportType: string): Observable<Models.ReportResponseModel> {
    reportRequest.reportType = reportType;
    return this.http.post<Models.ReportResponseModel>(
      environment.baseApiUri + '/report',
      reportRequest,
      { headers: { 'Content-Type': 'application/json' } }).pipe(
        catchError(err => {
          console.log(reportType, err);
          appInsights.trackException(err);
          return this.handleError(err);
        })
      );
  }

  getReportView(reportRequest: ReportRequestModel): Observable<Models.ReportViewResponse> {
    return this.http.post<Models.ReportViewResponse>(
      environment.baseV5ApiUri + '/report',
      reportRequest,
      { headers: { 'Content-Type': 'application/json' } }).pipe(
        catchError(err => {
          console.log(reportRequest.reportType, err);
          appInsights.trackException(err);
          return this.handleError(err);
        })
      );
  }

  getReportFilterNames(reportName: string): Observable<Models.ReportViewFilterConfiguration> {
    return this.http.get<Models.ReportViewFilterConfiguration>(environment.baseV5ApiUri + `/report/filternames?report=${reportName}`).pipe(
      catchError(err => {
        console.log(reportName, err);
        appInsights.trackException(err);
        return this.handleError(err);
      }));
  }

  getReportFilters(reportName: string, filterNames: string[], cultureCode: string): Observable<Models.ReportViewFilter[]> {
    const useCultureCode = cultureCode.toLowerCase().indexOf('en') > -1 ? 'en-US' : cultureCode;
    const filterNamesString = filterNames.join(',');
    return this.http.get<Models.ReportViewFilter[]>(
      environment.baseV5ApiUri + `/report/filters?report=${reportName}&filterNames=${filterNamesString}&cultureCode=${useCultureCode}`).pipe(
      catchError(err => {
        console.log(reportName, err);
        appInsights.trackException(err);
        return this.handleError(err);
      }));
  }

  getDataSet(reportRequest: ReportRequestModel): Observable<Models.DataSet[]> {
    return this.http.post<Models.DataSet[]>(
      environment.baseV5ApiUri + '/report/datasets',
      reportRequest,
      { headers: { 'Content-Type': 'application/json' } }).pipe(
        catchError(err => {
          console.log(reportRequest.reportType, err);
          appInsights.trackException(err);
          return this.handleError(err);
        })
      );
  }

  projectDataSet(queryName: string): Observable<GetDataSetConfigurationResponse> {
    const params = new HttpParams().set('queryName', queryName);
    return this.http.get<GetDataSetConfigurationResponse>(
      environment.baseV5ApiUri + '/report/projectcolumnconfigs',
      { params }
    ).pipe(
      catchError(err => {
        appInsights.trackException(err);
        return this.handleError(err);
      })
    );
  }

  // All of this could be consolidated into a single config call that returned all of these elements at once.
  // They're parameter-less
  getAppConfig(): Observable<Models.IAppConfig> {
    return this.http.get<Models.IAppConfig>(environment.baseApiUri + '/getAppConfig')
      .pipe(
        catchError(this.handleError));
  }
  
    private handleError(err: any) {
        let errorMessage: string;
        if (!!err.error && err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err?.status}: ${err?.body?.error}`;
        }
        this.appInsights.trackException(err);
        console.error(err);
        return throwError(() => errorMessage);
    }
}
