import { Component, Input, OnDestroy, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Enums, IEnums } from '../enums/enums';
import { Chart } from 'angular-highcharts';
import { SeriesOptionsType } from 'highcharts';
import * as SharedServices from '../../_shared/services/services-index';

@Component({
  selector: 'provider-multi-metric-scorecard-billboard',
  templateUrl: 'provider-multi-metric-scorecard-billboard.component.html',
  styleUrls: ['billboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderMultiMetricScorecardBillboardComponent implements OnDestroy, OnInit {
  @Input() providerName: string;
  @Input() providerCode: string;
  @Input() metrics: any[];

  @Output() openHelpText = new EventEmitter();
  enums: IEnums = Enums;
  primaryMetric: any;
  currentChart: any;
  subMetricWidth: string;
  constructor(
    private formattingService: SharedServices.FormattingService,) {
   }

  ngOnInit() {
    this.primaryMetric = this.metrics[0];
    this.currentChart = this.generateChart();
    this.subMetricWidth = this.getWidth();   }

  ngOnDestroy(): void { }

  getProviderImagePath(code: string): string {
        return '../../../assets/images/Providers/' + code + '.png';
  }

  getWidth() {
    if (this.metrics.length <= 1) return '';
    return `width: ${100/(this.metrics.length-1)}%`;
  }

  private generateChart() {
    const chartSeries: SeriesOptionsType[] = [{
      type: 'area',
      data: this.primaryMetric.seriesData,
      color: this.primaryMetric.chartLineColor,
      fillColor: this.primaryMetric.chartFillColor,
      lineWidth: 2,
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 3,
        color: this.primaryMetric.chartLineColor
      },
      states: {
        hover: {
          enabled: false
        }
      }
    }];

    var chart = new Chart({
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      chart: {
        showAxes: false,
        height: 160
      },
      tooltip: {
        enabled: false
      },
      // AXES.....
      xAxis: {
        title: {text: null},
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false
        },
        minorTickLength: 0,
        tickLength: 0,
      },
      yAxis: {
        title: {text: null},
        lineWidth: 0,
        gridLineColor: 'transparent',
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false
        },
        minorTickLength: 0,
        tickLength: 0
      },
      legend: {
        enabled: false
      },
      series: chartSeries
    });
    return chart;

  }

  getFormatter(formatter: string){
    return this.formattingService.getFormatter(formatter)
  }

  getTrendArrowIconClass(trend: number): string {
    trend = trend || 0;

    if (trend === 0) {
      return 'fa fa-horizontal-rule';
    } else if (trend < 0) {
      return 'fa fa-chevron-circle-down chevron-icon';
    } else {
      return 'fa fa-chevron-circle-up chevron-icon';
    }
  }
  getTrendColor(trend: number, reverseMetric: boolean = false): string {
    trend = trend || 0;
    const absTrend = !!reverseMetric ? trend * -1 : trend;

    if (absTrend === 0) {
      return 'bg-yellow'
    } else if (absTrend < 0) {
      return 'bg-red';
    } else {
      return 'bg-green';
    }
  }

  openHelpTextClicked(): void {
    this.openHelpText.emit();
  }
}
