import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { LayoutTranslationService } from '../locale/translation/layout-translation.service';
import { TranslationService } from '../services/services-index';

@Injectable()
@Pipe({ name: 'translatelayout', pure: true })
export class TranslateLayoutPipe implements PipeTransform {
  constructor(
    private translationService: LayoutTranslationService,
    private newTranslationService: TranslationService
  ) {}
  transform(val: any, locale?: string): string {
    if (locale === 'en') {
      return val;
    }
    const value = val.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    const newTranslation = this.newTranslationService.getResourceStringTranslation(value, locale);
    return newTranslation === value ? this.translationService.getLayoutTranslation(value, locale) : newTranslation;
  }
}
