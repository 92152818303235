

<ng-container>

    <div class="pane-wrapper date-range-pane">

        <div #startDatePanel class="pane bg-white border-r border-gray-300 scrollable-y scrollbar-thin scrollbar-thumb-primary-200 scrollbar-thumb-rounded-md scrollbar-track-primary-50 hover:scrollbar-thumb-primary-200">

            <div class="date-range-pane-input-container">

                <div class="input-wrapper">

                    <div class="input-type-label">{{'Start Date' | translate | async}}:</div>
                    <input [formControl]="startDateField" type="text" class="focus:ring-0 block pl-3 py-0 pr-0 border-0 sm:text-sm placeholder-gray-300" placeholder="{{'Enter Start Date' | translate | async}}">

                </div>

            </div>

            <div class="date-range-pane-calendar-container">

                <mat-calendar #startDateCalendar class="calendar-el" [(selected)]="selectedStartDate" [startAt]="startAtDate" (selectedChange)="startDateSelected($event)"></mat-calendar>

            </div>

            <div *ngIf="!isDesktop" class="w-full h-12 px-6 mt-6">

                <button [disabled]="!hasValidStartDate"
                        class="disabled:opacity-50 h-full w-full flex items-center justify-center px-4 py-2 shadow-sm font-inter text-base font-medium rounded-md text-white bg-primary-600 focus:outline-none"
                        (click)="scrollTo(datePaneContext.end)">

                    <span class="mr-3">{{'End Date' | translate | async}}</span><span [innerHtml]="forwardIcon | safeSvg"></span>
                </button>

            </div>

        </div>

        <div #endDatePanel class="pane-wrapper">

            <div class="pane bg-white border-r border-red-300 scrollable-y scrollbar-thin scrollbar-thumb-primary-200 scrollbar-thumb-rounded-md scrollbar-track-primary-50 hover:scrollbar-thumb-primary-200">

                <div class="date-range-pane-input-container">

                    <div class="input-wrapper">

                        <div class="input-type-label">{{'End Date' | translate | async}}:</div>
                        <input [formControl]="endDateField" type="text" class="focus:ring-0 block pl-3 py-0 pr-0 border-0 sm:text-sm placeholder-gray-300" placeholder="{{'Enter End Date' | translate | async}}">

                    </div>

                </div>

                <div class="date-range-pane-calendar-container">

                    <mat-calendar #endDateCalendar class="calendar-el" [(selected)]="selectedEndDate" [minDate]="selectedStartDate" (selectedChange)="endDateSelected($event)"></mat-calendar>

                </div>

                <div *ngIf="!isDesktop" class="w-full h-12 px-6 mt-6">

                    <button [disabled]="!hasValidStartDate"
                            class="disabled:opacity-50 h-full w-full flex items-center justify-center px-4 py-2 shadow-sm font-inter text-base font-medium rounded-md text-white bg-primary-600 focus:outline-none"
                            (click)="scrollTo(datePaneContext.start)">
                        <span [innerHtml]="backIcon | safeSvg" class="mr-3"></span><span>{{'Start Date' | translate | async}}:</span>
                    </button>

                </div>

            </div>

        </div>

    </div>


</ng-container>

