import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'navbar-notifications',
	templateUrl: './notifications.component.html'
})
export class NavbarNotificationsComponent implements OnInit {

		notificationCount: Observable<number>;

		constructor() { }

		ngOnInit() {
		}
}
