import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CreateDataSetConfigurationRequest, GetDataSetConfigurationResponse, GetDataSetConfigurationsRequest, GetDataSetConfigurationsResponse } from '../../models/data-set-configuration-models';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataSetConfigurationService {
  private api = `${environment.baseV5ApiUri}/DataSetConfiguration`;
  //private api = "https://localhost:7286/api/DataSetConfiguration"
  constructor(private http: HttpClient) { }

  getFiltered(request: GetDataSetConfigurationsRequest): Observable<GetDataSetConfigurationsResponse> {
    let params = new HttpParams();
    for (const [key, value] of Object.entries(request)) {
      if (value !== undefined && value !== null && value !== '') {
        params = params.append(key, value.toString());
      }
    }

    return this.http.get<GetDataSetConfigurationsResponse>(`${this.api}`, { params })
      .pipe(
        catchError((error) => {
          console.error(error);
          throw error;
        })
      );
  }

  create(request: CreateDataSetConfigurationRequest): Observable<any> {
    return this.http.post<GetDataSetConfigurationsResponse>(`${this.api}`, request)
      .pipe(
        catchError((error) => {
          console.error(error);
          throw error;
        })
      );
  }

  getById(id: string): Observable<GetDataSetConfigurationResponse> {
    return this.http.get<GetDataSetConfigurationResponse>(`${this.api}/${id}`)
      .pipe(
        catchError((error) => {
          console.error(error);
          throw error;
        })
      );
  }

  update(id: string, request: CreateDataSetConfigurationRequest): Observable<void> {
    return this.http.put<void>(`${this.api}/${id}`, request)
      .pipe(
        catchError((error) => {
          console.error(error);
          throw error;
        })
      );
  }
}
