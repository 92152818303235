import { Component, Input } from '@angular/core';
import * as SvgResources from '../../../lib/svg.resources';

@Component({
	selector: 'continuation-indicator',
	templateUrl: './continuation-indicator.component.html',
	styleUrls: ['./continuation-indicator.component.scss'],
})
export class ContinuationIndicatorComponent {
	private _isActive = false;
	get isActive(): boolean {
		return this._isActive;
	}
	@Input() set isActive(value: boolean) {
		this._isActive = value;
	}

	chevronIcon = SvgResources.sizableSvgIcon(SvgResources.svgIconTypes.chevronRight, 4);
}
