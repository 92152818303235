import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output, OnDestroy, } from '@angular/core';
import { Options } from 'highcharts';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { map, tap, takeWhile } from 'rxjs/operators';
import { IAnalysisVisualizationHeaderConfig } from '../../models/config.models';
import { CardService } from '../card/card.service';
import * as Models from '../../models/models-index';
import { CalendarChart } from '../../models/models-index';

@Component({
  selector: 'sd-card',
  templateUrl: './card.component.html'
})
export class CardComponent implements OnInit, OnDestroy {
  @Input() headerConfig: IAnalysisVisualizationHeaderConfig;
  @Output() expandClicked = new EventEmitter<boolean>();


  selectedTable: any


  private metricSelectChangeConfig: Models.IMetricSelectChangeConfig;

  componentActive: boolean = true;
  visualizationConfiguration$: Observable<{
    useHighcharts: boolean;
  }>;

  chartOptionsSubject = new BehaviorSubject<Options>(null);
  chartOptions$ = this.chartOptionsSubject.asObservable();

  calendarOptionsSubject = new BehaviorSubject<CalendarChart>(null);
  calendarOptions$ = this.calendarOptionsSubject.asObservable();

  visualizationDataSubject = new Subject<any>();
  visualizationData$ = this.visualizationDataSubject.asObservable();

  selectedMetricSubject = new BehaviorSubject<string>(null);
  selectedMetric$ = this.selectedMetricSubject.asObservable();

  constructor(private cardService: CardService,
    private changeDetector: ChangeDetectorRef) {

    }

  ngOnInit(): void {

    combineLatest([this.cardService.cardData$, this.selectedMetric$])
    .pipe(
      map(([visualizationInfo, selectedMetric]) => {
          let chartOptions = undefined;
          let useHighcharts = true;

        if (visualizationInfo.perspective.code === 'channel') {
            const propertyKey = visualizationInfo.data.metricCharts.findIndex(mc => mc.propertyKey === selectedMetric) != -1 ? selectedMetric : visualizationInfo.data.defaultPropertyKey;
            let chart = visualizationInfo.data.metricCharts.find(mc => mc.propertyKey === propertyKey);
            useHighcharts = true;
          if (!!chart) {
              chartOptions = chart.chartOptions;
            }
          }
          else if (visualizationInfo.perspective.code === 'bookingWindow12Months') {
            useHighcharts = true;
            chartOptions = visualizationInfo.data.metricChart[0].chartOptions
        }
          else if (visualizationInfo.perspective.code === 'bookingPace12Months') {
            useHighcharts = false;
            const propertyKey = visualizationInfo.data.metricCharts.findIndex(mc => mc.propertyKey === selectedMetric) != -1 ? selectedMetric : visualizationInfo.data.defaultPropertyKey;
            chartOptions = visualizationInfo.data.metricCharts.find(mc => mc.propertyKey === propertyKey);
        }

          let visualizationData = {
            useHighcharts: useHighcharts,
            chartConfig: chartOptions
          }
          this.visualizationDataSubject.next(visualizationData);

      }),
      takeWhile(() => this.componentActive)
      )
      .subscribe(() => {
      this.changeDetector.detectChanges();
    });
  }

  visualizationOptions$ = this.visualizationData$.pipe(
    map(data => {
      if (data.useHighcharts) {
        this.chartOptionsSubject.next(data.chartConfig)
      }
      else {
        this.calendarOptionsSubject.next(data.chartConfig)
      }
      return data;
    })
  )

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  perspectiveChanged(evt: Models.IVisualizationPerspective) {
    this.cardService.updatePerspective(evt);
  }

  metricSelectionChanged(evt: any) {
    this.selectedMetricSubject.next(evt.propertyName);
  }

  getObjectAsObservable(obj: any) {
    return of(obj);
  }

  expandButtonClicked(evt) {
    this.expandClicked.emit(evt)
  }

  private setChart(chartOptions: Options) {
    this.chartOptionsSubject.next(chartOptions);
  }

}
