import {
  IDefaultTableColumnConfig,
  IDigAdChannelConfig,
  IMultiLevelDualAxisLineGraphConfig,
  ICardHeaderConfig
} from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class DigAdChannelConfig implements IDigAdChannelConfig {
  reportTitle: string = 'Digital Advertising Channel Performance';
  public useDefaultComparisonColumns = true;
  public benchmarkCardHeader: ICardHeaderConfig = {
    title: 'Summary Data',
    iconClass: 'fa-file',
    helpTextKey: constants.helpTextKeys.digAdChannelSummaryHelp,
    helpTextTitle: 'Channel Summary',
    exportName: 'Digital Advertising Channel Performance - Summary Data',
    metricDisplayModes: ['MOM', 'YOY']
  };

  public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    parentMetrics: [
      {
        id: Enums.digitalAdvertisingMetrics.impressions.metricId,
        displayName: Enums.digitalAdvertisingMetrics.impressions.name,
        propertyName: Enums.digitalAdvertisingMetrics.impressions.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.clicks.metricId,
        displayName: Enums.digitalAdvertisingMetrics.clicks.name,
        propertyName: Enums.digitalAdvertisingMetrics.clicks.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.clickThroughRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.clickThroughRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.clickThroughRate.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.actualCostPerClick.metricId,
        displayName: Enums.digitalAdvertisingMetrics.actualCostPerClick.name,
        propertyName: 'costPerClick',
        metricFormatKey: constants.formatKeys.currency
      },
      {
        id: Enums.digitalAdvertisingMetrics.actualCostPerImpression.metricId,
        displayName: Enums.digitalAdvertisingMetrics.actualCostPerImpression.name,
        propertyName: 'costPerImpression',
        metricFormatKey: constants.formatKeys.currency
      },
      {
        id: Enums.digitalAdvertisingMetrics.totalVisits.metricId,
        displayName: Enums.digitalAdvertisingMetrics.totalVisits.name,
        propertyName: 'visits',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.uniqueVisitors.metricId,
        displayName: Enums.digitalAdvertisingMetrics.uniqueVisitors.name,
        propertyName: 'uniqueVisitors',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.formSubmissions.metricId,
        displayName: Enums.digitalAdvertisingMetrics.formSubmissions.name,
        propertyName: Enums.digitalAdvertisingMetrics.formSubmissions.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.phoneCalls.metricId,
        displayName: Enums.digitalAdvertisingMetrics.phoneCalls.name,
        propertyName: 'phoneLeads',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalAdvertisingMetrics.conversionRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.conversionRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.conversionRate.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.bounceRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.bounceRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.bounceRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      }
    ],
    childMetrics: [
      {
        id: Enums.digitalAdvertisingChannels.allChannels.channelId,
        displayName: Enums.digitalAdvertisingChannels.allChannels.channelName,
        propertyName: Enums.digitalAdvertisingChannels.allChannels.channelName,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: Enums.digitalAdvertisingChannels.search.channelId,
        displayName: Enums.digitalAdvertisingChannels.search.channelName,
        propertyName: 'Paid Search',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: Enums.digitalAdvertisingChannels.display.channelId,
        displayName: Enums.digitalAdvertisingChannels.display.channelName,
        propertyName: 'Paid Display',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: Enums.digitalAdvertisingChannels.video.channelId,
        displayName: Enums.digitalAdvertisingChannels.video.channelName,
        propertyName: 'Paid Video',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: Enums.digitalAdvertisingChannels.social.channelId,
        displayName: Enums.digitalAdvertisingChannels.social.channelName,
        propertyName: 'Paid Social',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      // {
      //     id: Enums.digitalAdvertisingChannels.email.channelId,
      //     displayName: Enums.digitalAdvertisingChannels.email.channelName,
      //     propertyName: Enums.digitalAdvertisingChannels.email.channelName,
      //     metricFormatKey: constants.formatKeys.entityDisplayName
      // },
    ],

    defaultChildrenIds: [Enums.digitalAdvertisingChannels.allChannels.channelId, Enums.digitalAdvertisingChannels.allChannels.channelId],
    defaultParentIds: [Enums.digitalAdvertisingMetrics.impressions.metricId, Enums.digitalAdvertisingMetrics.clicks.metricId],
    metricOneColorHexCode: '#b00',
    metricTwoColorHexCode: '#5f0003'
  };

  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: 'Channel',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    },
    // Impressions
    {
      show: true,
      header: 'Impressions',
      columnDef: 'impressions',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Clicks',
      columnDef: 'clicks',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // CTR
    {
      show: true,
      header: 'Click Through Rate',
      columnDef: 'clickThroughRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // CPC
    {
      show: true,
      header: 'Cost Per Click',
      columnDef: 'costPerClick',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.currency
    },
    // CPM
    {
      show: true,
      header: 'Cost Per Impressions',
      columnDef: 'costPerImpression',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.currency
    },
    {
      show: true,
      header: 'Total Visits',
      columnDef: 'visits',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Unique Visits',
      columnDef: 'uniqueVisitors',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Form Submissions
    {
      show: true,
      header: 'Form Submissions',
      columnDef: 'formSubmissions',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Phone Calls',
      columnDef: 'phoneLeads',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Conversion Rate
    {
      show: true,
      header: 'Conversion Rate',
      columnDef: 'conversionRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Bounce Rate
    {
      show: true,
      header: 'Bounce Rate',
      columnDef: 'bounceRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
  ];

}
