import { Component, Input, OnDestroy, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Enums, IEnums } from '../enums/enums';
import { Chart } from 'angular-highcharts';
import { SeriesOptionsType } from 'highcharts';

@Component({
  selector: 'provider-scorecard-billboard',
  templateUrl: 'provider-scorecard-billboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderScorecardBillboardComponent implements OnDestroy, OnInit {
  @Input() providerName: string;
  @Input() providerCode: string;
  @Input() primaryMetricName: string;
  @Input() primaryMetricValue: number;
  @Input() primaryMetricTrendColor: number;
  @Input() primaryMetricTrendArrow: string;
  @Input() primaryMetricFormatter: string;
  @Input() leftMetricName: string;
  @Input() leftMetricValue: any;
  @Input() leftMetricTrendColor: number;
  @Input() leftMetricTrendArrow: string;
  @Input() leftMetricFormatter: string;
  @Input() centerMetricName: string;
  @Input() centerMetricValue: any;
  @Input() centerMetricTrendColor: number;
  @Input() centerMetricTrendArrow: string;
  @Input() centerMetricFormatter: string;
  @Input() rightMetricName: string;
  @Input() rightMetricValue: any;
  @Input() rightMetricTrendColor: number;
  @Input() rightMetricTrendArrow: string;
  @Input() rightMetricFormatter: string;
  @Input() seriesData: any;
  @Input() chartLineColor: any;
  @Input() chartFillColor: any;

  @Output() openHelpText = new EventEmitter();
  enums: IEnums = Enums;
  currentChart: any;
  constructor() {
   }

  ngOnInit() {
    this.currentChart = this.generateChart();
  }

  ngOnDestroy(): void { }


  getProviderImagePath(code: string): string {
        return '../../../assets/images/Providers/' + code + '.png';
  }


  private generateChart() {
    const chartSeries: SeriesOptionsType[] = [{
      type: 'area',
      data: this.seriesData,
      color: this.chartLineColor,
      fillColor:  this.chartFillColor,
      lineWidth: 2,
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 3,
        color: this.chartLineColor
      },
      states: {
        hover: {
          enabled: false
        }
      }
    }];

    var chart = new Chart({
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      chart: {
        showAxes: false,
        height: 160
      },
      tooltip: {
        enabled: false
      },
      // AXES.....
      xAxis: {
        title: {text: null},
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false
        },
        minorTickLength: 0,
        tickLength: 0,
      },
      yAxis: {
        title: {text: null},
        lineWidth: 0,
        gridLineColor: 'transparent',
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false
        },
        minorTickLength: 0,
        tickLength: 0
      },
      legend: {
        enabled: false
      },
      series: chartSeries
    });
    return chart;

  }

  openHelpTextClicked(): void {
    this.openHelpText.emit();
  }
}
