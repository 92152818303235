export enum AppFilterStorageKey {
	listviewFilterwell = 'listView.filterWell',
	listviewFilterwellData = 'listView.filterWell.data'
}

export interface ISessionState {
  date: Date;
  data: object | string;
}

export class SessionStateStorage {

  set(key: string, value: ISessionState): void {
      const encodedValue = JSON.stringify(value);
      sessionStorage.setItem(key, encodedValue);
  }

  get(storageKey: string): ISessionState {
      const value = sessionStorage.getItem(storageKey) as any;
      return JSON.parse(value) ?? {};
  }

  remove(key: string): void {
      sessionStorage.removeItem(key);
  }

  clear(): void {
      sessionStorage.clear();
  }

}

export interface ISessionState {
  date: Date;
  data: object | string;
}
