import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { MatSelectionList } from '@angular/material/list';
import { MatTreeNestedDataSource, MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { IFilterFlatNode, IFilterTreeNode } from "../../types/types";
import { FilterPanelValueService } from "../../services/filter-panel-value.service";
import * as FilterPanelTypes from '../../types/types';
import { FilterPanelRef } from "../../types/filter-panel-ref";


@Component({
    selector: 'filter-panel-presenter',
    templateUrl: './filter-panel-collection.component.html',
    styleUrls: ['./filter-panel-collection.component.scss']
})
export class FilterPanelCollectionComponent implements OnInit, OnDestroy, AfterViewInit {
    private _subscription = new Subscription();
    private readonly _transformer = (node: IFilterTreeNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            display: node.display,
            value: node.value,
            unselectable: node.unselectable,
            nodeValue: node.nodeValue,
            treeFilterNode: node,
            level: level,
            formatDisplay: node.formatDisplay
        }
    }

    panelData: IFilterTreeNode[] = [];
    filteredPanelData: IFilterTreeNode[] = [];

    treeControl = new FlatTreeControl<IFilterFlatNode>(node => node.level, node => node.expandable);
    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.children);
    
    treeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    selected: any[] = [];
    
    checkBoxHeaderLabel: string;
    checkBoxHeaderLabelSuffix: string;
    totalNodeCount = 0;

    searchTerm = '';
    searchHasFocus = false;
    isSearching = false;
    searchResults: any[] = [];
    showProgress = false;

    rippleColor = 'rgba(0, 0, 0, .10)';

    checkListSelection = new SelectionModel<IFilterFlatNode>(!this.filterPanelRef.config.singleSelectionMode);

    hasChild = (_: number, node: IFilterFlatNode) => node.expandable;
    trackBy = (index: number, item: IFilterFlatNode) => `${item.nodeValue.nodeId}-${item.nodeValue.value}`;
    compareOptions = (val1: FilterPanelTypes.IFilterFlatNode, val2: FilterPanelTypes.IFilterFlatNode) => val1.value === val2.value;

    // TODO: determine if we need this
    // getNodeDisplayValue = (node: FilterFlatNode) => {
    //     if (node.formatDisplay) {
    //         return node.formatDisplay(node.treeFilterNode);
    //     } if (!!this.configService.filter.filterConfig.showDealerCodeInFilter && node.orgFilterValue.entityType === 'dealer') {
    //         return (node.display === node.value) ? node.display : node.orgFilterValue.value + ': ' + node.display;
    //     }

    //     // format for org code displaying
    //     if (this.configService.filter.filterConfig.showOrgCodeInFilter) {
    //         return (node.display === node.value) ? node.display : node.display + ': ' + node.value;
    //     }

    //     return node.display;
    // }

    @ViewChild('panelDataSelectList', { static: true }) panelDataSelectList: MatSelectionList;
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

    constructor(private filterPanelRef: FilterPanelRef, private valueService: FilterPanelValueService) { }
    
    ngOnInit() {
        this._subscription.add(
            this.filterPanelRef.config.inputData
              .subscribe(inputData => {
                this.panelData.push(...<FilterPanelTypes.IFilterTreeNode[]>inputData.inputData);
                this.valueService.updateTreeDataSource(this.panelData);
              })
        );
        
        

        this._subscription.add(
            this.valueService.selectedItems$
                .subscribe((selectedItems) => {
                    this.selected = [];
                    this.selected.push(...selectedItems);
                    if (this.selected.length <= 0)
                        this.clearSelectList();
                })
        );

        this._subscription.add(
            this.valueService.treeDataSource$
                .subscribe((nodes) => {
                    this.treeDataSource.data = nodes;

                    const currentFilterValues = this.filterPanelRef.config.currentFilterValues;
                    if (currentFilterValues && currentFilterValues.inputData) {
                        this.valueService.setSelectedItems(this.filterPanelRef.config.currentFilterValues.inputData);
                    }
                })
        );

        this._subscription.add(
            fromEvent(this.searchInput.nativeElement, 'keyup')
                .pipe(
                    map((event: any) => {
                        this.showProgress = true;
                        return event;
                    }),
                    filter(event => {
                        const isValidKey = this.isValidKey(event);
                        if (!isValidKey) {
                            this.showProgress = false;
                            return false;
                        }
                        return true;
                    }),
                    debounceTime(400),
                    distinctUntilChanged()
                ).subscribe((event: any) => {
                    this.searchTerm = event.target.value;
                    this.isSearching = !!this.searchTerm;
                    
                    this.filterSelectList();
                    this.showProgress = false;
                })
        )
    }

    ngOnDestroy() {
        if (this._subscription)
            this._subscription.unsubscribe();
    }

    ngAfterViewInit() {
        this._subscription.add(
            this.valueService.selectedItems$.subscribe(items => {
                this.setSelectionState(<IFilterTreeNode[]>items);
            }));
    }

    isTreeNodeSelected(node: IFilterFlatNode): boolean {
        if (this.filterPanelRef.config.singleSelectionMode) {
          return this.checkListSelection.selected.findIndex(s => this.compareOptions(s, node)) >= 0;
        } else {
          const childNodes = this.treeControl.getDescendants(node);
          const allIsSelected = childNodes.every(c => this.checkListSelection.isSelected(c));
  
          return allIsSelected;
        }
    }

    allChildNodesSelected(node: IFilterFlatNode): boolean {
        const childNodes = this.treeControl.getDescendants(node);
        const allIsSelected = childNodes.every(c => this.checkListSelection.isSelected(c));

        return allIsSelected;
    }

    childNodesPartiallySelected(node: IFilterFlatNode): boolean {
        if (this.isTreeNodeSelected(node)) {
            return false;
          }
    
          const childNodes = this.treeControl.getDescendants(node);
          const result = childNodes.some(c => this.checkListSelection.isSelected(c));
    
          return result && !this.isTreeNodeSelected(node);
    }

    clearAllSelected(): void {
        this.deselectAll();
        this.treeControl.collapseAll();
    }

    clearSearchTerm(): void {
        this.searchTerm = undefined;
        this.isSearching = false;
        this.filterSelectList();
    }

    // headerCheckboxChanged($event: any): void {
    //     if ($event.checked) {
    //         this.addUnselectedItemsToSelectedList();
    //     } else {
    //         this.deselectAll();
    //         this.treeControl.collapseAll();
    //     }
    // }

    isChecked(node: IFilterFlatNode): boolean {
        return this.checkListSelection.isSelected(node);
    }

    isIndeterminate(): boolean {
        const checkSelected = this.checkListSelection.selected.filter(cs => !cs.expandable);
        return checkSelected.length > 0 && checkSelected.length < this.totalNodeCount;
    }

    leafNodeSelectionToggle(node: IFilterFlatNode): void {
        this.checkListSelection.toggle(node);
        this.checkListSelection.isSelected(node) ? this.addToValueService(node.treeFilterNode) : this.valueService.removeSelectedItem(node.treeFilterNode);
    }
    // leafNodeSelectionToggle(node: IFilterFlatNode): void {
    //     this.checkListSelection.toggle(node);
    //     this.checkListSelection.isSelected(node) ? this.valueService.addSelectedItem(node) : this.valueService.removeSelectedItem(node);
    // }

    removeSelectedNode(node: IFilterFlatNode): void {
        this.checkListSelection.deselect(node);
        this.valueService.removeSelectedItem(node.treeFilterNode)
    }

    /* Handles Parent Node Clicks */
    nodeSelectionToggle(node: IFilterFlatNode) {

        this.deselectAll();
        this.checkListSelection.toggle(node);
  
        if (this.checkListSelection.isMultipleSelection()) {
          const childNodes = this.treeControl.getDescendants(node);
          const allChildNodesSelected: boolean = this.isTreeNodeSelected(node);
          const someChildNodesSelected: boolean = this.childNodesPartiallySelected(node);
  
          // TODO: Rewrite the shit out of this
          if (this.isTreeNodeSelected(node) && !allChildNodesSelected) {
              childNodes.forEach((n) => {
                  if (!this.checkListSelection.isSelected(n)) {
                      this.checkListSelection.select(n);
                      this.valueService.addSelectedItem(n.treeFilterNode);
                  }
              });
              this.treeControl.expandDescendants(node);

          } else if (!this.isTreeNodeSelected(node) && someChildNodesSelected) {
              childNodes.forEach((n) => {
                  if (!this.checkListSelection.isSelected(n)) {
                      this.checkListSelection.select(n);
                      this.valueService.addSelectedItem(n.treeFilterNode);
                  }
              });
              this.treeControl.expandDescendants(node);

          } else {
              this.checkListSelection.deselect(...childNodes);
              this.valueService.deselectItems(childNodes.map(cn => cn.treeFilterNode));
              this.treeControl.collapseDescendants(node);
          }
        } else {
          if (this.isTreeNodeSelected(node)) {
            this.addToValueService(node.treeFilterNode);
          }
        }
      }
    
    // private addUnselectedItemsToSelectedList(): void {
    //     this.filteredPanelData.forEach((rootNode) => {
    //         if (rootNode.childNodes && rootNode.childNodes.length) {
    //             const childNodes = this.treeControl.getDescendants(rootNode);
    //             childNodes.forEach((n) => {
    //                 if (!this.checkListSelection.isSelected(n)) {
    //                     this.checkListSelection.select(n);
    //                     this.valueService.addSelectedItem(n);
    //                 }
    //             })
    //         } else {
    //             if (!this.checkListSelection.isSelected(rootNode)) {
    //                 this.checkListSelection.select(rootNode);
    //                 this.valueService.addSelectedItem(rootNode);
    //             }
    //         }

    //         this.treeControl.expandDescendants(rootNode);
    //     });
    // }

    // private clearSelectList() {
    //     this.checkListSelection.selected.forEach((node) => {
    //         this.checkListSelection.toggle(node);
    //     });
    // }

    private clearSelectList() {
        this.checkListSelection.clear();
      }

    private deselectAll() {
      this.checkListSelection.clear();
    }

    private getNodeChildNodeCount(node: IFilterTreeNode): number {
        let count = 0;

        if (node.children && node.children.length) {
            count += node.children.length;

            node.children.forEach((childNode) => {
                count += this.getNodeChildNodeCount(childNode);
            });
        }

        return count;
    }

    private isValidKey(event: KeyboardEvent): boolean {
        return event.keyCode !== 13
            && event.keyCode !== 27
            && event.code !== 'Enter'
            && event.code !== 'Escape'
            && event.key !== 'Enter'
            && event.key !== 'Escape';
    }

    private addToValueService(node: IFilterTreeNode) {
        this.valueService.clear();
        this.valueService.addSelectedItem(node);
      }

    private manageCheckListSelection() {
        if (this.isSearching) {
            this.checkListSelection.clear();
            this.selected.forEach((node) => {
                if (this.filteredPanelData.indexOf(node) >= 0) {
                    this.checkListSelection.select(node);
                }
            })
        } else {
            if (this.checkListSelection.selected.length !== this.selected.length) {
                this.selected.forEach((node) => {
                    if (!this.checkListSelection.isSelected(node))
                        this.checkListSelection.select(node);
                });
            }
        }
    }

    private setTreeNodeCount() {
        this.totalNodeCount = 0;
        if (!this.isSearching) {
            this.filteredPanelData.forEach((rootNode) => {
                this.totalNodeCount += this.getNodeChildNodeCount(rootNode);
            });
        } else {
            this.totalNodeCount = this.filteredPanelData.length;
        }
        
    }

    private setPanelHeaderCheckboxLabel(): void {
        this.checkBoxHeaderLabelSuffix = this.filteredPanelData.length > 1 ? '\'s' : '';
        const title: string = this.searchTerm && this.searchTerm !== ''
            ? `matching: ${this.searchTerm}`
            : `${this.filterPanelRef.config.panelTitle.toLowerCase()}${this.checkBoxHeaderLabelSuffix}`;
        
        this.checkBoxHeaderLabel = `${this.filteredPanelData.length} ${title}`;
    }

    private nodeSearch(nodes: IFilterTreeNode[]) {
        const results: IFilterTreeNode[] = [];

        nodes.forEach(node => {
            if ((node.display.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1)
                || node.value.toString().toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1) {
                results.push(node);
            }

            if (node.children && node.children.length > 0) {
                results.push(...this.nodeSearch(node.children));
            }
        });

        return results;
    }

    private filterSelectList() {
        if (this.searchTerm && this.searchTerm !== '') {
            const filteredNodes = this.nodeSearch(this.panelData);

            this.valueService.updateTreeDataSource(filteredNodes);
        } else {
            this.valueService.updateTreeDataSource(this.panelData);
        }
    }

    private setSelectionState(currentFilterValues: IFilterTreeNode[]) {
        currentFilterValues.forEach((filterValue) => {
            const node = this.treeControl.dataNodes.find(n => n.value === filterValue.value);
            if (node && !this.checkListSelection.isSelected(node)) {
                this.checkListSelection.select(node);
            }
        });
    }
}
