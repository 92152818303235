import { Component, Input, OnInit } from '@angular/core';
import { FunnelRow, Segment } from './horizontal-funnel.model';
import * as SharedServices from '../../../_shared/services/services-index';
import { ExportCsv } from '../../../_shared/utilities/generate-csv';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sd-horizontal-funnel',
  templateUrl: './horizontal-funnel.component.html',
  styleUrls: ['./horizontal-funnel.component.scss']
})
export class HorizontalFunnelComponent implements OnInit {

  @Input() funnelRows: FunnelRow[] = [];
  filterBreadcrumbsForExport: string[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private filterService: SharedServices.FilterService,
    private dataTableService: SharedServices.DataTableService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.dataTableService.pathToPurchasePrintButtonClicked$.subscribe(data => {
        this.filterService.requestBreadcrumbString();
        this.excel(data.title);
      }),
      this.filterService.filterBreadcrumbsReturned$.subscribe(breadcrumbs => {
        this.filterBreadcrumbsForExport = breadcrumbs;
      })
    );
  }

  get headers() {
    return this.funnelRows[0]?.segments.map(s => s.title || s.name);
  }

  getSegmentsForDisplay(row: FunnelRow): {
    segment: Segment,
    value: string,
    classes: string[],
    path?: string,
    pathClass: string
  }[] {
    return row.segments.map((s, idx) => ({
      segment: s,
      value: s.value,
      classes: [
        'box',
        'box-' + (s.color || 'gray'),
        'box' + (idx + 1)
      ],
      path: idx != row.segments.length - 1 ? this.calculatePath(s, idx) : undefined,
      pathClass: `box-${s.color || 'gray'}`
    }));
  }

  calculatePath = (segment: Segment, idx: number): string => `M0,${idx * 5} L25,${5 + (idx * 5)} L25,${95 - (idx * 5)} L0,${100 - (idx * 5)}z`;

  private excel(title: string): void {
    let data: string[][] = [[title]];

    (this.filterBreadcrumbsForExport || []).forEach(bc => {
      data.push([bc]);
    });
    var cols: string[] = [];
    cols.push('')
    this.funnelRows[0]?.segments.map(s => cols.push(s.title || s.name));

    data.push(['']);
    data.push(cols);
    data = data.concat(this.getRowsForExport());

    const printTitle = title.replace(/-| /g, ''); // Regular expression /-| /g = all instances of ' ' or '-'

    new ExportCsv(data, printTitle);
  }

  private getRowsForExport(): string[][] {
    const results: string[][] = [];
    const rowData = this.funnelRows;

    rowData.forEach(dataRow => {
      const exportRow: string[] = [];

      exportRow.push(dataRow.name)
      dataRow.segments.map(s => exportRow.push(s.value))

      results.push(exportRow);
    });

    return results;
  }
}
