import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import * as ElementModels from '../../../models/report-view.models';
import { Chart } from 'angular-highcharts';
import { ChartService } from '../../chart/chart.service';
import { map, takeWhile } from 'rxjs';
import { get } from 'http';
import { getChartColors } from '../panel-utils';
import { SeriesOptionsType } from 'highcharts';

@Component({
  selector: 'scatter-plot',
  templateUrl: './scatter-plot.component.html',
  styleUrls: ['../panel-elements.scss', './scatter-plot.component.scss'],
  // ...other configurations
})
export class ScatterPlotComponent implements OnInit, OnDestroy {
  @Input() dataSet: ElementModels.DataSet;
  @Input() settings: ElementModels.ElementSettings;
  @Input() selectedMetrics: string[];

  currentChart: Chart;
  componentActive = true;

  constructor(private chartService: ChartService) {}

  ngOnInit(): void {
    if (!this.settings.dimensionNames || this.settings.dimensionNames.length === 0) {
      throw new Error('Dimension is required.');
    }
    if (this.selectedMetrics.length !== 2) {
      throw new Error('Two metrics are required.');
    }

    const dimIndex = this.dataSet.columns.findIndex(col => col.name === this.settings.dimensionNames[0]);
    const metricIndices = this.selectedMetrics.map(metric =>
      this.dataSet.columns.findIndex(col => col.name === metric));

    if (dimIndex === -1 || metricIndices.some(index => index === -1)) {
      throw new Error('Invalid dimension or metric selection.');
    }

    // const seriesData = this.dataSet.rows.map(row => {
    //   if (!row[dimIndex].value || row[dimIndex].label === ''
    //       || this.settings.dimensionRowFilters?.some(f => f === row[dimIndex].value || f === row[dimIndex].label)) {
    //     return;
    //   }

    //   return {
    //     x: row[metricIndices[0]].value,
    //     y: row[metricIndices[1]].value,
    //     xLabel: this.getMetricLabel(this.selectedMetrics[0]),
    //     yLabel: this.getMetricLabel(this.selectedMetrics[1]),
    //     xValueLabel: row[metricIndices[0]].label || row[metricIndices[0]].value,
    //     yValueLabel: row[metricIndices[1]].label || row[metricIndices[1]].value,
    //     name: row[dimIndex].label || row[dimIndex].value
    //   };
    // });

    this.currentChart = this.generateChart(this.dataSet.rows);

    this.chartService.reflowChart$.pipe(
      takeWhile(() => this.componentActive),
      map(() => {
        setTimeout(() => {
          this.currentChart.ref.reflow();
        }, 400);
      })
    ).subscribe()
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  private generateChart(rows: any[]): Chart {
    const xAxisIsPercentage = this.isPercentageMetric(this.selectedMetrics[0]);
    const yAxisIsPercentage = this.isPercentageMetric(this.selectedMetrics[1]);

    // Create a series for each row (district)
    const series = rows.map(row => {
      const dimIndex = this.dataSet.columns.findIndex(col => col.name === this.settings.dimensionNames[0]);
      const metricIndices = this.selectedMetrics.map(metric =>
        this.dataSet.columns.findIndex(col => col.name === metric));

      // Skip rows not matching the criteria (similar to previous filtering logic)
      if (!row[dimIndex].value || row[dimIndex].label === ''
          || this.settings.dimensionRowFilters?.some(f => f === row[dimIndex].value || f === row[dimIndex].label)) {
        return null; // Return null for rows that should be skipped
      }

      return <SeriesOptionsType>{
        name: row[dimIndex].label || row[dimIndex].value,
        type: 'scatter',
        data: [{
          x: row[metricIndices[0]].value,
          y: row[metricIndices[1]].value,
          custom: {
            xLabel: this.getMetricLabel(this.selectedMetrics[0]),
            yLabel: this.getMetricLabel(this.selectedMetrics[1]),
            xValueLabel: row[metricIndices[0]].label || row[metricIndices[0]].value,
            yValueLabel: row[metricIndices[1]].label || row[metricIndices[1]].value,
          },
          name: row[dimIndex].label || row[dimIndex].value // Name used for tooltip
        }],
        marker: {
          symbol: 'circle',
          radius: 6 // Customize marker size as needed
        }
      };
    }).filter(series => series !== null); // Filter out nulls from skipped rows

  const colors = getChartColors(this.settings);

    var chart = new Chart({
      chart: {
        type: 'scatter',
        zoomType: 'xy'
      },
      credits: { enabled: false },
      colors: colors,
      title: {
        text: this.getChartLabel()
      },
      xAxis: {
        title: {
          text: this.getMetricLabel(this.selectedMetrics[0])
        },
        labels: {
          formatter: function () {
            return xAxisIsPercentage ? (this.value * 100).toFixed(0) + '%' : this.value.toString();
          }
        }
      },
      yAxis: {
        title: {
          text: this.getMetricLabel(this.selectedMetrics[1])
        },
        labels: {
          formatter: function () {
            return yAxisIsPercentage ? (this.value * 100).toFixed(0) + '%' : this.value.toString();
          }
        }
      },
      tooltip: {
        formatter: function() {
          const point = <any>this.point;
          return '<b>' + point.name + '</b><br>' +
                 `${point.custom.xLabel}: <b>` + point.custom.xValueLabel + '</b><br>' +
                 `${point.custom.yLabel}: <b>` + point.custom.yValueLabel + '</b>';
        }
      },
      series: series
    });

    return chart;
  }

  private getDimensionLabel(dim: string): string {
    const dimIndex = this.dataSet.columns.findIndex(col => col.name === dim);
    return this.dataSet.columns[dimIndex].displayName || this.dataSet.columns[dimIndex].name;
  }

  private getMetricLabel(metric: string): string {
    const metricIndex = this.dataSet.columns.findIndex(col => col.name === metric);
    return this.dataSet.columns[metricIndex].displayName || this.dataSet.columns[metricIndex].name;
  }

  private getChartLabel(): string {
    const metricLabels = this.selectedMetrics.map(metric => this.getMetricLabel(metric));

    return metricLabels.join(' vs ');
  }

  private isPercentageMetric(metricName: string): boolean {
    // Implement logic to determine if the metric is a percentage
    // This could be based on the metric name, or any other criteria you have
    const metricIndex = this.dataSet.columns.findIndex(col => col.name === metricName);
    const metric = this.dataSet.columns[metricIndex];
    return this.dataSet.rows[0][metricIndex].label?.includes('%') || metric.name?.includes('%');
  }
}
