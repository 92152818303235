import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as Models from '../_shared/models/models-index';
import { AuthenticationService, LocalCacheService, SpinnerService, FilterService } from '../_shared/services/services-index';
import { Enums, IEnums } from '../_shared/enums/enums';
import * as APIService from '../_shared/services/api/api-index';
import { AppState } from '../_store/app-state.model';
import { Store } from '@ngrx/store';
import { logout } from '../_store/app.actions';

@Component({
  templateUrl: './slologout.component.html',
})

export class SloLogoutComponent implements OnInit {

  constructor(
    private store: Store<AppState>) {
  }

  ngOnInit() {
    this.store.dispatch(logout());
    // window.location.href = "https://www.google.com"
    // this.router.navigate['/sloLogut', ]

  }
}
