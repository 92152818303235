import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';

export class FilterConfig implements IFilterConfig {
  NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
  NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
  dateModeOptions = [
    Enums.dateModes.currentMonth,
    Enums.dateModes.lastMonth,
    Enums.dateModes.lastThirtyDays,
    Enums.dateModes.previousThreeMonths,
    Enums.dateModes.previousTwelveMonths
  ]
  showOrgCodeInFilter = false;
  showDealerCodeInFilter = false;
  showEntireOrgHierarchyForAllUsers: boolean = false;
  defaultMonthDateMode: DateMonthModes = 'calendar';
  filterTypeLookup: { [id: string]: FilterType } = {
    'org': { displayName: 'Hierarchy', name: 'org' },
    'channel': { displayName: 'Channel', name: 'channel' },
    'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
    'device-type': { displayName: 'Device Type', name: 'device-type' },
    'digad-provider': { displayName: 'Provider', name: 'digad-provider' },
    'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' },
    'website-provider': { displayName: 'Provider', name: 'website-provider' },
  };

  filterReportConfigs: { [id: string]: FilterConfigReport } = {
    // Chat
    'ChatOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: 'ChatOverview',
      filters: ['org', 'device-type'],
      defaults: ['org'],
      section: 'provider-tools',
      orgTypes: [1]
    },
    'DigitalAdvertisingOverview': {
        dateMode: Enums.dateModes.currentMonth,
        restrictDates: true,
        reportName: 'DigitalAdvertisingOverview',
        filters: ['org', 'channel'],
        defaults: ['org', 'channel'],
        section: 'digital-advertising',
        orgTypes: [1]
      },
      'DigitalAdvertisingScorecard': {
        dateMode: Enums.dateModes.currentMonth,
        restrictDates: true,
        reportName: 'DigitalAdvertisingScorecard',
        filters: ['org', 'channel', 'digad-provider'],
        defaults: ['org', 'channel'],
        section: 'digital-advertising',
        orgTypes: [1]
      },
      'DigitalAdvertisingSummary': {
        dateMode: Enums.dateModes.currentMonth,
        reportName: 'DigitalAdvertisingSummary',
        filters: ['org', 'channel', 'digad-provider'],
        defaults: ['org', 'channel'],
        section: 'digital-advertising',
        orgTypes: [1]
      },
      'DigitalAdvertisingProvider': {
        dateMode: Enums.dateModes.currentMonth,
        reportName: 'DigitalAdvertisingProvider',
        filters: ['org', 'channel'],
        defaults: ['org', 'channel'],
        section: 'digital-advertising',
        orgTypes: [1]
      },
      'DigitalAdvertisingChannel': {
        dateMode: Enums.dateModes.currentMonth,
        reportName: 'DigitalAdvertisingChannel',
        filters: ['org', 'digad-provider'],
        defaults: ['org'],
        section: 'digital-advertising',
        orgTypes: [1]
    },
    'DigitalRetailingOverview': {
      reportName: 'Digital Retailing Overview',
      dateMode: Enums.dateModes.currentMonth,
      filters: ['org', 'digital-retailing-provider', 'device-type'],
      defaults: ['org'],
      section: 'provider-tools',
      orgTypes: [1]
    },
    // Website
    'WebsiteOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: 'WebsiteOverview',
      filters: ['org'],
      defaults: ['org'],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteScorecard': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: 'WebsiteScorecard',
      filters: [
        'org', 'website-provider'
      ],
      defaults: [
        'org'
      ],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: 'WebsiteSummary',
      filters: [
        'org', 'website-provider'
      ],
      defaults: [
        'org'
      ],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteReferrerSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: 'WebsiteReferrerSummary',
      filters: [
        'org', 'device-type'
      ],
      defaults: [
        'org'
      ],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteProviderSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: 'WebsiteProviderSummary', // org only
      filters: ['org'],
      defaults: ['org'],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteVehicleSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: 'WebsiteVehicleSummary',
      filters: [
        'org', 'website-provider', 'device-type'
      ],
      defaults: [
        'org', 'website-provider'
      ],
      section: 'website',
      orgTypes: [1]
    },
  };

  FILTER_CONFIG: { [id: string]: Filter } = {
    'org': {
      type: 'org',
      selected: [],
      removable: false,
      lockable: true,
      locked: true
    },
    'chat-provider': {
      type: 'chat-provider',
      available: [2, 12, 15, 17, 18, 19],
      selected: [
        { value: 2, display: 'DealerInspire' },
        { value: 12, display: 'CarNow' },
        { value: 15, display: 'ActivEngage' },
        { value: 17, display: 'Gubagoo' },
        { value: 18, display: 'LivePerson' },
        { value: 19, display: 'Podium' }
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'device-type': {
      type: 'device-type',
      selected: [
        { value: 1, display: 'Desktop' },
        { value: 2, display: 'Tablet' },
        { value: 3, display: 'Phone' }
      ],
      removable: true,
      lockable: true,
      locked: false
    },
    'digital-retailing-provider': {
      type: 'digital-retailing-provider',
      available: [4, 12, 39, 42],
      selected: [
        { value: 4, display: 'Dealer.com' },
        { value: 12, display: 'CarNow' },
        { value: 39, display: 'Roadster' },
        { value: 42, display: 'MINIAnywhere' },
      ],

      removable: true,
      lockable: true,
      locked: false
    },
    'website-provider': {
      type: 'website-provider',
      available: [2, 3, 4, 21],
      selected: [
        { value: 2, display: 'DealerInspire' },
        { value: 3, display: 'DealerON' },
        { value: 4, display: 'Dealer.com' },
        { value: 21, display: 'eBizAutos'}
      ],
      removable: true,
      lockable: true,
      locked: false
    },
    'channel': {
      type: 'channel',
      available: [2, 4, 5, 8],
      selected: [
          { value: 2, display: 'Search' },
          { value: 4, display: 'Display' },
          { value: 5, display: 'Video' },
          { value: 8, display: 'Social' },
      ],
      removable: false,
      lockable: true,
      locked: false
    },
      'digad-provider': {
        type: 'digad-provider',
        available: [2,3,4,9,30,31,37,40,41],
        selected: [
          { value: 30, display: 'Constellation' },
          { value: 40, display: 'Dealer Teamwork' },
          { value: 4, display: 'Dealer.com' },
          { value: 2, display: 'DealerInspire' },
          { value: 3, display: 'DealerON' },
          { value: 41, display: 'Force Marketing' },
          { value: 31, display: 'PureCars' },
          { value: 37, display: 'Sincro' },
          { value: 9, display: 'Stream Companies' },
        ],
        removable: true,
        lockable: true,
        locked: false
      }
  };
}
