import { OptionModel } from '../../models/models-index';
import * as ElementModels from '../../models/report-view.models';

export const defaultChartColors = ["#1E3A8A", "#0284C7", "#22D3EE", "#2DD4BF", "#27AE60", "#F2C94C", "#F59E0B", "#EA580C", "#DC2626", "#DB2777", "#5B21B6", "#312E81"];


export const getChartColors = (settings: ElementModels.ElementSettings): string[] => {
  const chartColors = settings.chartColors;
  return !!chartColors && chartColors.length > 0 ? chartColors : defaultChartColors;
}

export const convertOptionsToDictionary = (options: OptionModel[]): Record<string, string> => {
  return options.reduce((map, option) => {
    map[option.name] = option.enabled.toString();
    return map;
  }, {} as Record<string, string>);
}
