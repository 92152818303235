import { Component, ViewChild, Input, OnChanges, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { SpinnerService, BillboardService } from "../../_shared/services/services-index";
import { Enums, IEnums } from '../../_shared/enums/enums';
import { Subscription } from 'rxjs';


@Component({
  selector: 'legacy-scorecard-billboard',
  templateUrl: 'legacyScorecardBillboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegacyScorecardBillboardComponent implements OnInit {

  @Input() title: string;
  @Input() metric: number;
  @Input() iconClass: number;
  @Output() openHelpText = new EventEmitter();
  enums: IEnums = Enums;
  subscriptions: Subscription[] = [];

  constructor(private billboardService: BillboardService, private spinner: SpinnerService, private changeDetector: ChangeDetectorRef) {
    this.subscriptions.push(
      //this.billboardService.dataUpdated$.subscribe(updatedData => {
      //  this.changeDetector.detectChanges();
      //})
    );
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getBillboardColor(metric): string {
    if (metric >= 8.5)
      return 'legacy-billboard-high-score';
    else
      return 'legacy-billboard-medium-score';
  }

  openHelpTextClicked(): void {
    this.openHelpText.emit();
  }
}
