import { Injectable } from '@angular/core';
import { DigitalRetailingTranslationConfig } from '../../../_config/translation/digital-retailing/digital-retailing.translation.config';
import { ITranslationService } from '../locale.model';


@Injectable()
export class DigitalRetailingTranslationService implements ITranslationService{
 translationConfig = new DigitalRetailingTranslationConfig;


  getMetricNameTranslation(metricId: string, defaultLabel: string, locale: string) {
    if (locale === 'en') {
      return defaultLabel;
    }
    const cleansedMetricId = metricId[0].toLowerCase()+ metricId.substring(1);
    return this.translationConfig.metrics[locale][cleansedMetricId] ?? defaultLabel;
  }

  getReportTitleTranslation(value: string, locale: string) {
    if (locale === 'en') {
      return value;
    }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.translationConfig.reportTitles[locale][cleansedValue] ?? value;
  }

  getLabelTranslation(value: string, locale: string) {
    if (!locale || locale === 'en' || !value) {
      return value;
    }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.translationConfig.labels[locale][cleansedValue] ?? value;
  }

  getChartDimensions(value: string, locale: string) {
    if (locale === 'en') {
      return value;
    }
    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.translationConfig.chartDimensions[locale][cleansedValue] ?? value;
  }


}
