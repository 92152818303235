<mat-card
  class="mat-elevation-z1 element-card kpi-dual-metric-detailed-element"
  style="background-color: white; color: black"
>
  <mat-card-title>
    <i class="fa pad-right" [ngClass]="titleIconClasses"></i><b>{{ title }}</b>
    <div class="pull-right ellipses-dropdown" *ngIf="settings.helpTextKey">
      <button
        title="options"
        mat-button
        class="card-options-button"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="menu"
      >
        <i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em"></i>
      </button>
      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          style="outline: none"
          (click)="openHelpTextClicked()"
        >
          {{ "Help" | translate : locale | async }}
        </button>
      </mat-menu>
    </div>
  </mat-card-title>
  <mat-card-content
    class="card-block target-card billboard"
    style="margin-bottom: 0px"
  >
    <div class="metrics-container">
      <ng-container *ngFor="let metric of metrics; let i = index">
      <div class="metric-item">
        <div class="metric-content">
          <b class="metric-value">
            {{ metric.value }}
          </b>
          <i
            [ngClass]="[
              getTrendArrowIconClass(metric),
              getTrendIconColor(metric)
            ]"
          ></i>
        </div>

        <div *ngIf="metric.subtitleOneValue" class="subtitle">
          <span class="subtitle-label">{{ metric.subtitleOneLabel }}</span>
          <span class="subtitle-value">{{ metric.subtitleOneValue }}</span>
        </div>
      </div>
      <div *ngIf="i != (metrics.length - 1)" class="metric-item-separator"></div>
    </ng-container>
    </div>
  </mat-card-content>
</mat-card>
