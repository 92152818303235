import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    templateUrl: './404.component.html'
})
export class NotFoundComponent implements OnInit {

    constructor(private routeLocation: Location, private router: Router) {}

    ngOnInit() {}

    goBack() {
        this.routeLocation.back();
    }

    goHome() {
        this.router.navigateByUrl('/');
    }
}
