<mat-card [@transformMenu]="panelState" class="mat-card filter-panel-card mat-elevation-z2 rounded-2xl">

    <div class="filter-panel-header" class="bg-primary-600 text-white font-inter h-14 w-full rounded-t-xl">

        <div class="mat-card-header-content" class="h-full m-0 py-0 px-5 flex items-center justify-between text-white">

            <span class="m-0 font-inter text-xl" style="margin: 0">
                {{title}}
            </span>

            <span class="cursor-pointer w-6 h-6" [innerHtml]="svgIcons.close | safeSvg" (click)="closePanel()"></span>

        </div>

    </div>

    <mat-card-content class="w-full m-0" style="height: 328px; max-height: 328px;">

        <ng-container [ngSwitch]="renderMethod">

            <div *ngSwitchCase="renderMethods.text" [innerHtml]="content"></div>

            <ng-container *ngSwitchCase="renderMethods.template">
                <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="renderMethods.component">
                <ng-container *ngComponentOutlet="content"></ng-container>
            </ng-container>

        </ng-container>

    </mat-card-content>

    <mat-card-footer class="m-0">

        <div class="py-0 h-14 w-full flex items-center justify-end border-gray-200 border-t m-0">

            <button [disabled]="applyButtonDisabled" mat-button class="apply-button" (click)="applySelected()">
                <span>{{ 'APPLY' | translate | async }}</span>
            </button>
    
        </div>

    </mat-card-footer>

</mat-card>
