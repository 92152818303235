import { TemplateRef, Type, ElementRef } from '@angular/core';
import { FilterPanelCloseType } from './constants';
import { Observable } from 'rxjs';
import { IFacetedFilterDataItem, IFacetedFilterSelection } from './faceted-filter.types';

export type FilterPanelParams = {
    originElement: ElementRef;
    content?: FilterPanelContent;
    inputDataType?: string
    config: IFilterPanelConfig;
};

export type FilterPanelClosedEvent<T> = {
    dialogData: T;
};

export interface IFilterPanelContext {
    close: any;
}

//export interface INamedValue { name: string; path: string }

export interface ISimpleValue { value: string | number; display: string; }
export interface ITreeNodeValue extends ISimpleValue {
    nodeId: number | string;
    nodeType: string;
}

export interface IFilterTreeNode {
  display: string;
  value?: string | number;
  unselectable?: boolean;
  nodeValue?: ITreeNodeValue;
  children?: IFilterTreeNode[];
  formatDisplay?: (node: IFilterTreeNode) => string;
}

export interface IFilterFlatNode {
  expandable: boolean;
  display: string;
  value?: string | number;
  nodeValue: ITreeNodeValue;
  treeFilterNode: IFilterTreeNode;
  level: number;
  formatDisplay?: (node: IFilterTreeNode) => string;
}

export type FilterDataType = IFilterTreeNode[] | ISimpleValue[] | IFacetedFilterDataItem[];

export type FilterDataTypeValue = IFilterTreeNode | ISimpleValue;

export interface IPanelInputData {
  inputData: FilterDataType;
}

export interface IRootNodeState {
    rootNode: IFilterTreeNode;
    isActive: boolean;
}

export interface IFilterPanelConfig {
    panelTitle: string;
    type: string;
    target: string;
    category: string;
    inputData: Observable<IPanelInputData>;
    currentFilterValues?: IPanelInputData;
    singleSelectionMode?: boolean;
    query?: string;
    queryTarget?: string;
    panelClass?: string;
    inputFacetData?: IFacetedFilterSelection;
    topic?: string;
}

export interface IFilterPanelResult {
    data: any[];
    resultType: FilterPanelCloseType.applied | FilterPanelCloseType.backdropClick | FilterPanelCloseType.cancel;
    type: string;
    target: string;
    category: string;
}

export type FilterPanelContent = TemplateRef<any> | Type<any> | string;
