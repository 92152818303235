import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { getExistingTagsQueryResponse } from '../../models/tag.models';

@Injectable({
    providedIn: 'root'
})
export class TagService {
    private api = `${environment.baseV5ApiUri}/Tag`;
    //private api = "https://localhost:7286/api/Tag"
    constructor(private http: HttpClient) { }

    getByKey(searchKey: string): Observable<getExistingTagsQueryResponse> {
        let params = new HttpParams();
        params = params.append("searchKey", searchKey);
        return this.http.get<getExistingTagsQueryResponse>(`${this.api}`, { params })
            .pipe(
                catchError((error) => {
                    console.error(error);
                    throw error;
                })
            );
    }
}