<div style="width: 800px; margin:10px auto;">
        <div class="container">
            <div class="card card-accent-primary rounded-0">
                <div class="card-header">
                    Oops! We've encountered an error.
                </div>
                <div class="card-block">
                    <h1><i class="fa fa-exclamation-triangle" style="font-size: 48px; color: orange;"></i> Oops! Something went wrong.</h1>
                    <div style="font-size: 18px;" class="exception-body">
                        The System Administrator has already been notified.
                    </div>
                    <br />
                    <br />
                    <br />
                    <div class="row">
                        <div class="col-md-8">
                            <button type="button" class="btn btn-sm btn-primary px-3" (click)="goBack()">
                                <i class="fa fa-arrow-circle-left"></i> Go Back
                            </button>

                            <button type="button" class="btn btn-sm btn-secondary px-3" (click)="goHome()">
                                <i class="fa fa-home"></i> Go Home
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

