import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormattingService } from '../services/services-index';
import { IEnums, Enums } from '../enums/enums';
import * as Models from '../../_shared/models/models-index';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
    selector: 'trend-graph-card',
    templateUrl: './trend-graph-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TrendGraphCardComponent implements OnDestroy, OnInit {

    @Input('graph') set graph(graph: any) {
        this.graph$.next(graph);
    }
    @Input() showHeaderLabel = true;

    private graph$ = new BehaviorSubject<any>(null);

    enums: IEnums = Enums;
    subscriptions: Subscription[] = [];
    chart: { settings: Models.IDualAxisLineGraphConfig, data: any[], locale?: string, metricTranslator?: Function };
    metricOneConfig: Models.ITrendGraphMetricPropertyConfig = null;
    metricTwoConfig: Models.ITrendGraphMetricPropertyConfig = null;

    metricOneSelectConfig: Models.IMetricSimpleSelectConfig = null;
    metricTwoSelectConfig: Models.IMetricSimpleSelectConfig = null;

    graphDataObject: Models.ITrendGraph;

    constructor(
        private formattingService: FormattingService) {
    }

    ngOnInit(): void {

        this.subscriptions.push(
            this.graph$.subscribe(metrics => {
                this.chart = metrics;
                this.chart.locale = !this.chart.locale ? 'en' : this.chart.locale;
                this.generateSelectInputs(metrics.settings);
                this.graphDataObject = this.mapTrendGraphMetrics(metrics.data, metrics.settings);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    generateSelectInputs(config: Models.IDualAxisLineGraphConfig) {
        if (!!this.chart.metricTranslator) {
            config.availableMetrics.map(m => m.displayName = this.chart.metricTranslator(m.propertyName, m.displayName, this.chart.locale));
        }

        this.metricOneSelectConfig = {
            defaultMetricId: config.defaultMetricOneId,
            options: config.availableMetrics
        };

        this.metricTwoSelectConfig = {
            defaultMetricId: config.defaultMetricTwoId,
            options: config.availableMetrics
        };

    }

    applyMetricOneChange(metricConfig: Models.ITrendGraphMetricPropertyConfig) {
        this.metricOneConfig = metricConfig;
    }

    applyMetricTwoChange(metricConfig: Models.ITrendGraphMetricPropertyConfig) {
        this.metricTwoConfig = metricConfig;
    }

    // TODO: This is very specific to reports with date as the x-axis...could be made more generic, not sure if it should be though.
    mapTrendGraphMetrics(data: any, trendGraphData: Models.ITrendGraphConfig): Models.ITrendGraph {
        const xAxisValues: any[] = [];
        const graphData: Models.ITrendGraphData[] = [];
        const iterationValues = [...new Set(data.map(m => m[trendGraphData.xAxisPropertyName]))];
        iterationValues.forEach(val => {
            xAxisValues.push(this.formatTrendDate(moment(val).toDate()));
        });

        trendGraphData.availableMetrics.forEach(m => {
            graphData.push({ metricId: m.id, data: [] });
        });

        iterationValues.forEach(iterationValue => {
            const currentIterationMetrics = data.filter(d => d[trendGraphData.xAxisPropertyName] === iterationValue);

            trendGraphData.availableMetrics.forEach(m => {
                graphData.find(d => d.metricId === m.id)
                  ?.data.push(currentIterationMetrics.map(im => im[m.propertyName]).reduce((acc, v) => (acc == null && v == null) ? null : acc + v));
            });
        });

        const graph: Models.ITrendGraph = {
            dates: xAxisValues,
            xAxisValues: xAxisValues,
            metrics: graphData,
            metricOneColorHexCode: this.chart.settings.metricOneColorHexCode,
            metricTwoColorHexCode: this.chart.settings.metricTwoColorHexCode
        };

        return graph;
    }

    private formatTrendDate(date: Date): string {
        // Create a proper date instance
        const d = new Date(date);

        return ((d.getMonth() + 1).toString() + '/' + d.getDate().toString());
    }

}
