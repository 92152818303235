import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CreateReportViewConfigurationRequest, GetReportViewConfigurationRequest, GetReportViewConfigurationResponse, ReportViewConfigurationResponse } from '../../models/report-view.models';

@Injectable({
    providedIn: 'root'
})
export class ReportViewConfigurationService {
    private api = `${environment.baseV5ApiUri}/ReportViewConfiguration`;
    //private api = "https://localhost:7286/api/ReportViewConfiguration"
    constructor(private http: HttpClient) { }

    getAll(request: GetReportViewConfigurationRequest): Observable<GetReportViewConfigurationResponse> {
        let params = new HttpParams();
        for (const [key, value] of Object.entries(request)) {
            if (value !== undefined && value !== null && value !== '') {
                params = params.append(key, value.toString());
            }
        }

        return this.http.get<GetReportViewConfigurationResponse>(`${this.api}`, { params })
            .pipe(
                catchError((error) => {
                    console.error(error);
                    throw error;
                })
            );
    }

    create(request: CreateReportViewConfigurationRequest): Observable<any> {
        return this.http.post<GetReportViewConfigurationResponse>(`${this.api}`, request)
          .pipe(
            catchError((error) => {
              console.error(error);
              throw error;
            })
          );
      }
    
      getById(id: string): Observable<ReportViewConfigurationResponse> {
        return this.http.get<ReportViewConfigurationResponse>(`${this.api}/${id}`)
          .pipe(
            catchError((error) => {
              console.error(error);
              throw error;
            })
          );
      }
    
      update(id: string, request: CreateReportViewConfigurationRequest): Observable<void> {
        return this.http.put<void>(`${this.api}/${id}`, request)
          .pipe(
            catchError((error) => {
              console.error(error);
              throw error;
            })
          );
      }
}