import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class FilterPanelQueryService {

    constructor() { }

    getPanelInputData(query: string, target: string): Observable<any> {
      return of(true);
        // return this.graphQl.getData(ClientName.Gateway, gql`${query}`).pipe(
        //     map((response) => {
        //         const items = response.data[target].items;
        //         return items.map(item => ({ id: item.id, name: item.displayName ?? item.name }));
        //     })
        //   );
    }
}
