import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeSvg'
})
export class SafeSvgPipe implements PipeTransform {

    constructor(protected sanitizer: DomSanitizer) {}

    public transform(value: any): any {

        if (value?.indexOf('<svg') < 0) {
          console.warn('SafeSvgPipe needs a svg element');
          return '';
        }

        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
