import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Breadcrumb } from '../_shared/services/breadcrumb/breadcrumb.service';
import { ExportsService } from '../_shared/services/exports/exports.service';
import { BreadcrumbService } from './../_shared/services/breadcrumb/breadcrumb.service';
import { Enums, IEnums } from '../_shared/enums/enums';
import { IFilterModel } from '../_shared/models/models';
import { Subscription, Observable } from 'rxjs';
import { LayoutHelperService } from './layoutHelper.service';
import * as Models from './../_shared/models/models-index';
import { delay, tap, map, filter } from 'rxjs/operators';
import { AppState } from '../_store/app-state.model';
import { Store } from '@ngrx/store';
import { AppSelectors } from '../_store/selector-types';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SharedHelpTextModalComponent } from '../_shared/modals/modals-index';
import { FilterService, LocaleService, SharedTranslationService } from '../_shared/services/services-index';

@Component({
    selector: 'layout-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit, OnDestroy {
    breadcrumbs: Breadcrumb[];
    filterModel: IFilterModel;
    pageTitle$ = this.filterService.activePageTitle$.pipe(delay(0), filter(pt => !!pt));
    enums: IEnums = Enums;
    subscriptions: Subscription[] = [];
    filterModel$: Observable<Models.IFilterModel>;
    showBreadcrumbOptions$: Observable<boolean>;
    showBreadcrumbMenu: boolean;
    pdfAvailable: boolean;
    locale$: Observable<string>;
    helpAvailable: boolean;
    showDateFilter: boolean;
    showMetricSelector: boolean;
    constructor(
        private store$: Store<AppState>,
        private breadcrumbService: BreadcrumbService,
        private exportsService: ExportsService,
        public localeService: LocaleService,
        public layoutHelper: LayoutHelperService,
        private router: Router,
        private dialog: MatDialog,
        private filterService: FilterService,
        private translationService: SharedTranslationService
    ) {
        this.filterModel$ = this.filterService.filter$;
        this.locale$ = this.localeService.locale$.pipe(tap(loc => loc));
    }

    ngOnInit() {
        this.showBreadcrumbOptions$ = this.store$.select(AppSelectors.selectCurrentRouteData).pipe(
            map(routeData =>  {
                this.pdfAvailable = this.exportsService.pdfAvailable(routeData.url);
                this.helpAvailable = this.exportsService.breadcrumbHelpAvailable(routeData.url);
                this.showBreadcrumbMenu = !!this.pdfAvailable || !!this.helpAvailable;
                this.showDateFilter = !routeData.useV5Filters && (!routeData.hideFilters || !!routeData.showDateFilter);
                this.showMetricSelector = !!routeData.showMetricSelector;

                return !!this.showDateFilter || !!this.pdfAvailable || !!this.helpAvailable || !!this.showMetricSelector;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    onClickPDF() {
        this.exportsService.export(Enums.printingOptions.pdf);
    }

    onClickExcel() {
        this.exportsService.export(Enums.printingOptions.excel);
    }

    excelAvailable(): boolean {
        return this.exportsService.excelAvailable(this.router.url);
    }

    getPageTitle(): string {
        return this.filterService.getActivePageTitle();
    }
    openHelp(name: string, title: string, locale: string) {
        this.dialog.open(SharedHelpTextModalComponent, {
            width: '50vw',
            data: {
                title: this.translationService.getLabelTranslation(title, locale),
                name: name,
                locale: locale
            }
        });
    }
}
