<mat-form-field>
  <mat-label>{{selectedChildLabel}} - {{selectedParentLabel}}</mat-label>
  <mat-select [matMenuTriggerFor]="parentMetrics"></mat-select>
  <mat-menu #parentMetrics="matMenu" [overlapTrigger]="false">
  <span *ngFor="let parent of config.parentOptions">
    <button mat-menu-item [matMenuTriggerFor]="childrenMetrics" (click)="emitSelectedParentMetric(parent.id)">{{parent.displayName}}</button>
    <mat-menu #childrenMetrics="matMenu">
    <span *ngFor="let child of config.childOptions">
      <button mat-menu-item (click)="emitSelectedChildMetric(parent.id, child.id)">{{child.displayName}}</button>
    </span>
    </mat-menu>
  </span>
  </mat-menu>
</mat-form-field>