import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable} from 'rxjs';
import { IFacetedFilterSelection, ISelectionInfo } from '../types/faceted-filter.types';
import * as FilterPanelTypes from '../types/types';
import { FacetedFilterResultBuilder } from '../Utils';

@Injectable({
	providedIn: 'root',
})
export class FilterPanelValueService {
	private _treeDataSource = new BehaviorSubject<FilterPanelTypes.IFilterTreeNode[]>([]);
	treeDataSource$: Observable<FilterPanelTypes.IFilterTreeNode[]> = this._treeDataSource.asObservable();

	private _selectedItems = new BehaviorSubject<FilterPanelTypes.FilterDataTypeValue[]>([]);
	selectedItems$: Observable<any> = this._selectedItems.asObservable();

	private _applyDisabled = new BehaviorSubject<boolean>(true);
	applyDisabled$: Observable<boolean> = this._applyDisabled.asObservable();

	compareValues(x: FilterPanelTypes.FilterDataTypeValue, y: FilterPanelTypes.FilterDataTypeValue) {
		return x.value === y.value;
	}

	addSelectedItem(item: any): void {
		const currentItems = [...this._selectedItems.getValue(), item];
		this._selectedItems.next(currentItems);
		this.setApplyDisabled();
	}

	addSelectedItems(items: any[]): void {
		const currentItems = [...this._selectedItems.getValue(), ...items];
		this._selectedItems.next(currentItems);

		this.setApplyDisabled();
	}

	clear() {
		this._selectedItems.next([]);
	}

	deselectAllSelectedItems(): void {
		this.clear();
		this.setApplyDisabled();
	}

	deselectItems(items: any[]): void {
		let currentItems = [...this._selectedItems.getValue()];
		if (currentItems) {
			items.forEach((node) => {
				currentItems = currentItems.filter(ci => ci.value !== node.value);
			});

			this._selectedItems.next(currentItems);
			this.setApplyDisabled();
		}
	}

	hasItemSelected(item: FilterPanelTypes.FilterDataTypeValue): boolean {
		const selected = this._selectedItems.value.find(si => this.compareValues(si, item));
		return !!selected;
	}

	getValues(): FilterPanelTypes.FilterDataTypeValue[] {
		const data = this._selectedItems.getValue();
		return data;
	}

	removeSelectedItem(item: any): void {
		const currentItems = [...this._selectedItems.getValue().filter(ci => ci !== item)];
		this._selectedItems.next(currentItems);

		this.setApplyDisabled();
	}

	setSelectedItems(items: any[]): void {
		this._selectedItems.next(items);
		this.setApplyDisabled();
	}
	
	updateTreeDataSource(nodes: FilterPanelTypes.IFilterTreeNode[]): void {
		this._treeDataSource.next(nodes);
	}

	private setApplyDisabled() {
		this._applyDisabled.next(this._selectedItems.getValue().length <= 0);
	}

	// FacetedFIlter Stuffs TODO: Determine if we need to move this to it's own file

	private _facetedFilterApplyDisabled = new BehaviorSubject<boolean>(true);
	facetedFilterApplyDisabled$: Observable<boolean> = this._facetedFilterApplyDisabled.asObservable();

	private _selectionInfo: ISelectionInfo;

	setSelectionInfo(selectionInfo: ISelectionInfo): void {
		this._selectionInfo = { ...selectionInfo };
		this.setFacetedFilterApplyDisabled();
	}

	clearSelectionInfo(): void {
		this._selectionInfo = undefined;
		this.setFacetedFilterApplyDisabled();
	}

	getSelectionInfo(): ISelectionInfo {
		return this._selectionInfo;
	}

	getSelectionResult(): IFacetedFilterSelection {	
		return FacetedFilterResultBuilder.buildSelectionResult(this._selectionInfo);
	}

	private setFacetedFilterApplyDisabled() {
		this._facetedFilterApplyDisabled.next(!this._selectionInfo);
	}
}
