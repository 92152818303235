import { ISelectionInfo, IFacetedFilterSelection, IDateRangeValue, IOperatorValue, IFacetedFilterResult, ISelectedFacetInfo, ISelectedDateRangeInfo, ISelectedOperatorInfo, ISelectedHierarchyInfo, IFacetedFilterDataItem } from "../types/faceted-filter.types";
import { DisplayValue } from "./display.value";
import { TypeCheck } from "./type-check";
import { TypeInstanceOf } from "./type-instance-of";


export class FacetedFilterResultBuilder {

	// TODO: I need to revisit this because the way we are building up the display vals and payloads need to change.
	// Right now we are relying on the selected facet's parent node to provide payload values (name & type) which is not what we want
	// We need to get these values from the actual items that were selected. The challenge here is in a multi-select situation
	// We need to have a mechanic the iterates through the selected items and merge values keeping the name / type that we get from the
	// first item in the iteration and if we encounter a selected item (sibling) with different name/type values we need to create another payload obj
	// This done while keeping the display mechanic generating the values that it is currently providing.
	// For now, for multi-select facets, I'm taking the first selected item and using that to provide the name/type values as my first attempt to pull this off
	// Caused a delay in the panel closing after hitting the apply btn
	static buildSelectionResult(selectionInfo: ISelectionInfo): IFacetedFilterSelection {
		//console.log('buildSelectionResult.SELECTIONINFO>>>>>>>>>>>>>', selectionInfo);
		const result: IFacetedFilterSelection = {
			name: selectionInfo.item.label ?? selectionInfo.item.name,
			type: selectionInfo.item.type,
			path: selectionInfo.item.path,
			displayPath: DisplayValue.FromPath(selectionInfo.item.path)
		};

		this.addFacetValueToResult(result, selectionInfo);
		this.addDateRangeValueToResult(result, selectionInfo);
		this.addOperatorValueToResult(result, selectionInfo);
		this.addHierarchyValueToResult(result, selectionInfo);

		//console.log('buildSelectionResult.RESULT>>>>>>>>>>>>>', result);

		return result;
	}

	private static addFacetValueToResult(result: IFacetedFilterSelection, selectionInfo: ISelectionInfo): void {
		if (!TypeInstanceOf.FacetInfo(selectionInfo))
			return;

		const typedInfo: ISelectedFacetInfo = <ISelectedFacetInfo>selectionInfo;

		if (!TypeCheck.isDateRangeValueType(selectionInfo.item.valueType)) {
			const values: string[] = <string[]>typedInfo.facets.map(f => f.value);
			result.value = values;
			//result.name = typedInfo.facets[0].name;
			result.displayValue = TypeCheck.isBooleanValueType(selectionInfo.item.valueType)
							    ? DisplayValue.FromBooleanStringArray(values, typedInfo.item.path)
								: DisplayValue.FromFacetSelection(result, typedInfo);
		} else {
			console.log('addFacetValueToResult.typedInfo>>>>>>>>>>>>>', typedInfo);
			result.value = { ...typedInfo.facets[0].value as IDateRangeValue };
			result.displayValue = DisplayValue.FromDateRange(result.value, typedInfo.item.path, true);
		}

		this.buildResultPayload(result, typedInfo, typedInfo.facets[0]);
	}

	private static addDateRangeValueToResult(result: IFacetedFilterSelection, selectionInfo: ISelectionInfo): void {
		if (!TypeInstanceOf.DateRangeInfo(selectionInfo))
			return;
		//console.log('addDateRangeValueToResult.selectionInfo>>>>>>>>>>>>>', selectionInfo);
		const typedInfo: ISelectedDateRangeInfo = <ISelectedDateRangeInfo>selectionInfo;
		result.value = { ...typedInfo.dateRange };
		result.displayValue = DisplayValue.FromDateRangeSelection(result, selectionInfo);

		this.buildResultPayload(result, typedInfo);
	}

	private static addOperatorValueToResult(result: IFacetedFilterSelection, selectionInfo: ISelectionInfo): void {
		if (!TypeInstanceOf.OperatorInfo(selectionInfo))
			return;

		const typedInfo: ISelectedOperatorInfo = <ISelectedOperatorInfo>selectionInfo;
		const value: IOperatorValue = { operator: typedInfo.operatorInfo.operator, operand: typedInfo.operand };
		result.value = value;
		result.displayValue = DisplayValue.FromOperatorValue(typedInfo, selectionInfo.item.path);

		this.buildResultPayload(result, selectionInfo);
	}

	private static addHierarchyValueToResult(result: IFacetedFilterSelection, selectionInfo: ISelectionInfo): void {
		if (!TypeInstanceOf.HierarchyInfo(selectionInfo))
			return;

		const typedInfo: ISelectedHierarchyInfo = <ISelectedHierarchyInfo>selectionInfo;
		result.value = [...typedInfo.hierarchies];
		const valuesAreFacets: boolean = typedInfo.item.items ? TypeCheck.isHierarchyFacet(typedInfo.item.items[0]?.type) : true;
		result.displayValue = DisplayValue.FromHierarchies(result.value, typedInfo.item.path, valuesAreFacets);

		const valueOverride: IFacetedFilterDataItem =
			valuesAreFacets &&
			typedInfo.hierarchies.length > 1 &&
			!!typedInfo.item.items
			? typedInfo.item.items[0] : undefined;

		this.buildResultPayload(result, typedInfo, valueOverride);
	}

	private static buildResultPayload(result: IFacetedFilterSelection, selectionInfo: ISelectionInfo, valueOverrideItem?: IFacetedFilterDataItem): void {
		const item: IFacetedFilterDataItem = valueOverrideItem ? valueOverrideItem : selectionInfo.item;
		const payload: IFacetedFilterResult = {
			name: item.name,
			type: item.filterType,
			value: JSON.stringify(result.value),
			valueType: item.valueType
		}
		result.payload = payload;
    }

}
