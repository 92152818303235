import { IFacetedFilterDataItem, IOperatorInfo } from "../types";
import { FacetOperatorGroups } from "../types/constants";


export class FilterUtils {

    public static kebabToCamelCase(value: string): string {
        if (value.indexOf('-') >= 0)
            return value.replace(/-./g, x => x[1].toUpperCase());
        
        return value;
    }

    public static getParentPath(path: string): string {
		const idx = path.lastIndexOf('|');
		return path.slice(0, idx);
    }

    public static pathToPathsList(path: string): string[] {
        return path.split('|');
    }

    public static pathToDisplayPathsList(path: string): string[] {
        const paths = this.pathToPathsList(path);
        paths.shift();
        return paths;
    }

    public static getNextNodePath(dataItem: IFacetedFilterDataItem, key: string): string {
        const item: IFacetedFilterDataItem = dataItem.items?.find(i => i.path.includes(key));

        return item?.path ?? null;
    }

    public static getOperatorGroup(type: string): IOperatorInfo[] {
        const normalizedType = this.kebabToCamelCase(type);
        return FacetOperatorGroups[normalizedType];
    }

    public static isNullOrWhitespace(value: string): boolean {
        return value === null
            || value === ''
            || value.match(/^ *$/) !== null;
    }
    
}
