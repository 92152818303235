<div class="animated fadeIn trafficDetail" style="width: 100%;">
  <div class="container-fluid" style="padding-left:0px; padding-right:0px;">
    <div class="full-width-panel-row">

      <mat-card class="mat-elevation-z3 panel-card">
        <mat-card-title>
          <span class="panel-title">
            <a *ngIf="!!panelConfiguration.titleLinkUrl" (click)="handleTitleClick()">{{panelConfiguration.label}}</a>
            <span *ngIf="!panelConfiguration.titleLinkUrl">{{panelConfiguration.label}}</span>
          </span>
          <div class="pull-right ellipses-dropdown">
            <button title="Options" mat-button class="card-options-button" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"><i
                class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em;"></i></button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item style="outline: none;" (click)="openHelpTextClicked()">{{'Help' | translate | async}}</button>
            </mat-menu>
          </div>
        </mat-card-title>

        <mat-card-content style="color: #222222; display: flex; padding-bottom:15px;" fxLayoutAlign="space-around center">
          <ng-template dynamicComponent ></ng-template>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
