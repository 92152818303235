import { FacetedFilterFacetItemType, FacetedFilterContainerItemType, FacetedFilterUtilContainerItemType, FacetedFilterOperatorType, FacetedFilterItemValueType } from "../types/constants";
import { IDateRangeValue, IHierarchyValue, ISelectedDateRangeInfo, ISelectedFacetInfo, ISelectedHierarchyInfo } from "../types/faceted-filter.types";


export class TypeCheck {
    public static isFacet(type: string): boolean {
        return Object.values(FacetedFilterFacetItemType).includes(type as FacetedFilterFacetItemType);
    }

    public static isHierarchyFacet(type: string): boolean {
        return type === FacetedFilterFacetItemType.HierarchyFacet;
    }

    public static isContainer(type: string): boolean {
        return Object.values(FacetedFilterContainerItemType).includes(type as FacetedFilterContainerItemType);
    }

    public static isUtilityContainer(type: string): boolean {
        if (type === FacetedFilterUtilContainerItemType.DateRangePicker || type.includes(FacetedFilterUtilContainerItemType.Operator))
            return true;
        
        return false;
    }

    public static isOperatorContainer(type: string): boolean {
        return type.includes(FacetedFilterUtilContainerItemType.Operator);
    }

    public static isDateRangePickerContainer(type: string): boolean {
        return type === FacetedFilterUtilContainerItemType.DateRangePicker;
    }

    public static isHierarchyContainer(type: string): boolean {
        return type === FacetedFilterContainerItemType.HierarchyContainer;
    }

    public static isReplayableContainer(type: string): boolean {
        return this.isContainer(type)
            || this.isDateRangePickerContainer(type);
    }

    public static hasOperatorType(type: string): boolean {
        return Object.values(FacetedFilterOperatorType).includes(type as FacetedFilterOperatorType);
    }

    public static isDateRangeValueType(valueType: string): boolean {
        return valueType === FacetedFilterItemValueType.DateRangeValue;
    }

    public static isBooleanValueType(valueType: string): boolean {
        return valueType === FacetedFilterItemValueType.BooleanValue;
    }

    public static isSelectedDateRangeInfo(item: any): item is ISelectedDateRangeInfo {
        return item && typeof item.startDate === 'string' && typeof item.endDate === 'string';
    }

    public static isSelectedFacetInfo(item: any): item is ISelectedFacetInfo {
        return item && Array.isArray(item.facets);
    }

    public static isSelectedHierarchyValue(item: any): item is ISelectedHierarchyInfo {
        return item && Array.isArray(item.hierarchies);
    }

    public static isHierarchyValue(item: any): item is IHierarchyValue {
        return item && typeof item.hierarchyTypeId === 'string';
    }

    public static isHierarchyValueArray(item: any): item is IHierarchyValue[] {
        return Array.isArray(item) && item.every(item => item != null && typeof item.hierarchyTypeId === 'string');
    }

    public static isDateRangeValue(item: any): item is IDateRangeValue {
        return item && typeof item.startDate === 'string' && typeof item.endDate === 'string';
    }

    public static isStringArray(item: any): item is string[] {
        return Array.isArray(item) && item.every(item => typeof item === 'string');
    }

}