import { IDateMode, DateMonthModes } from '../enums/enums';
import { environment } from '../../../environments/environment';
import { ReportFilterItem } from '../models/report-view.models';

export interface SimpleFilterValue {
  value: string | number;
  display: string;
}

export type OrgEntityType = 'model' | 'class' | 'allclasses' | 'dealer' | 'lma' | 'virtual20' | 'orgcode1' | 'orgcode2' | 'orgcode3' | 'orgcode4' | 'orgcode5' | 'national' | 'dealer-group' | 'sales' | 'director' | 'page' | 'page-type';

export interface OrgFilterValue extends SimpleFilterValue {
  levelTypeId: number;
  entityType: OrgEntityType;
}

export interface CollectionFilterValue extends SimpleFilterValue {
  levelTypeId: number;
  entityType: string;
}

export interface DateFilterValue {
  dateModeId: number;
  displayName?: string;
  startDate: Date;
  endDate?: Date;
  previousStartDate: Date;
  previousEndDate: Date;
  previousYearStartDate: Date;
  previousYearEndDate: Date;
  orgDateCode: number;
}

export type FilterValueType = SimpleFilterValue | OrgFilterValue | DateFilterValue | ReportFilterItem;

export enum FilterTypes {
  audienceSegment = 'audience-segment',
  date = 'date',
  digAdProvider = 'digad-provider',
  brand = 'brand',
  campaignType = 'campaign-type',
  channel = 'channel',
  digAdChannel = 'digad-channel',
  chatProvider = 'chat-provider',
  tradeInProvider = 'trade-in-provider',
  deviceType = 'device-type',
  digitalRetailingProvider = 'digital-retailing-provider',
  inventoryStatus = 'inventory-status',
  inventoryTypes = 'inventory-types',
  journeySegment = 'journey-segment',
  leadType = 'lead-type',
  lma = 'lma',
  lmaRegion = 'lma-region',
  org = 'org',
  profitCenter = 'profit-center',
  reputationPlatform = 'reputation-platform',
  reputationProvider = 'reputation-provider',
  saleType = 'sale-type',
  serviceSchedulerProvider = 'service-scheduler-provider',
  socialMediaPlatform = 'social-media-platform',
  socialMediaProvider = 'social-media-provider',
  sourceType = 'source-type',
  strategy = 'strategy',
  tactic = 'tactic',
  trait = 'trait',
  vehicleMake = 'vehicle-make',
  vehicleMakes = 'vehicle-makes',
  vehicle = 'vehicle',
  vehicleClass = 'vehicle-class',
  vehicleModel = 'vehicle-model',
  vehicleModels = 'vehicle-models',
  vehicleSeries = 'vehicle-series',
  websiteProvider = 'website-provider',
  websiteMetricType = 'website-metric-type',
  digAdProgram = 'digad-program',
  dealerSize = 'dealer-size',
  vehicleType = 'vehicle-type',
  dealType = 'deal-type',
  leadGate = 'lead-gate',
  page = 'page',
  role = 'role',
  salesUnit = 'sales-unit',
  productLine = 'product-line',
}

export interface FilterType {
  name: string;
  displayName: string;
}

export const filterTypeLookup: { [id: string]: FilterType } = {
  'audience-segment': { displayName: 'AdGroup Audience', name: 'audience-segment' },
  'org': { displayName: 'Org', name: 'org' },
  'brand': { displayName: 'Brand', name: 'brand' },
  'campaign-type': { displayName: 'Campaign Type', name: 'campaign-type' },
  'channel': { displayName: 'Channel', name: 'channel' },
  'digad-channel': { displayName: 'Channel', name: 'digad-channel' },
  'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
  'trade-in-provider': { displayName: 'Provider', name: 'trade-in-provider' },
  'device-type': { displayName: 'Device Type', name: 'device-type' },
  'digad-provider': { displayName: 'Provider', name: 'digad-provider' },
  'digad-provider-v2': { displayName: 'Provider', name: 'digad-provider-v2' },
  'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' },
  'inventory-status': { displayName: 'Inventory Status', name: 'inventory-status' },
  'inventory-types': { displayName: 'Inventory Type', name: 'inventory-types' },
  'journey-segment': { displayName: 'AdGroup Journey', name: 'journey-segment' },
  'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
  'lma': { displayName: 'LMA', name: 'lma' },
  'lma-region': { displayName: 'LMA Region', name: 'lma-region' },
  'page': { displayName: 'Page', name: 'page' },
  'profit-center': { displayName: 'Profit Center', name: 'profit-center' },
  'digad-program': { displayName: 'Program', name: 'digad-program' },
  'reputation-provider': { displayName: 'Provider', name: 'reputation-provider' },
  'role': { displayName: 'Role', name: 'role' },
  'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
  'social-media-platform': { displayName: 'Platform', name: 'social-media-platform' },
  'service-scheduler-provider': { displayName: 'Provider', name: 'service-scheduler-provider' },
  'reputation-platform': { displayName: 'Platform', name: 'reputation-platform' },
  'social-media-provider': { displayName: 'Provider', name: 'social-media-provider' },
  'source-type': { displayName: 'Source Type', name: 'source-type' },
  'strategy': { displayName: 'Strategy', name: 'strategy'},
  'tactic': { displayName: 'Tactic', name: 'tactic' },
  'trait': { displayName: 'Segment', name: 'trait' },
  'vehicle': { displayName: 'Vehicles', name: 'vehicle' },
  'vehicle-make': { displayName: 'Make', name: 'vehicle-make' },
  'vehicle-class': { displayName: 'Class', name: 'vehicle-class' },
  'vehicle-model': { displayName: 'Model', name: 'vehicle-model' },
  'vehicle-models': { displayName: 'Models', name: 'vehicle-models' },
  'vehicle-series': { displayName: 'Series', name: 'vehicle-series' },
  'website-provider': { displayName: 'Provider', name: 'website-provider' },
  'website-metric-type': { displayName: 'Metric', name: 'website-metric-type' },
  'sales-unit': { displayName: 'Sales Unit', name: 'sales-unit' },
  'product-line': { displayName: 'Product Line', name: 'product-line' },
};

export type ReportSectionType = 'chat' | 'inventory' | 'leads' | 'sales' | 'website' | 'digital-advertising' | 'provider-tools' | 'digital-retailing' | 'user-activity' | 'program-overview';

export interface Filter {
  type: string;
  selected: FilterValueType[];
  available?: number[];
  removable?: boolean;
  lockable?: boolean;
  locked?: boolean;
  visible?: boolean;
}

export class FilterConfigReport {
  reportName: string;
  filters: string[];
  defaults: string[];
  dateMode?: IDateMode;
  defaultMonthDateMode?: DateMonthModes;
  dateModeToggle?: boolean;
  numberOfMonthsToShow?: number;
  numberOfQuartersToShow?: number;
  quarterDateModeEnabled?: boolean;
  orgTypes?: number[];
  restrictDates?: boolean;
  section?: ReportSectionType;
  disableCalendarMode?: boolean;
  salesCalendarToggleDisabled?: boolean;
}
