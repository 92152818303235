<h2 mat-dialog-title>
  {{'Lead Details' | translate | async}}
  <!--<i class="fa fa-close close-icon pull-right" (click)="closeModal()"></i>-->
</h2>
<mat-dialog-content>
  <ng-container *ngIf="lead$ | async as lead">
    <table class="table table-striped">
      <tbody *ngIf="lead">
        <tr>
          <td><b>{{'Lead Key' | translate | async}}</b></td>
          <td>{{lead.leadKey}}</td>
          <td><b>{{'First Name' | translate | async}}</b></td>
          <td>{{lead.firstName}}</td>
        </tr>
        <tr>
          <td><b>{{'Lead Id' | translate | async}}</b></td>
          <td>{{lead.leadId}}</td>
          <td><b>{{'Last Name' | translate | async}}</b></td>
          <td>{{lead.lastName}}</td>
        </tr>
        <tr>
          <td><b>{{'Lead Received' | translate | async}}</b></td>
          <td>{{lead.created | date:'M/d/yyyy h:mm a'}}</td>
          <td><b>{{'Email Address' | translate | async}}</b></td>
          <td>{{lead.emailAddress}}</td>
        </tr>
        <tr>
          <td><b>{{'Source' | translate | async}}</b></td>
          <td>{{this.reportConfig.useSourceMask ? lead.maskedSource : lead.sourceName}}</td>
          <td><b>{{'Address 1' | translate | async}}</b></td>
          <td>{{lead.address1}}</td>
        </tr>
        <tr>
          <td><b>{{(reportConfig.dealerLabel || 'Dealer') + ' Code' | translate | async}}</b></td>
          <td>{{lead.dealerCode}}</td>
          <td><b>{{'Address 2' | translate | async}}</b></td>
          <td>{{lead.address2}}</td>
        </tr>
        <tr>
          <td><b>{{(reportConfig.dealerLabel || 'Dealer') + ' Name' | translate | async}}</b></td>
          <td>{{lead.dealerName}}</td>
          <td><b>{{'City' | translate | async}}</b></td>
          <td>{{lead.city}}</td>
        </tr>
        <tr>
          <td><b>{{'Model' | translate | async}}</b></td>
          <td>{{lead.vehicleModel}}</td>
          <td><b>{{reportConfig.stateLabel || 'State' | translate | async}}</b></td>
          <td>{{lead.state}}</td>
        </tr>
        <tr>
          <td><b>{{'Original Model' | translate | async}}</b></td>
          <td>{{lead.originalModel}}</td>
          <td><b>{{reportConfig.zipCodeLabel || 'Zip Code' | translate | async}}</b></td>
          <td>{{lead.zipCode}}</td>
        </tr>
        <tr>
          <td><b>{{'Model Year' | translate | async}}</b></td>
          <td>{{lead.vehicleYear}}</td>
          <td><b>{{'Live Date' | translate | async}}</b></td>
          <td><span *ngIf="lead.liveDate">{{lead.liveDate | date:'M/d/yyyy h:mm a'}}</span></td>
        </tr>
        <tr>
          <td><b>{{'Response Date' | translate | async}}</b></td>
          <td><span *ngIf="lead.responded">{{lead.responded | date:'M/d/yyyy h:mm a'}}</span></td>
          <td><b>{{'Work Phone' | translate | async}}</b></td>
          <td>{{lead.workPhone}}</td>
        </tr>
        <tr>
          <td><b>{{'Response Time' | translate | async}}</b></td>
          <td><span *ngIf="lead.responded">{{timeToRespond(lead.timeToRespond)}}</span></td>
          <td><b>{{'Home Phone' | translate | async}}</b></td>
          <td>{{lead.homePhone}}</td>
        </tr>
        <tr>
          <td><b>{{'Sale?' | translate | async}}</b></td>
          <td>{{sale(lead)}}</td>
          <td><b>{{'Mobile Phone' | translate | async}}</b></td>
          <td>{{lead.mobilePhone}}</td>
        </tr>
        <tr>
          <td><b>{{'Same ' + (reportConfig.dealerLabel || 'Dealer') + '?' | translate | async}}</b></td>
          <td>{{sameDealer(lead)}}</td>
          <td><b>{{'Lead Days to Sale' | translate | async}}</b></td>
          <td>{{lead.timeToClose}}</td>
        </tr>
        <tr>
          <td><b>{{'Sale Date' | translate | async}}</b></td>
          <td>{{lead.saleDate | date:'M/d/yyyy h:mm a'}}</td>
          <td><b>{{'Sale VIN' | translate | async}}</b></td>
          <td>{{lead.saleVin}}</td>
        </tr>
      </tbody>
    </table>

  </ng-container>

  <ng-container *ngIf="leadDispositions$ | async as leadDispositions">
    <ng-container *ngIf="leadDispositions.length > 0">
      <div class="row" style="margin-top: 20px">
        <div class="col-12">
          <h3 class="pull-left">{{'Lead Activity' | translate | async}}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table table-striped">
            <thead class="report-table-head">
              <tr>
                <th>{{'Activity' | translate | async}}</th>
                <th>{{'CRM' | translate | async}}</th>
                <th>{{'Date' | translate | async}}</th>
                <th>{{'Details' | translate | async}}</th>
              </tr>
            </thead>
            <tbody *ngIf="leadDispositions">
              <tr *ngFor="let disposition of leadDispositions" class="center">
                <td>{{disposition.activityName | translate | async}}</td>
                <td>{{disposition.crmName}}</td>
                <td>{{disposition.activityDate | date:'M/d/yyyy h:mm a'}}</td>
                <td>{{disposition.activityDetails}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close>{{'Close' | translate | async}}</button>
</mat-dialog-actions>
