import {
    IDefaultTableColumnConfig,
    IMultiLevelDualAxisLineGraphConfig,
    IWebsiteReferrerConfig,
    ICardHeaderConfig } from '../../../../_shared/models/models-index';
 import { Enums } from '../../../../_shared/enums/enums';
 import * as constants from '../../../../_shared/constants/constants';

 export class WebsiteReferrerConfig implements IWebsiteReferrerConfig {
    reportTitle = 'Referrer Report';

    public benchmarkCardHeader: ICardHeaderConfig = {
       title: 'Summary Data',
       iconClass: 'fa-file',
       helpTextKey: constants.helpTextKeys.websiteReferrerSummaryHelp,
       helpTextTitle: 'Referrer Summary',
       exportName: 'Referrer Report - Summary Data',
       metricDisplayModes: ['MOM', 'YOY']
    };

     public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
         xAxisPropertyName: 'date',
         parentMetrics: [
             // Engagements
             {
                id: Enums.websiteMetrics.engagements.metricId,
                displayName: Enums.websiteMetrics.engagements.name,
                propertyName: Enums.websiteMetrics.engagements.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            // Engagement Rate
            {
                id: Enums.websiteMetrics.engagementRate.metricId,
                displayName: Enums.websiteMetrics.engagementRate.name,
                propertyName: Enums.websiteMetrics.engagementRate.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            // Actions
            {
                id: Enums.websiteMetrics.actions.metricId,
                displayName: Enums.websiteMetrics.actions.name,
                propertyName: Enums.websiteMetrics.actions.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            // Action Rate
            {
               id: Enums.websiteMetrics.actionRate.metricId,
               displayName: Enums.websiteMetrics.actionRate.name,
               propertyName: Enums.websiteMetrics.actionRate.nameKey,
               metricFormatKey: constants.formatKeys.localeString
            },
             // Total Visits
             {
                id: Enums.websiteMetrics.totalVisits.metricId,
                displayName: 'Website Visits',
                propertyName: Enums.websiteMetrics.totalVisits.nameKey,
                metricFormatKey: constants.formatKeys.localeString
             },
             // Unique Visitors
             {
                id: Enums.websiteMetrics.uniqueVisits.metricId,
                displayName: 'Unique Visitors',
                propertyName: Enums.websiteMetrics.uniqueVisits.nameKey,
                metricFormatKey: constants.formatKeys.localeString
             },
             // Page Views
             {
                id: Enums.websiteMetrics.pageViews.metricId,
                displayName: 'Total Page Views',
                propertyName: Enums.websiteMetrics.pageViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
             },
             // Service Page Views
             {
                id: Enums.websiteMetrics.servicePageViews.metricId,
                displayName: Enums.websiteMetrics.servicePageViews.name,
                propertyName: Enums.websiteMetrics.servicePageViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
             },
             // Time on Site
             {
                 id: Enums.websiteMetrics.avgTimeOnSite.metricId,
                 displayName: Enums.websiteMetrics.avgTimeOnSite.name,
                 propertyName: Enums.websiteMetrics.avgTimeOnSite.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             // Form Submissions
             {
                id: Enums.websiteMetrics.formSubmissions.metricId,
                displayName: Enums.websiteMetrics.formSubmissions.name,
                propertyName: Enums.websiteMetrics.formSubmissions.nameKey,
                metricFormatKey: constants.formatKeys.localeString
             },
             // Form Conversion Rate
             {
                id: Enums.websiteMetrics.formConversionRate.metricId,
                displayName: Enums.websiteMetrics.formConversionRate.name,
                propertyName: Enums.websiteMetrics.formConversionRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
             },
             // Click to Call
             {
                id: Enums.websiteMetrics.clickToCalls.metricId,
                displayName: Enums.websiteMetrics.clickToCalls.name,
                propertyName: Enums.websiteMetrics.clickToCalls.nameKey,
                metricFormatKey: constants.formatKeys.localeString
             },
             // Bounce Rate
             {
                id: Enums.websiteMetrics.bounceRate.metricId,
                displayName: Enums.websiteMetrics.bounceRate.name,
                propertyName: Enums.websiteMetrics.bounceRate.nameKey,
                metricFormatKey: constants.formatKeys.localeString
             },

             // VDPs
             {
                id: Enums.websiteMetrics.newInventoryDetailViews.metricId,
                displayName: Enums.websiteMetrics.newInventoryDetailViews.name,
                propertyName: Enums.websiteMetrics.newInventoryDetailViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
             },
             {
                id: Enums.websiteMetrics.usedInventoryDetailViews.metricId,
                displayName: Enums.websiteMetrics.usedInventoryDetailViews.name,
                propertyName: Enums.websiteMetrics.usedInventoryDetailViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
             },
             {
                id: Enums.websiteMetrics.cpoInventoryDetailViews.metricId,
                displayName: Enums.websiteMetrics.cpoInventoryDetailViews.name,
                propertyName: Enums.websiteMetrics.cpoInventoryDetailViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
             },
         ],
         childMetrics: [
             {
                id: Enums.channels.allChannels.channelId,
                displayName: Enums.channels.allChannels.channelName,
                propertyName: Enums.channels.allChannels.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                id: Enums.channels.brand.channelId,
                displayName: Enums.channels.brand.channelName,
                propertyName: Enums.channels.brand.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                id: Enums.channels.organicEmail.channelId,
                displayName: Enums.channels.organicEmail.channelName,
                propertyName: Enums.channels.organicEmail.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                id: Enums.channels.organicSearch.channelId,
                displayName: Enums.channels.organicSearch.channelName,
                propertyName: Enums.channels.organicSearch.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                id: Enums.channels.organicSocial.channelId,
                displayName: Enums.channels.organicSocial.channelName,
                propertyName: Enums.channels.organicSocial.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                id: Enums.channels.other.channelId,
                displayName: Enums.channels.other.channelName,
                propertyName: Enums.channels.other.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                id: Enums.channels.paidDisplay.channelId,
                displayName: Enums.channels.paidDisplay.channelName,
                propertyName: Enums.channels.paidDisplay.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                id: Enums.channels.paidEmail.channelId,
                displayName: Enums.channels.paidEmail.channelName,
                propertyName: Enums.channels.paidEmail.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                id: Enums.channels.paidSearch.channelId,
                displayName: Enums.channels.paidSearch.channelName,
                propertyName: Enums.channels.paidSearch.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
               id: Enums.channels.paidSocial.channelId,
               displayName: Enums.channels.paidSocial.channelName,
               propertyName: Enums.channels.paidSocial.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                id: Enums.channels.paidVideo.channelId,
                displayName: Enums.channels.paidVideo.channelName,
                propertyName: Enums.channels.paidVideo.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                id: Enums.channels.thirdPartySites.channelId,
                displayName: Enums.channels.thirdPartySites.channelName,
                propertyName: Enums.channels.thirdPartySites.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                id: Enums.channels.typedBookmarked.channelId,
                displayName: Enums.channels.typedBookmarked.channelName,
                propertyName: Enums.channels.typedBookmarked.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
             },
         ],

         defaultChildrenIds: [ Enums.channels.allChannels.channelId, Enums.channels.allChannels.channelId ],
         defaultParentIds: [ Enums.websiteMetrics.totalVisits.metricId, Enums.websiteMetrics.engagements.metricId ],
         metricOneColorHexCode: '#b00',
         metricTwoColorHexCode: '#5f0003'
     };

     public summaryTableColumns: IDefaultTableColumnConfig[] = [
         {
             show: true,
             header: 'Referring Channel',
             columnDef: 'entity',
             isMom: false,
             isYoy: false,
             metricFormatKey: constants.formatKeys.entityDisplayName,
             clickable: false
         },
         // Engagements
         {
            show: true,
            header: 'Engagements',
            columnDef: 'engagements',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
            show: false,
            header: 'Previous Month Engagements',
            columnDef: 'engagementsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
            show: false,
            header: 'Engagements MOM',
            columnDef: 'engagementsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         {
            show: false,
            header: 'Previous Year Engagements',
            columnDef: 'engagementsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
            show: false,
            header: 'Engagements YOY',
            columnDef: 'engagementsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },

         // Engagement Rate
         {
            show: true,
            header: 'Engagement Rate',
            columnDef: 'engagementRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         {
            show: false,
            header: 'Previous Month Engagement Rate',
            columnDef: 'engagementRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         {
            show: false,
            header: 'Engagement Rate MOM',
            columnDef: 'engagementRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         {
            show: false,
            header: 'Previous Year Engagement Rate',
            columnDef: 'engagementRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         {
            show: false,
            header: 'Engagement Rate YOY',
            columnDef: 'engagementRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },

         // Actions
         {
            show: true,
            header: 'Actions',
            columnDef: 'actions',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
            show: false,
            header: 'Previous Month Actions',
            columnDef: 'actionsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
            show: false,
            header: 'Actions MOM',
            columnDef: 'actionsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         {
            show: false,
            header: 'Previous Year Actions',
            columnDef: 'actionsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
            show: false,
            header: 'Actions YOY',
            columnDef: 'actionsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },

         // Action Rate
         {
            show: true,
            header: 'Action Rate',
            columnDef: 'actionRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         {
            show: false,
            header: 'Previous Month Action Rate',
            columnDef: 'actionRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         {
            show: false,
            header: 'Action Rate MOM',
            columnDef: 'actionRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         {
            show: false,
            header: 'Previous Year Action Rate',
            columnDef: 'actionRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         {
            show: false,
            header: 'Action Rate YOY',
            columnDef: 'actionRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         // Total Visits
         {
             show: true,
             header: 'Website Visits',
             columnDef: 'totalVisits',
             isMom: false,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
             show: false,
             header: 'Previous Month Website Visits',
             columnDef: 'totalVisitsPreviousMonth',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
             show: false,
             header: 'Website Visits MOM',
             columnDef: 'totalVisitsMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         {
             show: false,
             header: 'Previous Year Website Visits',
             columnDef: 'totalVisitsPreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
             show: false,
             header: 'Website Visits YOY',
             columnDef: 'totalVisitsYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },

         // Unique Visitors
         {
             show: true,
             header: 'Unique Visitors',
             columnDef: 'uniqueVisits',
             isMom: false, isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
             show: false,
             header: 'Previous Month Unique Visitors',
             columnDef: 'uniqueVisitsPreviousMonth',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
             show: false,
             header: 'Unique Visitors MOM',
             columnDef: 'uniqueVisitsMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         {
             show: false,
             header: 'Previous Year Unique Visitors',
             columnDef: 'uniqueVisitsPreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
             show: false,
             header: 'Unique Visitors YOY',
             columnDef: 'uniqueVisitsYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },

         // Total Page Views
         {
             show: true,
             header: 'Total Page Views',
             columnDef: 'pageViews',
             isMom: false,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
             show: false,
             header: 'Previous Month Total Page Views',
             columnDef: 'pageViewsPreviousMonth',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
             show: false,
             header: 'Total Page Views MOM',
             columnDef: 'pageViewsMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         {
             show: false,
             header: 'Previous Year Total Page Views',
             columnDef: 'pageViewsPreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
             show: false,
             header: 'Total Page Views YOY',
             columnDef: 'pageViewsYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },

         // Service Page Views
         {
             show: true,
             header: 'Service Page Views',
             columnDef: 'servicePageViews',
             isMom: false,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
             show: false,
             header: 'Previous Month Service Page Views',
             columnDef: 'servicePageViewsPreviousMonth',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
             show: false,
             header: 'Service Page Views MOM',
             columnDef: 'servicePageViewsMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },
         {
             show: false,
             header: 'Previous Year Service Page Views',
             columnDef: 'servicePageViewsPreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
         },
         {
             show: false,
             header: 'Service Page Views YOY',
             columnDef: 'servicePageViewsYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
         },

          // Time on Site
         {
             show: true,
             header: 'Avg. Time on Site',
             columnDef: 'averageTimeOnSite',
             isMom: false, isYoy: false,
             metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
         },
         {
             show: false,
             header: 'Previous Month Avg. Time on Site',
             columnDef: 'averageTimeOnSitePreviousMonth',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
          },
          {
             show: false,
             header: 'Avg. Time on Site MOM',
             columnDef: 'averageTimeOnSiteMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Previous Year Avg. Time on Site',
             columnDef: 'averageTimeOnSitePreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
          },
          {
             show: false,
             header: 'Avg. Time on Site YOY',
             columnDef: 'averageTimeOnSiteYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          // Form Submissions
          {
             show: true,
             header: 'Form Submissions',
             columnDef: 'formSubmissions',
             isMom: false,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Previous Month Form Submissions',
             columnDef: 'formSubmissionsPreviousMonth',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Form Submissions MOM',
             columnDef: 'formSubmissionsMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Previous Year Form Submissions',
             columnDef: 'formSubmissionsPreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Form Submissions YOY',
             columnDef: 'formSubmissionsYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },

          // Form Conversion Rate
          {
             show: true,
             header: 'Form Conversion Rate',
             columnDef: 'formConversionRate',
             isMom: false,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Previous Month Form Conversion Rate',
             columnDef: 'formConversionRatePreviousMonth',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Form Conversion Rate MOM',
             columnDef: 'formConversionRateMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Previous Year Form Conversion Rate',
             columnDef: 'formConversionRatePreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Form Conversion Rate YOY',
             columnDef: 'formConversionRateYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },

          // Click to Call
          {
             show: true,
             header: 'Click to Call',
             columnDef: 'clickToCalls',
             isMom: false,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Previous Month Click to Call',
             columnDef: 'clickToCallsPreviousMonth',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Click to Call MOM',
             columnDef: 'clickToCallsMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Previous Year Click to Call',
             columnDef: 'clickToCallsPreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Click to Call YOY',
             columnDef: 'clickToCallsYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },

          // Bounce Rate
          {
             show: true,
             header: 'Bounce Rate',
             columnDef: 'bounceRate',
             isMom: false,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Previous Month Bounce Rate',
             columnDef: 'bounceRatePreviousMonth',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Bounce Rate MOM',
             columnDef: 'bounceRateMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Previous Year Bounce Rate',
             columnDef: 'bounceRatePreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Bounce Rate YOY',
             columnDef: 'bounceRateYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },

          // New VDPs
          {
             show: true,
             header: 'New Inventory Detail Views',
             columnDef: 'newInventoryDetailViews',
             isMom: false,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Previous Month New Inventory Detail Views',
             columnDef: 'newInventoryDetailViewsPreviousMonth',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'New Inventory Detail Views MOM',
             columnDef: 'newInventoryDetailViewsMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Previous Year New Inventory Detail Views',
             columnDef: 'newInventoryDetailViewsPreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'New Inventory Detail Views YOY',
             columnDef: 'newInventoryDetailViewsYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },

          // Used VDPs
          {
             show: true,
             header: 'Used Inventory Detail Views',
             columnDef: 'usedInventoryDetailViews',
             isMom: false,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Previous Month Used Inventory Detail Views',
             columnDef: 'usedInventoryDetailViewsPreviousMonth',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Used Inventory Detail Views MOM',
             columnDef: 'usedInventoryDetailViewsMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Previous Year Used Inventory Detail Views',
             columnDef: 'usedInventoryDetailViewsPreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Used Inventory Detail Views YOY',
             columnDef: 'usedInventoryDetailViewsYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },

          // CPO  Vdps
          {
             show: true,
             header: 'CPO Inventory Detail Views',
             columnDef: 'cpoInventoryDetailViews',
             isMom: false,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Previous Month CPO Inventory Detail Views',
             columnDef: 'cpoInventoryDetailViewsPreviousMonth',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'CPO Inventory Detail Views MOM',
             columnDef: 'cpoInventoryDetailViewsMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Previous Year CPO Inventory Detail Views',
             columnDef: 'cpoInventoryDetailViewsPreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'CPO Inventory Detail Views YOY',
             columnDef: 'cpoInventoryDetailViewsYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          }
       ];
 }

