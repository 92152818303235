import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as SvgResources from '../../../../lib/svg.resources';
import { debounceTime, distinctUntilChanged, startWith, takeUntil, tap } from 'rxjs/operators';
import { IOperatorInfo, IFacetedFilterDataItem, IReplayItem, ISelectedOperatorInfo, IOperatorValue } from '../../../../types/faceted-filter.types';
import { FilterUtils } from '../../../../Utils/filter.utils';
import { TypeInstanceOf } from '../../../../Utils/type-instance-of';
import { BasePaneComponent } from '../base-pane/base-pane.component';

// TODO: We need a way to represent the proper input type based on the value type we are working with
// When creating an expression for string types text input is fine but how do we determine:
// 1) When to use number (or range) input type
// 2) When using a number | range input type: how do we know the proper min/max values
// NOTE: It is important to have the right UI so the end user can create useful expressions

@Component({
	selector: 'ui-operator-pane',
	templateUrl: './operator-pane.component.html',
	styleUrls: ['./operator-pane.component.scss'],
})
export class OperatorPaneComponent extends BasePaneComponent implements OnInit, AfterViewInit, OnDestroy {

	operatorInfo: IOperatorInfo[] = [];

	valueFormGroup: FormGroup = new FormGroup({});
	valueField = new FormControl('', Validators.required);
	selectedOperator: string;

	replayDataItem: IFacetedFilterDataItem;

	smallCloseIcon = SvgResources.sizableSvgIcon(SvgResources.svgIconTypes.close, 3);

	@Input() set replayValue(value: any) {
		if (TypeInstanceOf.OperatorValue(value)) {
			this.replayUpdate(value);
		}
	}

	@Input() set replayItem(value: IReplayItem) {
		this.replayDataItem = value.item;
	}

	@ViewChild('valueInput') valueInput: ElementRef;

	constructor(private cdRef: ChangeDetectorRef) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.valueFormGroup.addControl('valueField', this.valueField);
		this.init();
	}

	ngAfterViewInit(): void {
		this.valueField.valueChanges.pipe(
			debounceTime(500),
			distinctUntilChanged(),
			startWith(''),
			takeUntil(this.destroy$),
			tap(val => this.buildOperatorExpression())
		).subscribe();

		this.valueInput.nativeElement.focus();
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	clearValue(): void {
		this.valueField.setValue('');
	}

	buildOperatorExpression(): void {
		if (this.selectedOperator && this.valueField.valid) {
			const selectedOperatorInfo: IOperatorInfo = this.operatorInfo.find(i => i.operator === this.selectedOperator);
			const expression: ISelectedOperatorInfo = {
				operatorInfo: {
					label: selectedOperatorInfo.label,
					operator: selectedOperatorInfo.operator
				},
				operand: this.valueField.value,
				item: this.item ? this.item : this.replayItem.item
			};

			this.onOperatorExpressionSelected.emit(expression);
		} else {
			this.valueInput.nativeElement.focus();
		}
	}

	private init(): void {
		this.operatorInfo = this.item ? FilterUtils.getOperatorGroup(this.item.type) : FilterUtils.getOperatorGroup(this.replayDataItem.type);
		if (!this.selectedOperator)
			this.selectedOperator = this.operatorInfo[0].operator;

	}
	private replayUpdate(replayValue: IOperatorValue) {
		if (replayValue && replayValue.operand) {
			this.valueField.patchValue(replayValue.operand as string);
			this.selectedOperator = replayValue.operator;
		}
	}
}
