import { Injectable } from '@angular/core';
import { LeadsTranslationConfig } from '../../../_config/translation/leads/leads.translation.config';
import { SharedTranslationConfig } from '../../../_config/translation/shared/shared.translation.config';
import { ITranslationService } from '../locale.model';
import { ITranslationConfig } from '../../../_config/translation/translation.config';


@Injectable()
export class LeadsTranslationService implements ITranslationService {
  translationConfig = new LeadsTranslationConfig;
  getChannels?: (value: string, locale: string) => string;

  /*
   * We're doing something funky here so this is backwards compatible with the old translation service
   * but uses the new ITranslationService interface
   */
  getLeadMetricNameTranslation(metricId: string, defaultLabel: string, locale: string) {
    // passing in and returning default label takes away dependance on any translation file at all
    if (locale === 'en') {
      return defaultLabel;
    }

    return this.translationConfig.metrics[locale][metricId] ?? '';
  }

  getMetricNameTranslation = (metricId: string, defaultLabel: string, locale: string): string => {
    return this.getLeadMetricNameTranslation(metricId, defaultLabel, locale);
  }

  getLeadReportTitleTranslation(value: string, locale: string) {
    if (locale === 'en') {
      return value;
    }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.translationConfig.reportTitles[locale][cleansedValue];
  }

  getReportTitleTranslation = (value: string, locale: string): string => {
    return this.getLeadReportTitleTranslation(value, locale);
  }

  getLeadLabelTranslation(value: string, locale: string) {
    if (!locale || locale === 'en' || !value) {
      return value;
    }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.translationConfig.labels[locale][cleansedValue];
  }

  getLabelTranslation = (value: string, locale: string): string => {
    return this.getLeadLabelTranslation(value, locale);
  }

  getLeadDisclaimerTranslation(value: string, timezone: string, locale: string) {
    if (!locale || locale === 'en' || !value) {
      return value + timezone;
    }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '') + timezone.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.translationConfig.labels[locale][cleansedValue];
  }

  getLeadChartDimension(value: string, locale: string) {
    if (locale === 'en') {
      return value;
    }
    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.translationConfig.chartDimensions[locale][cleansedValue] || value;
  }

  getChartDimensions = (value: string, locale: string):string => {
    return this.getLeadChartDimension(value, locale);
  };

}
