import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type TailwindBreakpointKey = 'all' | 'xs' | 'sm' | 'md' | '2md' | 'lg' | 'xl' | '1.5xl' | '2xl' | '3xl';

export enum TailwindBreakpoint {
  'all'   = 0,
  'xs'    = 425,
  'sm'    = 640,
  'md'    = 768,
  '2md'   = 992,
  'lg'    = 1024,
  'xl'    = 1280,
  '1.5xl' = 1440,
  '2xl'   = 1536,
  '3xl'   = 1792,
};

export enum TailwindBreakpointMediaQuery {
  'all'   = '(min-width: 0px)',
  'xs'    = '(min-width: 425px)',
  'sm'    = '(min-width: 640px)',
  'md'    = '(min-width: 768px)',
  '2md'   = '(min-width: 992px)',
  'lg'    = '(min-width: 1024px)',
  'xl'    = '(min-width: 1280px)',
  '1.5xl' = '(min-width: 1440px)',
  '2xl'   = '(min-width: 1536px)',
  '3xl'   = '(min-width: 1792px)',
}

@Injectable({
  providedIn: 'root'
})
export class TailwindBreakpointService {

  breakpoint$: Observable<TailwindBreakpointKey> = this.breakpointObserver.observe([
    TailwindBreakpointMediaQuery.all,
    TailwindBreakpointMediaQuery.xs,
    TailwindBreakpointMediaQuery.sm,
    TailwindBreakpointMediaQuery.md,
    TailwindBreakpointMediaQuery['2md'],
    TailwindBreakpointMediaQuery.lg,
    TailwindBreakpointMediaQuery.xl,
    TailwindBreakpointMediaQuery['1.5xl'],
    TailwindBreakpointMediaQuery['2xl'],
    TailwindBreakpointMediaQuery['3xl'],
  ]).pipe(
    map(({ breakpoints: breakpointResults }) => {
      let breakpoint = undefined;

      enumKeys(TailwindBreakpointMediaQuery).forEach(
        tailwindKey => {
          const isMatched = breakpointResults[TailwindBreakpointMediaQuery[tailwindKey]];
          if(isMatched) breakpoint = tailwindKey;
        }
      )

      return breakpoint;
    })
  );

  size$ = this.breakpoint$.pipe(
    map(key => TailwindBreakpoint[key])
  );

  sizeGte(size: TailwindBreakpoint): Observable<boolean> {
    return this.size$.pipe(
      map(currentSize => currentSize >= size)
    );
  }

  constructor(private breakpointObserver: BreakpointObserver) { }

}

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}
