import { createReducer, on } from '@ngrx/store';
import { FilterActions } from './action-types';
import * as FilterModels from '../filter.model';
import { FilterState, ReportViewFilterSelection, initialFilterState } from './filter-state.model';
import { Filter } from '../filter.model';
import { ReportViewFilter } from '../../models/report-view.models';

const _filterReducer = createReducer(
  initialFilterState,
  on(FilterActions.initializeFiltersSuccess, (state, action) => {
    let newState = {...state};

    // update report filters
    newState = updateReportFilters(newState, action.reportName, action.filters);

    // check to see if we got passed locked filters that need to be updated or set
    action.lockedFilters.forEach(locked => {
      const otherLocked = newState.lockedFilters.filter(lf => lf.type !== locked.type);
      newState.lockedFilters = [...otherLocked, locked];
    });
    return newState;
  }),
  on(FilterActions.updatePrintingFilters, (state, action) => {
    const newState = updateReportFilters(state, action.reportName, action.filters);

    return newState;
  }),
  on(FilterActions.updateCurrentReportFilterSelectedSuccess, (state, action) => {
    return action.filterState;
  }),
  on(FilterActions.toggleFilterLockSuccess, (state, action) => {
    return action.filterState;
  }),
  on(FilterActions.removeFilterSuccess, (state, action) => {
    const newState = updateReportFilters(state, action.reportName, action.filters, action.lockedFilters);

    return newState;
  }),
  on(FilterActions.resetFilters, (state, _) => {
    const newState = {...state, lockedFilters: [], filters: [], explicitUnlockedFilters: [] };
    return newState;
  }),
  on(FilterActions.updateReportOrgFilterSelectedSuccess, (state, action) => {
    const newState = updateReportFilters(state, action.reportName, action.filters);

    return newState;
  }),
  on(FilterActions.updateOptions, (state, action) => {
    return {...state, options: action.options};
  }),
  on(FilterActions.updateReportViewFilterConfiguration, (state, action) => {
    // check to see if the report configuration already exists and if so, replace it, otherwise, add it
    const config = action.config;
    const existing = state.reportViewFilterConfigurations.find(rc => rc.reportName === config.reportName);

    if (existing) {
      const newConfigurations = [...state.reportViewFilterConfigurations];
      newConfigurations.splice(
        newConfigurations.findIndex(rc => rc.reportName === existing.reportName),
        1,
        config
      );

      return {...state, reportViewFilterConfigurations: newConfigurations};
    } else {
      return {...state, reportViewFilterConfigurations: [...state.reportViewFilterConfigurations, config]};
    }
  }),
  on(FilterActions.initializeReportViewFiltersSuccess, (state, action) => {
    const newState = updateReportViewFilters(state, action.config.reportName, action.selections, action.filters, action.lockedFilters);

    return newState;
  }),
  on(FilterActions.updateReportViewFilterSelectionsSuccess, (state, action) => {
    return action.newFilterState;
  }),
  on(FilterActions.resetReportViewFiltersSuccess, (state, action) => {
    const newState = updateReportViewFilters(state, action.reportName, action.selections, undefined, action.lockedSelections);

    return newState;
  }),
  on(FilterActions.resetAllReportViewFilters, (state, _) => {
    return {...state, reportViewFilterConfigurations: [], reportViewFilters: [], reportViewFilterSelections: [], lockedReportViewFilters: []};
  })
);

export function filterReducer(state, action) {
  return _filterReducer(state, action);
}

const updateReportFilters = (state: FilterState, reportName: string, filters: Filter[], lockedFilters?: Filter[]) => {
  const newState = {...state};

  // associate new set of locked filters
  if (!!lockedFilters) {
    newState.lockedFilters = [...lockedFilters];
  }

  // associate new set of report filters
  const existing = newState.filters.find(filter => filter.reportName === reportName);

  if (existing) {
    const newReportFilters = [...newState.filters];

    newReportFilters.splice(
      newReportFilters.findIndex(rf => rf.reportName === existing.reportName),
      1,
      { reportName, filters }
    );
    newState.filters = newReportFilters;
  } else {
    newState.filters = [...newState.filters, { reportName, filters }];
  }

  return newState;
};

// used for both initializing available filters and updating the selected filters
const updateReportViewFilters = (
  state: FilterState, reportName: string,
  selections: ReportViewFilterSelection[],
  filters?: ReportViewFilter[],
  lockedFilters?: ReportViewFilterSelection[]) => {

  const newState = {...state};
  
  // associate new set of locked filters
  if (!!lockedFilters) {
    newState.lockedReportViewFilters = [...lockedFilters];
  }

  // cache new set of report view filters, note, we only do this if they were passed in
  if (!!filters) {
    // either add or update the report view filters to existing filters
    const newReportViewFilters = [...newState.reportViewFilters];

    for(const filter of filters) {
      const existing = newReportViewFilters.find(f => f.name === filter.name);

      if (!!existing) {
        newReportViewFilters.splice(
          newReportViewFilters.findIndex(rf => rf.name === existing.name),
          1,
          filter
        );
      } else {
        newReportViewFilters.push(filter);
      }
    }

    newState.reportViewFilters = newReportViewFilters;
  }

  // associate new set of report filter selections
  const existingSelections = newState.reportViewFilterSelections?.find(reportSelections => reportSelections.reportName === reportName);

  if (existingSelections) {
    const newSelections = [...newState.reportViewFilterSelections];

    newSelections.splice(
      newSelections.findIndex(rf => rf.reportName === existingSelections.reportName),
      1,
      { reportName, selections }
    );
    newState.reportViewFilterSelections = newSelections;
  } else {
    newState.reportViewFilterSelections = [...newState.reportViewFilterSelections ?? [], { reportName, selections }];
  }

  return newState;
}