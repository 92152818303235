<mat-card class="mat-elevation-z1" style="background-color: #ffffff;">
  <mat-card-title>
    <i class="pad-right"></i><img src="{{getProviderImagePath(providerCode)}}" alt="{{providerName}}" />
    <div class="pull-right">
      <button mat-button class="card-options-button" [matMenuTriggerFor]="helpOptions"><i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em;"></i></button>
      <mat-menu #helpOptions="matMenu">
        <button mat-menu-item style="outline: none;" (click)="openHelpTextClicked()">Help</button>
      </mat-menu>
    </div>
  </mat-card-title>
  <mat-card-content style="color: #222222" class="card-block target-card">
    <table>
      <tbody>
        <tr>
          <td>
            <div style="position: absolute; width: 99%; z-index: 0" [chart]="currentChart"></div>
            <span class="focus-metric-value">
              <b>{{primaryMetric.metricValue | formatter: primaryMetric.formatter}}</b>
              <i [ngClass]="[getTrendArrowIconClass(primaryMetric.trend),getTrendColor(primaryMetric.trend)]" class="pad-left"></i>
            </span>
            <br />
            <span class="focus-metric-label">{{primaryMetric.metricName}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
  <mat-card-footer>
    <table>
      <tbody>
        <tr>
          <td *ngFor="let metric of metrics.slice(1)" [style]="subMetricWidth"> <!-- this runs for the rest of the elements -->
            <span class="secondary-metric-value">
              <b>{{metric.metricValue | formatter: metric.formatter}}</b>
              <i [ngClass]="[getTrendArrowIconClass(metric.trend), getTrendColor(metric.trend)]" class="pad-left" style="padding-left: 3px;" ></i>
        </span>
            <br />
            <span class="secondary-metric-label">
              {{metric.metricName}}
            </span>
            <!-- <div class="row"></div> -->
            <hr>
          </td>
        </tr>
        <tr style="font-style: italic">
          <td *ngFor="let metric of metrics.slice(1)" [style]="subMetricWidth"> <!-- this runs for the rest of the elements -->
            <span>
              <b>POP: {{metric.metricMOM | formatter: getFormatter('percentageTwoDecimalsOrNa')}}</b>
            </span>
            <br />
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card-footer>
</mat-card>
