import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { TagService } from '../../services/api/tags.service';

@Component({
  selector: 'app-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.css']
})
export class TagsInputComponent implements OnInit {
  private _tags: string[] = [];

  @Input()
  set tags(value: string[] | null) {
    this._tags = value || [];
  }

  get tags(): string[] {
    return this._tags;
  }

  @Output() tagsChange = new EventEmitter<string[]>();

  tagInput = new FormControl();
  filteredSuggestions: Observable<string[]>;
  showInput = false;
  loading = false;

  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(private readonly tagService: TagService,
    private readonly changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.filteredSuggestions = this.tagInput.valueChanges.pipe(
      debounceTime(300),
      startWith(''), // Trigger the observable initially
      tap(() => this.loading = true),
      switchMap(value => 
        this.tagService.getByKey(value)
      ),
      map(response => Array.isArray(response.tags) ? response.tags.filter(tag => !this.tags.includes(tag)) : []),
      tap(() => this.loading = false)
    );    
  }

  addTag() {
    if(!this.tagInput.value)
    {
      return;
    }

    const inputVal = this.tagInput.value.trim();

    if (inputVal && !this.tags.includes(inputVal)) {
      this.tags.push(inputVal);
      this.tagsChange.emit(this.tags);
      this.tagInput.setValue('');
    }
    
    this.autocompleteTrigger.openPanel();
  }

  removeTag(tag: string) {
    this.tags = this.tags.filter(t => t !== tag);
    this.tagsChange.emit(this.tags);
    this.tagInput.setValue(this.tagInput.value);
  }

  onTagSelected(event: any) {
    const tag = event.option.value;
    if (tag && !this.tags.includes(tag)) {
      this.tags.push(tag);
      this.tagsChange.emit(this.tags);
    }
    this.tagInput.setValue('');
    this.toggleInput();
  }

  toggleInput() {
    this.showInput = !this.showInput;
    if (this.showInput) {
      this.changeDetector.detectChanges();
      this.focusInput();
      setTimeout(() => {
        this.autocompleteTrigger.openPanel();
      });
    }
  }

  focusInput() {
    if (this.inputElement && this.inputElement.nativeElement) {
      this.inputElement.nativeElement.focus();
    }
  }
}
