<header class="app-header navbar" id="app-navbar">
  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>&#9776;</button>
  <a class="navbar-brand" [routerLink]="[defaultRoute]"></a>
  <ng-container *ngIf="locale$ | async as locale">
    <ul class="nav navbar-nav ml-auto">
      <navbar-user></navbar-user>
      <li class="nav-item pl-3 pr-2">
        <a class="" (click)="openSupportModal()"><i class="fa fa-life-ring">&nbsp;</i><b>{{'Support' | translate: locale | async}}</b></a>
      </li>
      <ng-container *ngIf="!!showLanguageOptions">  
          <li class="nav-item pl-3 pr-2">
            <a class="" (click)="openLanguageSelectorModal()"><i class="fa fa-language">&nbsp;</i><b>{{'Language' | translate: locale | async}}</b></a>
          </li>
      </ng-container>
      <navbar-logout [locale]='locale'></navbar-logout>
    </ul>
  </ng-container>
</header>
