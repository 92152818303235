import { Injectable } from '@angular/core';
import { ITranslationService } from '../locale.model';
import { ConfigurationService } from '../../services/config/config.service';
import { ResourceStringMap } from '../../models/models-index';
import { ITranslationConfig } from '../../../_config/translation/translation.config';
import { Store } from '@ngrx/store';
import { AppState } from '../../../_store/app-state.model';
import { AppSelectors } from '../../../_store/selector-types';
import { filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationService implements ITranslationService {
  resourceStringMap: ResourceStringMap;
  translationConfig: ITranslationConfig;

  constructor(
    private store$: Store<AppState>
  ) {
    this.store$.select(AppSelectors.selectConfigState).pipe(
      filter(config => !!config),
      map(config => {
        return config.resourceStrings;
      })
    ).subscribe(rs => {
      this.resourceStringMap = rs;
    })
  }

  getMetricNameTranslation = (metricId: string, defaultLabel: string, locale: string): string => {
    return this.getResourceStringTranslation(metricId, locale);
  }

  getReportTitleTranslation = (value: string, locale: string): string => {
    return this.getResourceStringTranslation(value, locale);
  }

  getChartDimensions = (value: string, locale: string): string => {
    return this.getResourceStringTranslation(value, locale);
  }

  getChannels = (value: string, locale: string): string => {
    return this.getResourceStringTranslation(value, locale);
  }
  getLabelTranslation = (value: string, locale?: string): string => {
    return this.getResourceStringTranslation(value, locale);
  }

  getResourceStringTranslation(value: string, locale: string): string {
    let cultureCode = "en-US";
    if (!!locale || locale.indexOf('en-US') === -1) {
      cultureCode = locale;
    }

    if (cultureCode.toLowerCase() === 'en')
      cultureCode = 'en-US'; // fix for en culture code due to legacy things

    return (this.resourceStringMap?.[cultureCode] ? this.resourceStringMap[cultureCode][value] : null) ?? value;
  }
}