import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { IOperatorGroups } from './faceted-filter.types';


export const TopPosition: ConnectionPositionPair = {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom'
}

export const RightPosition: ConnectionPositionPair = {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top'
}

export const BottomPosition: ConnectionPositionPair = {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top'
}

export const LeftPosition: ConnectionPositionPair = {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top'
}

export enum FilterPanelRenderMethods {
    template = 'template',
    component = 'component',
    text = 'text'
}

export enum FilterPanelCloseType {
    backdropClick = 'backdropClick',
    cancel = 'cancel',
    applied = 'applied'
}

export enum FilterPanelStates {
    open = 'open',
    closed = 'closed'
}

export enum FilterPanelInputType {
    list = 'list',
    collection = 'collection',
    facetedFilter = 'faceted_filter'
} 

export enum FilterPanelDisplayContext {
    Filter = 'filter',
    Search = 'search'
}

export enum FilterDataContext {
    customersDefault = 'customers-default',
    tasksDefault = 'tasks-default'
}

export const FacetOperatorGroups: IOperatorGroups = {
    commonStringOperator: [
        { label: 'Contains', operator: 'contains' },
        { label: 'Not Contains', operator: 'not_contains' },
        { label: 'Equals', operator: 'equals' },
    ],
    numericOperator: [
        { label: 'Equals', operator: 'equals' },
        { label: 'Greater than', operator: 'greater_than' },
        { label: 'Less than', operator: 'less_than' },
        { label: 'Greater than or equal to', operator: 'greater_or_equals' },
        { label: 'Less than or equal to', operator: 'less_or_equals' },
    ],
    percentOperator: [
        { label: 'Equals', operator: 'equals' },
        { label: 'Greater than', operator: 'greater_than' },
        { label: 'Less than', operator: 'less_than' },
        { label: 'Greater than or equal to', operator: 'greater_or_equals' },
        { label: 'Less than or equal to', operator: 'less_or_equals' },
    ]
}

export enum FacetedFilterOperatorType {
    CommonStringOperator = 'common-string-operator',
    CommonPercentOperator = 'percent-operator',
    CommonNumberOperator = 'number-operator',
    CommonNumericOperator = 'numeric-operator',
}

export enum FacetedFilterFacetItemType {
    ExclusiveFacet = 'exclusive-facet',
    Facet = 'facet',
    HierarchyFacet = 'hierarchy-facet'
}

export enum FacetedFilterContainerItemType {
    Container = 'container',
    HierarchyContainer = 'hierarchy-facet-container'
}

export enum FacetedFilterUtilContainerItemType {
    DateRangePicker = 'date-range-picker',
    Operator = '-operator'
}

export enum FacetedFilterItemValueType {
    DateRangeValue = 'date-range-value',
    SimpleValue = 'simple-value',
    HierarchyValue = 'hierarchy-value',
    RetailerValue = 'retailer-value',
    OperatorValue = 'operator-value',
    BooleanValue = 'boolean-value'
}

export enum FilterReturnEventType {
    Initializing = 'init',
    Resetting = 'reset',
    Default = 'default'
}