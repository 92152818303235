// component: sql-editor.component.ts
import { Component, Input, ViewChild, OnInit, forwardRef, Injector, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';
import { MonacoEditorComponent, MonacoEditorConstructionOptions, MonacoEditorLoaderService, MonacoStandaloneCodeEditor } from '@materia-ui/ngx-monaco-editor';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'sql-editor',
  templateUrl: './sql-editor.component.html',
  styleUrls: ['./sql-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SqlEditorComponent),
      multi: true,
    },
  ]
})
export class SqlEditorComponent implements ControlValueAccessor, OnInit {
  private static instanceCounter = 0;
  @Input() private _value: string = '';
  public ngControl: NgControl | null;

  private instanceId: number;
  get value(): any {
    return this._value;
  }

  set value(value: any) {
    if (value !== this._value) {
      this._value = value;
      this.onChange(value);
      this.onTouched();
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  @ViewChild(MonacoEditorComponent, { static: false })
  monacoComponent: MonacoEditorComponent;
  editorOptions: MonacoEditorConstructionOptions = {
    theme: 'appTheme',
    language: 'sql',
    roundedSelection: true,
    autoIndent: 'full'
  };

  modelUri: monaco.Uri;

  constructor(
    private readonly monacoLoaderService: MonacoEditorLoaderService,
    private readonly injector: Injector,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.instanceId = SqlEditorComponent.instanceCounter++;
    this.ngControl = this.injector.get(NgControl, null);
    if (this.ngControl) {
      setTimeout(() => {
        this.ngControl.control!.updateValueAndValidity();
      });
    }
    this.cdRef.detectChanges();
  }

  editorInit(editor: MonacoStandaloneCodeEditor) {
    this.modelUri = monaco.Uri.parse(`a://b/${this.instanceId}.sql`);

    this.monacoLoaderService.isMonacoLoaded$
      .pipe(
        filter(isLoaded => !!isLoaded),
        take(1)
      )
      .subscribe(() => {
        editor.setModel(monaco.editor.createModel(this.value, 'sql', this.modelUri));
        monaco.editor.setTheme('appTheme');

        editor.onDidChangeModelContent(() => {
          setTimeout(() => this.onEditorValueChange(editor.getValue()));
        });    

        if (this.value) {
          editor.setValue(this.value);
        }
      });
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onEditorValueChange(value: string): void {
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.setValue(value, {emitEvent: false});
      if (this.ngControl.control.updateValueAndValidity) {
        this.ngControl.control.updateValueAndValidity();
      }
    }
  }  
}
