<mat-card class="mat-elevation-z1" style="background-color: white; color: black;">
  <mat-card-title>
      <i class="fa pad-right" [ngClass]="config.iconClass"></i><b>{{config.title}}</b>
      <div class="pull-right ellipses-dropdown">
          <button mat-button class="card-options-button" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"><i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em;"></i></button>
          <mat-menu #menu="matMenu">
              <button mat-menu-item style="outline: none;" (click)="openHelpTextClicked()">Help</button>
          </mat-menu>
      </div>
  </mat-card-title>
  <mat-card-content class="card-block target-card">
    <div fxLayout="row">
        <div fxFlex="50">
            <table>
            <tbody>
                <tr>
                    <td>
                        <b style="font-size: 1.15em;">
                            {{config.metricValue}}
                        </b>
                        <i [ngClass]="[config.metricTrendArrowClass, getTrendColor(config.metricTrendValue)]" class="pad-left" style="font-size: 1em;"></i>
                    </td>
                </tr>
                <tr *ngIf="config.subtitle1Value">
                    <td class="box-label" style="font-style: italic">{{config.subtitle1Value}}</td>
                </tr>
                <tr *ngIf="config.subtitle2Value">
                    <td class="box-label" style="font-style: italic">{{ config.subtitle2Value }}</td>
                </tr>
                <tr *ngIf="config.subtitle3Value">
                    <td class="box-label" style="font-style: italic">{{ config.subtitle3Value }}</td>
                </tr>
            </tbody>
        </table>
        </div>
        <div fxFlex="50" *ngIf="config.chartConfig" style="border-left: 1px solid #ccc; height: 125px;">
            <sd-chart [options]="config.chartConfig"></sd-chart>
        </div>
    </div>
  </mat-card-content>
  <mat-card-footer *ngIf="config.footerOneLeftValue || config.footerOneRightValue">
    <table>
        <tbody>
            <tr *ngIf="config.footerOneLeftValue || config.footerOneRightValue">
                <td style="text-align: left;">
                    {{config.footerOneLeftLabel}}:
                    <b [ngStyle]="config.footerOneLeftColor ? { 'color': config.footerOneLeftColor } : null">
                        {{config.footerOneLeftValue}}
                    </b>
                </td>
                <td style="text-align: right;">
                    {{config.footerOneRightLabel}}:
                    <b [ngStyle]="config.footerOneRightColor ? { 'color': config.footerOneRightColor } : null">
                        {{config.footerOneRightValue}}
                    </b>
                </td>
            </tr>
        </tbody>
    </table>
  </mat-card-footer>
</mat-card>
