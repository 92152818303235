<div class="filter-content-container" style="display: flex; flex-direction: column;">

    <div class=""
      style="display: flex; flex-direction: row; justify-content: space-evenly; border-bottom: 1px solid #ccc;">
  
      <div class="" style="height: 50px; width: 100%; display: flex; align-items: center;">
  
        <div class="filter-content-wrapper" style="padding: 0; width: 100%;">
  
          <mat-form-field style="width: 100%; max-height: 49px; padding-left: 5px;" appearance="outlined"
            appearance="outlined">
  
            <input #searchInput matInput type="text" placeholder="Search" [(ngModel)]="searchTerm"
              (focus)="searchHasFocus = true" (focusout)="searchHasFocus = false">
  
            <button mat-icon-button *ngIf="searchHasFocus || searchTerm" matSuffix (click)="clearSearchTerm()">
  
              <mat-icon>clear</mat-icon>
  
            </button>
  
            <button mat-icon-button *ngIf="!searchHasFocus && !searchTerm" matSuffix>
  
              <mat-icon>search</mat-icon>
  
            </button>
  
  
          </mat-form-field>
          <mat-progress-bar *ngIf="showProgress" mode="determinate" value="400" style="height: 1px;"></mat-progress-bar>
        </div>
  
      </div>
  
    </div>
  
    <div class="" style="display: flex; flex-direction: row; justify-content: space-evenly;">
      <div class="" style="width: 100%; display: flex; flex-direction: column; overflow-y: auto;">
        <div class="" style="overflow-y: auto; height: 226px;">
  
          <div class="filter-content-wrapper" style="display: flex; flex-direction: column;">
            <mat-tree [dataSource]="treeDataSource" [treeControl]="treeControl" class="filter-tree">
  
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
  
                <li class="mat-tree-node li-node">
  
                  <mat-checkbox class="checklist-leaf-node" [checked]="isChecked(node)"
                    (change)="leafNodeSelectionToggle(node)">
                    {{ node.display }}
                  </mat-checkbox>
  
                </li>
  
              </mat-tree-node>
  
              <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                <li>
                  <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.display">
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                  </button>
                  <mat-checkbox *ngIf="!node.unselectable" class="checklist-leaf-node" [checked]="isChecked(node)"
                    [indeterminate]="childNodesPartiallySelected(node)" (change)="nodeSelectionToggle(node)">
                    {{ node.display }}
                  </mat-checkbox>
                  <span *ngIf="!!node.unselectable">{{ node.display }}</span>
                </li>
  
              </mat-tree-node>
  
            </mat-tree>
          </div>
  
        </div>
  
      </div>
    </div>
  </div>