<div class="card card-no-border mat-elevation-z1">
    <div class="card-header">
        <div class="pull-left" *ngIf="showHeaderLabel">
            <h5><i class="fa fa-line-chart fa-primary pad-right"></i><b>{{'Trend' | translate: chart.locale | async}}</b></h5>
        </div>
        <ng-container *ngIf="showMetricSelectDropdown">
            <div class="pull-right actions">
                <metric-complex-select *ngIf="!!metricOneSelectConfig" [config]="metricOneSelectConfig" (selectionChange)="applyMetricOneChange($event)"></metric-complex-select>
                <metric-complex-select style="padding-left: 25px;" *ngIf="!!metricTwoSelectConfig" [config]="metricTwoSelectConfig" (selectionChange)="applyMetricTwoChange($event)"></metric-complex-select>
            </div>
        </ng-container>
    </div>
    <multi-level-dual-line-chart *ngIf="graphDataObject && metricOneConfig && metricTwoConfig"
        [chartConfig]="graphDataObject"
        [metricOneConfig]="metricOneConfig"
        [metricTwoConfig]="metricTwoConfig"
        [locale]="chart.locale">
    </multi-level-dual-line-chart>
</div>
