<div class="card card-no-border mat-elevation-z1 panel-card full-width-data-panel" #tableCard>
    <div class="card-header">
      <div class='pull-left'>
        <mat-form-field>
          <mat-select [value]="selectedConfiguration?.panelConfiguration" (selectionChange)="changeConfiguration($event)">
            <mat-option *ngFor="let config of panelConfigs" [value]="config">
              {{config.label ?? config.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="pull-right">
        <button mat-icon-button class="toggle-expand-button nofocus" appToggleExpandoDataTable [expandType]="'height'"
          [dataTableType]="dataTableType" [scrollToElement]="tableCard">
          <mat-icon class="fa-primary">fullscreen</mat-icon>
        </button>
        <button *ngIf="settings.length > 0" title="Settings" mat-button [matMenuTriggerFor]="settingsMenu"><i class="fa fa-sliders fa-primary"
            style="font-size: 1.4em;"></i></button>
        <mat-menu #settingsMenu="matMenu">
          <!-- <button mat-menu-item style="outline: none;" (click)="applySetting('MOM')">MOM</button> -->
          <mat-list>
            <mat-list-item *ngFor="let setting of settings">
              <mat-checkbox [checked]="isSettingSelected(setting)" (change)="applySetting($event, setting)">{{setting.label ?? setting.name }}</mat-checkbox>
            </mat-list-item>
          </mat-list>
        </mat-menu>
        <mat-form-field *ngIf="columnSets.length > 1">
          <mat-select [value]="selectedColumnSet?.name" (selectionChange)="columnSetChanged($event)">
            <mat-option *ngFor="let columnSet of columnSets" [value]="columnSet">
              {{columnSet}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button title="Options" class="ellipses-dropdown" mat-button class="card-options-button" #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="menu"><i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em;"></i></button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item style="outline: none;" (click)="exportToExcel(selectedConfiguration)">Excel</button>
          <button mat-menu-item style="outline: none;" (click)="openHelpTextClicked()">{{'Help' | translate: locale | async}}</button>
        </mat-menu>
      </div>
    </div>
    <ng-template dynamicComponent></ng-template>
  </div>
