import {createAction, props} from '@ngrx/store';
import { IFilterModel, ReportViewFilter, ReportViewFilterConfiguration } from '../../models/models-index';
import { IFilterTreeNode } from '../../sd-filter-panel-module/types/types';
import { FilterState, FilterOptions, ReportViewFilterSelection } from './filter-state.model';
import { Filter, FilterValueType, OrgFilterValue } from '../filter.model';

export const loadDefaultFilter = createAction(
  '[App Effects loadDefaultFilter] Load Default Filter'
);

export const loadDefaultFilterModelSuccess = createAction(
  '[App Component GetConfiguration] Load Default Filter Model Success',
  props<{defaultFilter: IFilterModel, userOrgFilterTreeNodes: IFilterTreeNode[], vehicleOrgFilterTreeNodes: IFilterTreeNode[]}>()
);

export const initializeFilters = createAction(
  '[Filter Bar Service getReportFilters] Initialize Report Filters',
  props<{reportName: string, selectedInitializeMap?: Map<string, FilterValueType[]>}>()
);

export const initializeFiltersSuccess = createAction(
  '[Filter Effects] Initialize Report Filters Success',
  props<{reportName: string, filters: Filter[], lockedFilters: Filter[]}>()
);

export const updatePrintingFilters = createAction(
  '[App Component] Initialize Report For Printing',
  props<{reportName: string, filters: Filter[]}>()
);

export const updateFiltersSuccess = createAction(
  '[Filter Effects] Update Filters',
  props<{reportName: string, filters: Filter[]}>()
);

export const resetFilters = createAction(
  '[Filter Bar Service] Reset Filters'
);

export const updateCurrentReportFilterSelected = createAction(
  '[Filter Bar Service] Update Filter Selected',
  props<{reportName: string, filterType: string, selected: FilterValueType[]}>()
);

export const updateCurrentReportFilterSelectedSuccess = createAction(
  '[Filter Effects] Update Current Report Filter Selected',
  props<{filterState: FilterState}>()
);

export const toggleFilterLock = createAction(
  '[Filter Bar Component] Toggle Filter Lock',
  props<{reportName: string, filter: Filter}>()
);

export const toggleDateFilterLock = createAction(
  '[Date Range Picker Component] Toggle Date Filter Lock',
  props<{reportName: string, filter: Filter}>()
);

export const toggleFilterLockSuccess = createAction(
  '[Filter Effects] Toggle Filter Lock Success',
  props<{filterState: FilterState}>()
);

export const removeFilter = createAction(
  '[Filter Bar Service] Remove Filter',
  props<{reportName: string, filterType: string}>()
);

export const updateReportOrgFilterSelected = createAction(
  '[Filter Bar Service] Update Filter Org Selected',
  props<{reportName: string, selected: OrgFilterValue}>()
);

export const updateReportOrgFilterSelectedSuccess = createAction(
  '[Filter Effects] Update Filter Org Selected Success',
  props<{reportName: string, filters: Filter[], shouldRedirect?: boolean }>()
);

export const removeFilterSuccess = createAction(
  '[Filter Effects] Remove Filter Success',
  props<{reportName: string, filters: Filter[], lockedFilters: Filter[]}>()
);

export const updateOptions = createAction(
  '[Filter Bar Service] Update Options',
  props<{options: FilterOptions}>()
);

export const updateReportViewFilterConfiguration = createAction(
  '[ReportViewComponent] Update Report View Filter Configuration',
  props<{config: ReportViewFilterConfiguration}>()
);

export const updateReportViewFilterConfigurationSuccess = createAction(
  '[Filter Effects] Update Report View Filter Configuration Success',
  props<{
    reportViewFilterConfiguration: ReportViewFilterConfiguration,
    reportViewFilters: ReportViewFilter[]
  }>()
);

export const loadReportViewFilters = createAction(
  '[Filter Effects] Load Report View Filters',
  props<{
    config: ReportViewFilterConfiguration,
    filtersToLoad: string[],
    locale: string
}>());

export const initializeReportViewFilters = createAction(
  '[FilterService InitializeReportViewFilters] Initialize Report Filters',
  props<{config: ReportViewFilterConfiguration, filters: ReportViewFilter[]}>()
);

export const initializeReportViewFiltersSuccess = createAction(
  '[Filter Effects] Initialize Report View Filters Success',
  props<{
    config: ReportViewFilterConfiguration,
    filters: ReportViewFilter[], 
    selections: ReportViewFilterSelection[],
    lockedFilters: ReportViewFilterSelection[]
   }>()
);

export const updateReportViewFilterSelections = createAction(
  '[Filter Bar Service] Update Report View Filter Selections',
  props<{reportName: string, selections: ReportViewFilterSelection[]}>()
);

export const updateReportViewFilterSelectionsSuccess = createAction(
  '[Filter Effects] Update Report View Filter Selections Success',
  props<{
    newFilterState: FilterState,
  }>()
);

export const updateReportViewFilterSelection = createAction(
  '[Filter Bar Service] Update Report View Filter Selection',
  props<{reportName: string, selection: ReportViewFilterSelection}>()
);

export const resetReportViewFilters = createAction(
  '[Filter Bar Service] Reset Report View Filters',
  props<{reportName: string}>()
);

export const resetReportViewFiltersSuccess = createAction(
  '[Filter Effects] Reset Report View Filters Success',
  props<{reportName: string, selections: ReportViewFilterSelection[], lockedSelections: ReportViewFilterSelection[]}>()
);

export const resetAllReportViewFilters = createAction('[Login] Reset All Report View Filters');