import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'region' })
export class RegionPipe implements PipeTransform {
  transform(metrics: any, regionName: any): any {

    if (regionName == null) return metrics;

    return metrics.filter(metric => metric.region.indexOf(regionName) !== -1);
  }
}
