import { Component, Input, ViewChild, ChangeDetectionStrategy, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelect as MatSelect, MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Enums, IEnums } from '../../enums/enums';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import * as Models from '../../models/models-index';
import { SharedHelpTextModalComponent,  } from '../../modals/modals-index';

@Component({
  selector: 'sd-card-header-select',
  templateUrl: './card-header-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardHeaderSelectComponent {
  @ViewChild('tableOptions', { static: true }) public ngSelect: MatSelect;
  @Input() defaultPerspective: string;
  @Input() perspectives: Models.IVisualizationPerspective[];

  @Output() expandClicked = new EventEmitter();
  @Output() metricSelectionChanged = new EventEmitter();
  @Output() displayModeChanged = new EventEmitter();
  @Output() perspectiveChanged = new EventEmitter<Models.IVisualizationPerspective>();

  enums: IEnums = Enums;
  isTrend = false;
  isComp = false;
  subscriptions: Subscription[] = [];
  metricSelectConfig: Models.IMetricSelectConfig = null;

  currentPerspective: any = null;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.currentPerspective = this.perspectives.find(p => p.code === this.defaultPerspective);
    this.perspectiveChanged.emit(this.currentPerspective);
  }

  applyPerspectiveChange(evt: MatSelectChange) {
    const newPerspective = this.perspectives.find(o => o.code === evt.value);
    this.currentPerspective = { ...newPerspective };
    this.perspectiveChanged.emit(this.currentPerspective);
  }

  applyMetricChange(evt) {
    this.metricSelectionChanged.emit(evt);
  }

  applyDisplayModeChange(evt) {
    this.displayModeChanged.emit(evt);
  }

  toggleExpanded(evt) {
    this.expandClicked.emit(evt)
  }

  openHelpText(key: string, title: string): void {
    this.dialog.open(SharedHelpTextModalComponent, {
      width: '50vw',
      data: {
        title: this.currentPerspective.helpConfig.helpTextTitle,
        name: this.currentPerspective.helpConfig.helpTextKey,
        locale: null
      }
    });
  }
}
