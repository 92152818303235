import { Component, Input } from '@angular/core';
import * as Models from '../../../models/models-index';
import * as SharedServices from '../../../services/services-index';
import { Subscription } from 'rxjs/internal/Subscription';
import { FunnelRow, Segment } from './types';

@Component({
  selector: 'horizontal-funnel',
  templateUrl: './horizontal-funnel.component.html',
  styleUrls: ['./horizontal-funnel.component.scss']
})
export class HorizontalFunnelV2Component {
  @Input() dataSet: Models.DataSet;
  @Input() settings: Models.ElementSettings;
  funnelRows: FunnelRow[] = [];
  filterBreadcrumbsForExport: string[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private filterService: SharedServices.FilterService,
    private dataTableService: SharedServices.DataTableService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.dataTableService.pathToPurchasePrintButtonClicked$.subscribe(data => {
        this.filterService.requestBreadcrumbString();
      }),
      this.filterService.filterBreadcrumbsReturned$.subscribe(breadcrumbs => {
        this.filterBreadcrumbsForExport = breadcrumbs;
      })
    );

    //validate metrics configured exist in dataset
    const missingMetrics = this.settings.metrics.filter(metric => !this.dataSet.columns.find(col => col.name === metric.name));
    console.log('missing metrics', missingMetrics);

    this.mapRowsToFunnelRows();
  }

  get headers() {
    return this.settings.metrics
      .map(metric => this.dataSet.columns.find(col => col.name === metric.name).displayName);
  }

  getSegmentsForDisplay(row: FunnelRow): {
    segment: Segment,
    value: string,
    classes: string[],
    path?: string,
    pathClass: string
  }[] {
    return row.segments.map((s, idx) => ({
      segment: s,
      value: s.value,
      classes: [
        'box',
        'box-' + (s.color || 'gray'),
        'box' + (idx + 1)
      ],
      path: idx != row.segments.length - 1 ? this.calculatePath(s, idx) : undefined,
      pathClass: `box-${s.color || 'gray'}`
    }));
  }

  calculatePath = (segment: Segment, idx: number): string => `M0,${idx * 5} L25,${5 + (idx * 5)} L25,${95 - (idx * 5)} L0,${100 - (idx * 5)}z`;

  private mapRowsToFunnelRows() {
    //console.log('dataset', this.dataSet);
    if(this.dataSet?.rows?.length === 0) return;

    // build indexes for each segment
    const indexes = this.settings.metrics.map(metric => {
      const index = this.dataSet.columns.findIndex(col => col.name === metric.name);
      const popIndex = this.dataSet.columns.findIndex(col => col.name === metric.pop);
      return { index, popIndex };
    });

    this.funnelRows = this.dataSet.rows.map(row => {
      // first element is always segment name
      const segmentName = (row[0].label || row[0].value)?.toString();
      const segments : Segment[] = indexes.map(i => {
        const value = row[i.index];
        const label = (value?.label || value?.value)?.toString()
        const pop = row[i.popIndex]?.value ?? 0;
        //console.log('pop', pop);
        return new Segment(
          label,
          label,
          null,
          null,
          value?.value > pop ? 'green' : 'red'
        );
      });

      return new FunnelRow(segmentName, segments);
    });
  }
}
