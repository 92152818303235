import { Component, OnInit, OnDestroy, TemplateRef, Type, ChangeDetectionStrategy } from "@angular/core";
import { trigger, state, style, transition, animate, group } from '@angular/animations';
import { Subject, Subscription } from 'rxjs';
import { FilterPanelValueService } from "../../../services/filter-panel-value.service";
import * as FilterPanelTypes from '../../../types/types';
import { FilterPanelRef } from "../../../types/filter-panel-ref";
import { FilterPanelCloseType, FilterPanelRenderMethods, FilterPanelStates } from "../../../types/constants";
import * as SvgResources from '../../../lib/svg.resources';

@Component({
    templateUrl: './filter-panel-host.component.html',
    styleUrls: ['./filter-panel-host.component.scss'],
    animations: [
        trigger('fadeInItems', [
            state('open', style({ opacity: 1 })),
            transition('void => *', [
                style({ opacity: 0 }),
                animate('100ms 50ms cubic-bezier(0.55, 0, 0.55, 0.2)')
            ])
        ]),
        trigger('transformMenu', [
            state('void', style({
                opacity: 0,
                transform: 'scale(0.4)', transformOrigin: 'top left'
            })),
            transition('void => open', group([
                animate('75ms 25ms linear', style({ transform: 'scale(1)', opacity: 1 })),
            ])),
            transition('* => void', animate('100ms 25ms linear', style({ opacity: 0, transform: 'scale(0)' })))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterPanelHostComponent implements OnInit, OnDestroy {
    protected _subscription = new Subscription();
    protected destroy$ = new Subject<boolean>();

    renderMethods = FilterPanelRenderMethods;

    renderMethod: FilterPanelRenderMethods.template
        | FilterPanelRenderMethods.component
        | FilterPanelRenderMethods.text = FilterPanelRenderMethods.component;

    context: FilterPanelTypes.IFilterPanelContext;
    content: FilterPanelTypes.FilterPanelContent;

    panelOpened = false;
    panelState: string = FilterPanelStates.closed;

    data: FilterPanelTypes.IFilterPanelConfig;
    title: string;

    applyButtonDisabled = true;

    svgIcons = SvgResources.svgIcons;
    smallCloseIcon = SvgResources.sizableSvgIcon(SvgResources.svgIconTypes.close, 3);
    mobileCloseIcon = SvgResources.sizableSvgIcon(SvgResources.svgIconTypes.close, 6);
    searchIcon = SvgResources.sizableSvgIcon(SvgResources.svgIconTypes.searchSvg, 4);

    panelClass: string;

    constructor(protected filterPanelRef: FilterPanelRef, protected valueService: FilterPanelValueService) { }

    ngOnInit() {
        this.content = this.filterPanelRef.content;
        this.data = this.filterPanelRef.config;
        this.title = this.data.panelTitle;
        this.panelClass = this.data.panelClass ? this.data.panelClass : null;
        this.panelState = 'showing';
        if (typeof this.content === 'string')
            this.renderMethod = FilterPanelRenderMethods.text
        else if (this.content instanceof TemplateRef) {
            this.renderMethod = FilterPanelRenderMethods.template;
        }
        this.context = {
            close: this.filterPanelRef.close.bind(this.filterPanelRef)
        };

        this._subscription.add(
            this.valueService.applyDisabled$
                .subscribe((isDisabled) => {
                    this.applyButtonDisabled = isDisabled;
                })
        );

        this._subscription.add(
            this.filterPanelRef.beforeClosedOnBackdropClick$
                .subscribe(() => {
                    this.valueService.deselectAllSelectedItems();
                })
        );
    }

    ngOnDestroy() {
        this.panelState = null;
        if (this._subscription)
            this._subscription.unsubscribe();

        this.destroy$.next(true);
        this.destroy$.complete();
    }

    closePanel() {
        this.valueService.deselectAllSelectedItems();
        const result: FilterPanelTypes.IFilterPanelResult = {
            type: FilterPanelCloseType.cancel,
            data: null,
            resultType: FilterPanelCloseType.cancel,
            category: this.data.category,
            target: this.data.target
        };
        this.context.close(result);
    }

    applySelected() {
        const result: FilterPanelTypes.IFilterPanelResult = {
            type: this.data.panelTitle, // FilterPanelCloseType.applied,
            data: this.valueService.getValues(),
            resultType: FilterPanelCloseType.applied,
            category: this.data.category,
            target: this.data.target
        };
        this.valueService.deselectAllSelectedItems();
        this.context.close(result);
    }
}
