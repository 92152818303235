import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ManagementStateService {
  // key is usually a component name and value is the string used for search input.
  private searchStrings = new Map<string, WritableSignal<string>>();

  setSearch(componentId: string, value: string): void {
    if (!this.searchStrings.has(componentId)) {
      this.searchStrings.set(componentId, signal(''));
    }
    this.searchStrings.get(componentId)?.set(value);
  }

  getSearchSignal(componentId: string): WritableSignal<string> {
    if (!this.searchStrings.has(componentId)) {
      this.searchStrings.set(componentId, signal(''));
    }
    return this.searchStrings.get(componentId)!;
  }
}
