<mat-card class="mat-elevation-z0" style="color: black;">
    <mat-card-content class="card-block target-card">
        <table>
            <tbody>
                <tr>
                    <td>
                        <b style="font-size: 0.9em;">
                            {{metricCurrent | formatter : metricFormatter}}
                        </b>
                    </td>
                </tr>
                <tr>
                  <td class="metric-row-2">
                      <ng-content select="[metric-row-2]"></ng-content>
                  </td>
              </tr>
            </tbody>
        </table>
    </mat-card-content>
    <mat-card-footer *ngIf="showFooter">
        <table>
            <tbody>
                <tr>
                    <ng-content select="[row-1-left]"></ng-content>
                    <ng-content select="[row-1-right]"></ng-content>
                </tr>
            </tbody>
        </table>
    </mat-card-footer>
</mat-card>
