import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { map } from 'rxjs/operators';
import { ChartService } from './chart.service';

@Component({
  selector: 'sd-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit {

  @Input() options: Observable<Highcharts.Options>;
  @Input() height: string = "100%";
  @Input() width: string = "100%";
  chart: Chart;
  subscriptions: Subscription[] = [];
  constructor(private chartService: ChartService) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.chartService.reflowChart$.subscribe(() => {
        setTimeout(() => {
          this.chart.ref.reflow();
        }, 200);
      }),
      this.getChart().subscribe()
    );
  }

  getChart() {
    return this.options.pipe(
      map(options => {
        var chart = new Chart(options)
        this.chart = chart;
        return chart;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
