import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from "@angular/core";
import { Subscription, fromEvent, forkJoin, of, EMPTY, zip } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import * as FilterPanelTypes from '../../types/types';
import { FilterPanelRef } from "../../types/filter-panel-ref";
import { FilterPanelValueService } from "../../services/filter-panel-value.service";
import { FilterPanelQueryService } from "../../services/filter-panel-query.service";
import * as SvgResources from '../../lib/svg.resources';

@Component({
    selector: 'filter-panel-list',
    templateUrl: './filter-panel-list.component.html',
    styleUrls: ['./filter-panel-list.component.scss']
})
export class FilterPanelListComponent implements OnInit, AfterViewInit, OnDestroy {
    private _subscription = new Subscription();

    panelData: FilterPanelTypes.ISimpleValue[] = [];
    filteredPanelData: FilterPanelTypes.ISimpleValue[] = [];
    isAsyncOp = false;

    selected: FilterPanelTypes.ISimpleValue[] = [];
    indeterminate = false;
    checkBoxHeaderLabel: string;
    // checkBoxHeaderLabelSuffix: string;

    searchTerm = '';
    searchHasFocus = false;
    showProgress = false;
    isLoading = false;

    rippleColor = 'rgba(0, 0, 0, .10)';

    svgIcons = SvgResources.svgIcons;
    closeIcon = SvgResources.sizableSvgIcon(SvgResources.svgIconTypes.close, 4);

    compareOptions = (val1: FilterPanelTypes.ISimpleValue, val2: FilterPanelTypes.ISimpleValue) => val1.value === val2.value;

    @ViewChild('panelDataSelectList', { static: true }) panelDataSelectList: MatSelectionList;
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

    constructor(private filterPanelRef: FilterPanelRef, private valueService: FilterPanelValueService, private queryService: FilterPanelQueryService, private cdRef: ChangeDetectorRef) { }

    ngOnInit() {
        if (this.filterPanelRef.config.query) {
            this.isAsyncOp = true;
            // if (!this.filterPanelRef.config.currentFilterValues)
            this.isLoading = true;
            const queryResult$ = this.queryService.getPanelInputData(this.filterPanelRef.config.query, this.filterPanelRef.config.queryTarget)
            const panelData$ = this.filterPanelRef.config.inputData ?? of({} as FilterPanelTypes.IPanelInputData);
            // const zippedData = zip([queryResult$, panelData$]);

            this._subscription.add(
              queryResult$.pipe(withLatestFrom(panelData$))
                  .subscribe(([queryResult, panelData]) => {
                      const mapped: FilterPanelTypes.ISimpleValue[] = [];

                      const pd = <FilterPanelTypes.IPanelInputData>panelData;
                      if (pd && pd.inputData) {
                          mapped.push(...(pd.inputData as any[]).map(r => ({ value: r.value, display: r.display })));
                      }

                      mapped.push(...queryResult.map((r: { id: string; name: string; }) => ({ value: r.id, display: r.name })));
                      this.initPanelData(mapped);
                  })
          )

        } else {
            this._subscription.add(
                this.filterPanelRef.config.inputData
                    .subscribe(inputData => {
                        this.initPanelData(<FilterPanelTypes.ISimpleValue[]>inputData.inputData);
                    })
            );
        }

        this._subscription.add(
            this.valueService.selectedItems$
                .subscribe((selectedItems) => {
                    this.selected = [];
                    this.selected.push(...selectedItems);
                    if (this.selected.length <= 0)
                        this.clearSelectList();
                })
        );

        this._subscription.add(
            fromEvent(this.searchInput.nativeElement, 'keyup')
                .pipe(
                    map((event: any) => {
                        this.showProgress = true;
                        return event;
                    }),
                    filter(event => {
                        const isValidKey = this.isValidKey(event);
                        if (!isValidKey) {
                            this.showProgress = false;
                            return false;
                        }
                        return true;
                    }),
                    debounceTime(400),
                    distinctUntilChanged()
                ).subscribe((event: any) => {
                    this.searchTerm = event.target.value;
                    this.filterSelectList();
                    this.showProgress = false;
                })
          );
    }

    ngOnDestroy() {
        if (this._subscription)
            this._subscription.unsubscribe();
    }

    ngAfterViewInit() {
        if (!this.isAsyncOp) {
            const currentFilterValues = this.filterPanelRef.config.currentFilterValues;
            if (currentFilterValues && currentFilterValues.inputData) {
                this.setSelectionState(<FilterPanelTypes.ISimpleValue[]>this.filterPanelRef.config.currentFilterValues.inputData);
            }
        }
    }

    isIndeterminate(): boolean {
        if (this.panelDataSelectList)
            return this.panelDataSelectList.selectedOptions.selected.length !== this.filteredPanelData.length && this.panelDataSelectList.selectedOptions.selected.length >= 1;

        return false;
    }

    isChecked(): boolean {
        if (this.panelDataSelectList)
            return this.panelDataSelectList.selectedOptions.selected.length === this.filteredPanelData.length;

        return false;
    }

    onSelectListChanged(options: MatListOption[]): void {
        this.valueService.setSelectedItems(options);
    }

    clearAllSelected(): void {
        this.deselectAll();
    }

    clearSearchTerm($event): void {
        this.searchTerm = '';
        this.filterSelectList();
    }

    filterDataOptionChanged(value) {
        const existingFilterItem = this.selected.find(s => s.value === value.value);
        if (existingFilterItem) {
            this.removeSelectedFilter(existingFilterItem);
        } else {
            this.valueService.addSelectedItem(value);
        }
    }

    filterSelectList() {
        this.filteredPanelData = [];
        this.panelData.forEach((valueModel) => {
            if (valueModel.display.toString().toLowerCase().includes(this.searchTerm.toLowerCase())) {
                this.filteredPanelData.push(valueModel);
            }
        });
        setTimeout(() => {
            this.selected.forEach((value) => {
                const scopedOption = this.panelDataSelectList.options.filter(o => o.value.value === value.value)[0];
                if (scopedOption) {
                    scopedOption.selected = true;
                }
            });
        }, 1);

        this.setHeaderCountLabel();

        this.cdRef.detectChanges();
    }

    removeSelectedFilter(filterValue) {
        this.valueService.removeSelectedItem(filterValue);
    }

    handleRemoveSelectedFilter(filterValue: FilterPanelTypes.ISimpleValue): void {
        const scopedOption = this.panelDataSelectList.selectedOptions.selected.filter(o => o.value.value === filterValue.value)[0];
        scopedOption.selected = false;
        this.removeSelectedFilter(filterValue);
    }

    headerCheckboxChanged($event: any): void { // MatCheckboxChange
        if ($event.checked) {
            this.addUnselectedItemsToSelectedList();
            this.panelDataSelectList.selectAll();
        } else {
            if (this.panelData.length === this.filteredPanelData.length) {
                // we are not filtered so de-select all
                this.deselectAll();
            } else {
                this.filteredPanelData.forEach((value) => {
                    // TODO: Not unselecting in main list!!!
                    this.removeSelectedFilter(value);
                })
            }

        }

    }

    private initPanelData(mapped: FilterPanelTypes.ISimpleValue[]): void {
        this.filteredPanelData.push(...mapped);
        this.panelData.push(...mapped);
        this.setHeaderCountLabel();

        if (this.isAsyncOp) {
            const currentFilterValues = this.filterPanelRef.config.currentFilterValues;
            if (currentFilterValues && currentFilterValues.inputData.length) {
                this.isLoading = false;
                this.setSelectionState(<FilterPanelTypes.ISimpleValue[]>currentFilterValues.inputData);
            }
        }

        this.isLoading = false;
        this.cdRef.detectChanges();
    }

    private setHeaderCountLabel(): void {
        const checkBoxHeaderLabelSuffix = this.filteredPanelData.length > 1 ? 's' : '';
        this.checkBoxHeaderLabel = `${this.filteredPanelData.length} ${this.filterPanelRef.config.topic}${checkBoxHeaderLabelSuffix}`;
    }

    private deselectAll() {
        this.valueService.deselectAllSelectedItems();
    }

    private clearSelectList() {
        if (this.panelDataSelectList && this.panelDataSelectList.selectedOptions.selected.length)
            this.panelDataSelectList.deselectAll();
    }

    private addUnselectedItemsToSelectedList(): void {
        this.filteredPanelData.forEach((item) => {
            const selectedItem = this.selected.filter(s => s.value === item.value)[0];
            if (!selectedItem) {
                this.valueService.addSelectedItem(item);
            }
        });
    }

    private isValidKey(event: KeyboardEvent): boolean {
        return event.keyCode !== 13
            && event.keyCode !== 27
            && event.code !== 'Enter'
            && event.code !== 'Escape'
            && event.key !== 'Enter'
            && event.key !== 'Escape';
    }

    private setSelectionState(currentFilterValues: FilterPanelTypes.ISimpleValue[]) {
        this.valueService.setSelectedItems(currentFilterValues);

        setTimeout(() => {
            this.panelDataSelectList.options.forEach((option, idx) => {
                if (idx > 5)
                    return false;
            })
            currentFilterValues.forEach((value) => {
                const option = this.panelDataSelectList.options.find(o => o.value.value === value.value);
                if (option) {
                    option.selected = true;
                }
            });
        }, 1);
    }
}
