<div [ngClass]="[expanded ? 'fill-canvas' : '', 'sd-infinite-scroll-xy', 'first-column-pinned']">
  <table mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let column of columns; trackBy: customTrackBy" [matColumnDef]="column.columnDef" [sticky]="column.columnDef === 'entity'" [stickyEnd]="column.stickyEnd">
      <div *ngIf="column.columnDef === 'entity'" [ngClass]="calculateClasses(column)">
        <th mat-header-cell *matHeaderCellDef [ngClass]="calculateClasses(column, ['mat-table-sticky-border-elem-left'])"> {{column.header}} </th>
        <td td mat-cell *matCellDef="let row" [ngClass]="calculateClasses(column, [row.entityType, 'sticky-column', 'data-table-header'])">
          <i *ngIf="(row.expandable)" (click)="toggleChildRows(row)" [ngClass]="[row.showChildren ? 'fa-caret-down' : 'fa-caret-right', 'fa']" style="cursor: pointer;">&nbsp;</i>
          &nbsp;
          <ng-container *ngIf="!column.canDrillthrough || (column.canDrillthrough && !column.canDrillthrough(row))" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ row['displayName'] | translate: locale  | async | formatter : column.formatter }}
          </ng-container>
          <a *ngIf="column.canDrillthrough && column.canDrillthrough(row)" (click)="updateReport(row)">
            {{ row['displayName'] | translate: locale  | async | formatter : column.formatter }}
          </a>
        </td>
      </div>
      <div *ngIf="(column.columnDef !== 'entity')" [ngClass]="calculateClasses(column)">
        <th mat-header-cell *matHeaderCellDef [ngClass]="calculateClasses(column, ['first-data-column', 'data-table-header'])"> {{column.header}} </th>
        <td td mat-cell *matCellDef="let row" [ngClass]="calculateClasses(column, ['first-data-column'])">
          {{ row[column.columnDef] | formatter : column.formatter }}
        </td>
      </div>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="data-table-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="[!row.show ? 'hideRow' : '', (row.entityType + '-row')]"></tr>
  </table>
</div>
