import { Component, ComponentRef, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as SvgResources from '../../../../lib/svg.resources';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FacetedFilterPanelSearch } from '../../../../services/faceted-filter-panel-search.service';
import { FilterPanelValueService } from '../../../../services/filter-panel-value.service';
import { FilterPanelRef, FilterPanelTypes } from '../../../../types';
import { FilterPanelCloseType, FilterPanelDisplayContext } from '../../../../types/constants';
import { IFacetedFilterDataItem, IFacetedFilterSelection } from '../../../../types/faceted-filter.types';
import { PaneInstanceManager, PaneInstanceScrollDirection } from '../../../../Utils/pane-instance.manager';
import { FilterPanelHostComponent } from '../../default/filter-panel-host.component';

@Component({
	templateUrl: './faceted-filter-panel-host.component.html',
	styleUrls: ['./faceted-filter-panel-host.component.scss'],
})
export class FacetedFilterPanelHostComponent extends FilterPanelHostComponent implements OnInit, OnDestroy {
	private _panelWidth = 230;
	filterDataItems: IFacetedFilterDataItem[];
	// filterDataInjector: Injector;

	searchField = new FormControl();
	mobileSearchEnabled = false;
	displayContext: FilterPanelDisplayContext;

	panelTitle: string;

	facetedFilterApplyButtonDisabled = true;

	backIcon = SvgResources.sizableSvgIcon(SvgResources.svgIconTypes.chevronDblLeft, 6);
	searchIcon = SvgResources.sizableSvgIcon(SvgResources.svgIconTypes.searchSvg, 6);
	chevronIcon = SvgResources.sizableSvgIcon(SvgResources.svgIconTypes.chevronRight, 4);

	scrollDirection = PaneInstanceScrollDirection;

	activeLocations$: Observable<ComponentRef<any>[]>;

	@ViewChild('rootContent') rootContent: ElementRef;

  	constructor(protected filterPanelRef: FilterPanelRef,
				protected valueService: FilterPanelValueService,
				private parentInjector: Injector,
				private searchService: FacetedFilterPanelSearch) {

		super(filterPanelRef, valueService);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.activeLocations$ = PaneInstanceManager.activePaneLocations$;

		this.filterPanelRef.config.inputData.pipe(
			takeUntil(this.destroy$)
		).subscribe((data) => {
			this.filterDataItems = [...data.inputData as IFacetedFilterDataItem[]];
		})

		this._subscription.add(
            this.valueService.facetedFilterApplyDisabled$
                .subscribe((isDisabled) => {
                    this.facetedFilterApplyButtonDisabled = isDisabled;
                })
		);

		this.searchService.searchState$.pipe(
			takeUntil(this.destroy$)
		).subscribe((state) => {
			if (state.searchCleared)
				this.searchField.patchValue(null);
			this.mobileSearchEnabled = state.isSearching;
		})

		this.searchField.valueChanges.pipe(
			debounceTime(500),
			filter(term => term !== null),
			takeUntil(this.destroy$),
			// distinctUntilChanged(),
			startWith(''),
			switchMap(term => this.searchService.searchData(this.filterDataItems, term))
		).subscribe();
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	startMobileSearch(): void {
		this.searchService.startMobileSearch();
		this.mobileSearchEnabled = true;
	}

	clearSearch(): void {
		this.searchField.patchValue('');
		this.searchService.clearSearch();
	}

	closeFacetedFilterPanel() {
		this.valueService.clearSelectionInfo();
		this.searchService.clearSearch();
		const result: FilterPanelTypes.IFilterPanelResult = {
            type: FilterPanelCloseType.cancel,
            data: null,
            resultType: FilterPanelCloseType.cancel,
            category: null,
            target: null
		};
		PaneInstanceManager.purgeActiveInstances();
        this.context.close(result);
    }

	facetedFilterApplySelected() {
		const selection: IFacetedFilterSelection = this.valueService.getSelectionResult();
		this.valueService.clearSelectionInfo();
		this.searchService.clearSearch();
		const result: FilterPanelTypes.IFilterPanelResult = {
            type: null, // FilterPanelCloseType.applied,
            data: [selection],
            resultType: FilterPanelCloseType.applied,
            category: null,
            target: null
		};
		PaneInstanceManager.purgeActiveInstances();
        this.context.close(result);
	}

	onBreadcrumbItemClick(item: ComponentRef<any>): void {
		item.location.nativeElement.previousElementSibling.scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
	}

	onPaneNavigationClick(direction: PaneInstanceScrollDirection): void {
		PaneInstanceManager.mobileScrollTo(direction, this.rootContent);
	}

	// private createFacetedFilterInjector(): void {
	// 	this.filterDataInjector = Injector.create({
	// 		providers: [{ provide: FACETED_FILTER_DATA_TOKEN, useValue: this.filterData }],
	// 		parent: this.parentInjector
	// 	});
	// }
}
