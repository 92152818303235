
<ng-container *ngIf="item && item.items; else noData">

    <div class="pane-wrapper">

        <div class="pane scrollable-y scrollbar-thin scrollbar-thumb-primary-200 scrollbar-thumb-rounded-md scrollbar-track-primary-50 hover:scrollbar-thumb-primary-200">

            <div *ngIf="canSelectAll" class="w-full h-10 flex items-center pxl-075 text-gray-900 bg-white hover:bg-gray-50 active:bg-gray-200 cursor-pointer border-b border-gray-300">

                <mat-checkbox [indeterminate]="isIndeterminate()" [checked]="selectAllIsChecked()"
                    (change)="selectAllCheckboxChanged($event)" labelPosition="right"><span class="text-base md:text-sm text-gray-900 font-medium ml-2">Select all</span>
                </mat-checkbox>

            </div>

            <!-- <ng-container *ngIf="itemsList && itemsList.length">

                <div *ngFor="let item of itemsList"
                        class="panel-option w-full h-10 flex items-center px-3 py-3 text-gray-900 font-medium cursor-pointer border-b border-gray-300"
                        [ngClass]="{'active': selectedItem?.label === item.label}"
                        (click)="containerItemSelected(item);">

                    <div class="flex-1">{{item.label}}</div>

                    <continuation-indicator [isActive]="selectedItem?.label === item.label"></continuation-indicator>

                </div>

            </ng-container> -->

            <ng-container *ngIf="hierarchyContainerItemsList && hierarchyContainerItemsList.length">

                <mat-selection-list class="filter-item-select-list" #selectList [(ngModel)]="selectedHierarchyContainerItems" (selectionChange)="hierarchyContainerItemsChanged($event)">

                    <mat-list-option checkboxPosition="before" *ngFor="let item of hierarchyContainerItemsList; let idx = index; let first = first; let last = last;" [value]="item"
                                     class="select-list-item">

                            <div [id]="'c_' + idx" class="w-full h-10 flex items-center pr-3 text-gray-900 font-medium cursor-pointer">

                                <div class="flex-1 truncate" [title]="item.label">{{item.label}}</div>

                                <continuation-indicator *ngIf="item.items?.length && canSelectChildren" [isActive]="selectedHierarchyContainerItems.includes(item)"></continuation-indicator>

                            </div>

                    </mat-list-option>

                </mat-selection-list>

            </ng-container>

            <ng-container *ngIf="hierarchyFacetItemsList && hierarchyFacetItemsList.length">

                <mat-selection-list class="filter-item-select-list" #selectList [(ngModel)]="selectedHierarchyFacetItems" (selectionChange)="hierarchyFacetItemsChanged($event)">

                    <mat-list-option checkboxPosition="before" *ngFor="let item of hierarchyFacetItemsList; let idx = index; let first = first; let last = last;" [value]="item"
                                     class="select-list-item">

                            <div [id]="'f_' + idx" class="flex-1 truncate text-base md:text-sm text-gray-900 font-medium" [title]="item.label">{{item.label}}</div>

                            <!-- <continuation-indicator [isActive]="selectedFacetOption && selectedFacetOption.label === facet.label"
                                                    [facet]="facet"
                                                    [parentData]="facetData">
                            </continuation-indicator> -->

                    </mat-list-option>

                </mat-selection-list>

            </ng-container>

        </div>

    </div>

</ng-container>

<ng-template #noData>

    <div class="w-full h-full flex items-center justify-items-center">
        No Facet Data!!
    </div>

</ng-template>
