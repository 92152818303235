import { Injectable } from '@angular/core';
import { MonacoEditorLoaderService } from '@materia-ui/ngx-monaco-editor';
import { filter, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class MonacoConfigService {
    private isConfigured = false;
    private schemas: any[] = [];

    constructor(private monacoLoaderService: MonacoEditorLoaderService) { }

    configureMonaco() {
        if (this.isConfigured) {
            return;
        }

        this.monacoLoaderService.isMonacoLoaded$
            .pipe(
                filter(isLoaded => isLoaded),
                take(1)
            )
            .subscribe(() => {
                monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                    validate: true,
                    schemas: this.schemas
                });
                this.isConfigured = true;
            });
    }

    addSchema(schema: any) {
        this.schemas.push(schema);
        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
            validate: true,
            schemas: this.schemas
        });
    }
}
