import { Component, OnInit, Input } from '@angular/core';
import { Enums } from '../../enums/enums';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';


@Component({
  selector: 'sd-activity-guage-chart',
  templateUrl: './activity-guage-chart.component.html',
  styleUrls: ['./activity-guage-chart.component.scss'],
})

export class ActivityGuageChartComponent implements OnInit {
    @Input() chartSize: number;
    @Input() chartheight: string;
    @Input() report: string;
    @Input() chartTitle: string;
    @Input() series1Name: string;
    @Input() series1Data: number;
    @Input() series1Color: string;
    @Input() series2Name: string;
    @Input() series2Data: number;
    @Input() series2Color: string;
    @Input() series3Name: string;
    @Input() series3Data: number;
    @Input() series3Color: string;
    @Input() seriesBackgroundOpacity: number;
    currentChart: any;
    trendArrow: string;
    private enums = Enums;
    private defaultBackgroundColor = '#ffffff';
    private series1OuterRadius = '110%';
    private series1InnerRadius = '80%';
    private series2OuterRadius = '82%';
    private series2InnerRadius = '62%';
    private series3OuterRadius = '62%';
    private series3InnerRadius = '42%';
    private defaultWidth: 100; // width drives the size of the circle
    constructor() { }

    ngOnInit() {
        let chartOptions: Highcharts.ChartOptions;
        let titleOptions: Highcharts.TitleOptions;
        let dataLabels: Highcharts.PlotSolidgaugeDataLabelsOptions;

        if (this.report === this.enums.vehicleEntityTypes.class.type) {
            chartOptions = {
                margin: 0,
                type: 'solidgauge',
                backgroundColor: '#fff',
                height: '100%',
                width: this.chartSize,
                style: {
                    fontFamily: 'Roboto, "Helvetica Neue", sans-serif'
                },
            };
            titleOptions = {
                text: ''
            };
            dataLabels = {
                enabled: true,
                borderWidth: 0,
                x: 0,
                y: -14, // x and y are necessary to center label for using custom font
                style: {
                    fontSize: '18px',
                    fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
                    fontWeight: 'bold'
                },
                formatter: function() {
                    return  this.y + '%';
                },
            };
        } else {
            chartOptions = {
                type: 'solidgauge',
                backgroundColor: '#fff',
                height: '100%',
                width: 100,
                style: {
                    fontFamily: 'Roboto, "Helvetica Neue", sans-serif'
                }
            };
            titleOptions = {
                text:  ''
            };
            dataLabels = {
                enabled: true,
                borderWidth: 0,
                x: 0,
                y: -12, // x and y are necessary to center label for using custom font
                style: {
                    fontSize: '12px',
                    fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
                    fontWeight: 'bold'
                },
                formatter: function() {
                    return  this.y + '%';
                },
            };
        }

        this.updateChart(chartOptions, titleOptions, dataLabels);
    }

    hexToRGB(hex, alpha) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
        } else {
            return 'rgb(' + r + ', ' + g + ', ' + b + ')';
        }
    }

    updateChart(chartOptions: Highcharts.ChartOptions, titleOptions: Highcharts.TitleOptions, dataLabels: Highcharts.PlotSolidgaugeDataLabelsOptions) {
        this.currentChart = new Chart({
            chart: chartOptions,
            title: titleOptions,
            credits: {
                enabled: false
            },
            tooltip: {
                enabled: false,
                borderWidth: 2,
                shape: 'circle',
                shadow: false,
                style: {
                    fontSize: '12px'

                },
                padding: 12,
                valueDecimals: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.85)',
                valueSuffix: '%',
                pointFormat: '{series.name}<br><span style="font-size:16px; color: {point.color}; font-weight: bold">{point.y}</span>',
                positioner: function (labelWidth, labelHeight) {
                    return {
                        x: 1,
                        y: 1
                    };
                },
            },
            pane: {
                startAngle: 0,
                endAngle: 360,
                background: [{
                    borderWidth: 0,
                    outerRadius: this.series1OuterRadius,
                    innerRadius: this.series1InnerRadius,
                    // backgroundColor: this.hexToRGB((this.series1Color || this.defaultBackgroundColor), (this.seriesBackgroundOpacity || .45)),
                    backgroundColor: '#EEE'
                }]
            },
            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: [],
                labels: {
                    style: {
                        fontWeight: 'bold',
                        color: 'black'
                    }
                }
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: dataLabels,
                    linecap: 'square',
                    lineWidth: 12,
                    stickyTracking: false,
                }
            },
            series: [
                this.getGaugeSeries(
                  this.series1Name,
                  this.series1Color,
                  this.series1OuterRadius,
                  this.series1InnerRadius,
                  this.series1Data
                ),
                this.getGaugeSeries(
                  this.series2Name,
                  this.series2Color,
                  this.series2OuterRadius,
                  this.series2InnerRadius,
                  this.series2Data
                ),
                this.getGaugeSeries(
                    this.series3Name,
                    this.series3Color,
                    this.series3OuterRadius,
                    this.series3InnerRadius,
                    this.series3Data)
            ]
        });
    }


  private getGaugeSeries(name: string, color: string, outerRadius: string, innerRadius: string, yValue: number): Highcharts.SeriesOptionsType {
    return <Highcharts.SeriesGaugeOptions>{
      name: name,
      data: [{
        color: color,
        radius: outerRadius,
        innerRadius: innerRadius,
        y: yValue,
      }]
    };
  }
}
