import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { Subject } from 'rxjs';
import { FacetedFilterFacetItemType } from '../../../../types/constants';
import { IFacetedFilterDataItem, ISelectionInfo, ISelectedFacetInfo } from '../../../../types/faceted-filter.types';
import { TypeCheck } from '../../../../Utils/type-check';

@Component({
	template: ``
})
export class BasePaneComponent implements OnInit, OnDestroy {

	destroy$ = new Subject<boolean>();

	isMultiSelect = false;

	selectedItem: IFacetedFilterDataItem;
	selectedCheckableItems: IFacetedFilterDataItem[] = [];

	itemsList: IFacetedFilterDataItem[] = [];
	checkableItemsList: IFacetedFilterDataItem[] = [];

	@Input() item: IFacetedFilterDataItem;
	@Input() activeIndex: number;

	@Output() onItemSelected = new EventEmitter<ISelectionInfo>();
	@Output() onContainerItemSelected = new EventEmitter<IFacetedFilterDataItem>();
	@Output() onCheckableItemsChanged = new EventEmitter<ISelectionInfo>();
	@Output() onOperatorExpressionSelected = new EventEmitter<ISelectionInfo>();
	@Output() onDateRangeSelected = new EventEmitter<ISelectionInfo>();

	ngOnInit(): void {
		this.manageSelectionList();
		this.determineSelectMode();
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	itemSelected($event: IFacetedFilterDataItem): void {
		this.selectedItem = $event;
		this.selectedCheckableItems = [];
		const selection: ISelectedFacetInfo = {
			facets: [$event],
			item: this.item
		}
		this.onItemSelected.emit(selection);
	}

	containerItemSelected($event: IFacetedFilterDataItem): void {
		this.selectedItem = $event;
		this.selectedCheckableItems = [];
		this.onContainerItemSelected.emit($event);
	}

	checkableItemsChanged($event: MatSelectionListChange): void {
		if (this.isMultiSelect) {
			this.handleMultiSelectItems();
		} else {
			//console.log('base-pane selectedOption change');
			const selectedOption = $event.options[0].value as IFacetedFilterDataItem;
			// const selectedOption = $event.options.value as IFacetedFilterDataItem;
			this.handleSingleSelectItem(selectedOption);
		}
	}

	protected handleMultiSelectItems(): void {
		const selection: ISelectedFacetInfo = {
			facets: [...this.selectedCheckableItems],
			item: this.item
		};
		this.onCheckableItemsChanged.emit(selection);
	}

	protected handleSingleSelectItem(selectedOption: IFacetedFilterDataItem): void {
		this.selectedItem = undefined;
		this.selectedCheckableItems = [selectedOption];

		const selection: ISelectedFacetInfo = {
			facets: [selectedOption],
			item: selectedOption
		};
		if (selection) {
			this.onCheckableItemsChanged.emit(selection);
		} else {
			this.onContainerItemSelected.emit(selectedOption);
		}
	}

	isIndeterminate(): boolean {
		if (this.selectedCheckableItems?.length)
			return this.selectedCheckableItems.length !== this.checkableItemsList.length
				&& this.selectedCheckableItems.length >= 1;

		return false;
	}

	selectAllIsChecked(): boolean {
		if (this.selectedCheckableItems?.length)
			return this.selectedCheckableItems.length === this.checkableItemsList.length;

		return false;
	}

	selectAllCheckboxChanged($event: any): void { // MatCheckboxChange
		this.selectedCheckableItems = [];
		if ($event.checked) {
			this.selectedCheckableItems = [...this.checkableItemsList];
		}
		const selection: ISelectionInfo = {
			facets: [...this.selectedCheckableItems],
			item: this.item
		};
		this.onCheckableItemsChanged.emit(selection);
	}

	protected determineSelectMode(): void {
		this.isMultiSelect = this.item && this.item.items?.filter(i => i.type !== FacetedFilterFacetItemType.Facet).length <= 0;
	}

	protected manageSelectionList(): void {
		if (this.item && this.item.items?.length) {
			this.checkableItemsList = this.item.items?.filter(i => TypeCheck.isFacet(i.type));
			this.itemsList = this.item.items?.filter(i => !TypeCheck.isFacet(i.type));
		}
	}
}
