import {
  IDefaultTableColumnConfig,
  IDigAdSummaryConfig,
  IDualAxisLineGraphConfig,
  ICardHeaderConfig,
  INameValuePair
} from '../../../../_shared/models/models-index';

import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class DigAdSummaryConfig implements IDigAdSummaryConfig {
  reportTitle: string = 'Digital Advertising Summary';
  public useDefaultComparisonColumns: boolean = true;
  public benchmarkCardHeader: ICardHeaderConfig = {
    title: 'Summary Data',
    iconClass: 'fa-file',
    helpTextKey: constants.helpTextKeys.digAdSummaryHelp,
    helpTextTitle: 'Digital Advertising Summary',
    exportName: 'Digital Advertising Summary - Summary Data',
    metricDisplayModes: ['MOM', 'YOY', 'Trend']
  };

  public graphConfig: IDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    availableMetrics: [
      {
        id: Enums.digitalAdvertisingMetrics.impressions.metricId,
        displayName: Enums.digitalAdvertisingMetrics.impressions.name,
        propertyName: Enums.digitalAdvertisingMetrics.impressions.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.clicks.metricId,
        displayName: Enums.digitalAdvertisingMetrics.clicks.name,
        propertyName: Enums.digitalAdvertisingMetrics.clicks.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.clickThroughRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.clickThroughRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.clickThroughRate.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.actualCostPerClick.metricId,
        displayName: Enums.digitalAdvertisingMetrics.actualCostPerClick.name,
        propertyName: 'costPerClick',
        metricFormatKey: constants.formatKeys.currency
      },
      {
        id: Enums.digitalAdvertisingMetrics.actualCostPerImpression.metricId,
        displayName: Enums.digitalAdvertisingMetrics.actualCostPerImpression.name,
        propertyName: 'costPerImpression',
        metricFormatKey: constants.formatKeys.currency
      },
      {
        id: Enums.digitalAdvertisingMetrics.totalVisits.metricId,
        displayName: Enums.digitalAdvertisingMetrics.totalVisits.name,
        propertyName: 'visits',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.uniqueVisitors.metricId,
        displayName: 'Unique Visits',
        propertyName: Enums.digitalAdvertisingMetrics.uniqueVisitors.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalAdvertisingMetrics.formSubmissions.metricId,
        displayName: Enums.digitalAdvertisingMetrics.formSubmissions.name,
        propertyName: Enums.digitalAdvertisingMetrics.formSubmissions.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.phoneCalls.metricId,
        displayName: Enums.digitalAdvertisingMetrics.phoneCalls.name,
        propertyName: 'phoneLeads',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalAdvertisingMetrics.conversionRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.conversionRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.conversionRate.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalAdvertisingMetrics.bounceRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.bounceRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.bounceRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
    ],
    defaultMetricOneId: Enums.digitalAdvertisingMetrics.impressions.metricId,
    defaultMetricTwoId: Enums.digitalAdvertisingMetrics.clicks.metricId,
    metricOneColorHexCode: '#b00',
    metricTwoColorHexCode: '#5f0003'
  };

  public trendTableMetrics: INameValuePair[] = [
    {name: 'Impressions', value: 'impressions'},
    {name: 'Clicks', value: 'clicks'},
    {name: 'Click Through Rate', value: 'clickThroughRate'},
    {name: 'Cost Per Click', value: 'costPerClick'},
    {name: 'Cost Per Impression', value: 'costPerImpression'},
    {name: 'Total Visits', value: 'visits'},
    {name: 'Unique Visits', value: 'uniqueVisitors'},
    {name: 'Form Submissions', value: 'formSubmissions'},
    {name: 'Phone Calls', value: 'phoneLeads'},
    {name: 'Conversion Rate', value: 'conversionRate'},
    {name: 'Bounce Rate', value: 'bounceRate'}
  ];

  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Retailer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
    },
    {
      show: true,
      header: 'Impressions',
      columnDef: 'impressions',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Clicks',
      columnDef: 'clicks',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Click Through Rate',
      columnDef: 'clickThroughRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: true,
      header: 'Total Visits',
      columnDef: 'visits',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Unique Visits',
      columnDef: 'uniqueVisitors',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Cost Per Click',
      columnDef: 'costPerClick',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.currency
    },

    {
      show: true,
      header: 'Cost Per Impression',
      columnDef: 'costPerImpression',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.currency
    },
    {
      show: true,
      header: 'Form Submissions',
      columnDef: 'formSubmissions',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Phone Calls',
      columnDef: 'phoneLeads',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: true,
      header: 'Conversion Rate',
      columnDef: 'conversionRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: true,
      header: 'Bounce Rate',
      columnDef: 'bounceRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
  ];
}
