import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Enums } from '../enums/enums';
import { Chart } from 'angular-highcharts';
import * as Models from '../models/models-index';
import * as Constants from '../constants/constants';

@Component({
  selector: 'sd-overview-linechart-billboard',
  templateUrl: './overviewBillboardLineChart.component.html',
  styleUrls: ['./overviewBillboardLineChart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewBillboardLineChartComponent implements OnInit {

    @Input() chartDataSource: any[];
    @Input() title: string;
    @Input() footerLabel: string;
    @Input() subtitle: string;
    @Input() iconClass: string;
    @Input() metricCurrentMonth: number;
    @Input() metricPreviousMonth: number;
    @Input() metricFormatKey: string;
    @Input() graphMetricFormatKey: string;
    @Input() metricMOM: number;
    @Input() reverseMetric: boolean;
    @Input() metricPropertyName: string;
    @Input() metricPropertyId: number;
    @Input() trendArrowIconClass: string;
    @Output() openHelpText = new EventEmitter();
    private enums = Enums;
    currentChart: Chart;
    constructor(
    ) { }

    ngOnInit() {
        this.currentChart = this.updateChart(this.chartDataSource, this.graphMetricFormatKey);
    }

    updateChart(dataSource: any[], formatKey: string) {
        let seriesData: Highcharts.SeriesAreaOptions[];
        let yAxisOptions: Highcharts.AxisOptions;
        let tooltipOptions: Highcharts.TooltipOptions;

        const chartMetrics = this.mapGraphMetrics(dataSource);

        seriesData = [{
            name: this.title,
            type: 'area',
            yAxis: 0,
            data: chartMetrics.metrics[0].data,
            color: this.enums.chartColors.color1
        }];
        tooltipOptions = {
            shadow: false,
            borderWidth: 1,
            formatter: this.formatTooltip(chartMetrics, formatKey),
            shared: true
        };

        yAxisOptions = {
            minorTickLength: 0,
            tickLength: 0,
            gridLineColor: 'transparent',
            lineColor: 'transparent',
            lineWidth: 0,
            labels: {
                enabled: false
            },
            title: {
            text: null,
                style: {
                    color: '#000',
                    fontWeight: 'normal',
                    fontSize: '12px',
                    fontFamily: 'Roboto, "Helvetica Neue", sans-serif'
                }
            }
        };

        return this.generateChart(chartMetrics.dates, yAxisOptions, seriesData, tooltipOptions);
    }

    mapGraphMetrics(dataSource: any): Models.ITrendGraph {
        const iterationDates =  dataSource.map(d => d.date).sort();
        const chartDates = [];

        iterationDates.forEach(i  => {
            chartDates.push(new Date(i));
        });

        const graphMetrics: Models.ITrendGraphData[] = [
            { metricId: this.metricPropertyId, data: [] },
        ];

        iterationDates.forEach(dt => {
            const currentDateMetrics = dataSource.find(d => d.date === dt);
            const currentMetric = currentDateMetrics[this.metricPropertyName] || 0;

            graphMetrics.find(m => m.metricId === this.metricPropertyId).data.push(currentMetric);
        });

        const trendGraph: Models.ITrendGraph = {
            dates: chartDates,
            metrics: graphMetrics,
            metricOneColorHexCode: '#000',
            metricTwoColorHexCode: '#737373'
        };

        return trendGraph;
    }

    generateChart(
        categories: any[],
        yAxisOptions: Highcharts.AxisOptions,
        seriesData: Highcharts.SeriesAreaOptions[],
        tooltipOptions: Highcharts.TooltipOptions
    ) {
        return new Chart({
            lang: {
                thousandsSep: ','
            },
            credits: {
                enabled: false
            },
            title: { text: ' ' },
            chart: {
                type: 'column',
                height: 80,
                animation: null,
                margin: [0, 0, 0, 15]
            },
            xAxis: {
                categories: null,
                lineWidth: 0,
                tickColor: 'none',
                minorGridLineWidth: 0,
                lineColor: 'transparent',
                labels: {
                    enabled: false
                }
            },
            yAxis: yAxisOptions,
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: tooltipOptions,
            series: seriesData
        });
    }

    private formatTooltip(chartMetrics: Models.ITrendGraph, formatKey: string) {
        return function () {
          return this.points.reduce(function (s, point) {
            let formattedMetric: string = point.y.toLocaleString();

            if (formatKey.toLowerCase().includes('percent')) {
                formattedMetric = (point.y * 100).toFixed(0) + '%';
            } else if (formatKey === Constants.formatKeys.roundToHundreth) {
                formattedMetric = point.y.toFixed(2);
            } else {
                formattedMetric = point.y.toLocaleString();
            }

            return `${s}<br/><span style="color:${point.series.options.color}">\u25CF </span>${point.series.name}: ${formattedMetric}`;
          }, `<b>${chartMetrics.dates[this.x].toDateString()}</b>`);
        };
    }

    openHelpTextClicked(): void {
        this.openHelpText.emit();
    }

}
