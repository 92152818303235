<div class="card-header" style="border-bottom: 0px">
  <div class="pull-left">
    <h5><i [ngClass]="iconClass" class="fa fa-icon pad-right"></i><b>{{title}}</b></h5>
  </div>
  <!-- <div class="pull-left">
    <h5><i [ngClass]="iconClass" class="fa fa-icon pad-right"></i><b>{{title}}</b></h5>
  </div> -->
  <div class="pull-right">
    <button mat-icon-button class="toggle-expand-button nofocus" (click)='toggleExpanded($event)'>
      <mat-icon>fullscreen</mat-icon>
    </button>
    <metric-simple-select class="actions" *ngIf="!!metricSelectConfig" [config]="metricSelectConfig" (selectionChange)="applyMetricChange($event)"></metric-simple-select>
    <ng-container *ngIf="!!helpTextKey || !!exportName">
      <button mat-button class="card-options-button" [matMenuTriggerFor]="menu"><i class="fa fa-primary fa-ellipsis-v" style="font-size: 1.4em;"></i></button>
      <mat-menu #menu="matMenu">
        <ng-container *ngIf="!!exportName">
          <button mat-menu-item style="outline: none;" (click)="printExcel(exportName, false)">Excel</button>
          <button mat-menu-item style="outline: none;" (click)="printExcel(exportName, true)">Excel - {{'Export All' | translate: locale | async}}</button>
        </ng-container>
        <button mat-menu-item style="outline: none;" (click)="openHelpText(helpTextKey, title)">{{'Help' | translate: locale | async}}</button>
      </mat-menu>
    </ng-container>
  </div>
</div>
