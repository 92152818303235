import { ComponentRef, ElementRef } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { FilterUtils } from "./filter.utils";


export class PaneInstanceManager {
    private static _activeInstances: ComponentRef<any>[] = [];
    private static _indexInView: number;

    private static _activePaneLocations = new BehaviorSubject<ComponentRef<any>[]>([]);
	static activePaneLocations$: Observable<ComponentRef<any>[]> = PaneInstanceManager._activePaneLocations.asObservable();

    public static addActiveInstance(instance: ComponentRef<any>): void {
        this._activeInstances.push(instance);
        this.setIndexInView();
        this._activePaneLocations.next(this._activeInstances);
    }

    public static getActiveInstance(path: string): ComponentRef<any> {
        return this._activeInstances.find(ai => ai.instance.item?.path === path);
    }

    public static getAllActiveInstances(): ComponentRef<any>[] {
        return this._activeInstances;
    }

    public static removeActiveInstances(instances: ComponentRef<any>[]): void {
        let i = instances.length;
		while (i--) {
			instances[i].destroy();
			instances.splice(i, 1);
        }
        this.setIndexInView();
        this._activePaneLocations.next(this._activeInstances);
    }

    public static purgeActiveInstances(): void {
        this.removeActiveInstances(this._activeInstances);
        this.setIndexInView();
        this._activePaneLocations.next(this._activeInstances);
    }

    public static removeChildItemsIfExists(path: string): void {
        const parentPath = FilterUtils.getParentPath(path);
		const existingIdx = this._activeInstances?.findIndex(i => (i.instance.item?.path === parentPath));
		if (existingIdx >= 0) {
			const existing = this._activeInstances.splice(existingIdx + 1);
			this.removeActiveInstances(existing);
		}
    }

    public static mobileScrollTo(direction: PaneInstanceScrollDirection, root: ElementRef): void {
        if (this._indexInView < 0)
            return;

        this._indexInView = direction === PaneInstanceScrollDirection.forward ? this._indexInView + 1 : this._indexInView - 1;
        const blockVal: string = PaneInstanceScrollDirection.forward ? 'end' : 'start'

        const instanceInView = this._activeInstances[this._indexInView];
        if (instanceInView) {
            setTimeout(() => {
                instanceInView.location.nativeElement.scrollIntoView({ behavior: 'smooth', block: blockVal });
            }, 1);
        } else if (this._indexInView <= 0) {
            const firstScrollable = root.nativeElement.querySelector('.faceted-filter-panel-content-container');
            if (firstScrollable)
                setTimeout(() => {
                    firstScrollable.scrollTo({ left: 0, behavior: 'smooth'});
                }, 1);
        }
    }

    public static desktopScrollTo(index: number): void {
        const instance = this._activeInstances[index];
        if (instance)
            setTimeout(() => {
                instance.location.nativeElement.scrollIntoView({ behavior: 'smooth' });
            }, 1);
    }

    private static setIndexInView(): void {
        this._indexInView = this._activeInstances.length - 1;
    }
}

export enum PaneInstanceScrollDirection {
    forward = 'forward',
    back = 'back'
}
