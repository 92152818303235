import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CreateResourceString, GetResourceStringsRequest, GetResourceStringsResponse, ResourceString } from '../../models/resource-string.models';

@Injectable({
    providedIn: 'root'
})
export class ResourceStringService {
    private api = `${environment.baseV5ApiUri}/ResourceString`;

    constructor(private http: HttpClient) { }

    getAllFrontEnd(): Observable<GetResourceStringsResponse> {
        const request: GetResourceStringsRequest = {
            label: 'FrontEnd',
            pageIndex: 0,
            pageSize: 10000,
            ascending: true
        };

        let params = new HttpParams();
        for (const [key, value] of Object.entries(request)) {
            if (value !== undefined && value !== null && value !== '') {
                params = params.append(key, value.toString());
            }
        }

        return this.http.get<GetResourceStringsResponse>(`${this.api}`, { params })
            .pipe(
                catchError((error) => {
                    console.error(error);
                    throw error;
                })
            );
    }

    getPage(request: GetResourceStringsRequest): Observable<GetResourceStringsResponse> {
        let params = new HttpParams();
        for (const [key, value] of Object.entries(request)) {
            if (value !== undefined && value !== null && value !== '') {
                params = params.append(key, value.toString());
            }
        }

        return this.http.get<GetResourceStringsResponse>(`${this.api}`, { params })
            .pipe(
                catchError((error) => {
                    console.error(error);
                    throw error;
                })
            );
    }

    getById(id: string): Observable<ResourceString> {
        return this.http.get<ResourceString>(`${this.api}/${id}`)
          .pipe(
            catchError((error) => {
              console.error(error);
              throw error;
            })
          );
      }

    create(request: CreateResourceString): Observable<any> {
        return this.http.post(`${this.api}`, request)
            .pipe(
                catchError((error) => {
                    console.error(error);
                    throw error;
                })
            );
    }

    update(id: string, request: CreateResourceString): Observable<any> {
        return this.http.put<void>(`${this.api}/${id}`, request)
        .pipe(
          catchError((error) => {
            console.error(error);
            throw error;
          })
        );
    }
}