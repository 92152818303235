<mat-card class="mat-elevation-z1" style="background-color: white; color: black;">
  <mat-card-title>
    <i class="fa pad-right" [ngClass]="iconClass"></i><b>{{title}}</b>
    <div class="pull-right ellipses-dropdown">
      <button mat-button class="card-options-button" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"><i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em;"></i></button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item style="outline: none;" (click)="openHelpTextClicked()">Help</button>
      </mat-menu>
    </div>
  </mat-card-title>
  <mat-card-content class="card-block target-card">
    <table>
      <tbody>
        <tr>
          <td>
            <b style="font-size: 1.15em;">
              {{metricCurrent | formatter : metricFormatter}}
            </b>
            <i [ngClass]="trendArrow" [ngStyle]="{'color': getTrendColor(metricMOM)}" class="pad-left" style="font-size: 1em;"></i>
          </td>
        </tr>
        <tr *ngIf="subtitle"><td class="box-label">{{subtitle}}</td></tr>
        <tr *ngIf="subtitleOne"><td class="box-label" style="font-style: italic">Prev. MTD: {{subtitleOne | formatter : metricFormatter}}</td></tr>
        <tr *ngIf="subtitleTwo"><td class="box-label" style="font-style: italic">Prev. Month: {{subtitleTwo | formatter : metricFormatter}}</td></tr>
      </tbody>
    </table>
  </mat-card-content>
  <mat-card-footer *ngIf="footerRowCount >= 1">
    <table>
      <tbody>
        <tr>
          <ng-content select="[row-1-left]"></ng-content>
          <ng-content select="[row-1-right]"></ng-content>
        </tr>
      </tbody>
    </table>
  </mat-card-footer>
  <mat-card-footer *ngIf="footerRowCount >= 2">
    <table>
      <tbody>
        <tr>
          <ng-content select="[row-2-left]"></ng-content>
          <ng-content select="[row-2-right]"></ng-content>
        </tr>
      </tbody>
    </table>
  </mat-card-footer>
</mat-card>
