

<ng-container *ngIf="localeState$ | async as localeState">
  <h2 mat-dialog-title>
    {{'Language' | translate: localeState.locale | async }}
    <i class="fa fa-close pull-right" style="cursor: pointer;" (click)="closeModal()"></i>
  </h2>
  
  <p>{{'Your language selection will be applied throughout the application.' | translate: localeState.locale | async}}</p>  
  
  <button mat-button style="margin-right: 0.5em; border: 1px solid #ddd;" [disabled]="locale === 'en'" (click)="english(localeState)">
    {{ 'English' | translate: localeState.locale | async }}
  </button>
  <button mat-button style="border: 1px solid #ddd;" [disabled]="locale === 'fr-CA'" (click)="french(localeState)">
    {{ 'French' | translate: localeState.locale | async }}
  </button>
</ng-container>
