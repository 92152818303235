import { ElementRef, Injectable, Injector } from "@angular/core";
import { Overlay, OverlayConfig, ConnectionPositionPair, FlexibleConnectedPositionStrategy, OverlayRef } from '@angular/cdk/overlay';
import { FilterPanelParams } from './types';
import { BottomPosition, RightPosition, LeftPosition, FilterPanelInputType } from './constants';
import { PortalInjector, ComponentPortal } from '@angular/cdk/portal';
import { FilterPanelRef } from './filter-panel-ref';
import { FilterPanelListComponent } from "../components/list/filter-panel-list.component";
import { FilterPanelCollectionComponent } from "../components/collection/filter-panel-collection.component";
import { FacetedFilterPanelComponent } from "../components/faceted-filter/faceted-filter-panel.component";
import { FilterPanelHostComponent } from "../components/host/default/filter-panel-host.component";
import { FacetedFilterPanelHostComponent } from "../components/host/faceted/faceted-filter-panel-host/faceted-filter-panel-host.component";

@Injectable()
export class HyFilterPanel {

    constructor(private overlay: Overlay, private injector: Injector) { }

    open({
        originElement,
        content,
        inputDataType,
        config
    }: FilterPanelParams): FilterPanelRef {
        const overlayRef = this.overlay.create(this.getOverlayConfig(originElement));

        //console.log('HyFilterPanel: open: config: ', config);

        if (!content && inputDataType) {
            content = this.setDefaultContent(inputDataType);
        } else if (!content && !inputDataType) {
          content = 'Please provide content. Either a component, TemplateRef or string';
        }

        const filterPanelRef = new FilterPanelRef(overlayRef, config, content, inputDataType);
        const injector = this.createInjector(filterPanelRef, this.injector);

        this.attachHost(overlayRef, injector, inputDataType);

        return filterPanelRef;
    }


    getOverlayConfig(originElement: ElementRef): OverlayConfig {
        return new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            positionStrategy: this.getOverlayPosition(originElement),
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            panelClass: 'mat-elevation-z8'
        })
    }


    getOverlayPosition(originElement: ElementRef): FlexibleConnectedPositionStrategy {
        const positionStrategy: FlexibleConnectedPositionStrategy =
            this.overlay.position()
                .flexibleConnectedTo(originElement)
                .withPositions(this.getPositions())
                .withPush(true);

        return positionStrategy;
    }


    getPositions(): ConnectionPositionPair[] {
        return [
            RightPosition,
            BottomPosition,
            LeftPosition
        ];
    }

    createInjector(filterPanelRef: FilterPanelRef, injector: Injector): Injector {
        const tokens = new WeakMap([[FilterPanelRef, filterPanelRef]]);

        return new PortalInjector(injector, tokens);
    }

    private setDefaultContent(inputDataType: string): any {
        switch (inputDataType) {
            case FilterPanelInputType.list:
                return FilterPanelListComponent;
            case FilterPanelInputType.collection:
                return FilterPanelCollectionComponent;
            case FilterPanelInputType.facetedFilter:
                return FacetedFilterPanelComponent;
        }
    }

    private attachHost(overlayRef: OverlayRef, injector: Injector, inputDataType: string): void {
        if (inputDataType === FilterPanelInputType.facetedFilter) {
            overlayRef.attach(new ComponentPortal(FacetedFilterPanelHostComponent, null, injector));
        }
        else {
            overlayRef.attach(new ComponentPortal(FilterPanelHostComponent, null, injector));
        }
    }
}