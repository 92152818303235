import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Subscription } from 'rxjs';
import * as ElementModels from '../../../models/report-view.models';
import * as Models from '../../../models/models-index';
import * as Highcharts from 'highcharts';
import { Enums } from '../../../enums/enums';
import * as SharedServices from '../../../services/services-index';

@Component({
  selector: 'line-chart-with-forecast',
  templateUrl: './line-chart-with-forecast.component.html',
  styleUrls: ['./line-chart-with-forecast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineChartWithForecastComponent implements Models.ElementComponent, OnInit, OnDestroy {
  @Input() dataSet: ElementModels.DataSet;
  @Input() settings: ElementModels.ElementSettings;
  @Input() selectedMetrics: string[];

  subscriptions: Subscription[] = [];
  currentChart: Chart;
  chartTitle: string = '';
  locale: string;
  componentActive: boolean = true;
  enums = Enums;
  labelTranslator = (val) => this.sharedTranslationService.getLabelTranslation(val, this.locale);

  constructor(
    private sharedTranslationService: SharedServices.SharedTranslationService,
    private localeService: SharedServices.LocaleService,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.localeService.locale$.subscribe(loc => this.locale = loc)
    );

    const metricIndexMapping = this.dataSet.columns.reduce((map, col, index) => {
      map[col.name] = { ...col, index };
      return map;
    }, {} as Record<string, typeof this.dataSet.columns[0] & { index: number }>);

    let rangeData = [];
    let actualSaleData = [];
    let forecastedSaleData = [];

    const currentDate = new Date();

    this.dataSet.rows.forEach(row => {
      const date = new Date(row[metricIndexMapping[this.settings.dimensionName].index].label).getTime();
      if (row[metricIndexMapping[this.selectedMetrics[0]].index].value !== null && date < currentDate.getTime()) {
        actualSaleData.push([date, row[metricIndexMapping[this.selectedMetrics[0]].index].value]);
      }
      if (row[metricIndexMapping[this.selectedMetrics[0]].index].value !== null && date >= currentDate.getTime()) {
        forecastedSaleData.push([date, row[metricIndexMapping[this.selectedMetrics[0]].index].value]);
        rangeData.push([
          date, 
          row[metricIndexMapping['forecastedSalesMin']?.index]?.value ?? null,
          row[metricIndexMapping['forecastedSalesMax']?.index]?.value ?? null
        ]);
      }
    });

    const dateRange = this.calculateDateRange(actualSaleData, forecastedSaleData);
    // maybe we can provide useDailyFormatter as a metric
    // or get it somehow from dataset or calculate from current date range filter
    const useDailyFormatter = this.shouldUseDailyFormatter(dateRange);

    this.currentChart = new Chart(this.generateChart(actualSaleData, forecastedSaleData, rangeData, 'datetime', useDailyFormatter ? 'daily' : 'monthly'));
  }

  ngOnDestroy(): void {
    this.componentActive = false;
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private calculateDateRange(actualSaleData, forecastedSaleData) {
    const allData = [...actualSaleData, ...forecastedSaleData];
    const dates = allData.map(data => data[0]);
    const minDate = Math.min(...dates);
    const maxDate = Math.max(...dates);
    return { minDate, maxDate };
  }

  private shouldUseDailyFormatter(dateRange) {
    const minDate = new Date(dateRange.minDate);
    const maxDate = new Date(dateRange.maxDate);
    const daysDifference = (maxDate.getTime() - minDate.getTime()) / (1000 * 3600 * 24);
    return daysDifference <= 31; // Use daily formatter if range is within one month
  }

  private generateChart(actualSaleData, forecastedSaleData, rangeData, xAxisType: string, monthOrYear: string): any {
    const firstForecastedDateIndex = actualSaleData.length;

    Highcharts.setOptions({
      colors: [this.enums.chartColors.color1, this.enums.chartColors.color2, '#000000'],
      lang: {
        decimalPoint: this.locale != 'en' ? ',' : '.',
        thousandsSep: this.locale != 'en' ? ' ' : ','
      }
    });

    return {
      xAxis: {
        type: xAxisType,
        labels: {
          format: '{value:%Y-%m-%d}' // Date format for the x-axis labels
        }
      },
      yAxis: {
        title: {
          text: this.sharedTranslationService.getLabelTranslation('Sales Volume')
        },
        labels: {
          formatter: function () {
            return '$' + this.value.toLocaleString();
          }
        }
      },
      legend: {
        enabled: false
      },
      title: {
        text: ''
      },
      tooltip: {
        shadow: false,
        shared: true,
        borderWidth: 1,
        formatter: monthOrYear === 'daily' ? this.dailyVelocityFormatter(firstForecastedDateIndex) : this.monthlyVelocityFormatter(),
        borderRadius: 0,
        valueSuffix: ''
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      series: [{
        name: this.sharedTranslationService.getLabelTranslation('Sales'),
        data: actualSaleData,
        showInLegend: false,
        zIndex: 2,
        type: 'line',
        color: 'black'
      }, {
        name: this.sharedTranslationService.getLabelTranslation('Sales'),
        data: forecastedSaleData,
        showInLegend: false,
        zIndex: 1,
        dashStyle: 'ShortDot',
        type: 'line',
        color: 'black'
      }, {
        name: this.sharedTranslationService.getLabelTranslation('Sales'),
        data: rangeData,
        showInLegend: false,
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: 'black',
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {
          enabled: false
        }
      }]
    }
  }

  private dailyVelocityFormatter(firstForecastedDateIndex: number): Highcharts.TooltipFormatterCallbackFunction {
    let dayLabel = this.labelTranslator('Day');

    return function () {
      let saleTypeLabel = this.x <= firstForecastedDateIndex ? 'Actual Sales' : 'Projected Sales';
      let toolTipTitle = `<b>${dayLabel} ${Highcharts.dateFormat('%Y-%m-%d', this.points[0].point.x)} - ${saleTypeLabel}</b>`;
      let actualSalesLabel = null;
      let lowLabel = null;
      let midLabel = null;
      let highLabel = null;

      this.points.forEach(point => {
        if (point.series.type === 'arearange') {
          lowLabel = `<br/><span style="color:${point.series.options.color}">\u25CF </span>Low: ${point.point.low.toLocaleString()}`;
          highLabel = `<br/><span style="color:${point.series.options.color}">\u25CF </span>High: ${point.point.high.toLocaleString()}`;
        } else {
          midLabel = `<br/><span style="color:${point.series.options.color}">\u25CF </span>Mid: ${point.y.toLocaleString()}`;
        }
      });

      let result = toolTipTitle
        + (actualSalesLabel ?? '')
        + (highLabel ?? '')
        + (midLabel ?? '')
        + (lowLabel ?? '');

      return result;
    }
  }

  private monthlyVelocityFormatter(): Highcharts.TooltipFormatterCallbackFunction {
    return function () {
      let saleTypeLabel = 'Actual Sales';
      let toolTipTitle = `<b>${this.points[0].key} - ${saleTypeLabel}</b>`;
      let actualSalesLabel = null;

      this.points.forEach(point => {
        actualSalesLabel = `<br/><span style="color:${point.series.options.color}">\u25CF </span>${point.series.name}: ${point.y.toLocaleString()}`;
      });

      let result = toolTipTitle + (actualSalesLabel ?? '');

      return result;
    }
  }
}
