
<mat-card [@transformMenu]="panelState" class="filter-panel-card mat-card flex flex-col mat-elevation-z2 rounded-md" [ngClass]="panelClass">

    <div class="faceted-filter-panel-header hidden md:flex">

        <div class="faceted-filter-panel-header-content">

            <span class="font-inter text-xl font-medium" style="margin: 0">
                {{title | translate | async}}
            </span>

            <div class="hidden md:flex w-96 h-9 relative">

                <label for="search" class="sr-only">{{'Search' | translate | async}}</label>
                <div [innerHtml]="searchIcon | safeSvg" class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-300"></div>
                <input [formControl]="searchField" type="text" class="rounded-md focus:ring-0 block w-full h-full border-0 pl-10 text-gray-900 font-inter font-medium text-sm placeholder-gray-300" placeholder="{{'Search' | translate | async}}">
                <div *ngIf="searchField.value && searchField.value.length" [innerHtml]="smallCloseIcon | safeSvg" class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-300" (click)="clearSearch()"></div>

            </div>

        </div>

    </div>

    <div class="faceted-filter-panel-header flex md:hidden">

        <div class="faceted-filter-panel-header-content">

            <div class="w-8 h-full flex items-center cursor-pointer"(click)="onPaneNavigationClick(scrollDirection.back)">
                <span [innerHtml]="backIcon | safeSvg"></span>
            </div>

            <div class="flex-1 flex items-center justify-center text-xl h-full">{{'Filter' | translate | async}}</div>

            <button class="w-8 h-full flex items-center cursor-pointer" (click)="startMobileSearch();"><span [innerHtml]="searchIcon | safeSvg"></span></button>

        </div>

    </div>

    <mat-card-content #rootContent class="faceted-filter-panel-content p-0">

        <div class="w-full" [ngStyle]="{'height': mobileSearchEnabled ? 'calc(100% - 64px)' : '100%'}">

            <div *ngIf="mobileSearchEnabled" class="flex md:hidden w-full h-16 p-1 relative border-b border-gray-200">

                <label for="search" class="sr-only">{{'Search' | translate | async}}</label>
                <div [innerHtml]="searchIcon | safeSvg" class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-300"></div>
                <input [formControl]="searchField" type="text" class="rounded-md focus:ring-0 block w-full h-full border-0 pl-10 text-gray-900 font-inter font-medium placeholder-gray-300" placeholder="{{'Search' | translate | async}}">
                <div [innerHtml]="mobileCloseIcon | safeSvg" class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-300" (click)="clearSearch()"></div>

            </div>

            <ng-container [ngSwitch]="renderMethod">

                <div *ngSwitchCase="renderMethods.text" [innerHtml]="content"></div>

                <ng-container *ngSwitchCase="renderMethods.template">
                    <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="renderMethods.component">
                    <ng-container *ngComponentOutlet="content"></ng-container>
                </ng-container>

            </ng-container>

        </div>

    </mat-card-content>

    <mat-card-footer class="hidden md:flex m-0 h-[100px] bg-white">

        <div class="py-0 px-6-dos w-full h-full flex flex-col items-center justify-items-center border-gray-200 border-t m-0">

            <div *ngIf="activeLocations$ | async as items;" class="w-full h-8 mt-1 flex items-center justify-start text-base text-gray-300 hover:text-gray-500 cursor-pointer">

                <ng-container *ngFor="let item of items let idx = index; let first = first; let last = last" >

                    <div *ngIf="item" class="flex items-center hover:text-primary-600 cursor-pointer hover:underline"
                            (click)="onBreadcrumbItemClick(item)">
                            <span class="mx-2">{{item.instance.replaying === true && item.instance.replayItem?.item
                                                                                   ? item.instance.replayItem.item.label
                                                                                   : item.instance.item.label}}
                            </span>
                            <span *ngIf="!last" [innerHtml]="chevronIcon | safeSvg"></span>
                    </div>

                </ng-container>

            </div>

            <div class="w-full h-[52px] flex items-center justify-end">
                <button class="button mr-6" (click)="applySelected()">
                    <span>{{'Cancel' | translate | async}}</span>
                </button>

                <button [disabled]="facetedFilterApplyButtonDisabled" class="button-primary" (click)="facetedFilterApplySelected()">
                    <span>{{'Apply' | translate | async }}</span>
                </button>
            </div>

        </div>

    </mat-card-footer>

    <mat-card-footer class="flex md:hidden m-0 h-16 border-t border-gray-200 bg-white">

        <div class="w-full h-full flex items-center justify-center">
            <button class="button mr-6" (click)="applySelected()">
                <span>{{'Cancel' | translate | async }}</span>
            </button>

            <button [disabled]="facetedFilterApplyButtonDisabled" class="button-primary" (click)="facetedFilterApplySelected()">
                <span>{{'Apply' | translate | async }}</span>
            </button>
        </div>

    </mat-card-footer>

</mat-card>
