<div *ngIf="currentCard" style="min-width: 800px;">
  <div class="row labels">
      <span class="col-md-5 col-xl-5"></span>
      <span class="col-md-2 col-xl-2"><b>{{currentCard.metricOneName}}</b></span>
      <span class="col-md-2 col-xl-2"><b>{{currentCard.metricTwoName}}</b></span>
      <span class="col-md-3 col-xl-3"><b>{{currentCard.metricThreeName}}</b></span>
      <mat-divider></mat-divider>
  </div>
  <div class="row" *ngFor="let cc of currentCard.barValues">
      <div class="col-12"><b>{{cc.dimensionValueName}}</b></div>
      <div class="col-md-5 col-xl-5">
          <div class="border">
              <table [ngStyle]="{ 'width': cc.barWidth }"
                      [matTooltip]="cc.tooltipText"
                      [matTooltipPosition]="'above'"
              >
              <tr>
                  <td [ngStyle]="cc.barStyleOne"></td>
                  <td [ngStyle]="cc.barStyleTwo"></td>
              </tr>
              </table>
          </div>
      </div>
      <div class="col-md-2 col-xl-2">{{cc.metricOneDisplay}} | <span [ngClass]="getTrendColorClass(cc.metricOneTrendValue)"><b>{{ cc.metricOneTrendValue | percent:'.1' }}</b></span></div>
      <div class="col-md-2 col-xl-2">{{cc.metricTwoDisplay}} | <span [ngClass]="getTrendColorClass(cc.metricTwoTrendValue)"><b>{{ cc.metricTwoTrendValue | percent:'.1' }}</b></span></div>
      <div class="col-md-3 col-xl-2">{{ cc.metricThreeDisplay }} | <span [ngClass]="getTrendColorClass(cc.metricThreeTrendValue)"><b>{{ cc.metricThreeTrendValue | percent:'.1' }}</b></span><div>
  </div>
</div>

