import { Component, OnInit, OnDestroy, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import * as Models from '../../models/models-index';

@Component({
  selector: 'metric-complex-select',
  templateUrl: './metric-complex-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MetricComplexSelectComponent implements OnDestroy, OnInit {

  @Input() config: Models.IMetricComplexSelectConfig;
  @Output() selectionChange = new EventEmitter<Models.IMultiLevelTrendGraphMetricSelectedPropertyConfig>();

  metricConfig: Models.IMultiLevelTrendGraphMetricSelectedPropertyConfig;
  selectedParentLabel: string;
  selectedChildLabel: string;

  constructor() {
  }

  ngOnInit(): void {
    this.selectedParentLabel = this.config.parentOptions.find(c => c.id === this.config.defaultParentId).displayName;
    this.selectedChildLabel = this.config.childOptions.find(c => c.id === this.config.defaultChildId).displayName;
    this.emitSelectedParentMetric(this.config.defaultParentId);
  }

  ngOnDestroy(): void {

  }

  emitSelectedParentMetric(parentMetricId: number) {
    const selectedParentMetric = this.config.parentOptions.find(o => o.id === parentMetricId);
    const childDefaultOptions = this.config.childOptions.find(c => c.id === this.config.defaultChildId);

    this.selectedParentLabel = selectedParentMetric.displayName;
    this.selectedChildLabel = childDefaultOptions.displayName;

    const selectedMetrics = {
      parentId: parentMetricId,
      childId: childDefaultOptions.id,
      parentDisplayName: selectedParentMetric.displayName,
      childDisplayName: childDefaultOptions.displayName,
      propertyName: selectedParentMetric.propertyName,
      metricFormatKey: selectedParentMetric.metricFormatKey
    };

    this.selectionChange.emit(selectedMetrics);
  }

  emitSelectedChildMetric(parentMetricId: number, childMetricId: number) {
    const selectedParentMetric = this.config.parentOptions.find(o => o.id === parentMetricId);
    const selectedChildMetric = this.config.childOptions.find(o => o.id === childMetricId);

    this.selectedParentLabel = selectedParentMetric.displayName;
    this.selectedChildLabel = selectedChildMetric.displayName;

    const selectedMetrics = {
      parentId: parentMetricId,
      childId: childMetricId,
      parentDisplayName: selectedParentMetric.displayName,
      childDisplayName: selectedChildMetric.displayName,
      propertyName: selectedParentMetric.propertyName,
      metricFormatKey: selectedParentMetric.metricFormatKey
    };

    this.selectionChange.emit(selectedMetrics);
  }
}
