<mat-card class="mat-elevation-z1 line-chart-billboard">
    <mat-card-title>
      <b>{{title}}</b>      
      <div class="pull-right ellipses-dropdown">
        <button mat-button class="card-options-button" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"><i class="fa fa-ellipsis-v" style="font-size: 1.4em;"></i></button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item style="outline: none;" (click)="openHelpTextClicked()">Help</button>
        </mat-menu>
      </div>
    </mat-card-title>
    <mat-card-content class="card-block target-card">
      <div class="row">
        <div class="col-md-8 col-sm-6 inventory-chart">
            <div [chart]="currentChart"></div>
        </div>
        <div class="col-md-4 col-sm-6 inventory-metric-container" style="padding-top: 18px;">
          <span class="inventory-chart-metric">{{metricCurrentMonth | formatter : metricFormatKey}}</span>
          <br>
          <span class="inventory-chart-subtitle">{{subtitle}}</span>
        </div>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <table>
        <tbody>
          <tr>
            <td>
               <div class="pull-left"><b>Previous Month / {{metricPreviousMonth | formatter : metricFormatKey}} <i [ngClass]="trendArrowIconClass"></i> </b></div>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-footer>    
  </mat-card>
  