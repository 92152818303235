

import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import * as constants from '../../constants/constants';
import { DatePipe } from '@angular/common';
import * as Models from '../../../_shared/models/models-index';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormattingService } from '../../services/services-index';

@Component({
  selector: 'sd-single-axis-area-chart',
  templateUrl: './single-axis-area-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SingleAxisAreaChartComponent implements OnInit, OnDestroy {
    @Input() chartMetrics: Observable<{ settings: Models.ISingleAxisTrendChartSettings, data: any[], locale: string}>;
    currentChart$: Observable<any>;
    // chartSettings: Models.ISingleAxisTrendChartSettings;
    subscriptions: Subscription[] = [];
    locale: string;
    constructor(
        private datePipe: DatePipe,
        private formattingService: FormattingService
    ) {

    }
    ngOnDestroy(): void {
      this.subscriptions.forEach(sub => sub.unsubscribe);
    }
    ngOnInit() {

      this.currentChart$ =
        this.chartMetrics.pipe(
            map(update => {
                return this.getTrendChart(update.data, update.settings, update.locale);
            })
        );
    }

    getMetricFormatter(formatKey: string, loc: string) {
      if (formatKey === constants.formatKeys.percentageTwoDecimals) {
          return (val) => this.formattingService.percentPipeTwoDecimals(val, loc);
      } else if (formatKey === constants.formatKeys.minutesTimeStringFromSeconds) {
          return (val) => this.formattingService.minutesTimeStringFromSeconds(val);
      } else if (formatKey === constants.formatKeys.roundToHundreth) {
          return (val) => this.formattingService.roundToHundredth(val, loc);
      } else {
          return (val) => this.formattingService.localeString(val, loc);
      }
    }

    getSeriesOptions(chartSettings, chartMetrics) {
      const seriesOptions = []
      // none of this is so great - should be able to iterate the avalable series from the config
      seriesOptions.push(
      <Highcharts.SeriesAreaOptions> {
        name: chartSettings.seriesOneDisplayName,
        data: chartMetrics.map(m => m[chartSettings.seriesOnePropertyName])
      },
      <Highcharts.SeriesAreaOptions>{
        name: chartSettings.seriesTwoDisplayName,
        data: chartMetrics.map(m => m[chartSettings.seriesTwoPropertyName])
      })

      if(!!chartSettings.seriesThreePropertyName) {
        seriesOptions.push(
          <Highcharts.SeriesAreaOptions>{
            name: chartSettings.seriesThreeDisplayName,
            data: chartMetrics.map(m => m[chartSettings.seriesThreePropertyName])
        })
      }

      return seriesOptions;
    }


    private getTrendChart(chartMetrics, chartSettings, locale) {

      const metricOneFormatter = this.getMetricFormatter(chartSettings.seriesOneFormatKey, locale);
      const metricTwoFormatter = this.getMetricFormatter(chartSettings.seriesTwoFormatKey, locale);
      const metricThreeFormatter = this.getMetricFormatter(chartSettings.seriesThreeFormatKey, locale);
      const rateformatter = (val) => this.formattingService.percentPipeTwoDecimals(val, locale);
      const seriesOptions = this.getSeriesOptions(chartSettings, chartMetrics);

      Highcharts.setOptions({
        // lang: {
        //   thousandsSep: ','
        // }
      });
      return new Chart({
        chart: {
          type: 'area',
          height: 363,
        },
        credits: { enabled: false },
        colors: chartSettings.chartColors,
        title: { text: chartSettings.chartTitle },
        xAxis: {
          allowDecimals: false,
          categories: chartMetrics.map(m => this.datePipe.transform(m[chartSettings.xAxisPropertyName], 'MMM dd', null, locale))
        },
        yAxis: {
          title: { text: chartSettings.yAxisTitle },
          labels: {
            formatter: function () {
              return (this.value / 1000).toLocaleString() + 'k';
            }
          }
        },
        tooltip: {
          shared: true,
          // pointFormat: '<b>{series.name}:</b> {point.y:,.0f}<br/>',
          formatter: function () {
            let s = '';

            for (let i = 0; i < this.points.length; i++) {

                const myPoint = this.points[i];
                const metricFormatter = myPoint.series.name === chartSettings.seriesOneDisplayName
                  ? metricOneFormatter
                  : myPoint.series.name === chartSettings.seriesTwoDisplayName
                  ? metricTwoFormatter
                  : metricThreeFormatter

                s += '<br/>' + myPoint.series.name + ': ';
                s += metricFormatter(myPoint.y);

              }

              if (!!chartSettings.showRateMetric) {
                const rateMetric = this.points[0].y !== 0 ? this.points[1].y / this.points[0].y : 0;

                s += `<br/>${chartSettings.rateMetricDisplayName}: ` + rateformatter(rateMetric);
            }

            return s;
          },
        },
        plotOptions: {
          area: {
            marker: {
              enabled: true,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            }
          }
        },
        series: seriesOptions
      });
    }
}

